<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- VERTICAL LAYOUT 2 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-2'">
    <vertical-layout-2></vertical-layout-2>
</ng-container>
<!-- / VERTICAL LAYOUT 2 -->

<!-- VERTICAL LAYOUT 3 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-3'">
    <vertical-layout-3></vertical-layout-3>
</ng-container>
<!-- / VERTICAL LAYOUT 3 -->

<!-- HORIZONTAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
    <horizontal-layout-1></horizontal-layout-1>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->

<!-- THEME OPTIONS PANEL -->
<!--<button mat-icon-button class="warn mat-elevation-z2 theme-options-button"-->
<!--        [ngClass]="{'right-side-panel': fuseConfig.layout.sidepanel.position === 'right',-->
<!--                    'side-panel-hidden': fuseConfig.layout.sidepanel.hidden === true}"-->
<!--        (click)="toggleSidebarOpen('themeOptionsPanel')">-->
<!--    <mat-icon>settings</mat-icon>-->
<!--</button>-->
<div *ngIf="loading" style="position: absolute;top:0px;bottom:0px;left:0px;right:0px;background: rgba(0,0,0,0.9);z-index: 1;">
    <mat-progress-spinner style="position: absolute;top: calc(50vh - 48px);left: calc(50vw - 20px);" color="accent" mode="indeterminate" [diameter]="40"></mat-progress-spinner>
    <div style="position: absolute;
    top: calc(50vh + 15px);
    left: calc(50vw - 117px);
    width: 245px;
    z-index: 999999999999999;
    color: #dee3ea;
    font-size: 16px;
    font-weight: 600;
    text-shadow: 1px 2px 10px #070606;">{{saving_oauth_data}}</div>
    <button *ngIf="showTwitterButton" (click)="signInWithTwitterOld()" mat-raised-button class="instagram" style="position: absolute;top: calc(50vh + 80px);left: calc(50vw - 95px);z-index: 999999999999999;">
        <i class="fab fa-x-twitter" style="font-size: 17px;"></i>
        <span>Connect with Twitter</span>
    </button>
</div>

<fuse-sidebar name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
    <fuse-theme-options></fuse-theme-options>
</fuse-sidebar>
<!-- / THEME OPTIONS PANEL -->
<ng-snotify></ng-snotify>
<div #emojiBlock style="position: absolute;z-index: 9999;" [ngStyle]="{top: emojisTop + 'px', left: emojisLeft + 'px', transform: 'translate(' + emojisTranslateX + 'px, 0px)'}">
    <emoji-mart color="#2196f3" set="apple" (emojiSelect)="emojiSelection($event)"></emoji-mart>
</div>