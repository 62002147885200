<div class="tab-content p-24">
    <div *ngIf="loading" style="position:absolute;width: 100%;height: 100%;background: rgba(255,255,255,0.6);">
        <mat-spinner style="top: 50% !important;left: 50% !important;position: absolute;color: #039be5 !important" diameter="50"></mat-spinner>
    </div>
    <div class="password-box w-100-p" style="display: flex;flex-direction: row" >
        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p pr-4">
            <mat-label>Action Type</mat-label>
            <mat-select multiple [(ngModel)]="actionType" (ngModelChange)="getActionLogs(userId)">
                <mat-option value="posting_new">New Posts Posting</mat-option>
                <mat-option value="posting_update">Update Scheduled Posts</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p pl-4">
            <mat-label>From Date</mat-label>
            <input [(ngModel)]="fromDate" matInput [matDatepicker]="picker" (ngModelChange)="getActionLogs(userId)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p pl-4">
            <mat-label>Choose a date</mat-label>
            <input [(ngModel)]="toDate" matInput [matDatepicker]="picker2" (ngModelChange)="getActionLogs(userId)">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef style="width: 300px;"> Action Date </th>
            <td mat-cell *matCellDef="let element" style="width: 300px;"> {{element.created_at}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="action_type">
            <th mat-header-cell *matHeaderCellDef style="width: 350px;"> Action Type </th>
            <td mat-cell *matCellDef="let element" style="width: 350px;"> {{element.action_type_message}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="data_request">
            <th mat-header-cell *matHeaderCellDef> Request Data (info) </th>
            <td mat-cell *matCellDef="let element" style="padding: 12px 0px;">
                <ng-container *ngIf="element.action_type === 'posting_new' || element.action_type === 'posting_update'">
                    <div><strong>Posts Data</strong></div>
                    <div>Text: {{element.data_request.data[0].text}}</div>
                    <div><strong>Networks</strong></div>
                    <div style="margin-top: 5px;">
                        <span style="background: #ccc;padding: 3px 10px;margin: 2px;border-radius: 12px;" *ngFor="let network of element.data_request.data">
                            <i style="margin: 0 !important;font-size: 16px;" *ngIf="network.network.network_type.indexOf('FACEBOOK') !== -1" class="fab fa-facebook-square mr-16"></i>
                            <i style="margin: 0 !important;font-size: 16px;" *ngIf="network.network.network_type.indexOf('INSTAGRAM') !== -1" class="fab fa-instagram-square mr-16"></i>
                            <i style="margin: 0 !important;font-size: 16px;" *ngIf="network.network.network_type.indexOf('LINKEDIN') !== -1" class="fab fa-linkedin mr-16"></i>
                            <i style="margin: 0 !important;font-size: 16px;" *ngIf="network.network.network_type.indexOf('TWITTER') !== -1"  class="fab fa-square-x-twitter"></i>
                            {{network.network.name}}
                        </span>
                    </div>
                </ng-container>
            </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="data_error">
            <th mat-header-cell *matHeaderCellDef  style="width: 350px;"> Request Errors </th>
            <td mat-cell *matCellDef="let element" style="width: 350px;padding: 12px 0px;">
                <ng-container *ngIf="element.action_type === 'posting_new' || element.action_type === 'posting_update'">
                    <div style="">
                        <div style="font-size: 11px;line-height: 13px;margin-top: 5px;" *ngFor="let error of element.data_error">
                            {{error}}
                        </div>
                    </div>
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
