import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable, Observer} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LocationsService} from '../../locations/locations.service';
import {debounce, debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {languages} from '../languages';
import {LinkedinService} from './linkedin.service';

@Component({
  selector: 'app-linkedin',
  templateUrl: './linkedin.component.html',
  styleUrls: ['./linkedin.component.scss']
})
export class LinkedinComponent implements OnInit {
    form: FormGroup;
    selectedList: {size: Array<any>, languages: Array<any>, locations: Array<any>, functions: Array<any>, industries: Array<any>, seniorities: Array<any>}
    = {size: [], languages: [], locations: [], functions: [], industries: [], seniorities: []};
    filteredLocationsData: Observable<any> = new Observable<any>((obs: Observer<any>) => {
        obs.next([]);
        obs.complete();
    });
    filteredFunctionsData: Observable<any> = new Observable<any>((obs: Observer<any>) => {
        obs.next([]);
        obs.complete();
    });
    filteredIndustriesData: Observable<any> = new Observable<any>((obs: Observer<any>) => {
        obs.next([]);
        obs.complete();
    });
    filteredSenioritiesData: Observable<any> = new Observable<any>((obs: Observer<any>) => {
        obs.next([]);
        obs.complete();
    });
    inputLoaders = {languages:  false, locations: false, functions: false, industries: false, seniorities: false};
    token: string = null;
    languages = languages;

    constructor(
        public matDialogRef: MatDialogRef<LinkedinComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private fb: FormBuilder,
        private locationsService: LocationsService,
        private linkedinService: LinkedinService
    ) {
        this.token = _data.token;
        if (typeof this._data.size !== 'undefined') {
            this.selectedList.size = this._data.size;
        }
        if (typeof this._data.languages !== 'undefined') {
            this.selectedList.languages = this._data.languages;
        }
        if (typeof this._data.locations !== 'undefined') {
            this.selectedList.locations = this._data.locations;
        }
        if (typeof this._data.functions !== 'undefined') {
            this.selectedList.functions = this._data.functions;
        }
        if (typeof this._data.industries !== 'undefined') {
            this.selectedList.industries = this._data.industries;
        }
        if (typeof this._data.seniorities !== 'undefined') {
            this.selectedList.seniorities = this._data.seniorities;
        }
        this.form = this.fb.group({
            size: [(typeof this._data.size !== 'undefined') ? this._data.size : ''],
            languages: [''],
            locations: [''],
            functions: [''],
            industries: [''],
            seniorities: ['']
        });
    }

    ngOnInit(): void {
        this.form.get('languages').valueChanges.subscribe(
            value => {
                if (value !== null && value !== '' && typeof value === 'string') {
                    this.inputLoaders.languages = true;
                    this.languages = languages.filter(item => item.English.indexOf(value) !== -1);
                    this.inputLoaders.languages = false;
                }
            }
        );
        this.form.get('locations').valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(
            value => {
                if (value !== null && value !== '' && typeof value === 'string') {
                    this.inputLoaders.locations = true;
                    this.filteredLocationsData = this.locationsService.getLinkedinLocations(this.token, value).pipe(
                        map(response => response.locations),
                        tap(response => {
                            this.inputLoaders.locations = false;
                            return response;
                        })
                    );
                }
            }
        );
        this.form.get('functions').valueChanges.subscribe(
            value => {
                if (value !== null && value !== '' && typeof value === 'string') {
                    this.inputLoaders.functions = true;
                    this.filteredFunctionsData = this.linkedinService.getLinkedinFunctions(this.token).pipe(
                        map(response => response.elements.filter(item => item.name.localized.en_US.toLowerCase().indexOf(value.toLowerCase()) !== -1)),
                        tap(response => {
                            this.inputLoaders.functions = false;
                            return response;
                        })
                    );
                }
            }
        );
        this.form.get('industries').valueChanges.subscribe(
            value => {
                if (value !== null && value !== '' && typeof value === 'string') {
                    this.inputLoaders.industries = true;
                    this.filteredIndustriesData = this.linkedinService.getLinkedinIndustries(this.token).pipe(
                        map(response => response.elements.filter(item => item.name.localized.en_US.toLowerCase().indexOf(value.toLowerCase()) !== -1)),
                        tap(response => {
                            this.inputLoaders.industries = false;
                            return response;
                        })
                    );
                }
            }
        );
        this.form.get('seniorities').valueChanges.subscribe(
            value => {
                if (value !== null && value !== '' && typeof value === 'string') {
                    this.inputLoaders.seniorities = true;
                    this.filteredSenioritiesData = this.linkedinService.getLinkedinSeniorities(this.token).pipe(
                        map(response => response.elements.filter(item => item.name.localized.en_US.toLowerCase().indexOf(value.toLowerCase()) !== -1)),
                        tap(response => {
                            this.inputLoaders.seniorities = false;
                            return response;
                        })
                    );
                }
            }
        );
    }

    remove(value: any, type: string = 'locations'): void {
        const index = this.selectedList[type].findIndex(item => item.id === value.id);
        if (index >= 0) {
            this.selectedList[type].splice(index, 1);
        }
        this.form.get(type).setValue('');
    }

    selected(event: MatAutocompleteSelectedEvent, input: any, type: string = 'locations'): void {
        this.selectedList[type].push(event.option.value);
        this.filteredIndustriesData = this.filteredSenioritiesData = this.filteredFunctionsData = this.filteredLocationsData = new Observable<any>((obs: Observer<any>) => {
            obs.next([]);
            obs.complete();
        });
        input.value = '';
        this.form.get(type).setValue('');
    }

}
