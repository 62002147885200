<div id="team" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/apps/social-media-management/teams'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div class="team-image mr-8 mr-sm-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    <mat-icon>folder_shared</mat-icon>
                    <!--<img *ngIf="!team.images[0]" [src]="'assets/images/ecommerce/team-image-placeholder.png'">-->
                </div>

                <div fxLayout="column" fxLayoutAlign="start start"
                     [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="pageType ==='edit'">
                        {{team.name}}
                    </div>
                    <div class="h2" *ngIf="pageType ==='new'">
                        New Team
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Team Detail</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button
                    class="save-team-button"
                    [disabled]="teamForm.invalid"
                    *ngIf="pageType ==='new'" (click)="addTeam()">
                <span>ADD</span>
            </button>

            <button mat-raised-button
                    class="save-team-button"
                    [disabled]="teamForm.invalid"
                    *ngIf="pageType ==='edit'" (click)="saveTeam()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="teamForm" [formGroup]="teamForm" class="team w-100-p" fxLayout="column" fxFlex>

                    <mat-tab-group>

                        <mat-tab label="Basic Info">

                            <div class="tab-content p-24" fusePerfectScrollbar>

                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Team Name</mat-label>
                                    <input matInput placeholder="Team Name"
                                           name="name"
                                           formControlName="name"
                                           required>
                                </mat-form-field>

                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Team Description</mat-label>
                                    <textarea matInput placeholder="Team Description"
                                              name="description"
                                              formControlName="description"
                                              rows="3">
                                    </textarea>
                                </mat-form-field>

                                <a (click)="selectAllNetworks()" mat-stroked-button>
                                    <mat-icon>check_box</mat-icon><span>SELECT ALL NETWORKS</span>
                                </a>
                                <a (click)="unSelectAllNetworks()" mat-stroked-button>
                                    <mat-icon>check_box_outline_blank</mat-icon><span>UNSELECT ALL NETWORKS</span>
                                </a>
                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Social Networks</mat-label>
                                    <mat-chip-list #networkList formControlName="networks">
                                        <mat-chip style="line-height: 20px;" *ngFor="let network of selectedNetworks"
                                                  [selectable]="true"
                                                  [value]="network.id"
                                                  [removable]="true" (removed)="removeNetwork(network)">
                                            <div style="padding: 5px" class="avatar-wrapper" fxFlex="0 1 auto" fxLayoutAlign="center center">
                                                <img class="avatar" [src]="(network.picture !== '') ? network.picture : 'assets/images/agency.png'"
                                                     alt="{{network.name}}"/>
                                                <mat-icon style="color: #0A66C2" *ngIf="network.network_type === 'FACEBOOK'" class="s-16 status">thumb_up</mat-icon>
                                                <mat-icon style="color: hotpink" *ngIf="network.network_type === 'INSTAGRAM'" class="s-16 status">favorite</mat-icon>
                                                <i style="color: #00A0DC;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'LINKEDIN'" class="s-16 status fab fa-linkedin"></i>
                                            </div>
                                            {{network.network_type === 'INSTAGRAM' ? '@' + network.name : network.name}}
                                            <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
                                        </mat-chip>
                                        <input  style="height: 100px" #networkInput [matChipInputFor]="networkList"
                                               [formControl]="networkCtrl"
                                               [matAutocomplete]="autoNet"
                                               [matChipInputAddOnBlur]="false"
                                               (matChipInputTokenEnd)="addNetwork($event)" placeholder="Type a network's name..." />
                                    </mat-chip-list>
                                    <mat-autocomplete #autoNet="matAutocomplete" (optionSelected)="selectedNetwork($event)">
                                        <mat-option *ngFor="let network of filteredNetworks | async" [value]="network">
                                            <div style="padding: 5px" class="avatar-wrapper" fxFlex="0 1 auto" fxLayoutAlign="center center">
                                                <img class="avatar" [src]="(network.picture !== '') ? network.picture : 'assets/images/agency.png'"
                                                     alt="{{network.name}}"/>
                                                <mat-icon style="color: #0A66C2" *ngIf="network.network_type === 'FACEBOOK'" class="s-16 status">thumb_up</mat-icon>
                                                <mat-icon style="color: hotpink" *ngIf="network.network_type === 'INSTAGRAM'" class="s-16 status">favorite</mat-icon>
                                                <i style="color: #00A0DC;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'LINKEDIN'" class="s-16 status fab fa-linkedin"></i>
                                            </div>
                                            {{network.network_type === 'INSTAGRAM' ? '@' + network.name : network.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-spinner diameter="20" class="input-loader" *ngIf="inputLoader"></mat-spinner>
                                </mat-form-field>

                                <a (click)="selectAllMembers()" mat-stroked-button>
                                    <mat-icon>check_box</mat-icon><span>SELECT ALL MEMBERS</span>
                                </a>
                                <a (click)="unSelectAllMembers()" mat-stroked-button>
                                    <mat-icon>check_box_outline_blank</mat-icon><span>UNSELECT ALL MEMBERS</span>
                                </a>
                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Team Members</mat-label>
                                    <mat-chip-list #memberList formControlName="members">
                                        <mat-chip *ngFor="let member of selectedMembers"
                                                  (removed)="removeMember(member)"
                                                  [selectable]="selectable"
                                                  [removable]="removable"
                                        >
                                            {{member.name}}
                                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                        </mat-chip>
                                        <input  style="height: 100px" #memberInput [matChipInputFor]="memberList"
                                               (matChipInputTokenEnd)="addMember($event)"
                                               [matAutocomplete]="autoMember"
                                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                               [matChipInputAddOnBlur]="addOnBlur"
                                               [formControl]="memberCtrl"
                                               placeholder="Type a member's name..."
                                        />
                                    </mat-chip-list>
                                    <mat-autocomplete #autoMember="matAutocomplete" (optionSelected)="selectedMember($event)">
                                        <mat-option *ngFor="let member of filteredMembers | async" [value]="member">
                                            <mat-icon *ngIf="!member.profile_photo">streetview</mat-icon>
                                            {{ member.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                            </div>

                        </mat-tab>

                        <!--<mat-tab label="Social Networks">-->

                            <!--<div class="tab-content p-24" fusePerfectScrollbar>-->
                                <!--<div fxLayout="row wrap" fxLayoutAlign="start start">-->
                                    <!---->
                                <!--</div>-->
                            <!--</div>-->

                        <!--</mat-tab>-->

                        <!--<mat-tab label="Team Members">-->

                            <!--<div class="tab-content p-24" fusePerfectScrollbar>-->
                                <!--<div fxLayout="row wrap" fxLayoutAlign="start start">-->
                                    <!---->
                                <!--</div>-->
                            <!--</div>-->

                        <!--</mat-tab>-->

                        <!--<mat-tab label="Pricing">-->

                            <!--<div class="tab-content p-24" fusePerfectScrollbar>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>Tax Excluded Price</mat-label>-->
                                    <!--<input matInput placeholder="Tax Excluded Price"-->
                                           <!--name="priceTaxExcl"-->
                                           <!--formControlName="priceTaxExcl"-->
                                           <!--type="number">-->
                                    <!--<span matPrefix>$&nbsp;</span>-->
                                <!--</mat-form-field>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>Tax Included Price</mat-label>-->
                                    <!--<input matInput placeholder="Tax Included Price"-->
                                           <!--name="priceTaxIncl"-->
                                           <!--formControlName="priceTaxIncl"-->
                                           <!--type="number">-->
                                    <!--<span matPrefix>$&nbsp;</span>-->
                                <!--</mat-form-field>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>Tax Rate</mat-label>-->
                                    <!--<input matInput placeholder="Tax Rate"-->
                                           <!--name="taxRate"-->
                                           <!--formControlName="taxRate"-->
                                           <!--type="number">-->
                                    <!--<span matPrefix>&#37;&nbsp;</span>-->
                                <!--</mat-form-field>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>Compared Price</mat-label>-->
                                    <!--<input matInput placeholder="Compared Price"-->
                                           <!--name="comparedPrice"-->
                                           <!--formControlName="comparedPrice"-->
                                           <!--type="number">-->
                                    <!--<span matPrefix>$&nbsp;</span>-->
                                    <!--<mat-hint align="start">Add a compare price to show next to the real price-->
                                    <!--</mat-hint>-->
                                <!--</mat-form-field>-->

                            <!--</div>-->
                        <!--</mat-tab>-->

                        <!--<mat-tab label="Inventory">-->

                            <!--<div class="tab-content p-24" fusePerfectScrollbar>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>SKU</mat-label>-->
                                    <!--<input matInput placeholder="SKU"-->
                                           <!--name="sku"-->
                                           <!--formControlName="sku">-->
                                <!--</mat-form-field>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>Quantity</mat-label>-->
                                    <!--<input matInput placeholder="Quantity"-->
                                           <!--name="quantity"-->
                                           <!--formControlName="quantity"-->
                                           <!--type="number">-->
                                <!--</mat-form-field>-->

                            <!--</div>-->

                        <!--</mat-tab>-->

                        <!--<mat-tab label="Shipping">-->

                            <!--<div class="tab-content p-24" fusePerfectScrollbar fxLayout="column">-->

                                <!--<div fxLayout="row" fxLayoutAlign="space-between">-->

                                    <!--<mat-form-field appearance="outline" floatLabel="always" fxFlex="30">-->
                                        <!--<mat-label>Width</mat-label>-->
                                        <!--<input matInput placeholder="Width"-->
                                               <!--name="Width"-->
                                               <!--formControlName="width">-->
                                        <!--<span matSuffix>cm</span>-->
                                    <!--</mat-form-field>-->

                                    <!--<mat-form-field appearance="outline" floatLabel="always" fxFlex="30">-->
                                        <!--<mat-label>Height</mat-label>-->
                                        <!--<input matInput placeholder="Height"-->
                                               <!--name="Height"-->
                                               <!--formControlName="height">-->
                                        <!--<span matSuffix>cm</span>-->
                                    <!--</mat-form-field>-->

                                    <!--<mat-form-field appearance="outline" floatLabel="always" fxFlex="30">-->
                                        <!--<mat-label>Depth</mat-label>-->
                                        <!--<input matInput placeholder="Depth"-->
                                               <!--name="Depth"-->
                                               <!--formControlName="depth">-->
                                        <!--<span matSuffix>cm</span>-->
                                    <!--</mat-form-field>-->

                                <!--</div>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>Weight</mat-label>-->
                                    <!--<input matInput placeholder="Weight"-->
                                           <!--name="Weight"-->
                                           <!--formControlName="weight">-->
                                    <!--<span matSuffix>kg</span>-->
                                <!--</mat-form-field>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>Extra Shipping Fee</mat-label>-->
                                    <!--<input matInput placeholder="Extra Shipping Fee"-->
                                           <!--name="extraShippingFee"-->
                                           <!--formControlName="extraShippingFee"-->
                                           <!--type="number">-->
                                    <!--<span matPrefix>$&nbsp;</span>-->
                                <!--</mat-form-field>-->

                            <!--</div>-->

                        <!--</mat-tab>-->

                    </mat-tab-group>

                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
