import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';
import {FuseSplashScreenService} from '../../../../@fuse/services/splash-screen.service';
import {environment} from "../../../../environments/environment";
import {Comment} from "../../../models/comment";
import {map, tap} from "rxjs/operators";
import {Post} from "../../../models/post";
import {SocialMediaManagementTeamsService} from "../social-media-management/teams/teams.service";

export interface ChatFilterValues {
    teams: any[];
    networks: string[];
    status: string;
}

@Injectable()
export class ChatService implements Resolve<any>
{
    pagePosts = [];
    contacts: any[];
    chats: any[];
    chats2: any[];
    networks: any[];
    user: any;
    filters: any;
    inputEmojis: HTMLElement = null;
    filtersValues: ChatFilterValues = {teams: [], networks: [], status: 'both'};
    onChatSelected: BehaviorSubject<any>;
    onContactSelected: BehaviorSubject<any>;
    onChatsUpdated: Subject<any>;
    onUserUpdated: Subject<any>;
    onFilterUpdated: Subject<any>;
    onLeftSidenavViewChanged: Subject<any>;
    onRightSidenavViewChanged: Subject<any>;
    onEmojisSelected: Subject<any>;
    onCommentSelected: Subject<any>;
    onChatChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    onEmojisOpen: Subject<any>;
    timelineChats: any[];

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private teamService: SocialMediaManagementTeamsService
    )
    {
        // Set the defaults
        this.onChatSelected = new BehaviorSubject(null);
        this.onContactSelected = new BehaviorSubject(null);
        this.onChatsUpdated = new Subject();
        this.onUserUpdated = new Subject();
        this.onFilterUpdated = new Subject();
        this.onLeftSidenavViewChanged = new Subject();
        this.onRightSidenavViewChanged = new Subject();
        this.onEmojisSelected = new Subject();
        this.onCommentSelected = new Subject();
        this.onChatChanged = new Subject();
        this.onFilterChanged = new Subject();
        this.onEmojisOpen = new Subject();
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
                // this.getContacts(),
                this.getChats(),
                this.getUser(),
                this.getFilters(),
                // this.getPagePosts(),
                this.getTimelineChats(),
                this.teamService.getTeams()
            ]).then(
                ([/*contacts, */chats, user, filters, /*chats2, */timelineChats, teams]) => {
                    // this.contacts = contacts;
                    this.chats = chats;
                    // this.chats2 = chats2['conversations'];
                    this.networks = timelineChats['networks'];
                    this.user = user;
                    this.filters = filters;
                    this.filters.networks = Object.values(timelineChats['networks']);
                    this.timelineChats = timelineChats['chats'];
                    this.filters.teams = teams.groups;
                    resolve();
                },
                reject
            );
        });
    }

    getLastChats(): Observable<any> {
        return this._httpClient.get(`${environment.apiUrl}all-conversations-posts/`);
    }

    getFbPost(networkId, postId): Observable<any> {
        return this._httpClient.get(`${environment.apiUrl}get-fb-post/` + networkId + `/` + postId);
    }

    replyFbMessage(data: any): Observable<any> {
        return this._httpClient.post(`${environment.apiUrl}fb-private-reply`, data);
    }

    replyTwitterMessage(data: any): Observable<any> {
        return this._httpClient.post(`${environment.apiUrl}twitter/messages/` + data.network_id + '/' + data.user_id, data);
    }

    getInPost(networkId, postId): Observable<any> {
        return this._httpClient.get(`${environment.apiUrl}get-in-post/` + networkId + `/` + postId);
    }

    getFbConversation(networkId, threadId): Observable<any> {
        return this._httpClient.get(`${environment.apiUrl}get-fb-conversation/` + networkId + `/` + threadId);
    }

    getChat2(chat): Promise<any> {
        let chatData;
        if (chat.messages) {
            return new Promise((resolve, reject) => {
                chatData = {
                    chatId: chat.id,
                    dialog: chat.messages['data'],
                    contact: chat.senders['data'][0],
                    network_id: chat.network_id,
                    network_pic: chat.network_pic,
                    type: chat.type,
                    network: chat.network
                };
                this.onChatSelected.next({...chatData});
            });
        } else {
            return new Promise((resolve, reject) => {
                if (chat.network_type === 'facebook') {
                    let comments = [];
                    if (chat['comments'] && chat['comments']['data']) {
                        for (let i in chat['comments']['data']) {
                            let comment = new Comment();
                            comment.id = chat['comments']['data'][i]['id'];
                            comment.text = chat['comments']['data'][i]['message'];
                            comment.contactId = chat['comments']['data'][i]['from']['id'];
                            comment.contactName = chat['comments']['data'][i]['from']['name'];
                            comment.postDate = chat['comments']['data'][i]['created_time'];
                            comment.contactAvatar = chat['comments']['data'][i]['from']['id'] === chat.network_id ? chat.network_pic : null;
                            if (typeof chat['comments']['data'][i]['comments'] !== 'undefined') {
                                let answers = [];
                                for (let j in chat['comments']['data'][i]['comments']['data']) {
                                    let reply = new Comment();
                                    reply.id = chat['comments']['data'][i]['comments']['data'][j]['id'];
                                    reply.text = chat['comments']['data'][i]['comments']['data'][j]['message'];
                                    reply.contactId = chat['comments']['data'][i]['comments']['data'][j]['from']['id'];
                                    reply.contactName = chat['comments']['data'][i]['comments']['data'][j]['from']['name'];
                                    reply.postDate = chat['comments']['data'][i]['comments']['data'][j]['created_time'];
                                    reply.contactAvatar = chat['comments']['data'][i]['comments']['data'][j]['from']['id'] === chat.network_id ? chat.network_pic : null;
                                    answers.push(reply);
                                }
                                comment.answers = answers;
                            }
                            comments.push(comment);
                        }
                    }
                    chatData = {
                        chatId: chat.id,
                        post: chat,
                        contact: chat.from,
                        network_id: chat.network_id,
                        network_pic: chat.network_pic,
                        type: chat.type,
                        comments: comments,
                        network: chat.network
                    };
                    this.onChatSelected.next({...chatData});
                } else if (chat.network_type === 'instagram') {
                    let comments = [];
                    if (chat['comments'] && chat['comments']['data']) {
                        for (let i in chat['comments']['data']) {
                            let comment = new Comment();
                            comment.id = chat['comments']['data'][i]['id'];
                            comment.text = chat['comments']['data'][i]['text'];
                            comment.contactId = chat['comments']['data'][i]['username'];
                            comment.contactName = chat['comments']['data'][i]['username'];
                            comment.postDate = chat['comments']['data'][i]['timestamp'];
                            comment.contactAvatar = chat['comments']['data'][i]['username'] === chat.network.name ? chat.network_pic : null;
                            if (typeof chat['comments']['data'][i]['replies'] !== 'undefined') {
                                let answers = [];
                                for(let j in chat['comments']['data'][i]['replies']['data']){
                                    let reply = new Comment();
                                    reply.id = chat['comments']['data'][i]['replies']['data'][j]['id'];
                                    reply.text = chat['comments']['data'][i]['replies']['data'][j]['text'];
                                    reply.contactId = chat['comments']['data'][i]['replies']['data'][j]['username'];
                                    reply.contactName = chat['comments']['data'][i]['replies']['data'][j]['username'];
                                    reply.postDate = chat['comments']['data'][i]['replies']['data'][j]['timestamp'];
                                    reply.contactAvatar = chat['comments']['data'][i]['replies']['data'][j]['username'] === chat.network.name ? chat.network_pic : null;
                                    answers.push(reply);
                                }
                                comment.answers = answers;
                            }
                            comments.push(comment);
                        }
                    }
                    chatData = {
                        chatId: chat.id,
                        post: chat,
                        contact: {
                            id: chat['owner']['id'],
                            name: chat['username']
                        },
                        network_id: chat.network_id,
                        network_pic: chat.network_pic,
                        type: chat.type,
                        comments: comments,
                        network: chat.network
                    };
                    this.onChatSelected.next({...chatData});
                }
            });
        }
    }

    /**
     * Get chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    getChat(contactId): Promise<any>
    {
        this.onChatChanged.next(true);
        const chatItem = this.user.chatList.find((item) => {
            return item.contactId === contactId;
        });
        const postItem = this.user.postList.find((item) => {
            return item.contactId === contactId;
        });

        if (chatItem.messageType === 'private') {
            // Create new chat, if it's not created yet.
            if (!chatItem) {
                this.createNewChat(contactId).then((newChats) => {
                    this.getChat(contactId);
                });
                return;
            }

            return new Promise((resolve, reject) => {
                this._httpClient.get('api/chat-chats/' + chatItem.id)
                    .subscribe((response: any) => {
                        const chat = response;

                        const chatContact = this.contacts.find((contact) => {
                            return contact.id === contactId;
                        });

                        const chatData = {
                            chatId: chat.id,
                            dialog: chat.dialog,
                            contact: chatContact
                        };

                        this.onChatSelected.next({...chatData});

                    }, reject);

            });
        }else{
            if (!postItem) {
                this.createNewPostChat(contactId).then((newChats) => {
                    this.getChat(contactId);
                });
                return;
            }else{
                return new Promise((resolve, reject) => {
                    this._httpClient.get('api/chat-posts/' + postItem.id)
                        .subscribe((response: any) => {
                            const chat = response;

                            const chatContact = this.contacts.find((contact) => {
                                return contact.id === contactId;
                            });

                            const chatData = {
                                chatId: chat.id,
                                post: chat.post,
                                contact: chatContact
                            };

                            this.onChatSelected.next({...chatData});

                        }, reject);

                });
            }
        }
    }

    /**
     * Create new chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    createNewChat(contactId): Promise<any>
    {
        return new Promise((resolve, reject) => {

            const contact = this.contacts.find((item) => {
                return item.id === contactId;
            });

            const chatId = FuseUtils.generateGUID();

            const chat = {
                id    : chatId,
                dialog: []
            };

            const chatListItem = {
                contactId      : contactId,
                id             : chatId,
                lastMessageTime: '2017-02-18T10:30:18.931Z',
                name           : contact.name,
                unread         : null
            };

            // Add new chat list item to the user's chat list
            this.user.chatList.push(chatListItem);

            // Post the created chat
            this._httpClient.post('api/chat-chats', {...chat})
                .subscribe((response: any) => {

                    // Post the new the user data
                    this._httpClient.post('api/chat-user/' + this.user.id, this.user)
                        .subscribe(newUserData => {

                            // Update the user data from server
                            this.getUser().then(updatedUser => {
                                this.onUserUpdated.next(updatedUser);
                                resolve(updatedUser);
                            });
                        });
                }, reject);
        });
    }

    /**
     * Create new post chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    createNewPostChat(contactId): Promise<any>
    {
        return new Promise((resolve, reject) => {

            const contact = this.contacts.find((item) => {
                return item.id === contactId;
            });

            const chatId = FuseUtils.generateGUID();

            const chat = {
                id    : chatId,
                post: null
            };

            // const chatListItem = {
            //     contactId      : contactId,
            //     id             : chatId,
            //     lastMessageTime: '2017-02-18T10:30:18.931Z',
            //     name           : contact.name,
            //     unread         : null
            // };
            //
            // // Add new chat list item to the user's chat list
            // this.user.chatList.push(chatListItem);

            // Post the created chat
            this._httpClient.post('api/chat-posts', {...chat})
                .subscribe((response: any) => {
                    // Post the new the user data
                    this._httpClient.post('api/chat-user/' + this.user.id, this.user)
                        .subscribe(newUserData => {
                            // Update the user data from server
                            // this.getUser().then(updatedUser => {
                            //     this.onUserUpdated.next(updatedUser);
                            //     resolve(updatedUser);
                            // });
                        });
                }, reject);
        });
    }

    /**
     * Select contact
     *
     * @param contact
     */
    selectContact(contact): void
    {
        this.onContactSelected.next(contact);
    }

    /**
     * Set user status
     *
     * @param status
     */
    setUserStatus(status): void
    {
        this.user.status = status;
    }

    /**
     * Update user data
     *
     * @param userData
     */
    updateUserData(userData): void
    {
        this._httpClient.post('api/chat-user/' + this.user.id, userData)
            .subscribe((response: any) => {
                    this.user = userData;
                }
            );
    }

    /**
     * Update user data
     *
     * @param userData
     */
    updateFilterData(filterData): void
    {
        // this._httpClient.post('api/chat-user/' + this.user.id, userData)
        //     .subscribe((response: any) => {
        //             this.user = userData;
        //         }
        //     );
    }

    /**
     * Update the chat dialog
     *
     * @param chatId
     * @param dialog
     * @returns {Promise<any>}
     */
    updateDialog(chatId, dialog): Promise<any>
    {
        return new Promise((resolve, reject) => {

            const newData = {
                id    : chatId,
                dialog: dialog
            };

            this._httpClient.post('api/chat-chats/' + chatId, newData)
                .subscribe(updatedChat => {
                    resolve(updatedChat);
                }, reject);
        });
    }

    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getContacts(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/chat-contacts')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get chats
     *
     * @returns {Promise<any>}
     */
    getChats(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/chat-chats')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get chats from backend
     * @params form
     * @returns {Promise<any>}
     */
    getChats2(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}all-conversations/`)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getPagePosts(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}all-conversations-posts/`)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/chat-user')
                .subscribe((response: any) => {
                    resolve(response[0]);
                }, reject);
        });
    }

    /**
     * Get filters
     *
     * @returns {Promise<any>}
     */
    getFilters(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/chat-filters')
                .subscribe((response: any) => {
                    resolve(response[0]);
                }, reject);
        });
    }

    setEmojiSelected(emoji: any): void {
        this.onEmojisSelected.next(emoji);
    }

    getEmoji(): Observable<any> {
        return this.onEmojisSelected.asObservable();
    }

    setCommentStatus(): void {
        this.onCommentSelected.next(false);
    }

    getCommentStatus(): Observable<any> {
        return this.onCommentSelected.asObservable();
    }

    getChatChanged(): Observable<any> {
        return this.onChatChanged.asObservable();
    }

    /**
     * Set the new filter's values
     * @param values
     * @returns void
     */
    setFilterChangedValues(values: ChatFilterValues): void {
        this.filtersValues = values;
        this.onFilterChanged.next(values);
    }

    /***
     * Get the filter's values
     * @returns Observable<any>
     */
    getFiltersValues(): Observable<ChatFilterValues> {
        return this.onFilterChanged.asObservable();
    }

    getEmojisOpen(): Observable<any> {
        return this.onEmojisOpen.asObservable();
    }

    getTimelineChats(from = 0, amount = 18, since = 0, status = '', networks: Array<string> = []): Promise<any> {
        if (status === '' && this.filtersValues.status !== 'both') {
            status = this.filtersValues.status;
        }
        if (networks.length === 0 && this.filtersValues.networks.length > 0) {
            networks = this.filtersValues.networks;
        }
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}timeline/chats?from=` + from + '&amount=' + amount + '&since=' + since +
                (status !== '' ? '&status=' + status : '') + (networks.length > 0 ? '&networks[]=' + networks.join('&networks[]=') : ''))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getFbChatMessages(networkId: string, userId: string): Observable<any> {
        return this._httpClient.get(
            `${environment.apiUrl}get-fb-user-conversation/`
            + networkId + '/'
            + userId
        );
    }

    getChatTypeView(chat: any): Observable<any> {
        switch (chat.network_type) {
            case 'instagram':
                return this.getInPost(chat.social_network_id, chat.post_account_id).pipe(
                    map(response => {
                        // const networkFound = this.networks.find(network => network.network_id === chat.social_network_id);
                        const frontPost = FuseUtils.formatServerPost(response.post, response.post.network) as Post;
                        const newChat = {
                            chatId: frontPost.id,
                            post: frontPost,
                            contact: {
                                id: response['post']['owner']['id'],
                                name: response['post']['username']
                            },
                            network_id: chat.social_network_id,
                            network_pic: this.networks[chat.social_network_id].picture,
                            type: chat.type,
                            comments: frontPost.comments,
                            network: this.networks[chat.social_network_id]
                        };
                        this.onChatSelected.next({...newChat});
                    })
                );
                break;
            case 'twitter':
                if (chat.type === 'message') {
                    const chatData = {
                        chatId: chat.id,
                        dialog: chat['messages'],
                        contact: chat['contact'],
                        network_id: chat.social_network_id,
                        network_pic: this.networks[chat.social_network_id].picture,
                        type: chat.type,
                        network: this.networks[chat.social_network_id]
                    };
                    this.onChatSelected.next({...chatData});
                    return new Observable((observer) => {
                        observer.next("empty")
                        observer.complete()
                    })
                } else {
                    const author = (chat.post.author_id === chat.contact.id)
                        ? chat.contact
                        : (
                            (chat.post.author_id === chat.sender.id)
                                ? chat.sender
                                : (chat.contacts.find(i => i.id === chat.post.author_id))
                        );
                    chat.post['user'] = author !== null ? author : {id: null, profile_image_url: '', name: 'Unknown'}
                    const frontPost = FuseUtils.formatServerPost(chat.post, this.networks[chat.social_network_id]) as Post;
                    const messages = FuseUtils.formatTwitterConversations(String(chat.post.id), chat.messages, [...chat.contacts, chat.contact, chat.sender]);
                    frontPost.comments = messages;
                    const newChat = {
                        chatId: frontPost.id,
                        post: frontPost,
                        contact: chat.contact,
                        network_id: chat.social_network_id,
                        network_pic: this.networks[chat.social_network_id].picture,
                        type: chat.type,
                        comments: messages,
                        network: this.networks[chat.social_network_id]
                    };
                    this.onChatSelected.next({...newChat});
                    return new Observable((observer) => {
                        observer.next("empty")
                        observer.complete()
                    })
                }
                break;
            default:
                if (chat.type === 'message') {
                    return this.getFbChatMessages(chat.social_network_id, ((chat.sender.id !== chat.social_network_id) ? chat.sender.id : chat.post_message_id)).pipe(
                        map(response => {
                            // const networkFound = this.networks.find(network => network.network_id === chat.social_network_id);
                            const chatData = {
                                chatId: chat.id,
                                dialog: response['messages'],
                                contact: (response['senders'][0].id === chat.sender.id) ? chat.sender : response['senders'][0],
                                network_id: chat.social_network_id,
                                network_pic: this.networks[chat.social_network_id].picture,
                                type: chat.type,
                                network: this.networks[chat.social_network_id]
                            };
                            this.onChatSelected.next({...chatData});
                        })
                    );
                } else {
                    return this.getFbPost(chat.social_network_id, chat.post_account_id).pipe(
                        map(response => {
                            // const networkFound = this.networks.find(network => network.network_id === chat.social_network_id);
                            const frontPost = FuseUtils.formatServerPost(response.post, response.post.network) as Post;
                            const newChat = {
                                chatId: frontPost.id,
                                post: frontPost,
                                contact: response.post.from,
                                network_id: chat.social_network_id,
                                network_pic: this.networks[chat.social_network_id].picture,
                                type: chat.type,
                                comments: frontPost.comments,
                                network: this.networks[chat.social_network_id]
                            };
                            this.onChatSelected.next({...newChat});
                        })
                    );
                }
                break;
        }
    }
}
