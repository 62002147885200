import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileSnippet} from '../file-uploader.component';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {FileUploaderService} from '../file-uploader.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.scss'],
  animations: fuseAnimations
})
export class FileViewComponent implements OnInit {
  @Input() fileSnippet: FileSnippet;
  @Input() index: number = null;
  @Output() uploadFile = new EventEmitter<{fileSnippet: FileSnippet, index: number}>();
  @Output() removeFile = new EventEmitter<number>();
  @Output() editFile = new EventEmitter<{index: number, type: string}>();
  previewLoading = true;

  constructor(private fileService: FileUploaderService, public dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.fileSnippet.source !== '' && this.fileSnippet.file === null) {
        const getFileUrl = this.fileSnippet.source;
        if (this.fileSnippet.source.indexOf('pixabay') !== -1) {
            this.fileSnippet.source = this.fileSnippet.source.replace('https://pixabay.com/get/', '/get-pixabay/');
        }
        // if (this.fileSnippet.source.indexOf('fmia1-1') !== -1) {
        //     this.fileSnippet.source = this.fileSnippet.source.replace('https://scontent.fmia1-1.fna.fbcdn.net', '/get-scontent1/');
        // }
        // if (this.fileSnippet.source.indexOf('fmia1-2') !== -1) {
        //     this.fileSnippet.source = this.fileSnippet.source.replace('https://scontent.fmia1-2.fna.fbcdn.net', '/get-scontent2/');
        // }
        this.fileService.getFile(getFileUrl).subscribe(
            response => {
                const name = this.fileSnippet.source.split('/');
                const file = new File([response], name[name.length - 1]);
                this.fileSnippet.type = response.type;
                this.fileSnippet.id = (typeof this.fileSnippet.id !== 'undefined' && this.fileSnippet.id !== null) ? this.fileSnippet.id : null;
                this.fileSnippet.file = file;
                this.fileSnippet.loading = false;
                this.uploadFile.emit({fileSnippet: this.fileSnippet, index: this.index});
            },
            error => console.log(error)
        );
    } else if (typeof this.fileSnippet.file !== 'undefined' && this.fileSnippet.file !== null) {
        this.fileSnippet.type = this.fileSnippet.file.type;
        this.uploadFile.emit({fileSnippet: this.fileSnippet, index: this.index});
    }
  }

    loadImagePreview(event: Event): void {
        this.previewLoading = false;
    }

    remove(): void {
        this.removeFile.emit(this.index);
    }
}
