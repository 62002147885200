import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {HttpClient, HttpEventType} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";
import {Subscription} from "rxjs";
import {ConversionSettings, facebookSettings, instagramSettings, linkedinSettings, twitterSettings} from "./model";
import {Network} from "../../../models/network";

// declare var FFmpeg: any;

@Component({
  selector: 'app-video-editor',
  templateUrl: './video-editor.component.html',
  styleUrls: ['./video-editor.component.scss']
})
export class VideoEditorComponent implements OnInit {

    @Input() file: File;
    @Input() mediaInfo: ConversionSettings = null;
    videoUrl: SafeUrl = '';
    showVideo = true;
    videoForm: FormGroup;
    // ffmpeg: any;
    // private createFFmpeg: any;
    // private fetchFile: any;
    convertingSub: Subscription;
    converting = false;
    buttonText = 'CONVERT VIDEO';
    percent = 0;
    videoConverted = false;
    @Output() newVideo: EventEmitter<any> = new EventEmitter<any>();
    percentSub: Subscription;
    notice = '';

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<VideoEditorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sanitizer: DomSanitizer,
        private http: HttpClient
    ) {
        // this.createFFmpeg = FFmpeg.createFFmpeg;
        // this.fetchFile = FFmpeg.fetchFile;
        // this.ffmpeg = this.createFFmpeg({ log: true });
        if (typeof this.file !== 'undefined' && this.file !== null) {
            this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.file));
        } else if (typeof this.data.file !== 'undefined' && this.data.file !== null) {
            this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.data.file));
            this.file = this.data.file;
        }
        if (typeof this.data.mediaInfo !== 'undefined' && this.data.mediaInfo !== null) {
            this.mediaInfo = this.data.mediaInfo;
        } else {
            this.mediaInfo = null;
        }
    }

    ngOnInit(): void {
        this.videoForm = this.fb.group({
            voutput: ['mp4'],
            vencoder: ['h.264'],
            vframerate: [''],
            vrotation: ['0'],
            vresolution: [''],
            vbitrate: [''],
            vchunk: [''],
            aencoder: ['aac'],
            aquality: ['128000'],
            achannel: ['2'],
            abitrate: ['128000'],
            asamplerate: ['48000'],
            vscan: ['progressive'],
            vgop: ['0'],
            vduration: [''],
            vsize: ['']
        });
    }

    cancelConverting() {
        if (typeof this.convertingSub !== 'undefined' && this.convertingSub !== null) {
            this.convertingSub.unsubscribe();
        }
        if (typeof this.percentSub !== 'undefined' && this.percentSub !== null) {
            this.percentSub.unsubscribe();
        }
        this.dialogRef.close();
    }

    getConvertingPercent() {
        this.percentSub = this.http.get(`${environment.apiUrl}` + 'medias/video/convert/percent').subscribe(
            response => {
                // @ts-ignore
                if (typeof response.percent !== 'undefined' && response.percent !== null) {
                    // @ts-ignore
                    if (response.percent == 100) {
                        this.percentSub.unsubscribe();
                    } else {
                        // @ts-ignore
                        this.percent = Number(response.percent);
                        setTimeout(() => this.getConvertingPercent(), 1000);

                    }
                }
            }, error => console.log(error)
        );
    }

    convert() {
        // let stream = new EventSource(`${environment.apiUrl}` + 'medias/video/events');
        // stream.addEventListener('ping', event => {
        //     console.log(event);
        // });
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('settings', JSON.stringify(this.videoForm.getRawValue()));
        this.convertingSub = this.http.post(`${environment.apiUrl}` + 'medias/video/convert', formData, {
            reportProgress: true,
            observe: 'events',
            responseType: 'blob'
        }).pipe(map((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(100 * event.loaded / event.total);
                        return { status: 'progress', message: progress };
                    case HttpEventType.Response:
                        return event.body;
                    default:
                        return null;
                }
            })
        ).subscribe(
            response => {
                if (typeof response === 'undefined' || response === null) {
                    // @ts-ignore
                } else if (typeof response.status !== 'undefined') {
                    this.converting = true;
                    // @ts-ignore
                    this.percent = response.message;
                    this.buttonText = 'Uploading Video...';
                    if (this.percent === 100) {
                        this.percent = 0;
                        this.buttonText = 'Converting Video...';
                        setTimeout(() => this.getConvertingPercent(), 3000);
                    }
                } else {
                    this.percent = 100;
                    this.buttonText = 'VIDEO CONVERTED';
                    let newFile: any = response;
                    newFile.lastModifiedDate = new Date();
                    this.file = newFile;
                    this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.file));
                    this.videoConverted = true;
                    this.converting = false;
                }
            }, error => console.log(error)
        );
    }

    useVideo() {
        this.newVideo.emit(this.file);
        this.dialogRef.close({file: this.file});
    }

    setPredefinedSettings(type: string) {
        switch (type) {
            case 'facebook':
                this.videoForm.patchValue(facebookSettings);
                this.notice = 'Please be aware that this "Facebook Settings" are the top values that each field can take when converting a Facebook video. You should modify each field depending on the current video settings.';
                break;
            case 'instagram':
                this.videoForm.patchValue(instagramSettings);
                this.notice = 'Please be aware that this "Instagram Settings" are the top values that each field can take when converting a Instagram video. You should modify each field depending on the current video settings.';
                break;
            case 'twitter':
                this.videoForm.patchValue(twitterSettings);
                this.notice = 'Please be aware that this "Twitter Settings" are the top values that each field can take when converting a Twitter video. You should modify each field depending on the current video settings.';
                break;
            case 'linkedin':
                this.videoForm.patchValue(linkedinSettings);
                this.notice = 'Please be aware that this "Linkedin Settings" are the top values that each field can take when converting a Linkedin video. You should modify each field depending on the current video settings.';
                break;
        }
    }
}
