    <div class="fuse-card" style="min-width: unset !important;">

        <div class="p-16" fxLayout="row" fxLayoutAlign="start center">
            <span style="cursor: pointer" matTooltipPosition="above"
                  [matTooltip]="post['posts_' + post.type][0].name + (post['posts_' + post.type].length > 1 ? (' +' + (post['posts_' + post.type].length - 1) + ' network(s)') : '')"
                  [matBadge]="'+' + (post['posts_' + post.type].length - 1)" matBadgePosition="below after" matBadgeOverlap="true" *ngIf="post['posts_' + post.type].length > 1" matBadgeColor="accent">
                <img class="w-40 h-40 border-radius-100" [src]="post['posts_' + post.type][0].picture">
            </span>
            <img *ngIf="post['posts_' + post.type].length === 1" style="cursor: pointer" matTooltipPosition="above" [matTooltip]="post['posts_' + post.type][0].name"
                 class="w-40 h-40 border-radius-100" [src]="post['posts_' + post.type][0].picture">

            <div class="ml-16">
<!--                <div class="text-bold">{{(post.post_type === 'draft') ? 'Draft' : 'Scheduled'}} Post</div>-->
                <div class="text-bold unposted-name">{{post['posts_' + post.type][0].name}}</div>
                <div class="h5 text-bold secondary-text">
                    {{formatedPostData.schedule | date}}
                </div>
            </div>
            <mat-icon style="cursor: pointer" *ngIf="post.type === 'draft'" aria-hidden="false" matTooltipPosition="above" matTooltip="Draft Post">delete_outline</mat-icon>
            <mat-icon style="cursor: pointer" *ngIf="post.type !== 'draft'" aria-hidden="false" matTooltipPosition="above" matTooltip="Schedule Post">history</mat-icon>
        </div>
        <div *ngIf="formatedPostData.mediaType.indexOf('image') !== -1 || formatedPostData.mediaType.indexOf('photo') !== -1 || formatedPostData.mediaType.indexOf('album') !== -1" class="post-images" [ngStyle]="{
            backgroundImage: 'url(' + formatedPostData.mediaUrl + ')'
        }">
        </div>
        <div *ngIf="formatedPostData.mediaType.indexOf('video') !== -1" class="post-images" style="position: relative;">
            <div class="play-button">
                <i class="fas fa-play-circle"></i>
            </div>
            <video src="{{formatedPostData.mediaUrl}}" style="width: 100%;height: 100%"></video>
        </div>

        <div class="line-height-1.75 shrink-text" [matTooltip]="formatedPostData.message" matTooltipPosition="above">
            {{formatedPostData.message}}
        </div>

        <div class="post-actions" fxLayout="row" fxLayoutAlign="start center">
            <mat-checkbox style="margin-left: 10px; margin-right: 10px" [checked]="checked" class="select-post" (change)="selectPost($event)" aria-label="Select Post"></mat-checkbox>
            <button (click)="editGroup()"><mat-icon aria-hidden="false" aria-label="Edit Post(s)">edit</mat-icon></button>
            <button (click)="removeUnposted()"><mat-icon aria-hidden="false" aria-label="Delete Post(s)">delete</mat-icon></button>
        </div>

    </div>
