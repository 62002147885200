import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Post} from '../../../../models/post';
import {fuseAnimations} from '../../../../../@fuse/animations';

@Component({
  selector: 'app-post-share-view',
  templateUrl: './post-share-view.component.html',
  styleUrls: ['./post-share-view.component.scss'],
  animations   : fuseAnimations
})
export class PostShareViewComponent implements OnInit {
    @Input() post: Post;
    showPlayButton = true;
    @ViewChild('videoBox') videoBox: ElementRef<HTMLVideoElement>;

    constructor() { }

    ngOnInit(): void {
    }

    playVideo(): void {
        this.showPlayButton = false;
        this.videoBox.nativeElement.play();
    }

}
