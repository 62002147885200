<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Facebook Audience Restrictions</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 pb-0 m-0" fusePerfectScrollbar>
        <form name="eventForm" [formGroup]="form" class="event-form w-100-p" fxLayout="column" fxFlex>
            <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>Minimum Age</mat-label>
                <mat-select formControlName="age_min">
                    <mat-option value="">Everyone</mat-option>
                    <mat-option value="13">13+</mat-option>
                    <mat-option value="15">15+</mat-option>
                    <mat-option value="18">18+</mat-option>
                    <mat-option value="21">21+</mat-option>
                    <mat-option value="25">25+</mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container formGroupName="geo_locations">
                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                    <mat-label>Countries</mat-label>
                    <mat-chip-list #countryList name="countries">
                        <mat-chip style="line-height: 16px;" *ngFor="let country of selectedList.countries"
                                  [selectable]="true"
                                  [value]="country.country_code"
                                  [removable]="true" (removed)="remove(country)">
                            {{country.name}}
                            <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
                        </mat-chip>
                        <input #countryInput [matChipInputFor]="countryList" autocomplete="none"
                               formControlName="countries"
                               [matAutocomplete]="autoCountry"
                               [matChipInputAddOnBlur]="false"
                               placeholder="Type a country..." />
                    </mat-chip-list>
                    <mat-autocomplete #autoCountry="matAutocomplete" (optionSelected)="selected($event, countryInput)">
                        <mat-option *ngFor="let country of filteredData | async" [value]="country">
                            {{country.name}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-spinner diameter="20" class="input-loader" *ngIf="inputLoaders.countries"></mat-spinner>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                    <mat-label>Regions</mat-label>
                    <mat-chip-list #regionList name="regions">
                        <mat-chip style="line-height: 16px;" *ngFor="let region of selectedList.regions"
                                  [selectable]="true"
                                  [value]="region.key"
                                  [removable]="true" (removed)="remove(region, 'regions')">
                            {{region.name}}
                            <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
                        </mat-chip>
                        <input #regionInput [matChipInputFor]="regionList" autocomplete="none"
                               formControlName="regions"
                               [matAutocomplete]="autoRegions"
                               [matChipInputAddOnBlur]="false"
                               placeholder="Type a region..." />
                    </mat-chip-list>
                    <mat-autocomplete #autoRegions="matAutocomplete" (optionSelected)="selected($event, regionInput, 'regions')">
                        <mat-option *ngFor="let region of filteredData | async" [value]="region">
                            {{region.name}} - <small>{{region.country_name}}</small>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-spinner diameter="20" class="input-loader" *ngIf="inputLoaders.regions"></mat-spinner>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                    <mat-label>Cities</mat-label>
                    <mat-chip-list #cityList name="cities">
                        <mat-chip style="line-height: 16px;" *ngFor="let city of selectedList.cities"
                                  [selectable]="true"
                                  [value]="city.key"
                                  [removable]="true" (removed)="remove(city, 'cities')">
                            {{city.name}}
                            <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
                        </mat-chip>
                        <input #cityInput [matChipInputFor]="cityList" autocomplete="none"
                               formControlName="cities"
                               [matAutocomplete]="autoCities"
                               [matChipInputAddOnBlur]="false"
                               placeholder="Type a city..." />
                    </mat-chip-list>
                    <mat-autocomplete #autoCities="matAutocomplete" (optionSelected)="selected($event, cityInput,'cities')">
                        <mat-option *ngFor="let city of filteredData | async" [value]="city">
                            {{city.name}} - <small>{{city.country_name}}</small>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-spinner diameter="20" class="input-loader" *ngIf="inputLoaders.cities"></mat-spinner>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                    <mat-label>Zip Codes</mat-label>
                    <mat-chip-list #zipList name="zip">
                        <mat-chip style="line-height: 16px;" *ngFor="let zip of selectedList.zips"
                                  [selectable]="true"
                                  [value]="zip.key"
                                  [removable]="true" (removed)="remove(zip, 'zips')">
                            {{zip.key}}
                            <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
                        </mat-chip>
                        <input #zipInput [matChipInputFor]="zipList" autocomplete="none"
                               formControlName="zips"
                               [matAutocomplete]="autoZipCodes"
                               [matChipInputAddOnBlur]="false"
                               placeholder="Type a zip code..." />
                    </mat-chip-list>
                    <mat-autocomplete #autoZipCodes="matAutocomplete" (optionSelected)="selected($event, zipInput, 'zips')">
                        <mat-option *ngFor="let zip of filteredData | async" [value]="zip">
                            {{zip.key}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-spinner diameter="20" class="input-loader" *ngIf="inputLoaders.zips"></mat-spinner>
                </mat-form-field>
            </ng-container>
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button
                mat-button color="primary"
                class="save-button mat-button-base"
                (click)="matDialogRef.close()"
                aria-label="CLOSE">
            CLOSE
        </button>
        <button
                mat-button
                class="save-button mat-focus-indicator mat-accent mat-raised-button mat-button-base"
                (click)="matDialogRef.close({
                    age_min: form.get('age_min').value,
                    geo_locations: {
                        countries: selectedList.countries,
                        regions: selectedList.regions,
                        cities: selectedList.cities,
                        zips: selectedList.zips
                    }
                })"
                aria-label="SAVE">
            SAVE
        </button>

    </div>
</div>
