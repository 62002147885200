import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkAutofillComponent } from './network-autofill/network-autofill.component';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [NetworkAutofillComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatIconModule
    ]
})
export class NetworkAutofillModule { }
