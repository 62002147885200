import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService} from 'angularx-social-login';
import {Router} from '@angular/router';
import {CommonAuthService} from '../../../../auth/common-auth.service';
import {SocialMediaManagementNetworksComponent} from '../networks/networks.component';
import {SocialMediaService} from '../social-media.service';
import {FuseProgressBarService} from '../../../../../@fuse/components/progress-bar/progress-bar.service';
import {LinkedinLoginProvider} from './LinkedinLoginProvider';
import {environment} from '../../../../../environments/environment';
import {TwitterLoginProvider} from "./TwitterLoginProvider";
import {CustomFacebookLoginProvider} from "./CustomFacebookLoginProvider";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {ChangeFileNameComponent} from "../../manage-posts/manage-posts/manage-posts.component";
import {PinterestLoginProvider} from "./PinterestLoginProvider";
declare var FB;
@Component({
  selector: 'app-connect-network',
  templateUrl: './connect-network.component.html',
  styleUrls: ['./connect-network.component.scss']
})
export class ConnectNetworkComponent implements OnInit, OnDestroy {

    googleLoginOptions = {
        scope: 'profile email',
        offline_access: true,
        prompt: 'consent',
        return_scopes: true,
        enable_profile_selector: true,
    }; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

    googleNetworkOptions = {
        scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.channel-memberships.creator https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtubepartner https://www.googleapis.com/auth/youtubepartner-channel-audit https://www.googleapis.com/auth/youtube.force-ssl',
        offline_access: true,
        prompt: 'consent',
        return_scopes: true,
        enable_profile_selector: true,
    }; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

    instLoginOptions = {
        scope: 'instagram_basic,instagram_manage_comments,instagram_manage_insights,pages_show_list,pages_read_engagement,read_insights,business_management,instagram_content_publish,ads_management',
        return_scopes: true,
        enable_profile_selector: true,
        offline_access: true,
        prompt: 'consent',
        auth_type: 'rerequest'
    }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

    fbLoginOptions = {
        scope: 'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,' +
            'pages_read_engagement,pages_read_user_content,pages_manage_posts,pages_manage_engagement,read_insights,pages_manage_metadata,publish_video',
        return_scopes: true,
        enable_profile_selector: true,
        offline_access: true,
        prompt: 'consent',
        auth_type: 'rerequest'
    }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

    fbCustomLoginOptions = {
        scope: 'pages_show_list,public_profile,pages_manage_posts,pages_manage_engagement,' +
            'read_insights,pages_manage_ads,pages_manage_metadata,pages_read_engagement,pages_read_user_content,ads_read',
        return_scopes: true,
        response_type: 'token,signed_request,graph_domain',
        enable_profile_selector: true,
        offline_access: true,
        prompt: 'consent',
        auth_type: 'rerequest',
        redirect_uri: environment.facebookRedirectUrl,
        url: 'https://www.facebook.com/v10.0/dialog/oauth'
    };

    linkedinLoginOptions = {
        scope: 'r_organization_social r_1st_connections_size r_ads_reporting r_emailaddress rw_organization_admin ' +
            'r_liteprofile r_basicprofile r_ads rw_ads w_member_social w_organization_social',
        response_type: 'code',
        // client_id: '86kizjjsidutf6',
        redirect_uri: environment.linkedinRedirectUrl,
        state: '43hg53j4h5g3iu45',
        url: 'https://www.linkedin.com/oauth/v2/authorization'
    };

    twitterLoginOptionsOld = {
        scope: 'r_organization_social r_1st_connections_size r_ads_reporting r_emailaddress rw_organization_admin ' +
            'r_liteprofile r_basicprofile r_ads rw_ads w_member_social w_organization_social',
        response_type: 'code',
        // client_id: '86kizjjsidutf6',
        oauth_callback: environment.twitterRedirectUrl,
        state: '43hg53j4h5g3iu45',
        url: 'https://api.twitter.com/oauth/authorize'
    };

    twitterLoginOptions = {
        scope: 'tweet.read tweet.write users.read offline.access',
        response_type: 'code',
        client_id: 'WG5FQmxRMUI5T2pnSENBQ2RNQkc6MTpjaQ',
        // redirect_uri: environment.twitterRedirectUrl + '/app/dashboard/boards?redirect_to=' + this.router.url,
        redirect_uri: environment.twitterRedirectUrl + '?callback_twitter_oauth2=' + this.router.url,
        state: '43hg53j4h5g3iu45',
        url: 'https://twitter.com/i/oauth2/authorize',
        code_challenge: 'likajrehvgfioansjhdvgoquierwfhcingfuc',
        code_challenge_method: 'plain'
    };

    pinterestLoginOptions = {
        scope: 'ads:read,boards:read,boards:read_secret,boards:write,boards:write_secret,pins:read,pins:read_secret,pins:write,pins:write_secret,user_accounts:read',
        response_type: 'code',
        client_id: environment.pinterestClientId,
        oauth_callback: environment.pinterestRedirectUrl,
        state: '43hg53j4h5g3iu45',
        url: 'https://www.pinterest.com/oauth/'
    };
    twitterV2OauthUrl = 'https://twitter.com/i/oauth2/authorize?scope=tweet.read%20tweet.write%20users.read%20offline.access&response_type=code&client_id=' + environment.twitterClientId + '&redirect_uri=' + environment.twitterRedirectUrl + '?callback_twitter_oauth2=' + this.router.url + '&state=43hg53j4h5g3iu45&code_challenge=likajrehvgfioansjhdvgoquierwfhcingfuc&code_challenge_method=plain';

    constructor(
        private auth: CommonAuthService,
        private authService: SocialAuthService,
        private networkService: SocialMediaService,
        private router: Router,
        public matDialogRef: MatDialogRef<SocialMediaManagementNetworksComponent>,
        private _fuseProgressBarService: FuseProgressBarService,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    signInWithGoogle(): void {
        this.authService.signIn(GoogleLoginProvider.PROVIDER_ID, this.googleNetworkOptions).then(
            (userData) => {
                console.log(userData);
                if (typeof userData.authorizationCode !== 'undefined') {
                    this.networkService.getYoutubeAccessToken(userData.authorizationCode).subscribe(
                        response => {
                            this._fuseProgressBarService.hide();
                            this.matDialogRef.close();
                        },
                        error => console.log(error)
                    );
                }
            }
        );
    }

    signInWithFB(): void {
        this._fuseProgressBarService.show();
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, this.fbLoginOptions).then(
            (userData) => {
                if (typeof userData.authToken !== 'undefined') {
                    this.networkService.getFacebookNetworks(userData).subscribe(
                        (response) => {
                            console.log(response);
                            const dialogRef = this.dialog.open(SelectNetworksToAddComponent, {
                                width: '420px',
                                data: response.pages
                            });
                            dialogRef.afterClosed().subscribe(result => {
                                console.log(result);
                                if (typeof result.selectedPages !== 'undefined' && result.selectedPages !== null && result.selectedPages.length > 0) {
                                    this.networkService.storeFacebookNetworks({pages: result.selectedPages, accountId: response.account.account_id}).subscribe(
                                        response => {
                                            this._fuseProgressBarService.hide();
                                            this.matDialogRef.close();
                                        }, error => {
                                            this._fuseProgressBarService.hide();
                                            this.matDialogRef.close();
                                            console.log('storeFacebookNetworks', error)
                                        }
                                    );
                                } else {
                                    this._fuseProgressBarService.hide();
                                    this.matDialogRef.close();
                                }
                            });
                        },
                        (error) => {
                            console.log(error);
                            this._fuseProgressBarService.hide();
                        });
                }
            }
        );
    }

    signInWithInstagram(): void {
        this._fuseProgressBarService.show();
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, this.instLoginOptions).then(
            (userData) => {
                if (typeof userData.authToken !== 'undefined') {
                    this.networkService.getInstagramNetworks(userData).subscribe(
                        (response) => {
                            const dialogRef = this.dialog.open(SelectNetworksToAddComponent, {
                                width: '420px',
                                data: response.pages
                            });
                            dialogRef.afterClosed().subscribe(result => {
                                console.log(result);
                                if (typeof result.selectedPages !== 'undefined' && result.selectedPages !== null && result.selectedPages.length > 0) {
                                    this.networkService.storeInstagramNetworks({pages: result.selectedPages, accountId: response.account.account_id}).subscribe(
                                        response => {
                                            this._fuseProgressBarService.hide();
                                            this.matDialogRef.close();
                                        }, error => {
                                            this._fuseProgressBarService.hide();
                                            this.matDialogRef.close();
                                            console.log('storeFacebookNetworks', error)
                                        }
                                    );
                                } else {
                                    this._fuseProgressBarService.hide();
                                    this.matDialogRef.close();
                                }
                            });
                        },
                        (error) => {
                            this._fuseProgressBarService.hide();
                        });
                }
            }
        );
    }

    signInWithIN(): void {
        this._fuseProgressBarService.show();
        this.authService.signIn(LinkedinLoginProvider.PROVIDER_ID, this.linkedinLoginOptions).then(
            (userData) => {
                this.networkService.getLinkedinAuthToken(userData.authToken).subscribe(
                    response => {
                        this._fuseProgressBarService.hide();
                        this.matDialogRef.close();
                    },
                    error => console.log(error)
                );
            }
        );
    }

    signInWithTwitterOld(): void {
        this._fuseProgressBarService.show();
        this.networkService.getTwitterAuthToken().subscribe(
            data => {
                this.twitterLoginOptionsOld['oauthTokenParam'] = data.data[0];
                this.authService.signIn(TwitterLoginProvider.PROVIDER_ID, this.twitterLoginOptionsOld).then(
                    (userData) => {
                        // this.networkService.getTwitterAccessToken(userData.authToken, userData.authorizationCode).subscribe(
                        //     response => {
                        //         this._fuseProgressBarService.hide();
                        //         this.matDialogRef.close();
                        //     },
                        //     error => console.log(error)
                        // );
                    }
                );
            }, error => console.log(error)
        );
    }

    signInWithTwitter(): void {
        this._fuseProgressBarService.show();

        window.location.href = this.twitterLoginOptions.url + '?scope=' + this.twitterLoginOptions.scope + '&response_type=' + this.twitterLoginOptions.response_type
            + '&client_id=' + this.twitterLoginOptions.client_id + '&redirect_uri=' + this.twitterLoginOptions.redirect_uri + '&state=' + this.twitterLoginOptions.state
            + '&code_challenge=' + this.twitterLoginOptions.code_challenge + '&code_challenge_method=' + this.twitterLoginOptions.code_challenge_method;

        // this.authService.signIn(TwitterLoginProvider.PROVIDER_ID, this.twitterLoginOptions).then(
        //     (userData) => {
        //         console.log('signInWithTwitter: ', userData);
        //     }
        // );
    }

    signInWithPinterest(): void {
        this._fuseProgressBarService.show();
        this.authService.signIn(PinterestLoginProvider.PROVIDER_ID, this.pinterestLoginOptions).then(
            (userData) => {
                this.networkService.getPinterestAccessToken(userData.authToken).subscribe(
                    response => {
                        this._fuseProgressBarService.hide();
                        this.matDialogRef.close();
                    },
                    error => console.log(error)
                );
            }
        );
    }

    signInWithCustomFB(): void {
        this._fuseProgressBarService.show();
        this.authService.signIn(CustomFacebookLoginProvider.PROVIDER_ID, this.fbCustomLoginOptions).then(
            (userData) => {
                this.networkService.getLinkedinAuthToken(userData.authToken).subscribe(
                    response => {
                        this._fuseProgressBarService.hide();
                        this.matDialogRef.close();
                    },
                    error => console.log(error)
                );
            }
        );
    }

    selectClientNetworks(pages: Array<any>) {

    }

}

@Component({
    selector: 'app-select-networks-to-add',
    templateUrl: './select-networks-to-add.component.html',
    styleUrls: ['./select-networks-to-add.component.scss']
})
export class SelectNetworksToAddComponent implements OnInit{
    pages: Array<any>;
    selectedPages: Array<any> = [];

    constructor(
        public matDialogRef: MatDialogRef<SelectNetworksToAddComponent>,
        private _fuseProgressBarService: FuseProgressBarService,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        this.pages = _data;
    }

    ngOnInit() {
        this.selectedPages = this.pages.filter(i => i.exist === true);
    }

    setPage(event: MatCheckboxChange, id) {
        if (event.checked) {
            this.selectedPages.push(this.pages.find(i => i.id === id));
        } else {
            this.selectedPages.splice(this.pages.findIndex(i => i.id === id), 1);
        }
    }
}