import {Network} from './network';
import {Media} from './media';
import {Tag} from './tag';
import {Comment} from './comment';
import {AudienceFacebook, AudienceLinkedin} from './audience';

export class Post {
    id: bigint = null;
    postDate: Date = null;
    text: string = null;
    text_twitter: string = null;
    type: string = null;
    networks: Network[] = null;
    network: Network = null;
    media: Media[] = null;
    status: string = null;
    tags: Tag[] = null;
    editable = false;
    comments: Comment[] = [];
    publishType = 'directly';
    postLink: null;
    visitor = 0;
    from: null;
    postActions: Array<any>;
    link: {link: string, imageUrl: string, title: string, description: string, host: string} = null;
    replyPrivately = true;
    likedBySelf: boolean;
}

export class FacebookPost extends Post{
    public location: { id: string, name: string};
    public audience: AudienceFacebook;
    public promotion: boolean;
    public reactions: {paging: any, data: Array<any>} = null;
    public shares: any = null;
}

export class InstagramPost extends Post{
    public location: { id: number, label: string};
    public retweets: number;
    public likes: number;
}

export class YoutubePost extends Post{
    public title: string;
}

export class PinterestPost extends Post{
    public title: string;
    public boards: Array<string>;
    public destination: string;
}

export class LinkedinPost extends Post{
    public audience: AudienceLinkedin;
    public promotion: boolean;
    public reactions: Array<any> = null;
}

export class TwitterPost extends Post{
    public location: { id: number, label: string };
    public id_str: string;
    public retweets: number;
    public retweeted: boolean;
    public quoted_status: any;
}

