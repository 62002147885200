import {BaseLoginProvider, SocialUser} from 'angularx-social-login';

declare let IN: any;

export class TwitterLoginProvider extends BaseLoginProvider {

    public static readonly PROVIDER_ID = 'TWITTER';
    // public loginProviderObj: LoginProviderClass = new LoginProviderClass();

    // authorization code and tokens
    authorizationCode: string;
    oAuthToken: string;
    oAuthVerifier: string;

    TW_REQ_TOKEN_URL = 'https://api.twitter.com/oauth/request_token';
    TW_AUTH_URL = 'https://api.twitter.com/oauth/authenticate';
    TW_ACCESS_TOKEN_URL = 'https://api.twitter.com/oauth/access_token';

    // popup related
    private windowHandle: Window;   // reference to the window object we will create
    private intervalId: any = null;  // For setting interval time between we check for authorization code or token
    private loopCount = 600;   // the count until which the check will be done, or after window be closed automatically.
    private intervalLength = 100;   // the gap in which the check will be done for code.

    constructor(private clientId: string) {
        super();
    }

    initialize(): Promise<void> {
        return new Promise((resolve, reject) => {
            resolve();
            // this.loadScript(TwitterLoginProvider.PROVIDER_ID, 'https://platform.linkedin.com/in.js', () => {
            //     IN.init({
            //         api_key: this.clientId,
            //         authorize: true,
            //         onLoad: this.onLinkedInLoad()
            //     });
            //
            //     resolve();
            //
            //     IN.Event.on(IN, 'auth', () => {
            //         if (IN.User.isAuthorized()) {
            //             IN.API.Raw(
            //                 '/people/~:(id,first-name,last-name,email-address,picture-url)'
            //             ).result( (res: any) => {
            //                 // resolve();
            //                 console.log('LINKEDIN DATA:', res);
            //             });
            //         }
            //     });
            //
            // });
        });
    }

    onLinkedInLoad(): void {
        // IN.Event.on(IN, 'systemReady', () => {
        //     IN.User.refresh();
        // });
    }

    drawUser(): SocialUser {
        const user: SocialUser = new SocialUser();
        user.authToken = this.oAuthToken;
        user.authorizationCode = this.oAuthVerifier;
        return user;
    }

    signOut(): Promise<any> {
        return new Promise((resolve, reject) => {
            IN.User.logout((response: any) => {
                resolve();
            }, (err: any) => {
                reject(err);
            });
        });
    }

    getLoginStatus(): Promise<SocialUser> {
        return Promise.resolve(undefined);
    }

    signIn(signInOptions?: any): Promise<SocialUser> {
        /* isRegisterAction flag i am using to check if the process is for registration or Login */
        /* socialMediaProvider is for name of social media , it is optional*/
        if (typeof signInOptions.url !== 'undefined') {
            const url = signInOptions.url + '?' + signInOptions.oauthTokenParam;
            // const url = signInOptions.url + '?scope=' + signInOptions.scope + '&response_type=' + signInOptions.response_type
            //     + '&client_id=' + signInOptions.client_id + '&redirect_uri=' + signInOptions.redirect_uri + '&state=' + signInOptions.state
            //     + '&code_challenge=' + signInOptions.code_challenge + '&code_challenge_method=' + signInOptions.code_challenge_method;
            return new Promise((resolve, reject) => {

                let loopCount = this.loopCount;

                /* Create the window object by passing url and optional window title */
                this.windowHandle = this.createOauthWindow(url, 'OAuth Twitter login');

                /* Now start the timer for which the window will stay, and after time over window will be closed */
                this.intervalId = window.setInterval(() => {
                    if (loopCount-- < 0) {
                        window.clearInterval(this.intervalId);
                        this.windowHandle.close();
                    } else {
                        let href: string;  // For referencing window url
                        try {
                            href = this.windowHandle.location.href; // set window location to href string
                        } catch (e) {
                            // console.log('Error:', e); // Handle any errors here
                        }
                        if (typeof href !== 'undefined' && href !== null && href !== '') {

                            // Method for getting query parameters from query string
                            const getQueryString = (field: any, urlQuery: string) => {
                                const windowLocationUrl = urlQuery ? urlQuery : href;
                                const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
                                const params = reg.exec(windowLocationUrl);
                                return params ? params[1] : null;
                            };
                            /* As i was getting code and oauth-token i added for same, you can replace with your expected variables */
                            if (href.match('code')) {
                                // for google , fb, github, linkedin
                                window.clearInterval(this.intervalId);
                                this.authorizationCode = getQueryString('code', href);
                                this.windowHandle.close();
                                resolve(this.drawUser());
                            } else if (href.match('oauth_token')) {
                                // for twitter
                                window.clearInterval(this.intervalId);
                                this.oAuthToken = getQueryString('oauth_token', href);
                                this.oAuthVerifier = getQueryString('oauth_verifier', href);
                                this.windowHandle.close();
                                resolve(this.drawUser());
                            }
                        }
                    }
                }, this.intervalLength);
            });
        }
    }

    createOauthWindow(url: string, name = 'Authorization', width = 500, height = 600, left = 0, top = 0): null | Window {
        if (url == null) {
            return null;
        }
        const options = `width=${width},height=${height},left=${left},top=${top}`;
        return window.open(url, name, options);
    }

}
