import { addDays, addHours, endOfDay, endOfMonth, startOfDay, subDays } from 'date-fns';
import {Post} from '../models/post';

export class CalendarFakeDb
{

    public static data = [
        {
            id  : 'events',
            // data: [
            //     {
            //         start    : subDays(startOfDay(new Date()), 1),
            //         end      : addDays(new Date(), 1),
            //         title    : 'A 3 day event',
            //         allDay   : true,
            //         color    : {
            //             primary  : '#F44336',
            //             secondary: '#FFCDD2'
            //         },
            //         resizable: {
            //             beforeStart: true,
            //             afterEnd   : true
            //         },
            //         draggable: true,
            //         meta     : {
            //             location: 'Los Angeles',
            //             notes   : 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.'
            //         },
            //         type: 'facebook',
            //         network: 'Network 1',
            //         mediaUrl: 'http://test.com',
            //         status: 'scheduled'
            //     },
            //     {
            //         start    : startOfDay(new Date()),
            //         end      : endOfDay(new Date()),
            //         title    : 'An event',
            //         allDay   : false,
            //         color    : {
            //             primary  : '#FF9800',
            //             secondary: '#FFE0B2'
            //         },
            //         resizable: {
            //             beforeStart: true,
            //             afterEnd   : true
            //         },
            //         draggable: true,
            //         meta     : {
            //             location: 'Los Angeles',
            //             notes   : 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.'
            //         },
            //         type: 'instagram',
            //         network: 'Network 2',
            //         mediaUrl: 'http://test.com',
            //         status: 'scheduled'
            //     },
            //     {
            //         start    : subDays(endOfMonth(new Date()), 3),
            //         end      : addDays(endOfMonth(new Date()), 3),
            //         title    : 'A long event that spans 2 months',
            //         allDay   : false,
            //         color    : {
            //             primary  : '#1E90FF',
            //             secondary: '#D1E8FF'
            //         },
            //         resizable: {
            //             beforeStart: true,
            //             afterEnd   : true
            //         },
            //         draggable: true,
            //         meta     : {
            //             location: 'Los Angeles',
            //             notes   : 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.'
            //         },
            //         type: 'linkedin',
            //         network: 'Network 4',
            //         mediaUrl: 'http://test.com',
            //         status: 'scheduled'
            //     },
            //     {
            //         start    : addHours(startOfDay(new Date()), 2),
            //         end      : new Date(),
            //         title    : 'A draggable and resizable event',
            //         allDay   : false,
            //         color    : {
            //             primary  : '#673AB7',
            //             secondary: '#D1C4E9'
            //         },
            //         resizable: {
            //             beforeStart: true,
            //             afterEnd   : true
            //         },
            //         draggable: true,
            //         meta     : {
            //             location: 'Los Angeles',
            //             notes   : 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.'
            //         },
            //         type: 'pinterest',
            //         network: 'Network 3',
            //         mediaUrl: 'http://test.com',
            //         status: 'scheduled'
            //     },
            //     {
            //         start    : addHours(startOfDay(new Date()), 2),
            //         end      : new Date(),
            //         title    : 'A draggable and resizable event',
            //         allDay   : false,
            //         color    : {
            //             primary  : '#673AB7',
            //             secondary: '#D1C4E9'
            //         },
            //         resizable: {
            //             beforeStart: true,
            //             afterEnd   : true
            //         },
            //         draggable: true,
            //         meta     : {
            //             location: 'Los Angeles',
            //             notes   : 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.'
            //         },
            //         type: 'youtube',
            //         network: 'Network 6',
            //         mediaUrl: 'http://test.com',
            //         status: 'scheduled'
            //     },
            //     {
            //         start    : addHours(startOfDay(new Date()), 2),
            //         end      : new Date(),
            //         title    : 'A draggable and resizable event',
            //         allDay   : false,
            //         color    : {
            //             primary  : '#673AB7',
            //             secondary: '#D1C4E9'
            //         },
            //         resizable: {
            //             beforeStart: true,
            //             afterEnd   : true
            //         },
            //         draggable: true,
            //         meta     : {
            //             location: 'Los Angeles',
            //             notes   : 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.'
            //         },
            //         type: 'twitter',
            //         network: 'Network 7',
            //         mediaUrl: 'http://test.com',
            //         status: 'scheduled'
            //     }
            // ],

            data: [
                {
                    id: 1,
                    postDate: addHours(startOfDay(new Date()), 2),
                    text: 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.',
                    type: 'facebook',
                    network: {
                        id: 1,
                        name: 'Network 4',
                        logo: 'http://localhost:4200/assets/images/avatars/alice.jpg',
                        type: 'facebook',
                        token: 'sadfasdfasdfasdf'
                    },
                    media: [
                        {
                            id: 4,
                            label: 'Deer',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1003/1181/1772.jpg?hmac=oN9fHMXiqe9Zq2RM6XT-RVZkojgPnECWwyEF1RvvTZk',
                            fileSize: ''
                        }
                    ],
                    status: 'sent',
                    tags: [],
                    editable: true,
                    comments: [{
                        id: '2134hv3g45898345n22354bh',
                        text: 'Asdasd asdasss asdasd asdadasd asdasdaasdasd.',
                        contactId: '5725a6802d10e277a0f35775',
                        contactAvatar: 'assets/images/avatars/Shauna.jpg',
                        contactName: '@testingUser',
                        postDate: subDays(startOfDay(new Date()), 1),
                        tags: [],
                        answers: [{
                            id: '2134hv3g45898345n22354bh',
                            text: 'Asdasd asdasss asdasd asdadasd asdasdaasdasd.',
                            contactId: '5725a6802d10e277a0f35775',
                            contactAvatar: 'assets/images/avatars/Shauna.jpg',
                            contactName: '@testingUser',
                            postDate: subDays(startOfDay(new Date()), 1),
                            tags: [],
                            answers: [{
                                id: '2134hv3g45898345n22354bh',
                                text: 'Asdasd asdasss asdasd asdadasd asdasdaasdasd.',
                                contactId: '5725a6802d10e277a0f35775',
                                contactAvatar: 'assets/images/avatars/Shauna.jpg',
                                contactName: '@testingUser',
                                postDate: subDays(startOfDay(new Date()), 1),
                                tags: [],
                                answers: [],
                            }],
                        },
                            {
                                id: '2134hv3g45898345n22354bh',
                                text: 'Asdasd asdasss asdasd asdadasd asdasdaasdasd.',
                                contactId: '5725a6802d10e277a0f35775',
                                contactAvatar: 'assets/images/avatars/Shauna.jpg',
                                contactName: '@testingUser',
                                postDate: subDays(startOfDay(new Date()), 1),
                                tags: [],
                                answers: [],
                            }],
                    }]
                } as unknown as Post,
                {
                    id: 2,
                    postDate: startOfDay(new Date()),
                    text: 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.',
                    type: 'instagram',
                    network: {
                        id: 1,
                        name: 'Network 2',
                        logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                        type: 'instagram',
                        token: 'sadfasdfasdfasdf'
                    },
                    media: [
                        {
                            id: 2,
                            label: 'Beach',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1001/5616/3744.jpg?hmac=38lkvX7tHXmlNbI0HzZbtkJ6_wpWyqvkX4Ty6vYElZE',
                            fileSize: ''
                        }
                    ],
                    status: 'sent',
                    tags: []
                } as unknown as Post,
                {
                    id: 3,
                    postDate: subDays(endOfMonth(new Date()), 3),
                    text: 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.',
                    type: 'linkedin',
                    network: {
                        id: 1,
                        name: 'Network 3',
                        logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                        type: 'linkedin',
                        token: 'sadfasdfasdfasdf'
                    },
                    media: [
                        {
                            id: 3,
                            label: 'World',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1002/4312/2868.jpg?hmac=5LlLE-NY9oMnmIQp7ms6IfdvSUQOzP_O3DPMWmyNxwo',
                            fileSize: ''
                        }
                    ],
                    status: 'sent',
                    tags: []
                } as unknown as Post,
                {
                    id: 4,
                    postDate: addHours(startOfDay(new Date()), 2),
                    text: 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.',
                    type: 'facebook',
                    network: {
                        id: 1,
                        name: 'Network 4',
                        logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                        type: 'facebook',
                        token: 'sadfasdfasdfasdf'
                    },
                    media: [
                        {
                            id: 4,
                            label: 'Deer',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1003/1181/1772.jpg?hmac=oN9fHMXiqe9Zq2RM6XT-RVZkojgPnECWwyEF1RvvTZk',
                            fileSize: '',
                            type: 'image/jpg',
                            videoPreviewUrl: '',
                            width: 1181,
                            height: 1772
                        }
                    ],
                    status: 'sent',
                    tags: [
                        {
                            id: 1,
                            name: 'First',
                            description: '',
                            status: 'ACTIVE'
                        },
                        {
                            id: 2,
                            name: 'Second',
                            description: '',
                            status: 'ACTIVE'
                        }
                    ],
                    audiences: {
                        minimumAge: 15,
                        countries: [{label: 'Canada'}, {label: 'United States'}],
                        regions: [{label: 'Ontario, Canada'}, {label: 'Florida, United States'}],
                        cities: [{label: 'West Palm Beach, Canada'}, {label: 'West Palm Beach, United States'}],
                        zipCodes: [{label: '33418'}, {label: '33412'}]
                    },
                    location: {
                        label: '4435 Brady Ln, Palm Beach Gardens FL, 33418'
                    }
                } as unknown as Post,
                {
                    id: 5,
                    postDate: addHours(startOfDay(new Date()), 2),
                    text: 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.',
                    type: 'linkedin',
                    network: {
                        id: 1,
                        name: 'Network 5',
                        logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                        type: 'linkedin',
                        token: 'sadfasdfasdfasdf'
                    },
                    media: [
                        {
                            id: 1,
                            label: 'Label 1',
                            altText: [],
                            mediaUrl: 'https://pixabay.com/get/55e0d340485aa814f6da8c7dda7936791c36dcec5a506c48732f7bd69f48cd5bb9_1280.jpg',
                            fileSize: 3574625,
                            height: 4000,
                            width: 6000,
                            previewUrl: 'https://pixabay.com/get/55e0d340485aa814f1dc8460962930771c3dd7ec524c704f752f79dc9245c659_640.jpg'
                        },
                        {
                            id: 1,
                            label: 'Label 1',
                            altText: [],
                            mediaUrl: 'https://pixabay.com/get/55e0d340485aa814f6da8c7dda7936791c36dcec5a506c48732f7bd69f48cd5bb9_1280.jpg',
                            fileSize: 3574625,
                            height: 4000,
                            width: 6000,
                            previewUrl: 'https://pixabay.com/get/55e0d340485aa814f1dc8460962930771c3dd7ec524c704f752f79dc9245c659_640.jpg'
                        },
                        {
                            id: 1,
                            label: 'Label 1',
                            altText: [],
                            mediaUrl: 'https://pixabay.com/get/55e0d340485aa814f6da8c7dda7936791c36dcec5a506c48732f7bd69f48cd5bb9_1280.jpg',
                            fileSize: 3574625,
                            height: 4000,
                            width: 6000,
                            previewUrl: 'https://pixabay.com/get/55e0d340485aa814f1dc8460962930771c3dd7ec524c704f752f79dc9245c659_640.jpg'
                        },
                        {
                            id: 1,
                            label: 'Label 1',
                            altText: [],
                            mediaUrl: 'https://pixabay.com/get/55e0d340485aa814f6da8c7dda7936791c36dcec5a506c48732f7bd69f48cd5bb9_1280.jpg',
                            fileSize: 3574625,
                            height: 4000,
                            width: 6000,
                            previewUrl: 'https://pixabay.com/get/55e0d340485aa814f1dc8460962930771c3dd7ec524c704f752f79dc9245c659_640.jpg'
                        },
                        {
                            id: 1,
                            label: 'Label 1',
                            altText: [],
                            mediaUrl: 'https://pixabay.com/get/55e0d340485aa814f6da8c7dda7936791c36dcec5a506c48732f7bd69f48cd5bb9_1280.jpg',
                            fileSize: 3574625,
                            height: 4000,
                            width: 6000,
                            previewUrl: 'https://pixabay.com/get/55e0d340485aa814f1dc8460962930771c3dd7ec524c704f752f79dc9245c659_640.jpg'
                        },
                        {
                            id: 1,
                            label: 'Label 1',
                            altText: [],
                            mediaUrl: 'https://pixabay.com/get/55e0d340485aa814f6da8c7dda7936791c36dcec5a506c48732f7bd69f48cd5bb9_1280.jpg',
                            fileSize: 3574625,
                            height: 4000,
                            width: 6000,
                            previewUrl: 'https://pixabay.com/get/55e0d340485aa814f1dc8460962930771c3dd7ec524c704f752f79dc9245c659_640.jpg'
                        },
                        {
                            id: 1,
                            label: 'Label 1',
                            altText: [],
                            mediaUrl: 'https://pixabay.com/get/55e0d340485aa814f6da8c7dda7936791c36dcec5a506c48732f7bd69f48cd5bb9_1280.jpg',
                            fileSize: 3574625,
                            height: 4000,
                            width: 6000,
                            previewUrl: 'https://pixabay.com/get/55e0d340485aa814f1dc8460962930771c3dd7ec524c704f752f79dc9245c659_640.jpg'
                        }

                    ],
                    status: 'sent',
                    tags: [
                        {
                            id: 1,
                            name: 'First',
                            description: '',
                            status: 'ACTIVE'
                        },
                        {
                            id: 2,
                            name: 'Second',
                            description: '',
                            status: 'ACTIVE'
                        }
                    ]
                } as unknown as Post,
                {
                    id: 6,
                    postDate: addHours(startOfDay(new Date()), 2),
                    text: 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.',
                    type: 'twitter',
                    network: {
                        id: 1,
                        name: 'Network 6',
                        logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                        type: 'twitter',
                        token: 'sadfasdfasdfasdf'
                    },
                    media: [
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        }
                    ],
                    status: 'sent',
                    tags: []
                } as unknown as Post,

                {
                    id: 7,
                    postDate: addHours(startOfDay(new Date()), 2),
                    text: 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.',
                    type: 'twitter',
                    network: {
                        id: 1,
                        name: 'Network 8',
                        logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                        type: 'twitter',
                        token: 'sadfasdfasdfasdf'
                    },
                    media: [
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        }
                    ],
                    status: 'sent',
                    tags: []
                } as unknown as Post,
                {
                    id: 8,
                    postDate: addHours(startOfDay(new Date()), 2),
                    text: 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.',
                    type: 'twitter',
                    network: {
                        id: 1,
                        name: 'Network 9',
                        logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                        type: 'twitter',
                        token: 'sadfasdfasdfasdf'
                    },
                    media: [
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        }
                    ],
                    status: 'sent',
                    tags: []
                } as unknown as Post,
                {
                    id: 9,
                    postDate: addHours(startOfDay(new Date()), 2),
                    text: 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.',
                    type: 'twitter',
                    network: {
                        id: 1,
                        name: 'Network 10',
                        logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                        type: 'twitter',
                        token: 'sadfasdfasdfasdf'
                    },
                    media: [
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        }
                    ],
                    status: 'sent',
                    tags: []
                } as unknown as Post,
                {
                    id: 10,
                    postDate: addHours(startOfDay(new Date()), 2),
                    text: 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.',
                    type: 'twitter',
                    network: {
                        id: 1,
                        name: 'Network 11',
                        logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                        type: 'twitter',
                        token: 'sadfasdfasdfasdf'
                    },
                    media: [
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        }
                    ],
                    status: 'sent',
                    tags: []
                } as unknown as Post,
                {
                    id: 11,
                    postDate: addHours(startOfDay(new Date()), 2),
                    text: 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.',
                    type: 'twitter',
                    network: {
                        id: 1,
                        name: 'Network 12',
                        logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                        type: 'twitter',
                        token: 'sadfasdfasdfasdf'
                    },
                    media: [
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        }
                    ],
                    status: 'sent',
                    tags: []
                } as unknown as Post,
                {
                    id: 12,
                    postDate: addHours(startOfDay(new Date()), 2),
                    text: 'Eos eu verear adipiscing, ex ornatus denique iracundia sed, quodsi oportere appellantur an pri.',
                    type: 'twitter',
                    network: {
                        id: 1,
                        name: 'Network 13',
                        logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                        type: 'twitter',
                        token: 'sadfasdfasdfasdf'
                    },
                    media: [
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        },
                        {
                            id: 6,
                            label: 'River',
                            altText: [],
                            mediaUrl: 'https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I',
                            fileSize: ''
                        }
                    ],
                    status: 'sent',
                    tags: []
                } as unknown as Post,
            ]
        },
        {
            id: 'networks',
            data: [
                {
                    id: 1,
                    name: 'Network 1',
                    // tslint:disable-next-line:max-line-length
                    logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                    type: 'facebook',
                    token: 'sadfasdfasdfasdf',
                    teams: [],
                    members: []
                }, {
                    id: 2,
                    name: 'Network 2',
                    // tslint:disable-next-line:max-line-length
                    logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                    type: 'instagram',
                    token: 'sadfasdfasdfasdf',
                    teams: [],
                    members: []
                }, {
                    id: 3,
                    name: 'Network 3',
                    // tslint:disable-next-line:max-line-length
                    logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                    type: 'linkedin',
                    token: 'sadfasdfasdfasdf',
                    teams: [],
                    members: []
                }, {
                    id: 4,
                    name: 'Network 4',
                    // tslint:disable-next-line:max-line-length
                    logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                    type: 'facebook',
                    token: 'sadfasdfasdfasdf',
                    teams: [],
                    members: []
                }, {
                    id: 5,
                    name: 'Network 5',
                    // tslint:disable-next-line:max-line-length
                    logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                    type: 'linkedin',
                    token: 'sadfasdfasdfasdf',
                    teams: [],
                    members: []
                }, {
                    id: 6,
                    name: 'Network 6',
                    // tslint:disable-next-line:max-line-length
                    logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                    type: 'twitter',
                    token: 'sadfasdfasdfasdf',
                    teams: [],
                    members: []
                }, {
                    id: 8,
                    name: 'Network 8',
                    // tslint:disable-next-line:max-line-length
                    logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                    type: 'twitter',
                    token: 'sadfasdfasdfasdf',
                    teams: [],
                    members: []
                }, {
                    id: 9,
                    name: 'Network 9',
                    // tslint:disable-next-line:max-line-length
                    logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                    type: 'twitter',
                    token: 'sadfasdfasdfasdf',
                    teams: [],
                    members: []
                }, {
                    id: 10,
                    name: 'Network 10',
                    // tslint:disable-next-line:max-line-length
                    logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                    type: 'twitter',
                    token: 'sadfasdfasdfasdf',
                    teams: [],
                    members: []
                }, {
                    id: 11,
                    name: 'Network 11',
                    // tslint:disable-next-line:max-line-length
                    logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                    type: 'twitter',
                    token: 'sadfasdfasdfasdf',
                    teams: [],
                    members: []
                }, {
                    id: 12,
                    name: 'Network 12',
                    // tslint:disable-next-line:max-line-length
                    logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                    type: 'twitter',
                    token: 'sadfasdfasdfasdf',
                    teams: [],
                    members: []
                }, {
                    id: 13,
                    name: 'Network 13',
                    // tslint:disable-next-line:max-line-length
                    logo: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/p200x200/19149104_803003523208423_2489101595324721737_n.jpg?_nc_cat=103&_nc_sid=0c64ff&_nc_oc=AQl-ZoD95QYaXFRI7M72G2Hahzl8yo7rXJbC_aBMkyreRvXk3_wI1VL5Z1nSdFlPFGs&_nc_ht=scontent-iad3-1.xx&_nc_tp=6&oh=2bcad0932d9ebfc1f665885c631855c8&oe=5F2C79A7',
                    type: 'twitter',
                    token: 'sadfasdfasdfasdf',
                    teams: [],
                    members: []
                }
            ]
        },
        {
            id: 'media-libraries',
            data: [
                {
                    id: 1,
                    name: 'Pixabay',
                    token: '23423423532452535235235',
                    status: 'active',
                    baseUrl: 'https://pixabay.com/api/',
                    className: 'Pixabay'
                },
                {
                    id: 2,
                    name: 'Giphy',
                    token: '5364525346234362465',
                    status: 'active',
                    baseUrl: 'https://api.giphy.com/v1/gifs/search',
                    className: 'Giphy'
                }
            ]
        },
        {
            id: 'tags',
            data: [
                {
                    id: 1,
                    name: 'First',
                    description: '',
                    status: 'ACTIVE'
                }, {
                    id: 2,
                    name: 'Second',
                    description: '',
                    status: 'ACTIVE'
                }, {
                    id: 3,
                    name: 'Third',
                    description: '',
                    status: 'ACTIVE'
                }
            ]
        }
    ];
}
