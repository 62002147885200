import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Observable, Subject} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

import { Member } from 'app/main/apps/social-media-management/member/member.model';
import { SocialMediaManagementMemberService } from 'app/main/apps/social-media-management/member/member.service';
import {Network} from '../../../../models/network';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Team} from '../../../../models/team';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {ManageNetworksService} from '../../manage-networks/manage-networks.service';
import {SocialMediaManagementTeamsService} from '../teams/teams.service';
import {MatChipInputEvent} from '@angular/material/chips';
import {roles} from '../../../../models/member';
import {Agency} from '../agency/agency.component';
import {AgenciesService} from '../agencies/agencies.service';
import {CommonAuthService} from '../../../../auth/common-auth.service';

@Component({
    selector     : 'social-media-management-member',
    templateUrl  : './member.component.html',
    styleUrls    : ['./member.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class SocialMediaManagementMemberComponent implements OnInit, OnDestroy
{
    member: any;
    pageType: string;
    memberForm: FormGroup;

    selectedNetworks: Array<any> = [];
    selectedTeams: Array<any> = [];
    teams: Array<any> = [];

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = false;
    separatorKeysCodes = [ENTER, COMMA];

    networkCtrl = new FormControl();
    filteredNetworks: Observable<any[]>;
    networks: Array<any> = [];

    teamCtrl = new FormControl();
    filteredTeams: Observable<any[]>;
    allTeams = [];

    inputLoader = false;
    roles = roles;

    @ViewChild('networkInput') networkInput: ElementRef;
    @ViewChild('autoNet') matAutocompleteNetwork: MatAutocomplete;

    @ViewChild('teamInput') teamInput: ElementRef;
    @ViewChild('autoTeam') matAutocompleteTeam: MatAutocomplete;

    agencies: Array<Agency> = [];

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     * @param {ManageNetworksService} networksService
     * @param {SocialMediaManagementTeamsService} teamsService
     * @param {SocialMediaManagementMemberService} _ecommerceMemberService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private _ecommerceMemberService: SocialMediaManagementMemberService,
        private _formBuilder: FormBuilder,
        private _location: Location,
        private _matSnackBar: MatSnackBar,
        private networksService: ManageNetworksService,
        private teamsService: SocialMediaManagementTeamsService,
        private agenciesService: AgenciesService,
        private userAuth: CommonAuthService
    )
    {
        // Set the default
        this.member = new Member();

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        console.log(this.userAuth.userLogged);

        this.filteredNetworks = this.networkCtrl.valueChanges.pipe(
            startWith(null),
            map((network: string | null) => network ? this._filter(network) : this.networks.slice()));

        this.filteredTeams = this.teamCtrl.valueChanges.pipe(
            startWith(null),
            map((team: string | null) => team ? this.filterTeam(team) : this.teams.slice()));

        this.agenciesService.pullAgencies().subscribe(
            response => {
                this.agencies = response.agencies;
            }, error => console.log(error)
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to update member on changes
        this._ecommerceMemberService.onMemberChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(member => {

                if ( member )
                {
                    this.member = new Member(member);
                    this.pageType = 'edit';
                    this.selectedNetworks = this.member.networks;
                    this.selectedTeams = this.member.teams;
                }
                else
                {
                    this.pageType = 'new';
                    this.member = new Member();
                }

                this.memberForm = this.createMemberForm();
            });
        this.getNetworks();
        this.getTeams();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create member form
     *
     * @returns {FormGroup}
     */
    createMemberForm(): FormGroup
    {
        return this._formBuilder.group({
            id              : [this.member.id],
            name            : [this.member.name],
            email            : [this.member.email, [Validators.email]],
            password            : [''],
            confirmPassword            : [''],
            handle          : [this.member.handle],
            description     : [this.member.description],
            networks      : [this.member.networks],
            teams            : [this.member.teams],
            profile_photo          : [this.member.profile_photo],
            role          : [this.member.role],
            agency_id          : [this.member.agency_id]
        }, {validator: this.checkPasswords });
    }

    checkPasswords(group: FormGroup): any {
        const pass = group.get('password').value;
        const id = group.get('id').value;
        const confirmPassword = group.get('confirmPassword').value;
        if (pass !== confirmPassword) {
            group.get('confirmPassword').setErrors( {MatchPassword: true} );
        } else if (confirmPassword === '' && (typeof id === 'undefined' || id === null)) {
            group.get('confirmPassword').setErrors( {required: true} );
        } else {
            return null;
        }
    }

    /**
     * Save member
     */
    saveMember(): void
    {
        const data = this.memberForm.getRawValue();
        console.log(data);
        data.handle = FuseUtils.handleize(data.name);

        this._ecommerceMemberService.saveMember(data)
            .then(() => {

                // Trigger the subscription with new data
                this._ecommerceMemberService.onMemberChanged.next(data);

                // Show the success message
                this._matSnackBar.open('Member saved', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }

    /**
     * Add member
     */
    addMember(): void
    {
        const data = this.memberForm.getRawValue();
        data.handle = FuseUtils.handleize(data.name);

        this._ecommerceMemberService.addMember(data)
            .then(() => {

                // Trigger the subscription with new data
                this._ecommerceMemberService.onMemberChanged.next(data);

                // Show the success message
                this._matSnackBar.open('Member added', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });

                // Change the location with new one
                this._location.go('apps/social-media-management/members/' + this.member.id + '/' + this.member.handle);
            });
    }

    private updateNetInput(): void {
        this.memberForm.controls.networks.setValue(this.selectedNetworks);
    }

    private updateTeamInput(): void {
        this.memberForm.controls.teams.setValue(this.selectedTeams);
    }

    selectAllNetworks(): void {
        this.selectedNetworks = [];
        for (const i in this.networks) {
            this.selectedNetworks.push(this.networks[i]);
        }
        this.updateNetInput();
    }

    unSelectAllNetworks(): void {
        this.selectedNetworks = [];
        this.updateNetInput();
    }

    selectAllTeams(): void {
        this.selectedTeams = [];
        for (const i in this.teams) {
            this.selectedTeams.push(this.teams[i]);
        }
        this.updateTeamInput();
    }

    unSelectAllTeas(): void {
        this.selectedTeams = [];
        this.updateNetInput();
    }

    private getNetworks(): void {
        this.inputLoader = true;
        this.networksService.getNetworks().subscribe(
            response => {
                this.networks = response.data;
                this.inputLoader = false;
            },
            error => { this.inputLoader = false; console.log(error); }
        );
    }

    private getTeams(): void {
        this.inputLoader = true;
        this.teamsService.getTeams2().subscribe(
            response => {
                this.teams = response.groups;
                this.inputLoader = false;
            },
            error => { this.inputLoader = false; console.log(error); }
        );
    }

    addNetwork(event: MatChipInputEvent): void {
        this.updateNetInput();
    }

    removeNetwork(network: Network): void {
        const index = this.selectedNetworks.indexOf(network);
        if (index >= 0) {
            this.selectedNetworks.splice(index, 1);
        }
        this.networkInput.nativeElement.value = '';
        this.updateNetInput();
    }

    private _filter(value: string): Network[] {
        const filterValue = (typeof value['name'] !== 'undefined') ? value['name'].toLowerCase() : value.toLowerCase();
        return this.networks.filter(
            network => network.name.toLowerCase().indexOf(filterValue) === 0
                && this.selectedNetworks.findIndex(item => item.name === network.name) === -1
        );
    }

    selectedNetwork(event: MatAutocompleteSelectedEvent): void {
        this.selectedNetworks.push(event.option.value);
        this.networkInput.nativeElement.value = '';
        this.networkCtrl.setValue(null);
        this.updateNetInput();
    }

    addTeam(event: MatChipInputEvent): void {
        this.updateTeamInput();
    }

    removeTeam(team: any): void {
        const index = this.selectedTeams.indexOf(team);
        if (index >= 0) {
            this.selectedTeams.splice(index, 1);
        }
        this.teamInput.nativeElement.value = '';
        this.updateTeamInput();
    }

    filterTeam(value: string): Team[] {
        const filterValue = (typeof value['name'] !== 'undefined') ? value['name'].toLowerCase() : value.toLowerCase();
        return this.teams.filter(
            team => team.name.toLowerCase().indexOf(filterValue) === 0
                && this.selectedTeams.findIndex(item => item.name === team.name) === -1
        );
    }

    selectedTeam(event: MatAutocompleteSelectedEvent): void {
        this.selectedTeams.push(event.option.value);
        this.teamInput.nativeElement.value = '';
        this.teamCtrl.setValue(null);
        this.updateTeamInput();
    }
}
