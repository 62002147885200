import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {Post} from '../../../models/post';
import {Media} from '../../../models/media';
import {TagsService} from '../../../main/apps/tags/tags.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Network} from '../../../models/network';

@Injectable({ providedIn: 'root' })
export class PostsService {
    private subject = new Subject<Post[]>();
    private posts: Post[] = [];

    constructor(
        private postService: PostService,
        private postTextService: PostTextService,
        private postMediasService: PostMediasService,
        private tagsService: TagsService,
        private _httpClient: HttpClient
    ) {
        this.postService.getPost().subscribe(
            response => { this.posts.push(response); this.subject.next(this.posts); }
        );
        this.postTextService.getText().subscribe(
            response => this.updateAllPosts('text', response)
        );
        this.postMediasService.getMedias().subscribe(
            response => { this.updateAllPosts('media', response); }
        );
        this.tagsService.getTags().subscribe(
            response => { this.updateAllPosts('tags', response); }
        );
    }

    addPosts(posts: Post[]): void {
        this.posts.push(...posts);
        this.subject.next(posts);
    }

    updatePost(index, post): void {
        this.posts[index] = post;
        this.subject.next(this.posts);
    }

    updatePostProperty(index, property: string, value: any): void {
        if (typeof this.posts[index][property] !== 'undefined') {
            this.posts[index][property] = value;
            this.subject.next(this.posts);
        }
    }

    updateAllPosts(property: string, value: any): void {
        this.posts.map(item => (typeof item[property] !== 'undefined') ? item[property] = value : item);
        this.subject.next(this.posts);
    }

    updateLocation(location: any, postType: string): void {
        this.posts.map(item => (typeof item['location'] !== 'undefined' && item.network.network_type.toLowerCase() === postType) ? item['location'] = location : item);
        this.subject.next(this.posts);
    }

    deletePost(index): void {
        this.posts.splice(index, 1);
        this.subject.next(this.posts);
    }

    removePost(id: number | bigint): void {
        this.posts.splice(this.posts.findIndex(item => item.id === id), 1);
        this.subject.next(this.posts);
    }

    removeUnpostedPosts(unpostedId: number | bigint): Observable<any> {
        return this._httpClient.delete(`${environment.apiUrl}posts/unposted/` + unpostedId);
    }

    removeAllUnpostedPosts(postIds: string): Observable<any> {
        return this._httpClient.delete(`${environment.apiUrl}allposts/unposted/` + postIds);
    }

    cleanPosts(): void {
        this.posts = [];
        this.subject.next(this.posts);
    }

    getUnpostedPosts(groupId: string, type: string): Observable<any> {
        return this._httpClient.get(`${environment.apiUrl}posts/unposted/` + groupId + '/' + type);
    }

    clearPosts(properties: Array<{property: string, value: any}> = []): void {
        if (properties.length > 0) {
            for (const prop of properties) {
                this.posts.map(item => (typeof item[prop.property] !== 'undefined') ? item[prop.property] = prop.value : item);
            }
            const newPosts = [...this.posts];
            this.posts = [];
            this.posts = newPosts;
        } else {
            this.posts = [];
        }
        this.subject.next();
    }

    getPosts(): Observable<Post[]> {
        return this.subject.asObservable();
    }

    saveSeveralPosts( postsData: any, saveType = 'post', type: 'add' | 'edit' = 'add'): Observable<any> {
        if (saveType === 'save_draft') {
            return (type === 'add') ? this._httpClient.post(`${environment.apiUrl}posts/drafts`, postsData) : this._httpClient.put(`${environment.apiUrl}posts/drafts`, postsData);
        } else {
            return (type === 'add') ? this._httpClient.post(`${environment.apiUrl}posts`, postsData)
                : this._httpClient.put(`${environment.apiUrl}posts`, postsData);
        }
    }

    getVideoFacebookPostRealIds(ids: Array<string>): Observable<any> {
        return this._httpClient.get(`${environment.apiUrl}posts-valid-id?ids[]=` + ids.join('&ids[]='));
    }
}

@Injectable({ providedIn: 'root' })
export class PostService {
    private subject = new Subject<Post>();
    linkedinUrl = '/linkedin-v2/';

    constructor(private _httpClient: HttpClient) {}

    addPost(post: Post): void {
        this.subject.next(post);
    }

    deletePost(): void {
        this.subject.next();
    }

    getPost(): Observable<Post> {
        return this.subject.asObservable();
    }

    saveNewPost(post: Post): Observable<Post> {
        return this._httpClient.post<Post>('/new-post', post);
    }

    updatePost(post: Post): Observable<Post> {
        return this._httpClient.put<Post>('/update-post', post);
    }

    updatePostProperty(property: string, value: any, postId: string, networkId: string): Observable<Post> {
        return this._httpClient.put<Post>(`${environment.apiUrl}posts/update-property`, {property, value, post_id: postId, network_id: networkId});
    }

    removePost(post: Post): Observable<any> {
        return this._httpClient.delete(`${environment.apiUrl}posts` + '/' + post.network.network_id + '/' + post.status + '/' +
        ((post.network.network_type.toLowerCase() === 'twitter' && post.status !== 'draft' && post.status !== 'scheduled') ? post['id_str'] : post.id));
    }

    likeManagment(like: boolean, token: string, postId: string): Observable<boolean> {
        return like ? this._httpClient.post<boolean>('https://graph.facebook.com/v7.0/' + postId + '/likes?access_token=' + token, {})
            : this._httpClient.delete<boolean>('https://graph.facebook.com/v7.0/' + postId + '/likes?access_token=' + token);
    }

    likeLinkedinManagment(like: boolean, network: Network, postId: string): Observable<any> {
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + network.auth_token
        //     })
        // };
        // if (like) {
        //     const data = {
        //         actor: 'urn:li:organization:' + network.network_id,
        //         object: postId
        //     };
        //     return this._httpClient.post(this.linkedinUrl + 'socialActions/' + postId + '/likes', data, httpOptions);
        // } else {
        //     return this._httpClient.delete(
        //         this.linkedinUrl + 'socialActions/' + postId + '/likes/'
        //         + 'urn:li:organization:' + network.network_id + '?actor=' + 'urn:li:organization:' + network.network_id,
        //         httpOptions
        //     );
        // }
        const wholePostId = postId.split(':');

        if (like) {
            return this._httpClient.get(`${environment.apiUrl}` + 'linkedin/post/like/' + wholePostId[wholePostId.length - 1] + '/' + network.network_id);
        } else {
            return this._httpClient.delete(`${environment.apiUrl}` + 'linkedin/post/like/' + wholePostId[wholePostId.length - 1] + '/' + network.network_id);
        }
    }

    getLinkPreview(link: string): Observable<any> {
        return this._httpClient.get(`${environment.apiUrl}` + 'link-preview?url=' + link);
    }

    reshareLinkedinPost(network: Network, postId: string, text: string, postType = 'regular'): Observable<any> {
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + network.auth_token
        //     })
        // };
        // const wholePostId = postId.split(':');
        // const data = {
        //     // originalShare: postId,
        //     // resharedShare: postId,
        //     // text: {
        //     //     text: text
        //     // },
        //     // owner: 'urn:li:organization:' + network.network_id,
        //     // distribution: {
        //     //     linkedInDistributionTarget: {}
        //     // }
        //
        //     lifecycleState: 'PUBLISHED',
        //     visibility: {
        //         'com.linkedin.ugc.MemberNetworkVisibility': 'PUBLIC'
        //     },
        //     specificContent: {
        //         'com.linkedin.ugc.ShareContent': {
        //             shareMediaCategory: 'NONE',
        //             shareCommentary: {
        //                 text: text
        //             },
        //             media: [],
        //             shareCategorization: {}
        //         }
        //     },
        //     author: 'urn:li:organization:' + network.network_id,
        //     responseContext: {
        //         parent: postId
        //     }
        // };
        // return this._httpClient.post(this.linkedinUrl + 'ugcPosts', data, httpOptions);
        const wholePostId = postId.split(':');
        const data = {
            text: text,
            type: postType
        };
        return this._httpClient.post(`${environment.apiUrl}` + 'linkedin/post/share/' + wholePostId[wholePostId.length - 1] + '/' + network.network_id, data);
    }

    reshareTwitterPost(network: Network, postId: string, screenName: string, text: string, postType = 'regular'): Observable<any> {
        // const wholePostId = postId.split(':');
        const data = {
            status: text,
            type: postType
        };
        return this._httpClient.post(`${environment.apiUrl}` + 'twitter/share/' + screenName + '/' + network.network_id + '/' + postId, data);
    }

    retweet(networkId: string, postId: string): Observable<boolean> {
        return this._httpClient.get<boolean>(`${environment.apiUrl}twitter/retweet/` + networkId + '/' + postId);
    }
    unretweet(networkId: string, postId: string): Observable<boolean> {
        return this._httpClient.get<boolean>(`${environment.apiUrl}twitter/unretweet/` + networkId + '/' + postId);
    }

    likeTwitter(networkId: string, postId: string): Observable<boolean> {
        return this._httpClient.get<boolean>(`${environment.apiUrl}twitter/like/` + networkId + '/' + postId);
    }
    unLikeTwitter(networkId: string, postId: string): Observable<boolean> {
        return this._httpClient.get<boolean>(`${environment.apiUrl}twitter/unlike/` + networkId + '/' + postId);
    }

    linkedinFollowersStatsValidation(networks: Array<string>, dateRange: {start: '', end: ''} = null, granularity: string = '') {
        return this._httpClient.post(`${environment.apiUrl}` + 'linkedin/followers/stats/validation', {networks: networks, dateRange: dateRange, granularity: granularity});
    }

}

@Injectable({ providedIn: 'root' })
export class PostTextService {
    private subject = new Subject<string>();

    addText(text: string): void {
        this.subject.next(text);
    }

    deleteText(): void {
        this.subject.next();
    }

    getText(): Observable<string> {
        return this.subject.asObservable();
    }
}

@Injectable({ providedIn: 'root' })
export class PostMediasService {
    private subject = new Subject<Media[]>();
    private medias: Media[] = [];

    constructor(
        private mediaService: MediaService,
        private _httpClient: HttpClient
    ) {
        this.mediaService.getMedia().subscribe(
            response => { this.medias.push(response); this.subject.next(this.medias); }
        );
    }

    addMedias(medias: Media[]): void {
        this.medias.push(...medias);
        this.subject.next(medias);
    }

    updateMedia(index: number, media: Media): void {
        this.medias[index] = media;
        this.subject.next(this.medias);
    }

    updateMediaProperties(index: number, propertiesAndValues: {}): void {
        for (const property of Object.keys(propertiesAndValues)) {
            if (typeof this.medias[index][property] !== 'undefined') {
                this.medias[index][property] = propertiesAndValues[property];
            }
        }
        this.subject.next(this.medias);
    }

    updateAllMedias(property: string, value: any): void {
        this.medias.map(item => item[property] =  value);
        this.subject.next(this.medias);
    }

    deleteMedia(index, fromType = 'edit'): void {
        if (fromType === 'add') {
            // @ts-ignore
            this.removeServerMedias([this.medias[index]]).subscribe();
        }
        this.medias.splice(index, 1);
        this.subject.next(this.medias);
    }

    changeOrderMedias(previousIndex: number, currentIndex: number): void {
        const media = this.medias[previousIndex];
        this.medias.splice(previousIndex, 1);
        this.medias.splice(currentIndex, 0, media);
        this.subject.next(this.medias);
    }

    deleteAllMedias(): void {
        this.medias = [];
        this.subject.next(null);
    }

    removeServerMedias(medias: Array<{id: bigint, path: string, pathPreview: string}>): Observable<any> {
        return this._httpClient.put(`${environment.apiUrl}` + 'post/medias/delete', medias);
    }

    getMedias(): Observable<Media[]> {
        return this.subject.asObservable();
    }
}

@Injectable({ providedIn: 'root' })
export class MediaService {
    private subject = new Subject<Media>();

    addMedia(media: Media): void {
        this.subject.next(media);
    }

    deleteMedia(): void {
        this.subject.next();
    }

    getMedia(): Observable<Media> {
        return this.subject.asObservable();
    }

    getMediaInfo(info: any, networkTypes = []): any {
        const mediaInfoValidation = {instagram: [], facebook: [], linkedin: [], twitter: [], pinterest: [], youtube: []};
        const mediaType = (typeof info.media.track[0].ImageCount !== 'undefined') ? 'image' : 'video';
        if (mediaType === 'image') {
            // INSTAGRAM VALIDATION
            if (networkTypes.indexOf('instagram') !== -1) {
                if (typeof info.media.track[1].StreamSize !== 'undefined' && Number(info.media.track[1].StreamSize) > 8388608) {
                    mediaInfoValidation.instagram.push('Image size needs to be smaller than 8 MB.');
                }
                if (typeof info.media.track[1].Format !== 'undefined' && info.media.track[1].Format.toLowerCase().indexOf('jpeg') === -1) {
                    mediaInfoValidation.instagram.push('Image needs to be JPEG format.');
                }
                if (
                    (typeof info.media.track[1].Width !== 'undefined' && typeof info.media.track[1].Height !== 'undefined')
                    && (
                        (Number(info.media.track[1].Width) / Number(info.media.track[1].Height)) < 0.8
                        || (Number(info.media.track[1].Width) / Number(info.media.track[1].Height)) > 1.91
                    )
                ) {
                    mediaInfoValidation.instagram.push('Image Minimum aspect ratio needs to be 4:5 and Image Maximum aspect ratio needs to be 1.91:1');
                }
                if (typeof info.media.track[1].Width !== 'undefined' && (Number(info.media.track[1].Width) < 320 || Number(info.media.track[1].Width) > 1440)) {
                    mediaInfoValidation.instagram.push('Image Minimum width needs to be 320px and Image Maximum width needs to be 1440px.');
                }
            }

            if (networkTypes.indexOf('youtube') !== -1) {
                mediaInfoValidation.youtube.push('You can\'t use images as media for YOUTUBE post.');
            }

            // FACEBOOK VALIDATION
            if (networkTypes.indexOf('facebook') !== -1) {
                if (typeof info.media.track[1].StreamSize !== 'undefined' && Number(info.media.track[1].StreamSize) > 10485760) {
                    mediaInfoValidation.facebook.push('Image size needs to be smaller than 10 MB.');
                }
            }

            // LINKEDIN VALIDATION
            if (networkTypes.indexOf('linkedin') !== -1) {
                if ((typeof info.media.track[1].Width !== 'undefined' && typeof info.media.track[1].Height !== 'undefined')
                    && (Number(info.media.track[1].Width) * Number(info.media.track[1].Height) > 36152320)) {
                    mediaInfoValidation.linkedin.push('Image needs to have less than 36152320 square pixels.');
                }
                if (
                    typeof info.media.track[1].Format !== 'undefined'
                    && info.media.track[1].Format.toLowerCase().indexOf('jpeg') === -1
                    && info.media.track[1].Format.toLowerCase().indexOf('gif') === -1
                    && info.media.track[1].Format.toLowerCase().indexOf('png') === -1
                ) {
                    mediaInfoValidation.linkedin.push('Image needs to be JPEG, PNG or GIF format.');
                }
            }

            // TWITTER VALIDATION
            if (networkTypes.indexOf('twitter') !== -1) {
                const twitterSize = (info.media.track[1].Format.toLowerCase().indexOf('gif') !== -1) ? 41395200 : 5242880;
                if (typeof info.media.track[1].StreamSize !== 'undefined' && Number(info.media.track[1].StreamSize) > twitterSize) {
                    mediaInfoValidation.twitter.push('Image size needs to be smaller than ' + (twitterSize === 5242880) ? '5' : '15' + ' MB.');
                }
            }

            // PINTEREST VALIDATION
            if (networkTypes.indexOf('pinterest') !== -1) {
                if (typeof info.media.track[1].StreamSize !== 'undefined' && Number(info.media.track[1].StreamSize) > 20971520) {
                    mediaInfoValidation.pinterest.push('Image size needs to be smaller than 20 MB.');
                }
                if (typeof info.media.track[1].Width !== 'undefined' && Number(info.media.track[1].Width) < 200) {
                    mediaInfoValidation.pinterest.push('Image width needs to be greater or equal to 200 px. Recommended 1000px x 1500px.');
                }
                if (typeof info.media.track[1].Height !== 'undefined' && Number(info.media.track[1].Height) < 300) {
                    mediaInfoValidation.pinterest.push('Image height needs to be greater or equal to 300 px. Recommended 1000px x 1500px.');
                }
            }
        }
        if (mediaType === 'video') {
            // INSTAGRAM VALIDATION
            if (networkTypes.indexOf('instagram') !== -1) {
                if (typeof info.media.track[2] !== 'undefined') {
                    if (typeof info.media.track[2].Channels !== 'undefined' && Number(info.media.track[2].Channels) > 2) {
                        mediaInfoValidation.instagram.push('Audio Channels can not be greater than 2.');
                    }
                    if (typeof info.media.track[2].SamplingRate !== 'undefined' && Number(info.media.track[2].SamplingRate) > 48000) {
                        mediaInfoValidation.instagram.push('Audio Sample Rate can not be greater than 48khz.');
                    }
                    if (typeof info.media.track[2].BitRate !== 'undefined' && Number(info.media.track[2].BitRate) > 150000) {
                        mediaInfoValidation.instagram.push('Audio bitrate can not be greater than 150kbps.');
                    }
                    if (typeof info.media.track[2].Format !== 'undefined' && info.media.track[2].Format.indexOf('AAC') === -1) {
                        mediaInfoValidation.instagram.push('Audio codec must be AAC.');
                    }
                }
                if (typeof info.media.track[1].BitRate !== 'undefined' && Number(info.media.track[1].BitRate) > 5000000) {
                    mediaInfoValidation.instagram.push('Video bitrate can not be greater than 5Mbps.');
                }
                if (typeof info.media.track[0].FileSize !== 'undefined' && Number(info.media.track[0].FileSize) > 104857600) {
                    mediaInfoValidation.instagram.push('Video size needs to be smaller than 100 MB.');
                }
                if (typeof info.media.track[0].Duration !== 'undefined' && (Number(info.media.track[0].Duration) > 60 || Number(info.media.track[0].Duration) < 3)) {
                    mediaInfoValidation.instagram.push('Video duration needs to be between 3 and 60 seconds');
                }
                // if (strpos($fileInfo['video']['fourcc_lookup'], 'HEVC') === false && strpos($fileInfo['video']['fourcc_lookup'], 'H.264') === false)  {
                //     $errors['instagram'][] = 'Video codec needs to be HEVC or H.264';
                // }
                if (typeof info.media.track[0].FrameRate !== 'undefined' && (Number(info.media.track[0].FrameRate) > 60 || Number(info.media.track[0].FrameRate) < 23)) {
                    mediaInfoValidation.instagram.push('Video Frame rate needs to be between 23 FPS and 60 FPS');
                }
                if (typeof info.media.track[1].ChromaSubsampling !== 'undefined' && info.media.track[1].ChromaSubsampling !== '4:2:0') {
                    mediaInfoValidation.instagram.push('Video Chroma Subsampling needs to be 4:2:0.');
                }
                if (typeof info.media.track[1].Width !== 'undefined' && Number(info.media.track[1].Width) > 1920) {
                    mediaInfoValidation.instagram.push('Video Resolution (horizontal pixels), can not be greater than 1920 pixels.');
                }
                if (typeof info.media.track[1].ScanType !== 'undefined' && info.media.track[1].ScanType !== 'Progressive') {
                    mediaInfoValidation.instagram.push('Video Scan Type needs to be PROGRESSIVE.');
                }
                if (
                    typeof info.media.track[1].DisplayAspectRatio !== 'undefined'
                    && (Number(info.media.track[1].DisplayAspectRatio) < 0.8 || Number(info.media.track[1].DisplayAspectRatio) > 1.78)
                    && Number(Math.floor(info.media.track[1].DisplayAspectRatio * 1000) / 1000) !== 0.562
                ) {
                    mediaInfoValidation.instagram.push('Video Minimum aspect ratio needs to be 4:5 and Video Maximum aspect ratio needs to be 16:9, or equal to 9:16.');
                }
            }

            // FACEBOOK VALIDATION
            if (networkTypes.indexOf('facebook') !== -1) {
                if (typeof info.media.track[0].FileSize !== 'undefined' && Number(info.media.track[0].FileSize) > 1073741824/*10737418240*/) {
                    // mediaInfoValidation.facebook.push('Video size needs to be smaller than 10 GB.');
                    mediaInfoValidation.facebook.push('Video size needs to be smaller than 1 GB.');
                }
                if (typeof info.media.track[0].Duration !== 'undefined' && Number(info.media.track[0].Duration) > 14400) {
                    mediaInfoValidation.facebook.push('Video duration needs to be above 4 hours');
                }
            }

            // TWITTER VALIDATION
            if (networkTypes.indexOf('twitter') !== -1) {
                if (typeof info.media.track[0].FileSize !== 'undefined' && Number(info.media.track[0].FileSize) > 41395200) {
                    mediaInfoValidation.twitter.push('Video size needs to be smaller than 15 MB.');
                }
            }

            // LINKEDIN VALIDATION
            if (networkTypes.indexOf('linkedin') !== -1) {
                if (typeof info.media.track[0].FileSize !== 'undefined' && Number(info.media.track[0].FileSize) > 551936000) {
                    mediaInfoValidation.twitter.push('Video size needs to be smaller than 200 MB.');
                }
            }

            if (networkTypes.indexOf('pinterest') !== -1) {
                mediaInfoValidation.pinterest.push('You can\'t use videos as media for PINTEREST posts.');
            }

            // PINTEREST VALIDATION
            if (networkTypes.indexOf('pinterest') !== -1) {
                if (typeof info.media.track[0].FileSize !== 'undefined' && Number(info.media.track[0].FileSize) > 5519360000) {
                    mediaInfoValidation.pinterest.push('Video size needs to be smaller than 2 GB.');
                }
                if (typeof info.media.track[1].Width !== 'undefined' && Number(info.media.track[1].Width) < 200) {
                    mediaInfoValidation.pinterest.push('Video Resolution (horizontal pixels), can not be lower than 200 pixels. Recommended 540px x 960px.');
                }
                if (typeof info.media.track[1].Height !== 'undefined' && Number(info.media.track[1].Height) < 300) {
                    mediaInfoValidation.pinterest.push('Video Resolution (vertical pixels), can not be lower than 300 pixels. Recommended 540px x 960px.');
                }
                if (typeof info.media.track[0].Duration !== 'undefined' && (Number(info.media.track[0].Duration) < 4)) {
                    mediaInfoValidation.pinterest.push('Video duration needs to be greater or equal to 4 seconds');
                }
            }

            // YOUTUBE VALIDATION
            if (networkTypes.indexOf('youtube') !== -1) {
                if (typeof info.media.track[0].FileSize !== 'undefined' && Number(info.media.track[0].FileSize) > 55193600000) {
                    mediaInfoValidation.pinterest.push('Video size needs to be smaller than 10 GB.');
                }
            }
        }
        return mediaInfoValidation;
    }
}

