import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ChangeFileNameComponent,
    ManagePostsComponent
} from './manage-posts/manage-posts.component';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {FileUploaderModule} from '../file-uploader/file-uploader.module';
import {MediaLibraryModule} from '../media-library/media-library.module';
import {PostModule} from '../../../layout/components/post/post.module';
import {AudienceModule} from '../audience/audience.module';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxMatDatetimePickerModule} from '@angular-material-components/datetime-picker';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMenuModule} from '@angular/material/menu';
import { SelectPostsMediaComponent } from './select-posts-media/select-posts-media.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatRadioModule} from '@angular/material/radio';
import {CustomPipesModule} from '../../../pipes/custom-pipes.module';
import {CalendarService} from '../calendar/calendar.service';
import {NetworkAutofillModule} from '../network-autofill/network-autofill.module';
import { FilesErrorsComponent } from './files-errors/files-errors.component';
import {VideoEditorModule} from "../video-editor/video-editor.module";
import {PinterestModule} from "../../../layout/components/pinterest/pinterest.module";
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [ManagePostsComponent, SelectPostsMediaComponent, ChangeFileNameComponent, FilesErrorsComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule,
        MatInputModule,
        FuseSharedModule,
        MatGridListModule,
        MatDialogModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        PickerModule,
        FileUploaderModule,
        MediaLibraryModule,
        PostModule,
        AudienceModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatTooltipModule,
        NgxMatDatetimePickerModule,
        MatDatepickerModule,
        MatMenuModule,
        MatToolbarModule,
        MatRadioModule,
        CustomPipesModule,
        NetworkAutofillModule,
        VideoEditorModule,
        PinterestModule,
        ToastrModule.forRoot(),
    ],
    providers      : [
        CalendarService
    ],
})
export class ManagePostsModule { }
