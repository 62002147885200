<label class="file-upload-container btn btn-bwm" for="file-uploader" dragDropFile (fileDropped)="onFileDropped($event)">
    <div><i class="fas fa-file-upload"></i></div>
    <div>
        <span>Drag & Drop files or</span>
        <span class="file-browse-button">Browse</span>
    </div>
    <input style="display: none;" id="file-uploader" #imageInput
           type="file"
           multiple
           accept="{{acceptTypes}}"
           (change)="processFile(imageInput)">
</label>
<div *ngIf="!hideUploadsList" cdkDropList class="file-list" (cdkDropListDropped)="drop($event)">
    <div class="file-box" *ngFor="let file of selectedFiles; let i = index;" cdkDrag>
        <app-file-view [fileSnippet]="file" [index]="i" (uploadFile)="uploadFile($event)" (removeFile)="removeFile($event)" (editFile)="fileEdit.emit($event)"></app-file-view>
    </div>
</div>