import {Component, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import {Subject, Subscription} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import {navigation, navigationAdmin, navigationBasic, navigationManager} from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import {ChatService} from './main/apps/chat/chat.service';
import {CommonAuthService} from './auth/common-auth.service';
import {ActivatedRoute, Router} from "@angular/router";
import {SocialMediaService} from "./main/apps/social-media-management/social-media.service";
import {SnotifyService} from "ng-snotify";
import {environment} from "../environments/environment";
import {SocialAuthService} from "angularx-social-login";
import {TwitterLoginProvider} from "./main/apps/social-media-management/connect-network/TwitterLoginProvider";
import {ConnectNetworkComponent} from "./main/apps/social-media-management/connect-network/connect-network.component";
import {MatDialog} from "@angular/material/dialog";
import {AcceptTermsComponent} from "./auth/accept-terms/accept-terms.component";

@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;
    emojisTop = -9999999;
    emojisLeft = -9999999;
    emojisTranslateX = 0;
    @ViewChild('emojiBlock') emojiBlock: any;
    loading = false;
    saving_oauth_data = 'Authenticating Twitter\'s API V2...';

    // Private
    private _unsubscribeAll: Subject<any>;

    twitterLoginOptionsOld = {
        scope: 'r_organization_social r_1st_connections_size r_ads_reporting r_emailaddress rw_organization_admin ' +
            'r_liteprofile r_basicprofile r_ads rw_ads w_member_social w_organization_social',
        response_type: 'code',
        // client_id: '86kizjjsidutf6',
        oauth_callback: environment.twitterRedirectUrl,
        state: '43hg53j4h5g3iu45',
        url: 'https://api.twitter.com/oauth/authorize'
    };

    v2token = null;
    callback_twitter_oauth2 = null;
    showTwitterButton = false;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     * @param _chatService
     * @param authService
     * @param route
     * @param router
     * @param userService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _chatService: ChatService,
        private authService: CommonAuthService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: CommonAuthService,
        private networkService: SocialMediaService,
        private alert: SnotifyService,
        private authSocialService: SocialAuthService,
        private _matDialog: MatDialog
    )
    {
        this.route.queryParams.subscribe(params => {
            console.log('params: ', params);
            if (typeof params['single_sign_on_code'] !== 'undefined' && params['single_sign_on_code'] !== null && params['single_sign_on_code'] !== '') {
                const oldToken = localStorage.getItem('token');
                localStorage.setItem('token', params['single_sign_on_code']);
                this.userService.getByToken(params['single_sign_on_code']).subscribe(
                    response => {
                        this.userService.loggedIn = true;
                        localStorage.setItem('user_logged', JSON.stringify( response));
                        this.userService.userLogged = response;
                        this.userService.userSub.next(this.userService.userLogged);
                        this.router.navigate(['/apps/dashboard/boards']);
                    }, error => {
                        console.log(error);
                    }
                );
            }
            if (typeof params['callback_twitter_oauth2'] !== 'undefined' && params['callback_twitter_oauth2'] !== null && params['callback_twitter_oauth2'] !== '') {
                if (typeof params['code'] !== 'undefined' && params['code'] !== null && params['code'] !== '') {
                    this.loading = true;
                    console.log(params['code']);
                    this.networkService.getTwitterOauth2Token(params).subscribe(
                        response => {
                            console.log('getTwitterOauth2Token: ', response);
                            this.v2token = response.data.oauth_token;
                            this.callback_twitter_oauth2 = params['callback_twitter_oauth2'];
                            this.showTwitterButton = true;
                            this.saving_oauth_data = 'Please, use the button below for Twitter\'s API V1 Authentication.';
                        }, error => {
                            this.loading = false;
                        }
                    );
                }
            }
        });

        // Get default navigation
        this.navigation = [];
        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);
        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        if (typeof localStorage.getItem('user_logged') !== 'undefined' && localStorage.getItem('user_logged') !== null) {
            this.authService.setUser(JSON.parse(localStorage.getItem('user_logged')));
        }

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if ( this._platform.ANDROID || this._platform.IOS )
        {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    @HostListener('document:click', ['$event']) onDocumentClick(event): void {
        if (event.target.className !== 'object' && event.target.className.indexOf('far fa-grin-alt add-emoji') === -1) {
            if (!((this.emojiBlock.nativeElement as HTMLElement).contains(event.srcElement as HTMLElement))) {
                this.emojisTop = -99999;
                this.emojisLeft = -99999;
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.authService.getUser()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
            user => {
                if (user !== null) {
                    switch (user.role) {
                        case 'ADMIN':
                            this.navigation = navigationAdmin;
                            this._fuseNavigationService.unregister('main');
                            this._fuseNavigationService.register('main', this.navigation);
                            this._fuseNavigationService.setCurrentNavigation('main');
                            break;
                        case 'MANAGER':
                            this.navigation = navigationManager;
                            this._fuseNavigationService.unregister('main');
                            this._fuseNavigationService.register('main', this.navigation);
                            this._fuseNavigationService.setCurrentNavigation('main');
                            break;
                        case 'BASIC':
                            this.navigation = navigationBasic;
                            this._fuseNavigationService.unregister('main');
                            this._fuseNavigationService.register('main', this.navigation);
                            this._fuseNavigationService.setCurrentNavigation('main');
                            break;
                        default:
                            break;
                    }
                }
            },
            error => console.log(error)
        );
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if ( this.fuseConfig.layout.width === 'boxed' )
                {
                    this.document.body.classList.add('boxed');
                }
                else
                {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for ( let i = 0; i < this.document.body.classList.length; i++ )
                {
                    const className = this.document.body.classList[i];

                    if ( className.startsWith('theme-') )
                    {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

        this._chatService.getEmojisOpen().subscribe(
            event => {
                this._chatService.inputEmojis = event.target.closest('.emojis-input-container').querySelector('.emojis-input');
                let pageY = event.pageY + 20;
                let pageX = event.pageX;
                if ((pageY + 427) > document.body.offsetHeight) {
                    pageY -= 457;
                }
                if ((pageX + 350) > document.body.offsetWidth) {
                    pageX -= 350;
                }
                this.emojisTop = pageY;
                this.emojisLeft = pageX;
            }
        );
        this.validateTermsAcceptance();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    emojiSelection(event: any): void {
        // this._chatService.setEmojiSelected(event.emoji.native);
        if (this._chatService.inputEmojis.tagName === 'DIV') {
            this._chatService.inputEmojis.innerText = this._chatService.inputEmojis.innerText + event.emoji.native;
        } else {
            // @ts-ignore
            this._chatService.inputEmojis.value = this._chatService.inputEmojis.value + event.emoji.native;
            this._chatService.setEmojiSelected(event.emoji.native);
        }
    }

    signInWithTwitterOld(): void {
        this.networkService.getTwitterAuthToken().subscribe(
            data => {
                this.twitterLoginOptionsOld['oauthTokenParam'] = data.data[0];
                this.authSocialService.signIn(TwitterLoginProvider.PROVIDER_ID, this.twitterLoginOptionsOld).then(
                    (userData) => {
                        this.networkService.getTwitterAccessToken(userData.authToken, userData.authorizationCode, this.v2token).subscribe(
                            response2 => {
                                this.router.navigate([this.callback_twitter_oauth2]);
                                this.loading = false;
                                this.alert.success('Twitter Account added successfully.');
                            },
                            error => console.log(error)
                        );
                    }
                );
            }, error => console.log(error)
        );
    }

    validateTermsAcceptance(): void {
        const user = this.userService.userSub.getValue();
        console.log('Entered...', user);
        if (typeof user !== 'undefined' && user !== null) {
            console.log('Entered 2...');
            if (typeof user.accepted_terms_date === 'undefined' || user.accepted_terms_date === null || user.accepted_terms_date === '') {
                console.log('Entered 3...');
                const modal = AcceptTermsComponent;
                const dialogRef = this._matDialog.open(modal, {
                    maxWidth: '500px',
                    width: '100%',
                    disableClose: true
                });
                dialogRef.afterClosed().subscribe(result => {
                    console.log('close dialog');
                    if ( !result )
                    {
                        return;
                    }
                });
            }
        }
    }
}
