<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Share Post</span>
            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 m-0 share-component" style="flex-direction: column !important;">
        <div class="mt-16">
            <app-networks-selector [onlyType]="post.network.network_type.toLowerCase()" (networkSelection)="setNetwork($event)"></app-networks-selector>
        </div>
        <div style="margin-top: -35px;position:relative;" class="emojis-input-container">
            <mat-form-field class="w-100-p" appearance="standard">
                <textarea [(ngModel)]="text" style="overflow-y: hidden;" class="emojis-input" [mat-autosize]="true" matInput placeholder="Write something to..."></textarea>
            </mat-form-field>
            <i class="far fa-grin-alt add-emoji" (click)="emojiClicked($event)"></i>
        </div>
        <div class="mt-16">
            <app-post-share-view [post]="post"></app-post-share-view>
        </div>
    </div>

    <div mat-dialog-actions style="border-top: 1px solid #ddd;" class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button
            mat-button
            class="save-button mat-button-base"
            (click)="dialogRef.close()"
            aria-label="CANCEL">
            CANCEL
        </button>
        <button
            mat-button
            class="save-button mat-focus-indicator mat-accent mat-raised-button mat-button-base"
            (click)="(networkSelected !== null) ? dialogRef.close({network: networkSelected, text: text}) : ''"
            aria-label="SHARE">
            <i class="far fa-share-square" style="font-size: 15px;color: #fff;margin-top: -4px;"></i> SHARE
        </button>

    </div>
</div>

