<ng-container *ngIf="!showOnlyMainInput">
    <div class="comment-list-box" *ngFor="let comment of comments" style="margin-bottom: 15px;">
        <div class="comment-box">
            <img *ngIf="comment.contactAvatar" matTooltip="{{comment.contactName}}" matTooltipPosition="above" class="avatar commment-avatar" [src]="comment.contactAvatar"
                 alt=""/>
            <img *ngIf="!comment.contactAvatar" matTooltip="{{comment.contactName}}" matTooltipPosition="above" class="avatar commment-avatar"
                 [src]="'assets/images/user-avatar.jpg'" alt=""/>
            <div style="display: flex;flex-direction: row;align-items: center;width: 100%;">
                <div class="user-comment">
                    <a *ngIf="post.type !== 'instagram'" href="#" style="color: rgba(0, 0, 0, 0.87);font-size: 13px;font-weight: 700;">{{comment.contactName}}</a>
                    <a *ngIf="post.type === 'instagram'" href="#" style="color: rgba(0, 0, 0, 0.87);font-size: 13px;font-weight: 700;">@{{comment.contactName}}</a><br />
                    <span>{{comment.text}}</span>
                </div>
                <i class="fas fa-ellipsis-h comment-actions" [matMenuTriggerFor]="menu"
                   *ngIf="post.from && post.from.id === post.network.network_id || comment.contactId === post.network.network_id || post.type.toLowerCase() === 'linkedin'"
                ></i>
                <mat-menu #menu="matMenu">
                    <button (click)="deleteComment(comment)" mat-menu-item>Delete</button>
                </mat-menu>
            </div>
        </div>
        <div style="margin-left: 42px;">
            <span class="comment-hour">{{comment.postDate | dateAgo}}</span>
            <span *ngIf="post.type.toLowerCase() !== 'linkedin'" class="Reply-link">Like</span>
            <span *ngIf="post.type.toLowerCase() === 'linkedin'" class="Reply-link" [ngStyle]="(comment.liked) ? {color: '#0a66c2', fontWeight: '700'} : null" (click)="likeUnlikeComment(comment)">Like</span>
            <span *ngIf="post.type !== 'instagram'" (click)="tagUserInput(comment)" class="Reply-link">Reply</span>
            <span *ngIf="post.type === 'instagram'" (click)="tagUserInput(comment)" class="Reply-link">Reply</span>
            <span *ngIf="post.replyPrivately && post.type !== 'linkedin'" (click)="sendPrivateMessage(comment)" class="Reply-link">Message</span>
        </div>
        <div class="comment-answers" style="margin-left: 42px;margin-top: 10px;">
            <app-comments [parent]="this" [commentParent]="comment" [comments]="comment.answers" [post]="post" [showCommentInput]="(post.type === 'instagram') ? false : showSubCommentInput" (tagInput)="setTaggedComment( $event )"></app-comments>
        </div>
    </div>
</ng-container>
<div *ngIf="fromBoard && comments && comments.length > 0" class="actions-box">
    <a href="javascript:;" class="link-action"
       *ngIf="showOnlyMainInput"
       (click)="(post.type.toLowerCase() !== 'linkedin') ? (showOnlyMainInput = !showOnlyMainInput) : getComments()"
    >
        View comments
    </a>
    <a href="javascript:;" class="link-action" *ngIf="!showOnlyMainInput"   (click)="showOnlyMainInput = !showOnlyMainInput">Hide comments</a>
</div>
<mat-progress-bar *ngIf="commentLoading" mode="indeterminate"></mat-progress-bar>
<div class="comment-box {{post.type}}" [ngStyle]="{position: ((showCommentInput || fromBoard) ? 'unset' : 'absolute'), visibility: ((showCommentInput || fromBoard) ? 'unset' : 'hidden')}">
    <img matTooltip="{{post.network.name}}" matTooltipPosition="above" class="avatar commment-avatar" [src]="post.network.picture"
         alt=""/>
    <div class="comment-input emojis-input-container" #commentContainer>
        <div #commentInput role="textbox" contenteditable class="emojis-input"></div>
        <div class="input-icons">
            <i #emojiIcon class="far fa-grin-alt add-emoji" matTooltip="Add Emojis" matTooltipPosition="above" (click)="emojiClicked($event)"></i>
            <span (click)="sendAnswer()">
                <mat-icon matTooltip="Send" matTooltipPosition="above" role="img" class="mat-icon secondary-text material-icons mat-icon-no-color" aria-hidden="true">send</mat-icon>
            </span>
        </div>
    </div>
</div>

