<div class="mini-body" *ngIf="(this.event['loadingData'] !== undefined && this.event['loadingData'] !== null) ? !this.event['loadingData'] : true">
    <div class="top-box">
        <img style="visibility: hidden;height: 0;position:absolute;"
             *ngIf="event.post.media !== null && event.post.media.length > 0 && (event.post.media[0].type.indexOf('image') !== -1 || event.post.media[0].type.indexOf('link') !== -1)"
            src="{{(event.post.media[0].previewUrl && event.post.media[0].previewUrl !== null && event.post.media[0].previewUrl !== '') ? event.post.media[0].previewUrl : event.post.media[0].mediaUrl }}"
            (load)="loadPreview()"
        />
        <div *ngIf="event.post.media !== null && event.post.media.length > 0 && (event.post.media[0].type.indexOf('image') !== -1 || event.post.media[0].type.indexOf('link') !== -1)" class="media-box" [ngStyle]="{
        backgroundImage: (loadingPreview ? 'none' :
            'url(' + ((event.post.media[0].previewUrl && event.post.media[0].previewUrl !== null && event.post.media[0].previewUrl !== '') ? event.post.media[0].previewUrl : event.post.media[0].mediaUrl) + ')'
            )}"></div>
        <div *ngIf="event.post.media !== null && event.post.media.length > 0 && event.post.media[0].type.indexOf('video') !== -1" class="media-box" [ngStyle]="{
        backgroundImage: (event.post.media[0].previewUrl && event.post.media[0].previewUrl !== null && event.post.media[0].previewUrl !== '' ? 'url(' + (event.post.media[0].previewUrl) + ')' : 'none'
            )}">
            <div *ngIf="event.post.media[0].type.indexOf('video') !== -1" class="play-button">
                <i class="fas fa-play-circle"></i>
            </div>
            <video *ngIf="!event.post.media[0].previewUrl || event.post.media[0].previewUrl === null || event.post.media[0].previewUrl === ''" src="{{event.post.media[0].mediaUrl}}" style="width: 100%;height: 100%;border-radius: 10px;"></video>
        </div>
        <div *ngIf="event.post.media !== null && event.post.media.length === 0 && event.post.link !== null" class="media-box" [ngStyle]="{backgroundImage: 'url(' + event.post.link.imageUrl + ')'}"></div>
        <div class="text-box">
            <small style="font-weight: bold;">{{event.post.network.name}}</small><br/>
            <small>{{event.post.text}}</small>
        </div>
    </div>
    <div class="bottom-box">
        <div class="social-network">
            <small>
                <i *ngIf="event.post.type.toLowerCase() === 'facebook'" class="fab fa-facebook" style="color: rgb(66, 103, 178);"></i>
                <i *ngIf="event.post.type.toLowerCase() === 'linkedin'" class="fab fa-linkedin" style="color: #0A66C2"></i>
                <i *ngIf="event.post.type.toLowerCase() === 'twitter'" class="fab fa-square-x-twitter" style="color: #000000;"></i>
                <i *ngIf="event.post.type.toLowerCase() === 'instagram'" class="fab fa-instagram-square" style="color: rgb(217, 49, 117)"></i>
                <span class="time">
                    <strong>
                        <i *ngIf="event.post.editable" class="far fa-clock" style="font-weight: bold;margin-right: 1px;margin-left: 0px;"></i>
                        <i *ngIf="!event.post.editable" class="fas fa-calendar-check" style="font-weight: bold;margin-right: 1px;margin-left: 0px;"></i>
                        <span>{{postTime}}</span>
                    </strong>
                </span>
            </small>
        </div>
    </div>
</div>
<div class="mini-body" *ngIf="(this.event['loadingData'] !== undefined && this.event['loadingData'] !== null) ? this.event['loadingData'] : false">
    <div class="top-box">
        <div class="media-box"></div>
    </div>
</div>
