import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DragDropFileDirective, FileUploaderComponent} from './file-uploader.component';
import {FileViewComponent} from './file-view/file-view.component';
import {FlexModule} from '@angular/flex-layout';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";



@NgModule({
    declarations: [FileUploaderComponent, FileViewComponent, DragDropFileDirective],
    exports: [
        FileUploaderComponent
    ],
    imports: [
        CommonModule,
        FlexModule,
        MatProgressBarModule,
        DragDropModule,
        MatIconModule,
        MatTooltipModule
    ]
})
export class FileUploaderModule { }
