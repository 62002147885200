import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SocialMediaService {

    constructor(private http: HttpClient) {
    }

    getFacebookNetworks(userAccount): Observable<any> {
        return this.http.post(`${environment.apiUrl}get-fb-networks/`, userAccount);
    }

    storeFacebookNetworks(data): Observable<any> {
        return this.http.post(`${environment.apiUrl}fb-networks/` + data.accountId, data.pages);
    }

    getInstagramNetworks(userAccount): Observable<any> {
        return this.http.post(`${environment.apiUrl}get-instagram-networks/`, userAccount);
    }

    storeInstagramNetworks(data): Observable<any> {
        return this.http.post(`${environment.apiUrl}instagram-networks/` + data.accountId, data.pages);
    }

    storeTwitterNetworks(userAccount): Observable<any> {
        return this.http.post(`${environment.apiUrl}twitter-networks/`, userAccount);
    }

    getTwitterOauth2Token(data): Observable<any> {
        return this.http.post(`${environment.apiUrl}twitter-oauth2-token/`, data);
    }

    getLinkedinAuthToken(code: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}linkedin-token/` + code);
    }

    getTwitterAuthToken(): Observable<any> {
        return this.http.get(`${environment.apiUrl}twitter-oauth-token/`);
    }

    getTwitterAccessToken(oauthToken: string, oauthVerifier: string, v2token: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}twitter-access-token/` + oauthToken + '/' + oauthVerifier + '/' + v2token);
    }

    getPinterestAccessToken(oauthToken: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}pinterest-access-token/` + oauthToken);
    }

    getYoutubeAccessToken(oauthToken: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}youtube-access-token/`, {code: oauthToken});
    }
}
