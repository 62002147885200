import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Observable, Subject} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

import { SocialMediaManagementNetworkService } from 'app/main/apps/social-media-management/network/network.service';
import {Team} from "../../../../models/team";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {Member} from "../../../../models/member";
import {MatAutocomplete, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {ManageNetworksService} from "../../manage-networks/manage-networks.service";
import {SocialMediaManagementTeamsService} from "../teams/teams.service";
import {SocialMediaManagementMembersService} from "../members/members.service";
import {MatChipInputEvent} from "@angular/material/chips";
import {Network} from "../../../../models/network";
import {ConnectNetworkComponent} from "../connect-network/connect-network.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SocialMediaManagementMemberService} from "../member/member.service";

@Component({
    selector     : 'social-media-management-network',
    templateUrl  : './network.component.html',
    styleUrls    : ['./network.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class SocialMediaManagementNetworkComponent implements OnInit, OnDestroy
{
    network: any;
    pageType: string;
    networkForm: FormGroup;

    selectedMembers: Array<any> = [];
    selectedTeams: Array<any> = [];
    teams: Array<Team> = [];

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = false;
    separatorKeysCodes = [ENTER, COMMA];

    memberCtrl = new FormControl();
    filteredMembers: Observable<any[]>;
    members: Array<Member> = [];

    teamCtrl = new FormControl();
    filteredTeams: Observable<any[]>;
    allTeams = [];

    inputLoader = false;

    @ViewChild('memberInput') memberInput: ElementRef;
    @ViewChild('autoMember') matAutocompleteMember: MatAutocomplete;

    @ViewChild('teamInput') teamInput: ElementRef;
    @ViewChild('autoTeam') matAutocompleteTeam: MatAutocomplete;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _ecommerceNetworkService: SocialMediaManagementNetworkService,
        private _formBuilder: FormBuilder,
        private _location: Location,
        private _matSnackBar: MatSnackBar,
        private membersService: SocialMediaManagementMembersService,
        private teamsService: SocialMediaManagementTeamsService,
        public dialog: MatDialog,
    )
    {
        // Set the default
        this.network = new Network();

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.filteredMembers = this.memberCtrl.valueChanges.pipe(
            startWith(null),
            map((member: string | null) => member ? this.filterMember(member) : this.members.slice()));

        this.filteredTeams = this.teamCtrl.valueChanges.pipe(
            startWith(null),
            map((team: string | null) => team ? this.filterTeam(team) : this.teams.slice()));

        this.getMembers();
        this.getTeams();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to update network on changes
        this._ecommerceNetworkService.onNetworkChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(network => {
                if ( network )
                {
                    this.network = new Network(network);
                    this.pageType = 'edit';
                    this.selectedMembers = network.members;
                    this.selectedTeams = network.teams;
                }
                else
                {
                    this.pageType = 'new';
                    this.network = new Network();
                }

                this.networkForm = this.createNetworkForm();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create network form
     *
     * @returns {FormGroup}
     */
    createNetworkForm(): FormGroup
    {
        return this._formBuilder.group({
            id              : [this.network.id],
            name            : [this.network.name],
            description     : [this.network.description],
            teams           : [this.network.teams],
            members         : [this.network.members],
            picture         : [this.network.picture],
        });
    }

    private updateTeamInput(): void {
        this.networkForm.controls.teams.setValue(this.selectedTeams);
    }

    private updateMemberInput(): void {
        this.networkForm.controls.members.setValue(this.selectedMembers);
    }

    selectAllMembers(): void {
        this.selectedMembers = [];
        for (let i in this.members)
            this.selectedMembers.push(this.members[i]);
        this.updateMemberInput();
    }

    unSelectAllMembers(): void {
        this.selectedMembers = [];
        this.updateMemberInput();
    }

    selectAllTeams(): void {
        this.selectedTeams = [];
        for (let i in this.teams)
            this.selectedTeams.push(this.teams[i]);
        this.updateMemberInput();
    }

    unSelectAllTeams(): void {
        this.selectedTeams = [];
        this.updateMemberInput();
    }
    importHootsuite(): void{
        const data = this.networkForm.getRawValue();
        data.handle = FuseUtils.handleize(data.name);
        this._ecommerceNetworkService.hootsuiteImport(data)
            .then(() => {
                // Show the success message
                this._matSnackBar.open('Import done.', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }

    /**
     * Save network
     */
    saveNetwork(): void
    {
        const data = this.networkForm.getRawValue();
        data.handle = FuseUtils.handleize(data.name);
        this._ecommerceNetworkService.saveNetwork(data)
            .then(() => {
                // Show the success message
                this._matSnackBar.open('Network saved', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }

    /**
     * Add network
     */
    addNetwork(): void
    {
        const data = this.networkForm.getRawValue();
        data.handle = FuseUtils.handleize(data.name);

        this._ecommerceNetworkService.addNetwork(data)
            .then(() => {

                // Trigger the subscription with new data
                this._ecommerceNetworkService.onNetworkChanged.next(data);

                // Show the success message
                this._matSnackBar.open('Network added', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });

                // Change the location with new one
                this._location.go('apps/social-media-management/networks/' + this.network.id + '/' + this.network.handle);
            });
    }

    private getTeams(): void {
        this.inputLoader = true;
        this.teamsService.getTeams2().subscribe(
            response => {
                this.teams = response.groups;
                this.inputLoader = false;
            },
            error => { this.inputLoader = false; console.log(error); }
        );
    }

    private getMembers(): void {
        this.inputLoader = true;
        this.membersService.getMembers2().subscribe(
            response => {
                this.members = response.members;
                this.inputLoader = false;
            },
            error => { this.inputLoader = false; console.log(error); }
        );
    }

    addMember(event: MatChipInputEvent): void {
        this.updateMemberInput();
    }

    removeMember(member: any): void {
        const index = this.selectedMembers.indexOf(member);
        if (index >= 0) {
            this.selectedMembers.splice(index, 1);
        }
        this.memberInput.nativeElement.value = '';
        this.updateMemberInput();
    }

    filterMember(value: string): Member[] {
        const filterValue = (typeof value['name'] !== 'undefined') ? value['name'].toLowerCase() : value.toLowerCase();
        return this.members.filter(
            member => member.name.toLowerCase().indexOf(filterValue) === 0
                && this.selectedMembers.findIndex(item => item.name === member.name) === -1
        );
    }

    selectedMember(event: MatAutocompleteSelectedEvent): void {
        this.selectedMembers.push(event.option.value);
        this.memberInput.nativeElement.value = '';
        this.memberCtrl.setValue(null);
        this.updateMemberInput();
    }

    addTeam(event: MatChipInputEvent): void {
        this.updateTeamInput();
    }

    removeTeam(team: any): void {
        const index = this.selectedTeams.indexOf(team);
        if (index >= 0) {
            this.selectedTeams.splice(index, 1);
        }
        this.teamInput.nativeElement.value = '';
        this.updateTeamInput();
    }

    filterTeam(value: string): Team[] {
        const filterValue = (typeof value['name'] !== 'undefined') ? value['name'].toLowerCase() : value.toLowerCase();
        return this.teams.filter(
            team => team.name.toLowerCase().indexOf(filterValue) === 0
                && this.selectedTeams.findIndex(item => item.name === team.name) === -1
        );
    }

    selectedTeam(event: MatAutocompleteSelectedEvent): void {
        this.selectedTeams.push(event.option.value);
        this.teamInput.nativeElement.value = '';
        this.teamCtrl.setValue(null);
        this.updateTeamInput();
    }

    openNewNetworkModal(): void {
        const modal = ConnectNetworkComponent;
        const dialogRef = this.dialog.open(modal, {
            maxWidth: '700px',
            width: '100%',
            disableClose: true,
            data: ''
        });
        dialogRef.afterClosed().subscribe(result => {
            // if (typeof result !== 'undefined' && result !== null) {
            //     this.setAudiences(type, result);
            //     this.postsService.updateAllPosts('audiences', this.selectedTags);
            // }
        });
    }
}
