import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActionLogsService {

    constructor(private http: HttpClient) {
    }

    getUserActionLogs(userId: string, params: {from: string, to: string, type: Array<string>}): Observable<any> {
        return this.http.get(`${environment.apiUrl}members/${userId}/actions-logs/?from=` + params.from + '&to=' + params.to + '&type=' + params.type.join(','));
    }
}
