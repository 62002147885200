import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable, Observer, Subject} from 'rxjs';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {debounceTime, distinctUntilChanged, map, mergeMap, startWith, tap} from 'rxjs/operators';
import {NetworkAutofillService} from '../network-autofill.service';
import {ManageNetworksService} from '../../manage-networks/manage-networks.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-network-autofill',
  templateUrl: './network-autofill.component.html',
  styleUrls: ['./network-autofill.component.scss']
})
export class NetworkAutofillComponent implements OnInit {
    pageInputControl: FormControl =  new FormControl('');
    pageInputControlTwitter: FormControl =  new FormControl('');
    filteredPages: Observable<{}[]> = new Observable<any>((obs: Observer<any>) => {
        obs.next([]);
        obs.complete();
    });
    inputLoader = false;
    @ViewChild('pageInput') pageInput: ElementRef<HTMLInputElement>;
    @Input() networksSelected = [];
    networks = [];
    networksSelectedTypes = [];
    @Output() pageSelection: EventEmitter<{text: string, offset: number, length: number}> = new EventEmitter<null>();
    // selectedNetworks: Array<Network> = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    networkType = 'facebook';
    network;
    constructor(
        public matDialogRef: MatDialogRef<NetworkAutofillComponent>,
        private networkAuto: NetworkAutofillService,
        private networksService: ManageNetworksService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.networksSelectedTypes = data.networks.map(i => i.network_type.toLowerCase());
        this.networkType = data.networks[0].network_type.toLowerCase();
        this.network = data.networks.find(i => i.network_type.toLowerCase() === this.networkType);
    }

    ngOnInit(): void {
        this.networksService.getNetworks('facebook').subscribe(
            response => {
                this.networks = response.data;
            },
            error => console.log(error)
        );
        // this.filteredPages = this.pageInputControl.valueChanges.pipe(
        //   startWith(null),
        //   map((text: string | null) => text ? this._filter(text) : [] )
        // );
        this.pageInputControl.valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged()
        ).subscribe(value => {
            if (typeof value !== 'undefined' && value !== null && value !== '') {
                this._filter(value);
            }
        });

        this.pageInputControlTwitter.valueChanges.subscribe(
            value => {
                this.filteredPages = this.networkAuto.getTwitterUsers(this.data.networks.find(i => i.network_type.toLowerCase() === 'twitter').network_id, value);
            }
        );
    }

    _filter(text: string): any {
        this.inputLoader = true;
        this.filteredPages = this.networkAuto.getFacebookPages(this.network.network_id, text).pipe(
            tap(response => {
                this.inputLoader = false;
                return response;
            })
        );
        // let pages = [];
        // this.networkAuto._filterFacebookPages(this.network.network_id, text).then((response) => {
        //     console.log(response);
        //     pages = response;
        // });
        // return pages;
        // const filterValue = (typeof text['name'] !== 'undefined') ? text['name'].toLowerCase() : text.toLowerCase();
        // return this.networks.filter(
        //     network => network.name.toLowerCase().indexOf(filterValue) === 0
        // );
    }

    selectedPage(event: MatAutocompleteSelectedEvent): void {
        this.matDialogRef.close({value: event.option.value, type: this.networkType});
    }

    sendTag(): void {
        this.matDialogRef.close({value: this.pageInput.nativeElement.value, type: this.networkType});
    }

    hasImage(page: any): boolean {
        return typeof page.picture !== 'undefined' && page.picture !== null
            && typeof page.picture.data !== 'undefined' && page.picture.data !== null
            && typeof page.picture.data.url !== 'undefined' && page.picture.data.url !== null;
    }
}
