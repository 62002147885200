import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoEditorComponent } from './video-editor.component';
import {FuseSharedModule} from "../../../../@fuse/shared.module";
import {MatTabsModule} from "@angular/material/tabs";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";



@NgModule({
  declarations: [VideoEditorComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatTabsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatProgressBarModule
    ]
})
export class VideoEditorModule { }
