import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PrivateMessageService {

  constructor(private _httpClient: HttpClient) { }

  sendMessage(network_type: string, data: any): Observable<any> {
      switch (network_type.toLowerCase()) {
          case 'facebook':
            return this._httpClient.post(`${environment.apiUrl}fb-private-reply`, data);
            break;
          case 'twitter':
              return this._httpClient.post(`${environment.apiUrl}twitter/messages/` + data.network_id + '/' + data.user_id, data);
              break;
      }
  }
}
