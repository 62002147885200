import { Injectable } from '@angular/core';
import {IMediaLibrary} from '../../../../models/IMediaLibrary';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MediaLibraryData, MediaLibraryPreview} from '../media-library/media-library.component';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GiphyService implements IMediaLibrary{

    private apiKey = 'k83M2u6EWUYOWWMKS9PRiGw4Ca1RkwGZ';
    constructor(private httpClient: HttpClient) { }

    getMediaData(query: string = '', amount: number = 18, page: number = 1): Observable<MediaLibraryData> {
        return this.httpClient.get('https://api.giphy.com/v1/gifs/search?api_key=' + this.apiKey
            + '&q=' + ((query !== '') ? query : 'a') + '&image_type=all&offset=' + page * amount + '&limit=' + (amount + 2))
            .pipe(
                map(item => {
                    const result = new MediaLibraryData();
                    result.total = item['pagination']['total_count'];
                    result.data = [];
                    for (const img of item['data']) {
                        result.data.push({
                            id: img['image'],
                            previewUrl: img['images']['downsized']['url'],
                            source: 'Giphy',
                            url: img['images']['original']['url'],
                            width: img['images']['original']['width'],
                            height: img['images']['original']['height'],
                            size: img['images']['original']['size']
                        } as MediaLibraryPreview);
                    }
                    return result;
                })
                // catchError(Errors.handleError<any>('getMediaData'))
            );
    }
}
