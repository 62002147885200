<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Contact Support</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 pb-0 m-0" fusePerfectScrollbar>
        <div class="container">
            <div id="content">
                <div id="main-content">
                    <div class="row">
                        <div class="col-lg-12">
                            Questions about our updated Terms of Services? Please feel free to reach out to Social Force team at support@galeforcedigital.com or (866) 233-8499.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button
            (click)="backToAcceptance()"
            class="mat-focus-indicator mat-accent mat-raised-button mat-button-base"
        >
            BACK TO TERMS
        </button>
        <!--<button-->
        <!--mat-button-->
        <!--class="save-button mat-focus-indicator mat-accent mat-raised-button mat-button-base"-->
        <!--(click)="matDialogRef.close({-->
        <!--minimumAge: form.get('minimumAge').value,-->
        <!--countries: selectedList.countries,-->
        <!--regions: selectedList.regions,-->
        <!--cities: selectedList.cities,-->
        <!--zipCodes: selectedList.zipCodes-->
        <!--})"-->
        <!--aria-label="SAVE">-->
        <!--SAVE-->
        <!--</button>-->

    </div>
</div>
