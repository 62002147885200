import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Network} from "../../../models/network";

@Injectable({
  providedIn: 'root'
})
export class PinterestService {
    boards: any;
    onBoardsUpdated: Subject<any>;
    onBoardsPassedUpdated: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    onAccountUpdated: BehaviorSubject<Network> = new BehaviorSubject<Network>(null);

    constructor(private _httpClient: HttpClient) {
        this.onBoardsUpdated = new Subject();
    }

    /**
     * Get networks
     *
     * @returns {Promise<any>}
     */
    getBoards(accountId): Observable<any>
    {
        return this._httpClient.get(`${environment.apiUrl}pinterest/${accountId}/boards`);
    }
}
