import {ErrorHandler, Injectable} from '@angular/core';
import {SnotifyService} from 'ng-snotify';
import {SnotifyToastConfig} from 'ng-snotify/lib/interfaces/snotify-toast-config.interface';

@Injectable()
export class FuseErrorHandler implements ErrorHandler{

    constructor(private alertService: SnotifyService) { }

    handleError(error: any): void {
        console.log(error);
        // this.alertService.error(error.message, {
        //     position: 'rightTop'
        // } as SnotifyToastConfig);
    }
}
