import {Tag} from './tag';

export class Comment {
    id: string = null;
    text: string = null;
    contactId: string = null;
    contactAvatar: string = null;
    contactName: string = null;
    postDate: string = null;
    tags: Tag[] = [];
    postId: number = null;
    commentParent: number = null;
    answers: Comment[] = [];
    from: {id: number, name: string} = null;
    message: string;
    // tslint:disable-next-line:variable-name
    created_time: string;
    liked = false;
}
