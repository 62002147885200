import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {environment} from "../../../../../environments/environment";
import {tap} from "rxjs/operators";

@Injectable()
export class SocialMediaManagementTeamsService implements Resolve<any>
{
    teams: any[];
    onTeamsChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onTeamsChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getTeams()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get teams
     *
     * @returns {Promise<any>}
     */
    getTeams(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}all-teams/`)
                .subscribe((response: any) => {
                    this.teams = response['groups'];
                    this.onTeamsChanged.next(this.teams);
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get owned teams
     *
     * @returns {Promise<any>}
     */
    getOwnedTeams(): Observable<any>
    {
        return this._httpClient.get(`${environment.apiUrl}owned-teams/`);
    }

    getTeamNetworks(teamId: string | Array<string>): Observable<any> {
        return this._httpClient.get(`${environment.apiUrl}group/networks/` + (typeof teamId === 'string' ? ('?teamId[]=' + teamId) : ('?teamId[]=' + teamId.join('&teamId[]='))));
    }

    getTeams2(): Observable<any> {
        return this._httpClient.get(`${environment.apiUrl}all-teams/`);
    }

    removeTeam(teamId: number): Observable<any> {
        return this._httpClient.delete(`${environment.apiUrl}teams/` + teamId).pipe(
            tap(item => { this.teams.splice(this.teams.findIndex(i => i.id === teamId), 1); this.onTeamsChanged.next(this.teams); })
        );
    }
}
