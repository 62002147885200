<div class="dialog-content-wrapper">
    <div *ngIf="loading" style="position: absolute;top:0px;bottom:0px;left:0px;right:0px;background: rgba(0,0,0,0.6);z-index: 1;">
        <mat-progress-spinner style="position: absolute;top: calc(50vh - 165px);left: calc(50vw - 20px);" color="accent" mode="indeterminate" [diameter]="40"></mat-progress-spinner>
    </div>
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Accept Our Updated Terms of Service</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 pb-0 m-0" fusePerfectScrollbar>
        <div class="container">
            <div id="content">
                <div id="main-content">
                    <div class="row">
                        <div class="col-lg-12">
                            AdForce has updated its Terms of Services. Please click "Accept New terms" below to continue. You may also <a target="_blank" href="{{envDataApiUrl}}SOCIAL_FORCE_TERMS_OF_SERVICE.pdf">click here</a> to download updated Terms of Service for review.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button
            (click)="sendAcceptance()"
            class="mat-focus-indicator mat-accent mat-raised-button mat-button-base"
        >
            ACCEPT NEW TERMS
        </button>
        <button
            mat-button color="primary"
            class="save-button mat-button-base"
            (click)="closeAcceptance()"
            aria-label="CLOSE">
            CLOSE
        </button>
        <!--<button-->
        <!--mat-button-->
        <!--class="save-button mat-focus-indicator mat-accent mat-raised-button mat-button-base"-->
        <!--(click)="matDialogRef.close({-->
        <!--minimumAge: form.get('minimumAge').value,-->
        <!--countries: selectedList.countries,-->
        <!--regions: selectedList.regions,-->
        <!--cities: selectedList.cities,-->
        <!--zipCodes: selectedList.zipCodes-->
        <!--})"-->
        <!--aria-label="SAVE">-->
        <!--SAVE-->
        <!--</button>-->

    </div>
</div>
