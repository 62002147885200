import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaLibraryComponent } from './media-library/media-library.component';
import {MatSelectModule} from '@angular/material/select';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatProgressBarModule} from '@angular/material/progress-bar';



@NgModule({
    declarations: [MediaLibraryComponent],
    exports: [
        MediaLibraryComponent
    ],
    imports: [
        CommonModule,
        MatSelectModule,
        FuseSharedModule,
        MatButtonModule,
        MatInputModule,
        ScrollingModule,
        MatProgressBarModule
    ]
})
export class MediaLibraryModule { }
