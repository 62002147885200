<div class="message-container">
    <div class="profile mb-4" fxLayout="row" (click)="openConversation()">
        <div class="profile-picture">
            <img class="avatar commment-avatar" src="assets/images/user-avatar.jpg">
        </div>
        <div class="user" fxLayout="column">
            <div class="name">{{message.messages[message.messages.length - 1].from.name}}</div>
            <div class="time">{{message.messages[message.messages.length - 1].created_time | date : 'medium'}}</div>
        </div>
    </div>
    <div [ngStyle]="{maxHeight: (shortText) ? '58px' : 'unset'}" class="text-message ml-52" *ngIf="message.messages[message.messages.length - 1].message !== null && message.messages[message.messages.length - 1].message !== ''">
        {{message.messages[message.messages.length - 1].message}}
    </div>
    <a style="font-size: 10px;font-weight: 600;margin-left: 52px;" *ngIf="fromBoard
        && message.messages[message.messages.length - 1].message !== null
        && message.messages[message.messages.length - 1].message.length > 120" href="javascript::;" (click)="shortText = !shortText">
        {{(shortText) ? 'READ MORE &#10549;' : 'READ LESS &#10548;'}}
    </a>
</div>
