import {Team} from './team';
import {Network} from './network';
import {MatChipInputEvent} from "@angular/material/chips";
import {FuseUtils} from "../../@fuse/utils";

export class Member {
    id: bigint;
    fullName: string;
    name: string;
    email: string;
    image: string;
    company: string;
    jobTitle: string;
    description: string;
    role: string;
    twitterUrl: string;
    facebookUrl: string;
    linkedinUrl: string;
    googleUrl: string;
    teams: Team[];
    networks: Network[];
    active: boolean;
    accepted_terms_date: string;

    /**
     * Constructor
     *
     * @param member
     */
    constructor(member?)
    {
        member = member || {};
        this.id = member.id || FuseUtils.generateGUID();
        this.name = member.name || '';
        this.email = member.email || '';
        this.description = member.description || '';
        this.networks = member.networks || [];
        this.teams = member.teams || [];
        this.image = member.image || [];
        this.active = member.active || true;
        this.accepted_terms_date = member.accepted_terms_date || null;
    }

    /**
     * Add network
     *
     * @param {MatChipInputEvent} event
     */
    addNetwork(event: MatChipInputEvent): void
    {
        const input = event.input;
        const value = event.value;

        // Add network
        if ( value )
        {
            // this.networks.push(value);
        }

        // Reset the input value
        if ( input )
        {
            input.value = '';
        }
    }

    /**
     * Remove network
     *
     * @param network
     */
    removeNetwork(network): void
    {
        const index = this.networks.indexOf(network);

        if ( index >= 0 )
        {
            this.networks.splice(index, 1);
        }
    }

    /**
     * Add team
     *
     * @param {MatChipInputEvent} event
     */
    addTeam(event: MatChipInputEvent): void
    {
        const input = event.input;
        const value = event.value;

        // Add team
        if ( value )
        {
            // this.teams.push(value);
        }

        // Reset the input value
        if ( input )
        {
            input.value = '';
        }
    }

    /**
     * Remove team
     *
     * @param team
     */
    removeTeam(team): void
    {
        const index = this.teams.indexOf(team);

        if ( index >= 0 )
        {
            this.teams.splice(index, 1);
        }
    }
}

export const roles = ['ADMIN', 'MANAGER', 'BASIC'];
