import {Team} from './team';
import {Member} from './member';
import {Post} from './post';
import {FuseUtils} from "../../@fuse/utils";
import {MatChipInputEvent} from "@angular/material/chips";

export class Network {
    id: bigint;
    name: string;
    logo: string;
    image: string;
    type: string;
    token: string;
    teams: Team[];
    members: Member[];
    posts: Post[];
    active: boolean;
    description: string;
    handle: string;
    picture: string;
    // tslint:disable-next-line:variable-name
    network_type: string;
    auth_token: string;
    network_id: string;

    /**
     * Constructor
     *
     * @param network
     */
    constructor(network?)
    {
        network = network || {};
        this.id = network.id || FuseUtils.generateGUID();
        this.name = network.name || '';
        this.logo = network.logo || '';
        this.picture = network.picture || '';
        this.description = network.description || '';
        this.teams = network.teams || [];
        this.members = network.members || [];
        this.image = network.image || '';
        this.active = network.active || true;
        this.posts = network.posts || true;
        this.token = network.token || true;
        this.type = network.type || true;
        this.handle = network.handle || true;
        this.network_type = network.network_type || null;
        this.auth_token = network.auth_token || null;
        this.network_id = network.network_id || null;
    }

    /**
     * Add category
     *
     * @param {MatChipInputEvent} event
     */
    addTeam(event: MatChipInputEvent): void
    {
        const input = event.input;
        const value = event.value;

        // Add category
        if ( value )
        {
            // this.teams.push(value);
        }

        // Reset the input value
        if ( input )
        {
            input.value = '';
        }
    }

    /**
     * Remove category
     *
     * @param category
     */
    removeTeam(category): void
    {
        const index = this.teams.indexOf(category);

        if ( index >= 0 )
        {
            this.teams.splice(index, 1);
        }
    }

    /**
     * Add member
     *
     * @param {MatChipInputEvent} event
     */
    addMember(event: MatChipInputEvent): void
    {
        const input = event.input;
        const value = event.value;

        // Add member
        if ( value )
        {
            // this.members.push(value);
        }

        // Reset the input value
        if ( input )
        {
            input.value = '';
        }
    }

    /**
     * Remove member
     *
     * @param member
     */
    removeMember(member): void
    {
        const index = this.members.indexOf(member);

        if ( index >= 0 )
        {
            this.members.splice(index, 1);
        }
    }
}
