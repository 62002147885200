import {
    FacebookPost,
    InstagramPost,
    LinkedinPost,
    PinterestPost,
    Post,
    TwitterPost,
    YoutubePost
} from '../../app/models/post';
import {Media} from '../../app/models/media';
import {Network} from '../../app/models/network';
import {Comment} from '../../app/models/comment';

export class FuseUtils
{
    /**
     * Filter array by string
     *
     * @param mainArr
     * @param searchText
     * @returns {any}
     */
    public static filterArrayByString(mainArr, searchText): any
    {
        if ( searchText === '' )
        {
            return mainArr;
        }

        searchText = searchText.toLowerCase();

        return mainArr.filter(itemObj => {
            return this.searchInObj(itemObj, searchText);
        });
    }

    /**
     * Search in object
     *
     * @param itemObj
     * @param searchText
     * @returns {boolean}
     */
    public static searchInObj(itemObj, searchText): boolean
    {
        for ( const prop in itemObj )
        {
            if ( !itemObj.hasOwnProperty(prop) )
            {
                continue;
            }

            const value = itemObj[prop];

            if ( typeof value === 'string' )
            {
                if ( this.searchInString(value, searchText) )
                {
                    return true;
                }
            }

            else if ( Array.isArray(value) )
            {
                if ( this.searchInArray(value, searchText) )
                {
                    return true;
                }
            }

            if ( typeof value === 'object' )
            {
                if ( this.searchInObj(value, searchText) )
                {
                    return true;
                }
            }
        }
    }

    /**
     * Search in array
     *
     * @param arr
     * @param searchText
     * @returns {boolean}
     */
    public static searchInArray(arr, searchText): boolean
    {
        for ( const value of arr )
        {
            if ( typeof value === 'string' )
            {
                if ( this.searchInString(value, searchText) )
                {
                    return true;
                }
            }

            if ( typeof value === 'object' )
            {
                if ( this.searchInObj(value, searchText) )
                {
                    return true;
                }
            }
        }
    }

    /**
     * Search in string
     *
     * @param value
     * @param searchText
     * @returns {any}
     */
    public static searchInString(value, searchText): any
    {
        return value.toLowerCase().includes(searchText);
    }

    /**
     * Generate a unique GUID
     *
     * @returns {string}
     */
    public static generateGUID(): string
    {
        function S4(): string
        {
            return Math.floor((1 + Math.random()) * 0x10000)
                       .toString(16)
                       .substring(1);
        }

        return S4() + S4();
    }

    /**
     * Toggle in array
     *
     * @param item
     * @param array
     */
    public static toggleInArray(item, array): void
    {
        if ( array.indexOf(item) === -1 )
        {
            array.push(item);
        }
        else
        {
            array.splice(array.indexOf(item), 1);
        }
    }

    /**
     * Handleize
     *
     * @param text
     * @returns {string}
     */
    public static handleize(text): string
    {
        return text.toString().toLowerCase()
                   .replace(/\s+/g, '-')           // Replace spaces with -
                   .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                   .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                   .replace(/^-+/, '')             // Trim - from start of text
                   .replace(/-+$/, '');            // Trim - from end of text
    }

    /**
     * Format a post Object from the server.
     * @param serverPost
     * @param network
     * @returns Post | boolean
     */
    public static formatServerPost(serverPost: any, network: Network): Post | boolean {
        const classes = { post: Post, facebook: FacebookPost, instagram: InstagramPost };
        if (network.network_type.toLowerCase() === 'facebook')
        {
            const media = [];
            let link = null;
            if (typeof serverPost.schedule_for !== 'undefined') {
                const postDate = new Date(serverPost.schedule_for.replace(' ', 'T') + 'Z');
                const now = new Date();
                return Object.assign(new FacebookPost(), {
                    ...serverPost.nonformatted_data,
                    id: serverPost.id,
                    status: (postDate.getTime() - now.getTime() < 0) ? 'published' : 'scheduled',
                    editable: (postDate.getTime() - now.getTime() > 0),
                    type: 'facebook',
                    platform_id: serverPost.platform_id,
                    network: serverPost.network,
                    postDate: postDate
                });
            }
            if (
                typeof serverPost.attachments !== 'undefined'
                && serverPost.attachments !== null
                && typeof serverPost.attachments.data !== 'undefined'
                && serverPost.attachments.data !== null
            ) {
                for (const med of serverPost.attachments.data) {
                    switch (med.media_type) {
                        case 'photo':
                            media.push({
                                id: (typeof med.target !== 'undefined' && med.target !== null) ? med.target.id : serverPost.id,
                                height: med.media['image'].height,
                                width: med.media['image'].width,
                                mediaUrl: med.media['image'].src,
                                type: 'image'
                            });
                            break;
                        case 'album':
                            for (const m of med.subattachments.data) {
                                media.push({
                                    id: typeof med.target !== 'undefined' ? med.target.id : '',
                                    height: m.media['image'].height,
                                    width: m.media['image'].width,
                                    mediaUrl: m.media['image'].src,
                                    type: 'image'
                                });
                            }
                            break;
                        case 'video':
                            media.push({
                                id: (typeof med.target !== 'undefined' && med.target !== null) ? med.target.id : serverPost.id,
                                height: med.media['image'].height,
                                width: med.media['image'].width,
                                mediaUrl: (typeof med.media['source'] !== 'undefined') ? med.media['source'] : med.media['image'].src,
                                previewUrl: med.media['image'].src,
                                type: 'video'
                            });
                            break;
                        case 'link':
                            link = {
                                link: med.unshimmed_url,
                                imageUrl: (typeof med.media !== 'undefined' && med.media !== null) ? med.media['image'].src : '',
                                title: med.title,
                                description: med.description,
                                // tslint:disable-next-line:max-line-length
                                host: (typeof med.unshimmed_url !== 'undefined' && med.unshimmed_url !== null) ? med.unshimmed_url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0] : ''
                            };
                            break;
                    }
                }
            }

            const comments: Array<Comment> = [];
            if (typeof serverPost['comments'] !== 'undefined') {
                // tslint:disable-next-line:forin
                for (const i in serverPost['comments']['data']) {
                    const comment = new Comment();
                    comment.id = serverPost['comments']['data'][i]['id'];
                    comment.text = serverPost['comments']['data'][i]['message'];
                    comment.contactId = '0';
                    comment.contactName = 'Guest';
                    comment.postDate = serverPost['comments']['data'][i]['created_time'];
                    comment.contactAvatar = null;
                    if (typeof serverPost['comments']['data'][i]['from'] !== 'undefined') {
                        comment.contactId = serverPost['comments']['data'][i]['from']['id'];
                        comment.contactName = serverPost['comments']['data'][i]['from']['name'];
                        comment.contactAvatar = serverPost['comments']['data'][i]['from']['id'] === network.network_id ? network.picture : null;
                    }
                    if (typeof serverPost['comments']['data'][i]['comments'] !== 'undefined') {
                        const answers = [];
                        // tslint:disable-next-line:forin
                        for (const j in serverPost['comments']['data'][i]['comments']['data']) {
                            const reply = new Comment();
                            reply.id = serverPost['comments']['data'][i]['comments']['data'][j]['id'];
                            reply.text = serverPost['comments']['data'][i]['comments']['data'][j]['message'];
                            reply.contactId = '0';
                            reply.contactName = 'Guest';
                            reply.postDate = serverPost['comments']['data'][i]['comments']['data'][j]['created_time'];
                            reply.contactAvatar = null;
                            if (typeof serverPost['comments']['data'][i]['comments']['data'][j]['from'] !== 'undefined') {
                                reply.contactId = serverPost['comments']['data'][i]['comments']['data'][j]['from']['id'];
                                reply.contactName = serverPost['comments']['data'][i]['comments']['data'][j]['from']['name'];
                                reply.contactAvatar = serverPost['comments']['data'][i]['comments']['data'][j]['from']['id'] === network.network_id ? network.picture : null;
                            }
                            answers.push(reply);
                        }
                        comment.answers = answers;
                    }
                    comments.push(comment);
                }
            }

            return Object.assign(new classes[network.network_type.toLowerCase()](), {
                id: serverPost.id,
                postDate: (!serverPost.is_published && typeof serverPost.scheduled_publish_time !== 'undefined')
                    ? new Date(serverPost.scheduled_publish_time * 1000)
                    : serverPost.created_time,
                text: serverPost.message,
                type: network.network_type.toLowerCase(),
                network: network,
                media: media,
                status: (serverPost.is_published) ? 'published' : 'scheduled',
                // tags: (typeof serverPost.message_tags !== 'undefined') ? serverPost.message_tags.reduce(
                //     (allTags, tag) => {
                //         if (typeof tag.type === 'undefined') {
                //             tag.name = serverPost.message.substring(tag.offset, tag.offset + tag.length);
                //             allTags.push(tag);
                //         }
                //         return allTags;
                //     }, []) : null,
                tags: (typeof serverPost.message_tags !== 'undefined') ? serverPost.message_tags : [],
                editable: !serverPost.is_published,
                reactions: serverPost.reactions,
                shares: serverPost.shares,
                link: link,
                comments: comments,
                location: (typeof serverPost.place !== 'undefined') ? serverPost.place : null,
                audience: (typeof serverPost.targeting !== 'undefined') ? serverPost.targeting : null,
                visitor: (String(network.network_id).indexOf(serverPost.from.id) === -1),
                from: serverPost.from,
                postLink: serverPost.permalink_url
            });
        } else if (network.network_type.toLowerCase() === 'instagram')
        {
            if (typeof serverPost.schedule_for !== 'undefined') {
                const postDate = new Date(serverPost.schedule_for.replace(' ', 'T') + 'Z');
                const now = new Date();
                return Object.assign(new InstagramPost(), {
                    ...serverPost.nonformatted_data,
                    id: serverPost.id,
                    status: (postDate.getTime() - now.getTime() < 0) ? 'published' : 'scheduled',
                    editable: (postDate.getTime() - now.getTime() > 0),
                    type: 'instagram',
                    platform_id: serverPost.platform_id,
                    network: serverPost.network,
                    postDate: postDate
                });
            }
            let media = [];
            if (serverPost.media_type === 'CAROUSEL_ALBUM') {
                media = serverPost.children.data.map(img => ({id: img.id, height: null, width: null, mediaUrl: img.media_url, type: 'image'} as Media));
            } else {
                media.push({
                    id: serverPost.id,
                    height: null,
                    width: null,
                    mediaUrl: serverPost.media_url,
                    type: serverPost.media_type.toLowerCase()
                });
            }
            const postDate = new Date(serverPost.timestamp);
            const now = new Date();
            const comments: Array<Comment> = [];
            if (typeof serverPost['comments'] !== 'undefined') {
                // tslint:disable-next-line:forin
                for (const i in serverPost.comments['data']) {
                    const comment = new Comment();
                    comment.id = serverPost.comments['data'][i]['id'];
                    comment.text = serverPost.comments['data'][i]['text'];
                    comment.contactId = serverPost.comments['data'][i]['username'];
                    comment.contactName = serverPost.comments['data'][i]['username'];
                    comment.postDate = serverPost.comments['data'][i]['timestamp'];
                    comment.contactAvatar = serverPost['comments']['data'][i]['username'] === network.name ? network.picture : null;
                    if (typeof serverPost.comments['data'][i]['replies'] !== 'undefined') {
                        const answers = [];
                        // tslint:disable-next-line:forin
                        for (const j in serverPost.comments['data'][i]['replies']['data']) {
                            const reply = new Comment();
                            reply.id = serverPost.comments['data'][i]['replies']['data'][j]['id'];
                            reply.text = serverPost.comments['data'][i]['replies']['data'][j]['text'];
                            reply.contactId = serverPost.comments['data'][i]['replies']['data'][j]['username'];
                            reply.contactName = serverPost.comments['data'][i]['replies']['data'][j]['username'];
                            reply.postDate = serverPost.comments['data'][i]['replies']['data'][j]['timestamp'];
                            reply.contactAvatar = serverPost['comments']['data'][i]['replies']['data'][j]['username'] === network.name ? network.picture : null;
                            answers.push(reply);
                        }
                        comment.answers = answers;
                    }
                    comments.push(comment);
                }
            }
            return Object.assign(new InstagramPost(), {
                id: serverPost.id,
                postDate: serverPost.timestamp,
                text: serverPost.caption,
                type: network.network_type.toLowerCase(),
                network: network,
                media: media,
                status: (postDate.getTime() - now.getTime() < 0) ? 'published' : 'scheduled',
                tags: serverPost.message_tags,
                editable: (postDate.getTime() - now.getTime() > 0),
                link: null,
                comments: comments,
                likes: serverPost.like_count,
                from: serverPost.owner,
                postLink: serverPost.permalink
            });
        } else if (network.network_type.toLowerCase() === 'linkedin')
        {
            if (typeof serverPost.schedule_for !== 'undefined') {
                const postDate = new Date(serverPost.schedule_for.replace(' ', 'T') + 'Z');
                const now = new Date();
                return Object.assign(new LinkedinPost(), {
                    ...serverPost.nonformatted_data,
                    id: serverPost.id,
                    status: (postDate.getTime() - now.getTime() < 0) ? 'published' : 'scheduled',
                    editable: (postDate.getTime() - now.getTime() > 0),
                    type: 'linkedin',
                    platform_id: serverPost.platform_id,
                    network: serverPost.network,
                    postDate: postDate
                });
            }
            const media = [];
            let link = null;
            // if )_

            if (typeof serverPost.content !== 'undefined' && serverPost.content !== null) {
                if (typeof serverPost.content.media !== 'undefined' && typeof serverPost.content.media.links !== 'undefined' && serverPost.content.media.links.length > 0) {
                    if (typeof serverPost.content.article !== 'undefined') {
                        link = {
                            link: serverPost.content.article.source,
                            imageUrl: serverPost.content.media.links[0].downloadUrl,
                            title: serverPost.content.article.title,
                            description: serverPost.content.article.description,
                            // tslint:disable-next-line:max-line-length
                            host: (typeof serverPost.content.article.source !== 'undefined') ? serverPost.content.article.source.replace('http://', '').replace('https://', '').split(/[/?#]/)[0] : ''
                        };
                    } else {
                        for (const asset of serverPost.content.media.links) {
                            const type = asset.id.split(':');
                            media.push({
                                id: asset.id,
                                height: null,
                                width: null,
                                mediaUrl: asset.downloadUrl,
                                type: type[2]
                            });
                        }
                    }
                }
            }

            // if (
            //     typeof serverPost.specificContent !== 'undefined'
            //     && typeof serverPost.specificContent['com.linkedin.ugc.ShareContent'] !== 'undefined'
            //     && typeof serverPost.specificContent['com.linkedin.ugc.ShareContent'].media !== 'undefined'
            //     && serverPost.specificContent['com.linkedin.ugc.ShareContent'].media.length > 0
            // ) {
            //     for (const asset of serverPost.specificContent['com.linkedin.ugc.ShareContent'].media) {
            //         if (serverPost.specificContent['com.linkedin.ugc.ShareContent'].shareMediaCategory.toLowerCase() === 'image') {
            //             media.push({
            //                 id: asset.media,
            //                 height: (typeof asset.thumbnails !== 'undefined' && typeof asset.thumbnails[0] !== 'undefined'
            //                     && typeof asset.thumbnails[0].height !== 'undefined') ? typeof asset.thumbnails[0].height : null,
            //                 width: (typeof asset.thumbnails !== 'undefined' && typeof asset.thumbnails[0] !== 'undefined'
            //                     && typeof asset.thumbnails[0].width !== 'undefined') ? typeof asset.thumbnails[0].width : null,
            //                 mediaUrl: asset.originalUrl,
            //                 type: serverPost.specificContent['com.linkedin.ugc.ShareContent'].shareMediaCategory.toLowerCase()
            //             });
            //         } else if (
            //             serverPost.specificContent['com.linkedin.ugc.ShareContent'].shareMediaCategory.toLowerCase() === 'video'
            //             && typeof asset['media~'] !== 'undefined'
            //             && typeof asset['media~'].elements !== 'undefined'
            //             && asset['media~'].elements.length > 0
            //             && typeof asset['media~'].elements !== 'undefined'
            //             && typeof asset['media~'].elements[asset['media~'].elements.length - 1] !== 'undefined'
            //             && typeof asset['media~'].elements[asset['media~'].elements.length - 1].identifiers !== 'undefined'
            //             && asset['media~'].elements[asset['media~'].elements.length - 1].identifiers.length > 0
            //             && typeof asset['media~'].elements[asset['media~'].elements.length - 1].identifiers[0].identifier !== 'undefined'
            //         ) {
            //             media.push({
            //                 id: asset.media,
            //                 height: (typeof asset.thumbnails !== 'undefined' && typeof asset.thumbnails[0] !== 'undefined'
            //                     && typeof asset.thumbnails[0].height !== 'undefined') ? asset.thumbnails[0].height : null,
            //                 width: (typeof asset.thumbnails !== 'undefined' && typeof asset.thumbnails[0] !== 'undefined'
            //                     && typeof asset.thumbnails[0].width !== 'undefined') ? asset.thumbnails[0].width : null,
            //                 mediaUrl: asset['media~'].elements[asset['media~'].elements.length - 1].identifiers[0].identifier,
            //                 type: serverPost.specificContent['com.linkedin.ugc.ShareContent'].shareMediaCategory.toLowerCase(),
            //                 fileSize: asset['media~'].elements[asset['media~'].elements.length - 1].identifiers[0].size
            //             });
            //         } else if (
            //             serverPost.specificContent['com.linkedin.ugc.ShareContent'].shareMediaCategory.toLowerCase() === 'article'
            //
            //
            //             && typeof asset.originalUrl !== 'undefined'
            //             && typeof asset.thumbnails !== 'undefined'
            //             && asset.thumbnails.length > 0
            //         ) {
            //             link = {
            //                 link: asset.originalUrl,
            //                 imageUrl: (typeof asset.thumbnails[0].url !== 'undefined') ? asset.thumbnails[0].url : '',
            //                 title: null,
            //                 description: null,
            //                 // tslint:disable-next-line:max-line-length
            //                 host: (typeof asset.thumbnails[0].url !== 'undefined') ? asset.thumbnails[0].url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0] : ''
            //             };
            //         }
            //     }
            // }

            const postDate = new Date(serverPost.createdAt);
            const now = new Date();
            return Object.assign(new LinkedinPost(), {
                id: serverPost.id,
                postDate: postDate,
                text: (typeof serverPost.commentary !== 'undefined'
                ) ? serverPost.commentary : '',
                type: network.network_type.toLowerCase(),
                network: network,
                media: media,
                status: (postDate.getTime() - now.getTime() < 0) ? 'published' : 'scheduled',
                tags: null,
                editable: (postDate.getTime() - now.getTime() > 0),
                link: link,
                comments: (
                    typeof serverPost.comments !== 'undefined'
                    && typeof serverPost.comments.commentsSummary !== 'undefined'
                    && typeof serverPost.comments.commentsSummary.totalFirstLevelComments !== 'undefined'
                    && serverPost.comments.commentsSummary.totalFirstLevelComments > 0)
                    ? new Array(serverPost.comments.commentsSummary.totalFirstLevelComments)
                    : null,
                likes: (
                    typeof serverPost.comments !== 'undefined'
                    && typeof serverPost.comments.likesSummary !== 'undefined'
                    && typeof serverPost.comments.likesSummary.totalLikes !== 'undefined'
                    && serverPost.comments.likesSummary.totalLikes > 0)
                ? serverPost.comments.likesSummary.totalLikes
                : null,
                from: null,
                likedBySelf: (
                    typeof serverPost.comments !== 'undefined'
                    && typeof serverPost.comments.likesSummary !== 'undefined'
                    && typeof serverPost.comments.likesSummary.likedByCurrentUser !== 'undefined'
                ) ? serverPost.comments.likesSummary.likedByCurrentUser : false,
                postLink: 'https://www.linkedin.com/feed/update/' + serverPost.id + '/',
                reactions: (typeof serverPost.reactions !== 'undefined' && typeof serverPost.reactions.reactionSummaries !== 'undefined')
                    ? serverPost.reactions.reactionSummaries
                    : null
            });
        } else if (network.network_type.toLowerCase() === 'twitter')
        {
            if (typeof serverPost.schedule_for !== 'undefined') {
                const postDate = new Date(serverPost.schedule_for.replace(' ', 'T') + 'Z');
                const now = new Date();
                return Object.assign(new TwitterPost(), {
                    ...serverPost.nonformatted_data,
                    id: serverPost.id,
                    status: (postDate.getTime() - now.getTime() < 0) ? 'published' : 'scheduled',
                    editable: (postDate.getTime() - now.getTime() > 0),
                    type: 'twitter',
                    platform_id: serverPost.platform_id,
                    network: serverPost.network,
                    postDate: postDate
                });
            }
            const media = [];
            let link = null;
            if (
                typeof serverPost.entities !== 'undefined'
                && serverPost.entities !== null
                && typeof serverPost.entities.urls !== 'undefined'
                && serverPost.entities.urls !== null
                && serverPost.entities.urls.length > 0
            ) {
                for (const url of serverPost.entities.urls) {
                    if (
                        typeof url.expanded_url !== 'undefined'
                        && url.expanded_url !== null
                        && url.expanded_url !== ''
                        && url.expanded_url.indexOf('twitter.com/') !== -1
                    ) {
                        serverPost.text = serverPost.text.replace(url.url, '');
                    }
                }
            }
            if (typeof serverPost.public_metrics === 'undefined') {
                if (
                    typeof serverPost.extended_entities !== 'undefined'
                    && typeof serverPost.extended_entities.media !== 'undefined'
                    && serverPost.extended_entities.media.length > 0
                ) {
                    for (const asset of serverPost.extended_entities.media) {
                        if (asset.type === 'photo' || asset.type === 'animated_gif') {
                            media.push({
                                id: asset.id,
                                height: (typeof asset.sizes !== 'undefined' && typeof asset.sizes.large !== 'undefined'
                                    && typeof asset.sizes.large.h !== 'undefined') ? asset.sizes.large.h : null,
                                width: (typeof asset.sizes !== 'undefined' && typeof asset.sizes.large !== 'undefined'
                                    && typeof asset.sizes.large.w !== 'undefined') ? asset.sizes.large.w : null,
                                mediaUrl: asset.media_url_https,
                                type: 'image'
                            });
                        } else if (asset.type === 'video') {
                            const videoPos = asset.variants.findIndex(i => i.content_type === 'video/mp4');
                            media.push({
                                id: asset.id,
                                height: null,
                                width: null,
                                mediaUrl: asset.variants[(videoPos !== -1 ? videoPos : asset.variants.length - 1)].url,
                                type: asset.variants[(videoPos !== -1 ? videoPos : asset.variants.length - 1)].content_type,
                                fileSize: null
                            });
                        }
                    }
                }
                if (typeof serverPost.entities !== 'undefined') {
                    if (typeof serverPost.entities.urls !== 'undefined' && serverPost.entities.urls.length > 0) {
                        link = {
                            link: serverPost.entities.urls[0].expanded_url,
                            imageUrl: '',
                            title: null,
                            description: null,
                            // tslint:disable-next-line:max-line-length
                            host: (typeof serverPost.entities.urls[0].expanded_url !== 'undefined') ? serverPost.entities.urls[0].expanded_url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0] : ''
                        };
                    }
                }

                const postDate = new Date(serverPost.created_at);
                const now = new Date();
                return Object.assign(new TwitterPost(), {
                    id: serverPost.id,
                    id_str: serverPost.id_str,
                    postDate: postDate,
                    text: serverPost.text,
                    type: network.network_type.toLowerCase(),
                    network: network,
                    media: media,
                    status: (postDate.getTime() - now.getTime() < 0) ? 'published' : 'scheduled',
                    tags: null,
                    editable: (postDate.getTime() - now.getTime() > 0),
                    link: link,
                    likes: serverPost.favorite_count,
                    retweets: serverPost.retweet_count,
                    retweeted: serverPost.retweeted,
                    from: (serverPost.in_replay_to_user_id !== null) ? serverPost.user : null,
                    quoted_status: typeof serverPost.quoted_status !== 'undefined' ? serverPost.quoted_status : null,
                    likedBySelf: serverPost.favorited,
                    postLink: 'https://twitter.com/' + serverPost.user.screen_name + '/status/' + serverPost.id_str
                });
            } else {
                if (
                    typeof serverPost.attachments !== 'undefined'
                    && typeof serverPost.attachments.medias !== 'undefined'
                    && serverPost.attachments.medias.length > 0
                ) {
                    for (const asset of serverPost.attachments.medias) {
                        if (asset.type === 'photo' || asset.type === 'animated_gif') {
                            media.push({
                                id: asset.media_key,
                                height: (typeof asset.height !== 'undefined') ? asset.height : null,
                                width: (typeof asset.width !== 'undefined') ? asset.width : null,
                                mediaUrl: asset.url,
                                type: 'image'
                            });
                        } else if (asset.type === 'video') {
                            const videoPos = asset.variants.findIndex(i => i.content_type === 'video/mp4');
                            media.push({
                                id: asset.id,
                                height: null,
                                width: null,
                                mediaUrl: asset.variants[(videoPos !== -1 ? videoPos : asset.variants.length - 1)].url,
                                type: asset.variants[(videoPos !== -1 ? videoPos : asset.variants.length - 1)].content_type,
                                fileSize: null
                            });
                        }
                    }
                }
                if (typeof serverPost.entities !== 'undefined') {
                    if (typeof serverPost.entities.urls !== 'undefined' && serverPost.entities.urls.length > 0) {
                        link = {
                            link: serverPost.entities.urls[0].expanded_url,
                            imageUrl: '',
                            title: null,
                            description: null,
                            // tslint:disable-next-line:max-line-length
                            host: (typeof serverPost.entities.urls[0].expanded_url !== 'undefined') ? serverPost.entities.urls[0].expanded_url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0] : ''
                        };
                    }
                }

                const postDate = new Date(serverPost.created_at);
                const now = new Date();
                return Object.assign(new TwitterPost(), {
                    id: serverPost.id,
                    id_str: serverPost.id_str,
                    postDate: postDate,
                    text: serverPost.text,
                    type: network.network_type.toLowerCase(),
                    network_type: network.network_type.toLowerCase(),
                    network: network,
                    media: media,
                    status: (postDate.getTime() - now.getTime() < 0) ? 'published' : 'scheduled',
                    tags: null,
                    editable: (postDate.getTime() - now.getTime() > 0),
                    link: link,
                    likes: serverPost.favorite_count,
                    retweets: serverPost.retweet_count,
                    retweeted: serverPost.retweeted,
                    from: (serverPost.in_replay_to_user_id !== null) ? serverPost.user : null,
                    quoted_status: typeof serverPost.quoted_status !== 'undefined' ? serverPost.quoted_status : null,
                    likedBySelf: serverPost.favorited,
                    postLink: 'https://twitter.com/' + serverPost.user.screen_name + '/status/' + serverPost.id_str
                });
            }
        } else if (network.network_type.toLowerCase() === 'pinterest')
        {
            if (typeof serverPost.schedule_for !== 'undefined') {
                const postDate = new Date(serverPost.schedule_for.replace(' ', 'T') + 'Z');
                const now = new Date();
                return Object.assign(new PinterestPost(), {
                    ...serverPost.nonformatted_data,
                    id: serverPost.id,
                    status: (postDate.getTime() - now.getTime() < 0) ? 'published' : 'scheduled',
                    editable: (postDate.getTime() - now.getTime() > 0),
                    type: 'pinterest',
                    platform_id: serverPost.platform_id,
                    network: serverPost.network,
                    postDate: postDate
                });
            }
            const media = [];
            if (serverPost.media.media_type === 'image') {
                media.push({
                    id: serverPost.id,
                    height: serverPost.media.images.originals.height,
                    width: serverPost.media.images.originals.width,
                    mediaUrl: serverPost.media.images.originals.url,
                    type: serverPost.media.media_type.toLowerCase()
                });
            }
            const postDate = new Date(serverPost.created_at);
            const now = new Date();
            return Object.assign(new PinterestPost(), {
                id: serverPost.id,
                postDate: postDate,
                text: serverPost.description,
                type: network.network_type.toLowerCase(),
                network: network,
                media: media,
                status: (postDate.getTime() - now.getTime() < 0) ? 'published' : 'scheduled',
                tags: null,
                editable: (postDate.getTime() - now.getTime() > 0),
                title: serverPost.title,
                destination: serverPost.link,
                boards: [serverPost.board_id],
                from: serverPost.board_owner,
                postLink: 'https://pinterest.com/pin/' + serverPost.id
            });
        } else if (network.network_type.toLowerCase() === 'youtube')
        {
            if (typeof serverPost.schedule_for !== 'undefined') {
                const postDate = new Date(serverPost.schedule_for.replace(' ', 'T') + 'Z');
                const now = new Date();
                return Object.assign(new YoutubePost(), {
                    ...serverPost.nonformatted_data,
                    id: serverPost.id,
                    status: (postDate.getTime() - now.getTime() < 0) ? 'published' : 'scheduled',
                    editable: (postDate.getTime() - now.getTime() > 0),
                    type: 'youtube',
                    platform_id: serverPost.platform_id,
                    network: serverPost.network,
                    postDate: postDate
                });
            }
            const media = [];
            media.push({
                id: serverPost.id.videoId,
                height: serverPost.snippet.thumbnails.medium.height,
                width: serverPost.snippet.thumbnails.medium.width,
                mediaUrl: serverPost.snippet.thumbnails.medium.url,
                type: 'image'
            });
            const postDate = new Date(serverPost.snippet.publishedAt);
            const now = new Date();
            return Object.assign(new YoutubePost(), {
                id: serverPost.id.videoId,
                postDate: postDate,
                text: serverPost.snippet.description,
                type: 'youtube',
                network: network,
                media: media,
                status: (postDate.getTime() - now.getTime() < 0) ? 'published' : 'scheduled',
                tags: null,
                editable: (postDate.getTime() - now.getTime() > 0),
                title: serverPost.snippet.title,
                from: serverPost.snippet.channelId,
                postLink: 'https://www.youtube.com/watch?v=' + serverPost.id.videoId
            });
        } else {
            return false;
        }
    }

    public static formatDraftPost(serverPost: any, network: Network): Post | boolean {
        const classes = { post: Post, facebook: FacebookPost, instagram: InstagramPost, linkedin: LinkedinPost, twitter: TwitterPost };
        // if (network.network_type.toLowerCase() === 'facebook') {
        const media = [];
        let link = null;
        if (
            typeof serverPost.medias !== 'undefined'
            && serverPost.medias !== null
        ) {
            for (const med of serverPost.medias) {

                if (med.type.indexOf('image') !== -1 || med.type.indexOf('photo') !== -1 || med.type.indexOf('album') !== -1) {
                    media.push({
                        id: med.id,
                        height: med.height,
                        width: med.width,
                        mediaUrl: med.url,
                        type: 'image'
                    });
                } else if (med.type.indexOf('video') !== -1) {
                    media.push({
                        id: med.id,
                        height: med.height,
                        width: med.width,
                        mediaUrl: med.url,
                        type: 'video'
                    });
                } else if (med.type.indexOf('link') !== -1) {
                    link = {
                        imageUrl: med.url,
                        title: med.name,
                        description: med.name,
                        host: (typeof med.url !== 'undefined' && med.url !== null) ? med.url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0] : ''
                    };
                }
            }
        }

        const comments: Array<Comment> = [];

        return Object.assign(new classes[network.network_type.toLowerCase()](), {
            id: serverPost.id,
            postDate: (typeof serverPost.schedule_time !== 'undefined' && serverPost.schedule_time !== null) ? new Date(serverPost.schedule_time) : null,
            text: serverPost.message,
            type: network.network_type.toLowerCase(),
            network: network,
            media: media,
            status: 'draft',
            tags: JSON.parse(serverPost.tags),
            editable: true,
            reactions: null,
            shares: null,
            link: link,
            comments: comments,
            location: (typeof serverPost.location_id !== 'undefined') ? serverPost.location_id : null,
            audience: (typeof serverPost.audience !== 'undefined') ? JSON.parse(serverPost.audience) : null,
            visitor: false,
            from: {name: network.name, id: network.network_id},
            postLink: null
        });

        return false;
    }

    public static formatTwitterConversations(postId: string, messages: Array<any>, contacts: Array<any>) {
        const comments: Array<Comment> = [];
        const leafComments = []
        for (let i = 0; i < messages.length; i++) {
            const referenced_tweet = messages[i].referenced_tweets.find(a => a.type === 'replied_to');
            if (typeof referenced_tweet !== 'undefined' && (referenced_tweet.id === postId || messages.findIndex(a => a.id === referenced_tweet.id) === -1)) {
                const contact = contacts.find(a => a.id === messages[i]['author_id']);
                const comment = new Comment();
                comment.id = messages[i]['id'];
                comment.text = messages[i]['text'];
                comment.contactId = contact.username;
                comment.contactName = contact.name;
                comment['contactTwitterId'] = contact.id;
                comment.postDate = messages[i]['created_at'];
                comment.contactAvatar = contact.profile_image_url;
                comment.answers = [];
                comments.push(this.twitterConversationBuild(comment, messages, contacts));
            }
        }
        return comments;
    }

    public static twitterConversationBuild( comment: Comment, unorganized: Array<any>, contacts: Array<any>) {
        for (let i = 0; i < unorganized.length; i++) {
            const referenced_tweet = unorganized[i].referenced_tweets.find(a => a.type === 'replied_to');
            if (typeof referenced_tweet !== 'undefined' && comment.id === referenced_tweet.id) {
                const contact = contacts.find(a => a.id === unorganized[i]['author_id']);
                const newComment = new Comment();
                newComment.id = unorganized[i]['id'];
                newComment.text = unorganized[i]['text'];
                newComment.contactId = contact.username;
                newComment.contactName = contact.name;
                newComment['contactTwitterId'] = contact.id;
                newComment.postDate = unorganized[i]['created_at'];
                newComment.contactAvatar = contact.profile_image_url;
                newComment.answers = [];
                comment.answers.push(this.twitterConversationBuild(newComment, unorganized, contacts));
            }
        }
        return comment;
    }
}
