import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgencyService implements Resolve<any>{
    routeParams: any;
    agency: any;
    onAgencyChanged: BehaviorSubject<any>;

    constructor(private _httpClient: HttpClient) {
        this.onAgencyChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getAgency()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get agency
     *
     * @returns {Promise<any>}
     */
    getAgency(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onAgencyChanged.next(false);
                resolve(false);
            }
            else
            {
                this._httpClient.get(`${environment.apiUrl}get-agency/` + this.routeParams.id)
                    .subscribe((response: any) => {
                        this.agency = response['agency'];
                        this.onAgencyChanged.next(this.agency);
                        resolve(response);
                    }, reject);
            }
        });
    }

    /**
     * Save agency
     *
     * @param agency
     * @returns {Promise<any>}
     */
    saveAgency(agency): Promise<any>
    {
        console.log(agency);
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}update-agency/` + agency.id, agency)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Add agency
     *
     * @param agency
     * @returns {Promise<any>}
     */
    addAgency(agency): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}add-agency`, agency)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
