import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-circle-loader',
  templateUrl: './circle-loader.component.html',
  styleUrls: ['./circle-loader.component.scss']
})
export class CircleLoaderComponent implements OnInit {
    @Input() loading = false;
    constructor() { }

    ngOnInit(): void {
    }

}
