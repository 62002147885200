<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Select Client Networks To Add</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 pb-0 m-0" fusePerfectScrollbar style="max-height: 360px;">
        <div class="container">
            <div id="content">
                <div id="main-content" fxLayout="column">
                    <mat-checkbox [disabled]="page.exist" *ngFor="let page of pages" class="example-margin"
                                  [checked]="page.exist"
                                  (change)="setPage($event, page.id)">
                        <label class="check-label">
                            <img src="{{(page.profile_picture_url !== undefined) ? page.profile_picture_url : (page.picture !== undefined ? page.picture.data.url : 'assets/images/user-avatar.jpg')}}" width="50px;">
                            <span>{{page.name}}</span>
                        </label>
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button
            mat-button color="primary"
            class="save-button mat-button-base"
            (click)="matDialogRef.close()"
            aria-label="CLOSE">
            CLOSE
        </button>
        <button
            mat-button
            class="save-button mat-focus-indicator mat-accent mat-raised-button mat-button-base"
            (click)="matDialogRef.close({selectedPages: selectedPages})"
            aria-label="SAVE">
            SAVE
        </button>

    </div>
</div>
