import {Component, ElementRef, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Observable, Observer} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {LocationsService} from '../../locations/locations.service';

@Component({
  selector: 'app-facebook',
  templateUrl: './facebook.component.html',
  styleUrls: ['./facebook.component.scss']
})
export class FacebookComponent implements OnInit, OnDestroy {

    form: FormGroup;
    selectedList: {countries: Array<any>, regions: Array<any>, cities: Array<any>, zips: Array<any>} = {countries: [], regions: [], cities: [], zips: []};
    filteredData: Observable<any> = new Observable<any>((obs: Observer<any>) => {
        obs.next([]);
        obs.complete();
    });
    inputLoaders = {countries:  false, regions: false, cities: false, zips: false};
    token: string = null;

    constructor(
      public matDialogRef: MatDialogRef<FacebookComponent>,
      @Inject(MAT_DIALOG_DATA) private _data: any,
      private fb: FormBuilder,
      private locationsService: LocationsService
    ) {
        this.token = _data.token;
        if (typeof this._data.geo_locations !== 'undefined' && this._data.geo_locations !== null) {
            if (typeof this._data.geo_locations.countries !== 'undefined') {
                this.selectedList.countries = this._data.geo_locations.countries;
            }
            if (typeof this._data.geo_locations.regions !== 'undefined') {
                this.selectedList.regions = this._data.geo_locations.regions;
            }
            if (typeof this._data.geo_locations.cities !== 'undefined') {
                this.selectedList.cities = this._data.geo_locations.cities;
            }
            if (typeof this._data.geo_locations.zips !== 'undefined') {
                this.selectedList.zips = this._data.geo_locations.zips;
            }
        }
        this.form = this.fb.group({
          age_min: [(typeof this._data.age_min !== 'undefined') ? this._data.age_min : ''],
          geo_locations: this.fb.group({
              countries: [''],
              regions: [''],
              cities: [''],
              zips: ['']
          })
        });
    }

    ngOnInit(): void {
        this.form.get('geo_locations').get('countries').valueChanges.subscribe(
            value => {
                if (value !== null && value !== '' && typeof value === 'string') {
                    this.inputLoaders.countries = true;
                    this.filteredData = this.locationsService.getFacebookPageLocations(this.token, value, 'adcountry').pipe(
                        map(response => response.data),
                        tap(response => {
                            this.inputLoaders.countries = false;
                            return response;
                        })
                    );
                }
            }
        );
        this.form.get('geo_locations').get('regions').valueChanges.subscribe(
            value => {
                if (value !== null && value !== '' && typeof value === 'string') {
                    this.inputLoaders.regions = true;
                    this.filteredData = this.locationsService.getFacebookPageLocations(this.token, value, 'adgeolocation', 'region').pipe(
                        map(response => response.data.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)),
                        tap(response => {
                            this.inputLoaders.regions = false;
                            return response;
                        })
                    );
                }
            }
        );
        this.form.get('geo_locations').get('cities').valueChanges.subscribe(
            value => {
                if (value !== null && value !== '' && typeof value === 'string') {
                    this.inputLoaders.cities = true;
                    this.filteredData = this.locationsService.getFacebookPageLocations(this.token, value, 'adgeolocation', 'city').pipe(
                        map(response => response.data.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)),
                        tap(response => {
                            this.inputLoaders.cities = false;
                            return response;
                        })
                    );
                }
            }
        );
        this.form.get('geo_locations').get('zips').valueChanges.subscribe(
            value => {
                if (value !== null && value !== '' && typeof value === 'string') {
                    this.inputLoaders.zips = true;
                    this.filteredData = this.locationsService.getFacebookPageLocations(this.token, value, 'adgeolocation', 'zip').pipe(
                        map(response => response.data),
                        tap(response => { this.inputLoaders.zips = false; return response; })
                    );
                }
            }
        );
    }

    remove(value: any, type: string = 'countries'): void {
        const index = this.selectedList[type].findIndex(item => item.id === value.id);
        if (index >= 0) {
            this.selectedList[type].splice(index, 1);
        }
        this.form.get('geo_locations').get(type).setValue('');
    }

    selected(event: MatAutocompleteSelectedEvent, input: any, type: string = 'countries'): void {
        this.selectedList[type].push(event.option.value);
        this.filteredData = new Observable<any>((obs: Observer<any>) => {
            obs.next([]);
            obs.complete();
        });
        input.value = '';
        this.form.get('geo_locations').get(type).setValue('');
    }

    ngOnDestroy(): void {}
}
