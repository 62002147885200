import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
    apiKey = 'M5eW0jd9VF-8TY4OJjDgU7nu34L4PftOuXSVTiA4_Y0';
    apiAutoCompleteUrl = 'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json';
    apiCoordinatesUrl = 'https://geocoder.ls.hereapi.com/6.2/geocode.json';
    facebookUrl = 'https://graph.facebook.com/';
    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient) { }

    // getFacebookPageLocations(token: string, address: string, type: string = 'adcountry', param = '', fields = 'id,name,location'): Observable<any> {
    //     return this.http.get(
    //         this.facebookUrl + 'pages/search/?q=' + address + '&access_token=' + token + (fields !== '' ? ('&fields=' + fields) : '')
    //     );
    // }

    getFacebookPageLocations(token: string, address: string, type: string = 'adcountry', param = 'country', fields = ''): Observable<any> {
        return this.http.get(
            this.facebookUrl + '/search/?q=' + address + '&access_token=' + token + '&type=adgeolocation&location_types=["' + param + '"]'
            + (fields !== '' ? ('&fields=' + fields) : '')
        );
    }

    getFacebookPageLocationsMetadata(token: string, data: any): Observable<any> {
        return this.http.get(
            this.facebookUrl + '/search/?access_token=' + token + '&type=adgeolocationmeta'
            + ((typeof data.countries !== 'undefined' && data.countries.length > 0) ? ('&countries[]='
            + ((typeof data.countries[0] === 'string') ? data.countries.join('&countries[]=') : data.countries.map(c => c.key).join('&countries[]='))) : '')
            + ((typeof data.regions !== 'undefined' && data.regions.length > 0) ? '&regions[]=' + data.regions.map(item => item.key).join('&regions[]=') : '')
            + ((typeof data.zips !== 'undefined' && data.zips.length > 0) ? '&zips[]=' + data.zips.map(item => item.key).join('&zips[]=') : '')
            + ((typeof data.cities !== 'undefined' && data.cities.length > 0) ? '&cities[]=' + data.cities.map(item => item.key).join('&cities[]=') : '')
        );
    }

    getAddressAutocompleteSuggest(address: string, type: string = ''): Observable<any> {
        return this.http.get(
            this.apiAutoCompleteUrl + '?apiKey=' + this.apiKey + '&query=' + address
        );
    }

    getLinkedinLocations(token: string, text: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}` + 'linkedin-locations/' + token + '/' + text);
    }

    getTwitterLocations(networkId: string, text: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}` + 'twitter/locations/' + networkId + '/' + text);
    }

    getTwitterLocation(networkId: string, id: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}` + 'twitter/locations-by-id/' + networkId + '/' + id);
    }

    // getAddressCoordinates(address: string): Observable<any> {
    //     return this.http.get(
    //         // this.apiCoordinatesUrl + '?apiKey=' + this.apiKey + '&searchtext=' + address
    //     );
    // }
}
