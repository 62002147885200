import { MatChipInputEvent } from '@angular/material/chips';

import { FuseUtils } from '@fuse/utils';
import {Network} from '../../../../models/network';
import {Team} from '../../../../models/team';

export class Member
{
    id: string;
    name: string;
    email: string;
    handle: string;
    description: string;
    networks: Network[];
    teams: Team[];
    image: string;
    active: boolean;
    profile_photo: string;
    password: string;
    confirmPassword: string;
    role: string;
    agency_id: string;

    /**
     * Constructor
     *
     * @param member
     */
    constructor(member?)
    {
        member = member || {};
        this.id = member.id || null;
        this.name = member.name || '';
        this.email = member.email || '';
        this.handle = member.handle || FuseUtils.handleize(this.name);
        this.description = member.description || '';
        this.networks = member.networks || [];
        this.teams = member.teams || [];
        this.image = member.image || [];
        this.profile_photo = member.profile_photo || '';
        this.active = member.active || true;
        this.password = member.password || '';
        this.confirmPassword = member.confirmPassword || '';
        this.role = member.role || 'BASIC';
        this.agency_id = member.agency_id || '';
    }

    /**
     * Add network
     *
     * @param {MatChipInputEvent} event
     */
    addNetwork(event: MatChipInputEvent): void
    {
        const input = event.input;
        const value = event.value;

        // Add network
        if ( value )
        {
            // this.networks.push(value);
        }

        // Reset the input value
        if ( input )
        {
            input.value = '';
        }
    }

    /**
     * Remove network
     *
     * @param network
     */
    removeNetwork(network): void
    {
        const index = this.networks.indexOf(network);

        if ( index >= 0 )
        {
            this.networks.splice(index, 1);
        }
    }

    /**
     * Add team
     *
     * @param {MatChipInputEvent} event
     */
    addTeam(event: MatChipInputEvent): void
    {
        const input = event.input;
        const value = event.value;

        // Add team
        if ( value )
        {
            // this.teams.push(value);
        }

        // Reset the input value
        if ( input )
        {
            input.value = '';
        }
    }

    /**
     * Remove team
     *
     * @param team
     */
    removeTeam(team): void
    {
        const index = this.teams.indexOf(team);

        if ( index >= 0 )
        {
            this.teams.splice(index, 1);
        }
    }
}
