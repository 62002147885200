import { MatChipInputEvent } from '@angular/material/chips';

import { FuseUtils } from '@fuse/utils';
import {Network} from "../../../../models/network";
import {Member} from "../../../../models/member";

export class Team
{
    id: string;
    name: string;
    handle: string;
    description: string;
    networks: Network[];
    members: Member[];
    image: string;
    active: boolean;

    /**
     * Constructor
     *
     * @param team
     */
    constructor(team?)
    {
        team = team || {};
        this.id = team.id || FuseUtils.generateGUID();
        this.name = team.name || '';
        this.handle = team.handle || FuseUtils.handleize(this.name);
        this.description = team.description || '';
        this.networks = team.networks || [];
        this.members = team.members || [];
        this.image = team.image || '' ;
        this.active = team.active || true;
    }

    /**
     * Add category
     *
     * @param {MatChipInputEvent} event
     */
    addNetwork(event: MatChipInputEvent): void
    {
        const input = event.input;
        const value = event.value;

        // Add network
        if ( value )
        {
            // this.networks.push(value);
        }

        // Reset the input value
        if ( input )
        {
            input.value = '';
        }
    }

    /**
     * Remove category
     *
     * @param category
     */
    removeNetwork(category): void
    {
        const index = this.networks.indexOf(category);

        if ( index >= 0 )
        {
            this.networks.splice(index, 1);
        }
    }

    /**
     * Add tag
     *
     * @param {MatChipInputEvent} event
     */
    addMember(event: MatChipInputEvent): void
    {
        const input = event.input;
        const value = event.value;

        // Add tag
        if ( value )
        {
            // this.members.push(value);
        }

        // Reset the input value
        if ( input )
        {
            input.value = '';
        }
    }

    /**
     * Remove tag
     *
     * @param tag
     */
    removeMember(tag): void
    {
        const index = this.members.indexOf(tag);

        if ( index >= 0 )
        {
            this.members.splice(index, 1);
        }
    }
}
