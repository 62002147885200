import {Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FacebookPost, InstagramPost, Post} from '../../../../models/post';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Network} from '../../../../models/network';
import {debounceTime, map, startWith, tap} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {ManageNetworksService} from '../../manage-networks/manage-networks.service';
import {FileSnippet} from '../../file-uploader/file-uploader.component';
import {ManagePostsService} from '../manage-posts.service';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {MediaLibraryPreview} from '../../media-library/media-library/media-library.component';
import {Media} from '../../../../models/media';
import {
    MediaService, PostMediasService,
    PostService,
    PostsService,
    PostTextService
} from '../../../../layout/components/post/post.service';
import {Tag} from '../../../../models/tag';
import {TagsService} from '../../tags/tags.service';
import {LocationsService} from '../../locations/locations.service';
import {FacebookComponent} from '../../audience/facebook/facebook.component';
import {MatDatetimePickerInputEvent} from '@angular-material-components/datetime-picker';
import {ChatService} from '../../chat/chat.service';
import {SelectPostsMediaComponent} from '../select-posts-media/select-posts-media.component';
import {CommonAuthService} from '../../../../auth/common-auth.service';
import {CalendarEventModel} from '../../calendar/event.model';
import {FuseConfirmDialogComponent} from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {CalendarService} from '../../calendar/calendar.service';
import {SocialMediaManagementTeamsService} from '../../social-media-management/teams/teams.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FileUploaderService} from '../../file-uploader/file-uploader.service';
import {NetworkAutofillComponent} from '../../network-autofill/network-autofill/network-autofill.component';
import {LinkedinComponent} from '../../audience/linkedin/linkedin.component';
import {FilesErrorsComponent} from '../files-errors/files-errors.component';
import {VideoEditorComponent} from "../../video-editor/video-editor.component";
import {FuseUtils} from "../../../../../@fuse/utils";
import { ToastrService } from 'ngx-toastr';

declare var MediaInfo: any;
declare var FilerobotImageEditor: any;

@Component({
  selector: 'app-manage-posts',
  templateUrl: './manage-posts.component.html',
  styleUrls: ['./manage-posts.component.scss'],
  animations: fuseAnimations
})
export class ManagePostsComponent implements OnInit, OnDestroy {
    action: 'edit' | 'add';
    events: Array<Post> = [];
    eventsForm: FormGroup;
    dialogTitle: string;
    networks: Array<Network> = [];
    tags: Array<Tag> = [];
    filteredNetworks: Observable<Network[]>;
    filteredTags: Observable<Tag[]>;
    selectedNetworks: Array<Network> = [];
    selectedTags: Array<Tag> = [];
    networkInputControl: FormControl =  new FormControl();
    tagInputControl: FormControl =  new FormControl();
    selectedFiles: Array<FileSnippet> = [];
    showMediaLibrary = false;
    dateSelectorOpened = false;

    @ViewChild('networkInput') networkInput: ElementRef<HTMLInputElement>;
    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
    @ViewChild('text') textInput: ElementRef<HTMLInputElement>;
    @ViewChild('textTwitter') textTwitterInput: ElementRef<HTMLInputElement>;
    @ViewChild('emojiBlock') emojiBlock: any;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    @ViewChild('mediaPond') mediaPond: any;
    inputLoader = false;
    inputLocationLoader = false;
    showEmojis = false;
    cursorRange = [0, 0];
    mediaUploaderSettings = null;
    textValue = '';
    posts: Post[];
    postSubs: Subscription;
    tagLoader = false;
    filteredLocations: Observable<any>;
    minDate = new Date();
    token: string = null;
    loading = false;
    teams: Array<any> = [];
    filteredTeams: Observable<any[]>;
    linkedinFollowers = {followerCountsByAssociationType: 0};
    ImageEditor = new FilerobotImageEditor(
        {
            tools: ['adjust', 'effects', 'filters', 'rotate', 'crop', 'resize', 'shapes', 'image', 'text'],
            finishButtonLabel: 'save',
            cropPresets: [
                { name: 'original', value: 0 },
                { name: 'square', value: 1 },
                { name: 'banner', value: 7.8 },
                { name: '4 : 3', value: 1.33333 },
                { name: '16 : 9', value: 1.7777 },
                { name: 'Twitter', value: 1.7796 },
                { name: 'Facebook', value: 1.9075 },
                { name: 'Instagram ↕', value: 0.8 },
                { name: 'Instagram ↔', value: 1.9115 },
                { name: 'Stories', value: 0.5614 },
                { name: 'Linkedin', value: 1.9115 },
                { name: 'Pinterest', value: 0.6657 },
            ]
        },
        {
            onBeforeComplete: (file: object) => {
                const manualFile = new FileSnippet();
                // @ts-ignore
                manualFile.source = file.canvas.toDataURL("image/jpeg");
                manualFile.previewUrl = '';
                // @ts-ignore
                manualFile.width = file.canvas.width;
                // @ts-ignore
                manualFile.height = file.canvas.height;
                manualFile.type = 'image/jpeg';
                // @ts-ignore
                const blobBin = atob(file.canvas.toDataURL("image/jpeg").split(',')[1]);
                const array = [];
                for(var i = 0; i < blobBin.length; i++) {
                    array.push(blobBin.charCodeAt(i));
                }
                const newName = this.selectedFiles[this.currentMediaIndexEditing].file.name.split('.')[0] + '.jpeg';
                manualFile.file = new File([new Blob([new Uint8Array(array)], {type: 'image/jpeg'})], newName);
                // this.selectedFiles.push(manualFile);
                if (this.selectedFiles[this.currentMediaIndexEditing].id !== null) {
                    this.editMedia(manualFile, this.loadFile, this.currentMediaIndexEditing);
                } else {
                    this.uploadMediaServer(manualFile, this.loadFile, this.currentMediaIndexEditing);
                }
            },
            onClose: (status: object) => {
                // @ts-ignore
                if (status.status === 'image-edits-completed') {
                    this.currentMediaIndexEditing = -1;
                } else {
                    if (this.selectedFiles[this.currentMediaIndexEditing].id === null) {
                        this.selectedFiles.splice(this.currentMediaIndexEditing, 1);
                    }
                    this.currentMediaIndexEditing = -1;
                }
            }
        });
    currentMediaIndexEditing = -1;
    pinterestNetworks: Network[] = [];
    pinterestBoards = {};
    pinterestTitle = '';
    pinterestDestination = '';
    defaultTime = [11, 0 , 0];

    constructor(
        public matDialogRef: MatDialogRef<ManagePostsComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private networksService: ManageNetworksService,
        private managePostsService: ManagePostsService,
        private postsService: PostsService,
        private postService: PostService,
        private postTextService: PostTextService,
        private mediaService: MediaService,
        private postMediasService: PostMediasService,
        private tagsService: TagsService,
        private locationsService: LocationsService,
        private _chatService: ChatService,
        private authService: CommonAuthService,
        private calendarService: CalendarService,
        private teamService: SocialMediaManagementTeamsService,
        private _matSnackBar: MatSnackBar,
        private fileService: FileUploaderService,
        private alert: ToastrService
    ) {
        let currentDate = new Date();
        if (currentDate.getHours() > 10) {
            currentDate.setHours(currentDate.getHours() + 24);
        }
        currentDate.setHours(11);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        this.eventsForm = this._formBuilder.group({
            networks: this._formBuilder.array([]),
            tags: this._formBuilder.array([]),
            text: [''],
            text_twitter: [''],
            locations:  this._formBuilder.group({
                facebook: [''],
                instagram: [''],
                twitter: [''],
                linkedin: ['']
            }),
            audience: this._formBuilder.group({
                facebook: this._formBuilder.group({
                    age_min: [''],
                    geo_locations: this._formBuilder.group({
                        countries: this._formBuilder.array([]),
                        regions: this._formBuilder.array([]),
                        cities: this._formBuilder.array([]),
                        zips: this._formBuilder.array([])
                    })
                }),
                linkedin: this._formBuilder.group({
                    size: this._formBuilder.array([]),
                    languages: this._formBuilder.array([]),
                    locations: this._formBuilder.array([]),
                    functions: this._formBuilder.array([]),
                    industries: this._formBuilder.array([]),
                    seniorities: this._formBuilder.array([])
                })
            }),
            publishType: ['directly'],
            ig_post_type: ['regular'],
            schedule: [currentDate],
            medias: this._formBuilder.array([])
        });
        this.filteredNetworks = this.networkInputControl.valueChanges.pipe(
            startWith(null),
            map((network: string | null) => network ? this._filter(network) : this.networks.slice()));

        this.filteredTeams = this.networkInputControl.valueChanges.pipe(
            startWith(null),
            map((team: string | null) => team ? this._filterTeam(team) : this.teams.slice()));

        this.filteredTags = this.tagInputControl.valueChanges.pipe(
            startWith(null),
            map((tag: string | null) => tag ? this._filterTag(tag) : this.tags.slice()));
        this.postSubs = this.postsService.getPosts().subscribe(
            response => { this.posts = response; }
        );

        this.setPassedData();
    }

    ngOnInit(): void {
        this.authService.getSocialToken('facebook').then(result => this.token = result.authToken);
        this.mediaUploaderSettings = {
            uploader: (file: FileSnippet, load, index) => {
                // MediaInfo({ format: 'object' }, (mediainfo: any) => {
                //     this.getMetadata(mediainfo, file.file).then((info) => {
                //         const errors = this.validateMediaInfo(file, info, this.selectedNetworks.map(i => i.network_type.toLowerCase()));
                //         if (
                //             this.selectedNetworks.length === 0
                //             || (errors.errors.facebook.length === 0 && errors.errors.linkedin.length === 0 && errors.errors.instagram.length === 0)
                //         ) {
                //             this.openVideoEditor(0);
                            if (file.id === null) {
                                if (file.type.indexOf('video') !== -1 && this.selectedNetworks.findIndex(i => i.network_type.toLowerCase() === 'pinterest') !== -1) {
                                    // Show the success message
                                    this._matSnackBar.open('You can not upload videos media type for Pinterest Networks.', 'OK', {
                                        verticalPosition: 'top',
                                        duration        : 4000
                                    });
                                    this.selectedFiles.splice(this.selectedFiles.length - 1, 1);
                                }
                                if (this.selectedFiles.length > 1 && (file.type.indexOf('video') !== -1 ||
                                    (this.selectedFiles.findIndex(item => item.type.indexOf('video') !== -1) !== -1))) {
                                    this.validatePostsMediaType().subscribe(result => {
                                        if (typeof result !== 'undefined' && result !== null && result.index !== 0) {
                                            this.selectedFiles.splice(0, this.selectedFiles.length - 1);
                                            this.postMediasService.deleteAllMedias();
                                            this.uploadMediaServer(file, load, 0);
                                        } else {
                                            this.selectedFiles.splice(this.selectedFiles.length - 1, 1);
                                        }
                                    });
                                } else {
                                    this.uploadMediaServer(file, load, index);
                                }
                            }
                //         } else {
                //             const selectedFile = this.selectedFiles[this.selectedFiles.length - 1];
                //             const dialogRef = this.dialog.open(FilesErrorsComponent, {
                //                 width: '400px',
                //                 data: {from: 'file', networks: errors.errors}
                //             });
                //             dialogRef.afterClosed().subscribe(
                //                 data => {
                //                     console.log('dialogRef.afterClosed():', data)
                //                     if (typeof data !== 'undefined' && data !== null && typeof data.data !== 'undefined' && data.data !== null && data.data === 'edit') {
                //                         console.log(selectedFile);
                //                         this.currentMediaIndexEditing = this.selectedFiles.length - 1;
                //                         if (this.selectedFiles[this.currentMediaIndexEditing].type.indexOf('image') !== -1) {
                //                             this.ImageEditor.open(selectedFile.file);
                //                         } else {
                //                             this.openVideoEditor(this.currentMediaIndexEditing);
                //                         }
                //                     } else {
                //                         this.selectedFiles.splice(this.selectedFiles.length - 1, 1);
                //                     }
                //                 }
                //             );
                //         }
                //     });
                // });
            },
            loader: (source: string, load) => {

            },
            remove: (index: number) => {
                this.postMediasService.deleteMedia(index, this.action);
            }
        };
        this.eventsForm.get('locations').get('facebook').valueChanges.subscribe(
           value => {
               if (value !== null) {
                   value = (typeof value === 'string') ? value : value.name;
                   this.filteredLocations = this.locationsService.getFacebookPageLocations(this.selectedNetworks[0].auth_token, value, 'place', '', 'id,name,location').pipe(
                       map(response => response.data.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1))
                   );
               }
           }
        );
        this.eventsForm.get('locations').get('instagram').valueChanges.subscribe(
            value => {
                if (value !== null) {
                    value = (typeof value === 'string') ? value : value.name;
                    this.filteredLocations = this.locationsService.getFacebookPageLocations(this.token, value, 'place', '', 'id,name,location').pipe(
                        map(response => response.data.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1))
                    );
                }
            }
        );
        this.eventsForm.get('locations').get('twitter').valueChanges.subscribe(
            value => {
                if (value !== null) {
                    this.inputLocationLoader = true;
                    this.filteredLocations = this.locationsService.getTwitterLocations(
                        this.selectedNetworks.find(i => i.network_type.toLowerCase() === 'twitter').network_id,
                        value).pipe(
                        tap(() => this.inputLocationLoader = false),
                        map(response => response.result.places.filter(item => item.full_name.toLowerCase().indexOf(value.toLowerCase()) !== -1)),
                        debounceTime(300),
                    );
                }
            }
        );
        this.eventsForm.get('text').valueChanges.subscribe(
            value => {
                if (value !== null && value !== '') {
                    const wordOffset = (String(value).charAt(value.length - 1) === '@');
                    this.validateTextTags(value);
                    if (wordOffset) {
                        if (this.selectedNetworks.length > 0) {
                            const dialogRef = this.dialog.open(NetworkAutofillComponent, {
                                width: '400px',
                                data: {networks: this.selectedNetworks}
                            });
                            dialogRef.afterClosed().subscribe(result => {
                                if (typeof result !== 'undefined' && result.value !== '') {
                                    switch (result.type) {
                                        case 'facebook':
                                            // value = value.replace('@', result.value.name);
                                            value = value.slice(0, -1) + result.value.name;
                                            this.textInput.nativeElement.value = value;
                                            const tag = {
                                                id: result.value.network_id,
                                                name: result.value.name,
                                                description: '',
                                                status: 'ACTIVE',
                                                type: 'page',
                                                networkType: result.value.network_type
                                            } as Tag;
                                            this.tags.push(tag);
                                            this.selectedTags.push(tag);
                                            this.postsService.updateAllPosts('tags', this.selectedTags);
                                            break;
                                        case 'instagram':
                                            value = value + result.value; // value.replace('@', '@' + result.value);
                                            this.textInput.nativeElement.value = value;
                                            break;
                                        case 'twitter':
                                            value = value + result.value.screen_name; // value.replace('@', '@' + result.value);
                                            this.textInput.nativeElement.value = value;
                                            break;
                                    }
                                } else {
                                    // value = value.replace('@', '');
                                    value = value.slice(0, -1);
                                    this.textInput.nativeElement.value = value;
                                }
                                this.textValue = value;
                                this.propertyChanged('text', value);
                                this.getPostLinkPreview();
                            });
                        } else {
                            // value = value.replace('@', '');
                            value = value.slice(0, -1);
                            this.textInput.nativeElement.value = value;
                            this._matSnackBar.open('You need to select at least one network before tagging content.', 'OK', {
                                verticalPosition: 'top',
                                duration: 5000
                            });
                        }
                    } else {
                        this.textValue = value;
                        this.propertyChanged('text', value);
                        this.getPostLinkPreview();
                    }
                }
            }
        );
        this.eventsForm.get('text_twitter').valueChanges.subscribe(
            value => {
                if (value !== null && value !== '') {
                    const wordOffset = (String(value).charAt(value.length - 1) === '@');
                    if (wordOffset) {
                        if (this.selectedNetworks.length > 0) {
                            const dialogRef = this.dialog.open(NetworkAutofillComponent, {
                                width: '400px',
                                data: {networks: this.selectedNetworks.filter(i => i.network_type === 'twitter')}
                            });
                            dialogRef.afterClosed().subscribe(result => {
                                if (typeof result !== 'undefined' && result.value !== '') {
                                    value = value + result.value.screen_name;
                                    this.textTwitterInput.nativeElement.value = value;
                                } else {
                                    value = value.slice(0, -1);
                                    this.textTwitterInput.nativeElement.value = value;
                                }
                                this.propertyChanged('text_twitter', value);
                                this.getPostLinkPreview('text_twitter');
                            });
                        } else {
                            // value = value.replace('@', '');
                            value = value.slice(0, -1);
                            this.textInput.nativeElement.value = value;
                            this._matSnackBar.open('You need to select at least one network before tagging content.', 'OK', {
                                verticalPosition: 'top',
                                duration: 5000
                            });
                        }
                    } else {
                        this.textValue = value;
                        this.propertyChanged('text_twitter', value);
                        this.getPostLinkPreview('text_twitter');
                    }
                }
        });
        this._chatService.getEmoji().subscribe(
            emoji => {
                this.propertyChanged('text', this.textInput.nativeElement.value);
                this.getPostLinkPreview();
            },
            error => console.log(error)
        );
        this.getNetworks();
        this.getTags();
        this.getOwnedTeams();
    }

    public setPassedData(): void {
        this.events = this._data.events;
        this.action = this._data.action;
        if ( this.action === 'edit' )
        {
            this.dialogTitle = 'Edit Post(s)';
        }
        else
        {
            this.dialogTitle = 'Create Post(s)';
        }
        if (typeof this._data.events !== 'undefined' && this._data.events !== null && this._data.events.length > 0) {
            this.postsService.cleanPosts();
            this.selectedTags = this._data.events[0].tags ? this._data.events[0].tags.map(item => {
                item.name = item.name.replace('#', '');
                // this._data.events = this._data.events.map(post => { post.text =  post.text.replace('#' + item.name, ''); return post; });
                return item;
            }) : [];
            const current = this._data.events[0];
            if (typeof current.text !== 'undefined' && current.text !== null && current.text !== '') {
                this.validateTextTags(current.text);
                this.textValue = current.text;
                this.eventsForm.get('text').setValue(this.textValue);
            }
            if (this._data.events.length > 1) {
                this.selectedNetworks = this._data.events.map(post => {
                    if (post.network.network_type.toLowerCase() === 'pinterest') {
                        this.pinterestNetworks.push(post.network);
                        this.pinterestTitle = post.network.title;
                        this.pinterestDestination = post.network.destination;
                        this.pinterestBoards[post.network.network.network_id] = post.network.boards;
                    }
                    return post.network;
                });
            } else {
                this.selectedNetworks.push(current.network);
                if (current.network.network_type.toLowerCase() === 'pinterest') {
                    this.pinterestTitle = current.title;
                    this.pinterestDestination = current.destination;
                    this.pinterestBoards[current.network.network_id] = current.boards;
                    this.pinterestNetworks.push(current.network);
                }
            }
            this.setAudiences(current.type.toLowerCase(), current.audience);
            // // tslint:disable-next-line:max-line-length
            // if (typeof this.eventsForm.get('locations').get(current.type.toLowerCase()) !== 'undefined' && this.eventsForm.get('locations').get(current.type.toLowerCase()) !== null) {
            //     if (typeof current.location !== 'undefined' && current.location !== null) {
            //         this.eventsForm.get('locations').get(current.type.toLowerCase()).setValue(current.location);
            //     }
            // }
            this.eventsForm.get('schedule').setValue((typeof current.postDate === 'string') ? new Date(current.postDate) : current.postDate);
            if (current.media !== null) {
                this.postMediasService.addMedias(current.media);
                current.media.map(item => this.selectedFiles.push(new FileSnippet(
                    item.id,
                    item.mediaUrl,
                    item.previewUrl,
                    null,
                    item.type,
                    true,
                    100,
                    item.width,
                    item.height,
                )));
            }
            this._data.events.forEach(post => {
                this.eventsForm.get('locations').get(post.type.toLowerCase())?.setValue(post.location);
                if (typeof post.location !== 'undefined' && post.location !== null) {
                    switch (post.type.toLowerCase()) {
                        case 'twitter':
                            if (typeof post.location === 'string') {
                                this.inputLocationLoader = true;
                                this.locationsService.getTwitterLocation(post.network.network_id, post.location).subscribe(
                                    response => {
                                        this.eventsForm.get('locations').get(post.type.toLowerCase()).setValue(response);
                                        this.inputLocationLoader = false;
                                    }, error => { console.log(error); this.inputLocationLoader = false; }
                                );
                            } else {
                                this.eventsForm.get('locations').get(post.type.toLowerCase()).setValue(post.location);
                            }
                            break;
                    }
                }
            });
            this.postsService.addPosts(this._data.events);
        } else {
            this.postService.addPost(new Post());
        }
    }

    private getNetworks(): void {
        this.inputLoader = true;
        this.networksService.getNetworks().subscribe(
            response => {
                this.networks = response.data;
                this.inputLoader = false;
            },
            error => { this.inputLoader = false; console.log(error); }
        );
    }

    private getOwnedTeams(): void {
        this.inputLoader = true;
        this.teamService.getOwnedTeams().subscribe(
            response => {
                this.teams = response.teams;
                this.inputLoader = false;
            },
            error => { this.inputLoader = false; console.log(error); }
        );
    }

    private getTags(): void {
        this.tagLoader = true;
        this.tagsService.getTagsList().subscribe(
            response => {
                this.tags = response.data;
                this.tagLoader = false;
            },
            error => { this.tagLoader = false; console.log(error); }
        );
    }

    private _filter(value: string): Network[] {
        const filterValue = (typeof value['name'] !== 'undefined') ? value['name'].toLowerCase() : value.toLowerCase();
        return this.networks.filter(
            network => network.name.toLowerCase().indexOf(filterValue) === 0
                && this.selectedNetworks.findIndex(item => item.id === network.id) === -1
        );
    }

    private _filterTeam(value: string): any[] {
        const filterValue = (typeof value['name'] !== 'undefined') ? value['name'].toLowerCase() : value.toLowerCase();
        return this.teams.filter(
            team => team.name.toLowerCase().indexOf(filterValue) === 0
        );
    }

    private _filterTag(value: string): Tag[] {
        const filterValue = (typeof value['name'] !== 'undefined') ? value['name'].toLowerCase() : value.toLowerCase();
        return this.tags.filter(
            tag => tag.name.toLowerCase().indexOf(filterValue) === 0
                && this.selectedTags.findIndex(item => item.name === tag.name) === -1
        );
    }

    addNetwork(event: MatChipInputEvent): void { }

    addTag(event: MatChipInputEvent): void {
        const value = event.value;
        // this.tagLoader = true;

        // Add our fruit
        if ((value || '').trim()) {
            const tag = {id: null, name: value.trim(), description: '', status: 'ACTIVE', type: 'tag'} as Tag;
            this.tags.push(tag);
            this.selectedTags.push(tag);
            this.postsService.updateAllPosts('tags', this.selectedTags);
            if (this.eventsForm.get('text').value.indexOf('#') === -1) {
                this.eventsForm.get('text').setValue(this.eventsForm.get('text').value + ('\r\n\r\n #' + value.replace('#', '')));
            } else {
                this.eventsForm.get('text').setValue(this.eventsForm.get('text').value + (' #' + value.replace('#', '')));
            }
        }
        this.tagInput.nativeElement.value = '';
        this.tagInputControl.setValue(null);
    }

    removeNetwork(network: Network): void {
        const index = this.selectedNetworks.indexOf(network);
        if (index >= 0) {
            this.selectedNetworks.splice(index, 1);
            if (this.selectedNetworks.length === 0){
                this.postsService.updateAllPosts('network', null);
                this.postsService.updateAllPosts('type', null);
            } else {
                 this.postsService.deletePost(index);
            }
        }
        this.networkInput.nativeElement.value = '';
    }

    removeTag(tag: Tag): void {
        const index = this.selectedTags.indexOf(tag);
        if (index >= 0) {
            this.selectedTags.splice(index, 1);
            this.postsService.updateAllPosts('tags', this.selectedTags);
            this.eventsForm.get('text').setValue(this.eventsForm.get('text').value.replace('#' + tag.name.replace('#', ''), ''));
        }
        this.tagInput.nativeElement.value = '';
    }

    selectedNetwork(event: MatAutocompleteSelectedEvent): void {
        const networks = [];
        if (typeof event.option.value['allowed_social_networks'] !== 'undefined') {
            networks.push(...event.option.value['allowed_social_networks']);
        } else {
            networks.push(event.option.value);
        }
        const networkTypes = networks.map(i => i.network_type.toLowerCase());
        if (networkTypes.indexOf('pinterest') !== -1 && this.selectedFiles.findIndex(i => i.type.indexOf('video') !== -1) !== -1) {
            // Show the success message
            this._matSnackBar.open('You can not upload videos media type to Pinterest Networks.', 'OK', {
                verticalPosition: 'top',
                duration        : 4000
            });
            return;
        }
        if (this.selectedFiles.length > 0) {
            const files = [];
            const networksError = [];
            MediaInfo({format: 'object'}, (mediainfo: any) => {
                const allPromises = [];
                for (const file of this.selectedFiles) {
                    allPromises.push(this.getMetadata(mediainfo, file.file));
                }
                Promise.all(allPromises).then((values) => {
                    for (let pos = 0; pos < values.length; pos++) {
                        const errors = this.validateMediaInfo(this.selectedFiles[pos], values[pos], networkTypes.map(i => i.toLowerCase()));
                        if (
                            (errors.errors.pinterest.length > 0
                                || errors.errors.youtube.length > 0
                                || errors.errors.facebook.length > 0
                                || errors.errors.linkedin.length > 0
                                || errors.errors.instagram.length > 0
                                || errors.errors.twitter.length > 0)
                        ) {
                            if (errors.errors.facebook.length > 0) {
                                networksError.push('facebook');
                            }
                            if (errors.errors.linkedin.length > 0) {
                                networksError.push('linkedin');
                            }
                            if (errors.errors.instagram.length > 0) {
                                networksError.push('instagram');
                            }
                            if (errors.errors.twitter.length > 0) {
                                networksError.push('twitter');
                            }
                            if (errors.errors.pinterest.length > 0) {
                                networksError.push('pinterest');
                            }
                            files.push(errors);
                        }
                    }
                    if (files.length === 0) {
                        this.manageSelectedNetwork(networks);
                    } else {
                        this.manageSelectedNetwork(networks.filter(item => (networksError.findIndex(i => i === item.network_type.toLowerCase()) === -1)));
                        const dialogRef = this.dialog.open(FilesErrorsComponent, {
                            width: '400px',
                            data: {deniedNetworkType: networksError, from: 'network', files: files}
                        });
                    }
                });
            });
        } else {
            this.manageSelectedNetwork(networks);
        }
    }

    manageSelectedNetwork(networks: Array<Network>): void {
        this.selectedNetworks.push(...networks);
        this.networkInput.nativeElement.value = '';
        this.networkInputControl.setValue(null);
        const linkedinNetworks = [];
        const currentPostData = this.posts[0];
        for (const network of networks) {
            if (network.network_type.toLowerCase() === 'linkedin') {
                linkedinNetworks.push(network.network_id);
            }
            if (network.network_type.toLowerCase() === 'pinterest') {
                this.pinterestNetworks.push(network);
            }
            const newPost = this.managePostsService.createPost(
                network,
                // this.posts[0].postDate,
                this.eventsForm.get('schedule').value,
                this.eventsForm.get('text').value,
                this.eventsForm.get('text_twitter').value,
                currentPostData.media,
                'scheduled',
                this.selectedTags,
                (network.network_type === 'facebook') ? this.eventsForm.get('audience').get(network.network_type.toLowerCase()).value : null,
                // tslint:disable-next-line:max-line-length
                (network.network_type.toLowerCase() === 'facebook' || network.network_type.toLowerCase() === 'instagram' || network.network_type.toLowerCase() === 'twitter') ? this.eventsForm.get('locations').get(network.network_type.toLowerCase()).value : null,
                this.eventsForm.get('publishType').value,
                currentPostData.link,
                null
            );
            this.postService.addPost(newPost);
        }
        if (linkedinNetworks.length > 0) {
            this.postService.linkedinFollowersStatsValidation(linkedinNetworks).subscribe(
                response => {
                    this.linkedinFollowers = response['data'];
                    if (this.linkedinFollowers.followerCountsByAssociationType < 300) {
                        this.eventsForm.get('audience').get('linkedin').setValue({
                            size: [],
                            languages: [],
                            locations: [],
                            functions: [],
                            industries: [],
                            seniorities: []
                        });
                    }
                }, error => console.log(error)
            );
        }
        if (this.selectedNetworks.length > 0 && (typeof currentPostData.type === 'undefined' || currentPostData.type === null || currentPostData.type === '')) {
            this.postsService.deletePost(0);
        }
    }

    selectedTag(event: MatAutocompleteSelectedEvent): void {
        this.selectedTags.push(event.option.value);
        this.postsService.updateAllPosts('tags', this.selectedTags);
        this.tagInput.nativeElement.value = '';
        this.tagInputControl.setValue(null);
        // this.postsService.updateAllPosts('network', event.option.value);
    }

    emojiSelection(event: any): void {
        this.textInput.nativeElement.value = this.textInput.nativeElement.value.substring(0, this.cursorRange[0])
            + event.emoji.native
            + this.textInput.nativeElement.value.substring(this.cursorRange[1], this.textInput.nativeElement.value.length);
    }

    addExternalMedia(event: MediaLibraryPreview): void {
        const manualFile = new FileSnippet();
        manualFile.source = event.url;
        manualFile.previewUrl = event.previewUrl;
        // manualFile.id = event.id;
        manualFile.width = event.width;
        manualFile.height = event.height;
        this.selectedFiles.push(manualFile);
    }

    ngOnDestroy(): void {
        this.postsService.clearPosts();
        this.postMediasService.deleteAllMedias();
        this.postSubs.unsubscribe();
    }

    propertyChanged(property: string, value: string): void {
        switch (property) {
            case 'text':
                this.postTextService.addText(value);
            default:
                this.postsService.updateAllPosts(property, value);
                break;
        }
    }

    mediaOrderChanged(event: {files: Array<FileSnippet>, previousIndex: number, currentIndex: number}): void {
        this.postMediasService.changeOrderMedias(event.previousIndex, event.currentIndex);
    }

    hasTypeOfPost(type: string): boolean {
        return this.posts.findIndex(post => (post.type !== null) ? post.type.toLowerCase() === type : false) !== -1;
    }

    openAudienceModal(type: string): void {
        let modal = FacebookComponent;
        let networkToken = this.token;
        if (type === 'linkedin') {
            // @ts-ignore
            modal = LinkedinComponent;
            networkToken = this.selectedNetworks.find(item => item.network_type.toLowerCase() === 'linkedin').auth_token;
        }
        const dialogRef = this.dialog.open(modal, {
            maxWidth: '550px',
            width: '100%',
            disableClose: true,
            data: {...this.eventsForm.get('audience').get(type).value, token: networkToken}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (typeof result !== 'undefined' && result !== null) {
                this.setAudiences(type, result, 'set');
                this.postsService.updateAllPosts('audience', result);
            }
        });
    }

    setAudiences(type: string, data: any, from = 'edit'): void {
        if (typeof this.eventsForm.get('audience').get(type) !== 'undefined' && this.eventsForm.get('audience').get(type) !== null) {
            if (typeof data !== 'undefined' && data !== null) {
                if (type.toLowerCase() === 'facebook') {
                    if (typeof data.geo_locations !== 'undefined' && data.geo_locations !== null) {
                        let allMetadata = null;
                        this.eventsForm.get('audience').get(type).get('age_min').setValue(data.age_min);
                        this.eventsForm.get('audience').get(type).reset();
                        (this.eventsForm.get('audience').get(type).get('geo_locations').get('countries') as FormArray).clear();
                        (this.eventsForm.get('audience').get(type).get('geo_locations').get('cities') as FormArray).clear();
                        (this.eventsForm.get('audience').get(type).get('geo_locations').get('regions') as FormArray).clear();
                        (this.eventsForm.get('audience').get(type).get('geo_locations').get('zips') as FormArray).clear();
                        if (from === 'edit') {
                            this.locationsService.getFacebookPageLocationsMetadata(this.selectedNetworks[0].auth_token, data.geo_locations).subscribe(
                                response => {
                                    allMetadata = response.data;
                                    if (typeof data.geo_locations.countries !== 'undefined' && typeof allMetadata.countries !== 'undefined') {
                                        const countries = Object.values(allMetadata.countries);
                                        countries.map(item => (this.eventsForm.get('audience').get(type).get('geo_locations').get('countries') as FormArray).push(
                                            new FormControl(item)
                                        ));
                                    }
                                    if (typeof data.geo_locations.cities !== 'undefined' && typeof allMetadata.cities !== 'undefined') {
                                        const cities = Object.values(allMetadata.cities);
                                        cities.map(item => (this.eventsForm.get('audience').get(type).get('geo_locations').get('cities') as FormArray).push(
                                            new FormControl(item)
                                        ));
                                    }
                                    if (typeof data.geo_locations.regions !== 'undefined' && typeof allMetadata.regions !== 'undefined') {
                                        const regions = Object.values(allMetadata.regions);
                                        regions.map(item => (this.eventsForm.get('audience').get(type).get('geo_locations').get('regions') as FormArray).push(
                                            new FormControl(item/*this.locationsService.getFacebookPageLocations(this.token, item.key, 'adgeolocation', 'region' )*/)
                                        ));
                                    }
                                    if (typeof data.geo_locations.zips !== 'undefined' && typeof allMetadata.zips !== 'undefined') {
                                        const zips = Object.values(allMetadata.zips);
                                        zips.map(item => (this.eventsForm.get('audience').get(type).get('geo_locations').get('zips') as FormArray).push(
                                            new FormControl(item)
                                        ));
                                    }
                                }
                            );
                        } else {
                            if (typeof data.geo_locations.countries !== 'undefined') {
                                data.geo_locations.countries.map(item => (this.eventsForm.get('audience').get(type).get('geo_locations').get('countries') as FormArray).push(
                                    new FormControl(item)
                                ));
                            }
                            if (typeof data.geo_locations.cities !== 'undefined') {
                                data.geo_locations.cities.map(item => (this.eventsForm.get('audience').get(type).get('geo_locations').get('cities') as FormArray).push(
                                    new FormControl(item)
                                ));
                            }
                            if (typeof data.geo_locations.regions !== 'undefined') {
                                data.geo_locations.regions.map(item => (this.eventsForm.get('audience').get(type).get('geo_locations').get('regions') as FormArray).push(
                                    new FormControl(item/*this.locationsService.getFacebookPageLocations(this.token, item.key, 'adgeolocation', 'region' )*/)
                                ));
                            }
                            if (typeof data.geo_locations.zips !== 'undefined') {
                                data.geo_locations.zips.map(item => (this.eventsForm.get('audience').get(type).get('geo_locations').get('zips') as FormArray).push(
                                    new FormControl(item)
                                ));
                            }
                        }
                    }
                } else if (type.toLowerCase() === 'linkedin') {
                    this.eventsForm.get('audience').get(type).reset();
                    (this.eventsForm.get('audience').get(type).get('size') as FormArray).clear();
                    (this.eventsForm.get('audience').get(type).get('languages') as FormArray).clear();
                    (this.eventsForm.get('audience').get(type).get('locations') as FormArray).clear();
                    (this.eventsForm.get('audience').get(type).get('functions') as FormArray).clear();
                    (this.eventsForm.get('audience').get(type).get('industries') as FormArray).clear();
                    (this.eventsForm.get('audience').get(type).get('seniorities') as FormArray).clear();

                    data.size.map(item => (this.eventsForm.get('audience').get(type).get('size') as FormArray).push(
                        new FormControl(item)
                    ));
                    data.languages.map(item => (this.eventsForm.get('audience').get(type).get('languages') as FormArray).push(
                        new FormControl(item)
                    ));
                    data.locations.map(item => (this.eventsForm.get('audience').get(type).get('locations') as FormArray).push(
                        new FormControl(item)
                    ));
                    data.functions.map(item => (this.eventsForm.get('audience').get(type).get('functions') as FormArray).push(
                        new FormControl(item)
                    ));
                    data.industries.map(item => (this.eventsForm.get('audience').get(type).get('industries') as FormArray).push(
                        new FormControl(item)
                    ));
                    data.seniorities.map(item => (this.eventsForm.get('audience').get(type).get('seniorities') as FormArray).push(
                        new FormControl(item)
                    ));
                }
            }
        }
    }

    getAudiencesJoinViewableData(data: Array<any>, property: string): string {
        return data.map(item => item[property]).join(', ');
    }

    publishTypeChanged(event: any): void {
        this.eventsForm.get('publishType').setValue(event);
        this.postsService.updateAllPosts('publishType', event);
    }

    scheduleDateChanged(event: MatDatetimePickerInputEvent<any>): void {
        this.eventsForm.get('schedule').setValue(event.value);
        this.dateSelectorOpened = false;
        this.postsService.updateAllPosts('postDate', event.value);
    }

    emojiClicked(event: MouseEvent): void {
        this._chatService.onEmojisOpen.next(event);
    }

    uploadMediaServer(file: FileSnippet, load, index): void {
        MediaInfo({ format: 'object' }, (mediainfo: any) => {
            this.getMetadata(mediainfo, file.file).then((info) => {
                const errors = this.validateMediaInfo(file, info, this.selectedNetworks.map(i => i.network_type.toLowerCase()));
                if (
                    this.selectedNetworks.length === 0
                    || (errors.errors.facebook.length === 0
                        && errors.errors.linkedin.length === 0
                        && errors.errors.pinterest.length === 0
                        && errors.errors.youtube.length === 0
                        && errors.errors.instagram.length === 0)
                ) {
                    this.managePostsService.uploadMedia(file.file, this.selectedNetworks).subscribe(
                        response => {
                            if (typeof response !== 'undefined' && response !== null) {
                                if (response.status === 'progress') {
                                    load(response.message, '', this.selectedFiles, index);
                                } else if (response.status === 200) {
                                    file.source = response.data.url;
                                    this.selectedFiles[index].width = file.width;
                                    this.selectedFiles[index].height = file.height;
                                    this.selectedFiles[index].file = file.file;
                                    this.selectedFiles[index].id = response.data.id;
                                    this.selectedFiles[index].mediaInfo = info;
                                    load(response.data.url, response.data.url_preview, this.selectedFiles, index);
                                    this.mediaService.addMedia({
                                        id: response.data.id,
                                        label: response.data.id + '.' + file.type.split('/')[1],
                                        altText: [],
                                        mediaUrl: file.source,
                                        fileSize: file.file.size,
                                        height: file.height,
                                        width: file.width,
                                        previewUrl: response.data.previewUrl,
                                        file: (typeof file.file !== 'undefined') ? file.file : null,
                                        type: file.type,
                                        path: response.data.path,
                                        pathPreview: response.data.path_preview,
                                        postId: response.data.post_id
                                    } as Media);
                                }
                            }
                        },
                        error => console.log(error)
                    );
                } else {
                    const dialogRef = this.dialog.open(FilesErrorsComponent, {
                        width: '400px',
                        data: {from: 'file', networks: errors.errors}
                    });
                    dialogRef.afterClosed().subscribe(
                        data => {
                            if (typeof data !== 'undefined' && data !== null && typeof data.data !== 'undefined' && data.data !== null && data.data === 'edit') {
                                this.currentMediaIndexEditing = index;
                                if (this.selectedFiles[this.currentMediaIndexEditing].type.indexOf('image') !== -1) {
                                    this.ImageEditor.open(file.file);
                                } else {
                                    this.openVideoEditor(this.currentMediaIndexEditing, info);
                                }
                            }
                        }
                    );
                }
            });
        });
    }

    loadFile(source: string | number, previewUrl: string, selectedFiles: Array<FileSnippet>, index: number): void {
        if (typeof source === 'string') {
            selectedFiles[index].source = source;
            if (previewUrl !== null && previewUrl !== '') {
                selectedFiles[index].previewUrl = previewUrl;
            }
            selectedFiles[index].loading = false;
        } else if (typeof source === 'number') {
            selectedFiles[index].process = source;
        }
    }

    validatePostsMediaType(): Observable<any> {
        const dialogRef = this.dialog.open(SelectPostsMediaComponent, {
            maxWidth: '550px',
            width: '100%',
            disableClose: true,
            data: this.selectedFiles
        });
        return dialogRef.afterClosed();
    }

    removeMedia(): void {

    }

    clearPostsData(): void {
        this.selectedFiles = [];
        this.postMediasService.deleteAllMedias();
        this.selectedTags = [];
        this.postsService.clearPosts([
            // {property: 'postDate', value: ''},
            {property: 'publishType', value: 'directly'},
            {property: 'text', value: ''},
            {property: 'text_twitter', value: ''},
            {property: 'tags', value: []},
            {property: 'audience', value: null},
            {property: 'status', value: 'post'},
            {property: 'id', value: null}
        ]);
        // this.eventsForm.get('schedule').setValue('');
        this.eventsForm.get('text').setValue('');
        this.eventsForm.get('audience').get('facebook').get('age_min').setValue('');
        (this.eventsForm.get('audience').get('facebook').get('geo_locations').get('countries') as FormArray).clear();
        (this.eventsForm.get('audience').get('facebook').get('geo_locations').get('regions') as FormArray).clear();
        (this.eventsForm.get('audience').get('facebook').get('geo_locations').get('cities') as FormArray).clear();
        (this.eventsForm.get('audience').get('facebook').get('geo_locations').get('zips') as FormArray).clear();
    }

    postPosts(action: string = 'post'): void {
        const scheduleValue = (new Date(this.eventsForm.get('schedule').value)).getTime();
        const nowValue = (new Date().getTime());
        if (scheduleValue >= nowValue && scheduleValue <= (nowValue + 605000)) {
            this._matSnackBar.open('Schedule Posts Time needs to be greater than 10 minutes after current time.', 'OK', {
                verticalPosition: 'top',
                duration: 15000
            });
            return;
        }
        let twitterTextWarning = false;
        this.posts = this.posts.map(i => {
            if (i.network.network_type === 'TWITTER') {
                if (i.text.length > 270 && (i.text_twitter === null || i.text_twitter === '')) {
                    twitterTextWarning = true;
                }
                if (i.text_twitter !== '' && i.text_twitter !== null) {
                    i.text = i.text_twitter;
                }
            }
            if (i.network.network_type === 'INSTAGRAM') {
                i['ig_post_type'] = this.eventsForm.get('ig_post_type').value;
            }
            return i;
        });
        if (twitterTextWarning) {
            this._matSnackBar.open('Twitter post does not have any text added.', 'OK', {
                verticalPosition: 'top',
                duration: 15000
            });
            return;
        }
        this.loading = true;
        this.postsService.saveSeveralPosts(
            { posts: this.posts, draft: (action === 'save_draft') }, action, this.action).subscribe(
            response => {
                const errors = [];
                this.loading = false;
                const arrayResults = Object.values(response.results);
                if (typeof response.errors !== 'undefined' && Object.values(response.errors).length > 0) {
                    for (const post of this.posts) {
                        if (typeof response.errors[post.network.network_id] !== 'undefined' && typeof response.errors[post.network.network_id].error !== 'undefined') {
                            this.alert.error(
                                this.errorMessageFormat(response.errors[post.network.network_id].error, post.network.network_type.toLowerCase()),
                                post.network.network_type.toUpperCase() + ' Network "' + post.network.name + '" was not posted. ERROR:',
                                {
                                    timeOut: 15000
                                }
                            );
                        }

                    }
                }
                if (typeof response.results !== 'undefined' && arrayResults.length > 0) {
                    if (action === 'save_draft') {
                        this.matDialogRef.close(['add', response.results, action]);
                        return;
                    }
                    if (action === 'reuse_networks') {
                        const oldPosts = [...this.posts];
                        const events = oldPosts.reduce((result, post) => {
                            if (typeof response.results[post.network.network_id] !== 'undefined' && response.results[post.network.network_id] !== null) {
                                const newItem = Object.assign(Object.create(Object.getPrototypeOf(post)), post);
                                if (typeof response.results[post.network.network_id].id === 'undefined' || response.results[post.network.network_id].id === null) {
                                    if (typeof response.results[post.network.network_id].error !== 'undefined') {
                                        // errors.push('Social Network "' + post.network.name + '" ERROR:\r\n' + response.results[post.network.network_id].error.message);
                                        this.alert.error(
                                            this.errorMessageFormat(response.results[post.network.network_id].error, post.network.network_type.toLowerCase()),
                                            post.network.network_type.toUpperCase() + ' Network "' + post.network.name + '" was not posted. ERROR:',
                                            {
                                                timeOut: 15000
                                            }
                                        );
                                    }
                                    return result;
                                }
                                newItem.id = response.results[post.network.network_id].id;
                                if (post.network.network_type.toLowerCase() === 'twitter') {
                                    newItem.id_str = response.results[post.network.network_id].id_str;
                                }
                                newItem.editable = !(this.eventsForm.value.schedule === '');
                                newItem.status = (this.eventsForm.value.schedule === '' ? 'published' : 'scheduled');
                                newItem.postDate = (typeof newItem.postDate !== 'undefined' && newItem.postDate !== null) ? newItem.postDate : new Date();
                                const calendarEvent = new CalendarEventModel(newItem);
                                result.push(calendarEvent);
                            }
                            return result;
                        }, []);
                        this.calendarService.addFormatedEvents([...events]);
                        this.clearPostsData();
                        return;
                    }
                    if (action === 'duplicate') {
                        const oldPosts = [...this.posts];
                        const media = (this.posts[0].media !== null) ? [...this.posts[0].media.map(item => ({...item}))] : null;
                        const tags = (this.posts[0].tags !== null) ? [...this.posts[0].tags.map(item => ({...item}))] : null;
                        const events = oldPosts.reduce((result, post) => {
                            if (typeof response.results[post.network.network_id] !== 'undefined' && response.results[post.network.network_id] !== null) {
                                const newItem = Object.assign(Object.create(Object.getPrototypeOf(post)), post);
                                if (typeof response.results[post.network.network_id].id === 'undefined' || response.results[post.network.network_id].id === null) {
                                    if (typeof response.results[post.network.network_id].error !== 'undefined') {
                                       // errors.push('Social Network "' + post.network.name + '" ERROR:\r\n' + response.results[post.network.network_id].error.message);
                                        this.alert.error(
                                            this.errorMessageFormat(response.results[post.network.network_id].error, post.network.network_type.toLowerCase()),
                                            post.network.network_type.toUpperCase() + ' Network "' + post.network.name + '" was not posted. ERROR:',
                                            {
                                                timeOut: 15000
                                            }
                                        );
                                    }
                                    return result;
                                }
                                newItem.id = response.results[post.network.network_id].id;
                                if (post.network.network_type.toLowerCase() === 'twitter') {
                                    newItem.id_str = response.results[post.network.network_id].id_str;
                                }
                                newItem.editable = !(this.eventsForm.value.schedule === '');
                                newItem.status = (this.eventsForm.value.schedule === '' ? 'published' : 'scheduled');
                                newItem.postDate = (typeof newItem.postDate !== 'undefined' && newItem.postDate !== null) ? newItem.postDate : new Date();
                                const calendarEvent = new CalendarEventModel(newItem);
                                result.push(calendarEvent);
                            }
                            return result;
                        }, []);
                        this.calendarService.addFormatedEvents([...events]);
                        return;
                    }
                    if (this.action === 'add') {
                        const oldPosts = [...this.posts];
                        this.matDialogRef.close(['add', oldPosts.reduce((result, post) => {
                            if (typeof response.results[post.network.network_id] !== 'undefined' && response.results[post.network.network_id] !== null) {
                                const newItem = Object.assign(Object.create(Object.getPrototypeOf(post)), post);
                                if (typeof response.results[post.network.network_id].id === 'undefined' || response.results[post.network.network_id].id === null) {
                                    if (typeof response.results[post.network.network_id].error !== 'undefined') {
                                        // errors.push('Social Network "' + post.network.name + '" ERROR:\r\n' + response.results[post.network.network_id].error.message);
                                        this.alert.error(
                                            this.errorMessageFormat(response.results[post.network.network_id].error, post.network.network_type.toLowerCase()),
                                            post.network.network_type.toUpperCase() + ' Network "' + post.network.name + '" was not posted. ERROR:',
                                            {
                                                timeOut: 15000
                                            }
                                        );
                                    }
                                    return result;
                                }
                                // this.clearPostsData();
                                newItem.id = response.results[post.network.network_id].id;
                                if (post.network.network_type.toLowerCase() === 'twitter') {
                                    newItem.id_str = response.results[post.network.network_id].id_str;
                                }
                                newItem.editable = !(this.eventsForm.value.schedule === '');
                                newItem.status = (this.eventsForm.value.schedule === '' ? 'published' : 'scheduled');
                                newItem.postDate = (typeof newItem.postDate !== 'undefined' && newItem.postDate !== null) ? newItem.postDate : new Date();
                                const calendarEvent = new CalendarEventModel(newItem);
                                result.push(calendarEvent);
                            }
                            return result;
                        }, []), action]);
                    } else {
                        const oldPosts = [...this.posts];
                        this.matDialogRef.close(['edit', oldPosts.reduce((result, post) => {
                            if (typeof response.results[post.network.network_id] !== 'undefined' && response.results[post.network.network_id] !== null) {
                                const newItem = Object.assign(Object.create(Object.getPrototypeOf(post)), post);
                                if (typeof response.results[post.network.network_id].id === 'undefined' || response.results[post.network.network_id].id === null) {
                                    if (typeof response.results[post.network.network_id].error !== 'undefined') {
                                        // errors.push('Social Network "' + post.network.name + '" ERROR:\r\n' + response.results[post.network.network_id].error.message);
                                        this.alert.error(
                                            this.errorMessageFormat(response.results[post.network.network_id].error, post.network.network_type.toLowerCase()),
                                            post.network.network_type.toUpperCase() + ' Network "' + post.network.name + '" was not posted. ERROR:',
                                            {
                                                timeOut: 15000
                                            }
                                        );
                                    }
                                    return result;
                                }
                                newItem.id = response.results[post.network.network_id].id;
                                if (post.network.network_type.toLowerCase() === 'twitter') {
                                    newItem.id_str = response.results[post.network.network_id].id_str;
                                }
                                newItem.editable = !(this.eventsForm.value.schedule === '');
                                newItem.status = (this.eventsForm.value.schedule === '' ? 'published' : 'scheduled');
                                newItem.postDate = (typeof newItem.postDate !== 'undefined' && newItem.postDate !== null) ? newItem.postDate : new Date();
                                const calendarEvent = new CalendarEventModel(newItem);
                                result.push(calendarEvent);
                            }
                            return result;
                        }, []), action]);
                    }
                    if (errors.length > 0) {
                        this._matSnackBar.open(errors.join('\r\n\r\n'), 'OK', {
                            verticalPosition: 'top',
                            duration: 15000
                        });
                    }
                } else {
                    if (errors.length > 0) {
                        this._matSnackBar.open(errors.join('\r\n\r\n'), 'OK', {
                            verticalPosition: 'top',
                            duration: 15000
                        });
                    }
                }
            }, error => {
                console.log(error);
                this.loading = false;
            }
        );
    }

    errorMessageFormat(errorResponse: any, networkType = 'facebook'): string {
        const error = typeof errorResponse.message !== 'undefined'
                ? errorResponse.message : errorResponse;
        switch (networkType.toLowerCase()) {
            case 'facebook':
            case 'instagram':
                return error.indexOf('Error validating access token:') !== -1 ? error + ' Please re-connect this network.' : error;
                break;
        }
        return error;
    }

    removePosts(): void {
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
            panelClass: 'fuse-config-dialog',
        });

        dialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete the post(s)?';

        dialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                console.log('removePosts: ', this.posts);
                for (const post of this.posts) {
                    this.postService.removePost(post).subscribe(
                        response => {
                            this.postsService.removePost(post.id);
                        }, error => {
                            // this.postsService.removePost(this.post.id);
                            // this.deletedPost.emit(this.post.id);
                        }
                    );
                }
                this.matDialogRef.close(['delete']);
            }
        });
    }

    getPostLinkPreview(text = 'text'): void {
        // if (this.selectedFiles.length === 0) {
            const input = text === 'text_twitter' ? this.textTwitterInput : this.textInput;
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const urls = input.nativeElement.value.match(urlRegex);
            if (urls !== null && typeof urls[0] !== 'undefined' && urls[0] !== null && urls[0] !== '') {
                if (this.selectedFiles.length === 0 && urls[0].indexOf('https://t.ly') === -1) {
                    this.managePostsService.getLinkPreview(urls[0], false).subscribe(
                        response => {
                            this.postsService.updateAllPosts('link', {...response});
                            // input.nativeElement.value = input.nativeElement.value.replace(urls[0], response.link);
                            // this.postsService.updateAllPosts(text, input.nativeElement.value);
                        },
                        error => console.log(error)
                    );
                }
                for (const url of urls) {
                    if (urls[0].indexOf('https://t.ly') === -1) {
                        this.managePostsService.getLinkShortener(urls[0]).subscribe(
                            response => {
                                // input.nativeElement.value = input.nativeElement.value.replace(url, response.shortUrl);
                                input.nativeElement.value = input.nativeElement.value.replace(url, response.short_url);
                                this.postsService.updateAllPosts(text, input.nativeElement.value);
                                this.eventsForm.get('text').setValue(input.nativeElement.value);
                            },
                            error => console.log(error)
                        );
                    }
                }
            } else {
                if (input.nativeElement.value.indexOf('rebrand.ly') === -1 && input.nativeElement.value.indexOf('https://t.ly') === -1) {
                    this.postsService.updateAllPosts('link', null);
                }
            }
        // }
    }

    locationSelected(event: MatAutocompleteSelectedEvent, locationInput: HTMLInputElement, networkType): void {
        this.postsService.updateLocation(event.option.value, networkType);
        locationInput.value = '';
    }

    groupSelected(event: Event): void {
        console.log(event);
    }

    editMedia(newFile: FileSnippet, load, index): void {
        MediaInfo({ format: 'object' }, (mediainfo: any) => {
            this.getMetadata(mediainfo, newFile.file).then((info) => {
                const errors = this.validateMediaInfo(newFile, info, this.selectedNetworks.map(i => i.network_type.toLowerCase()));
                if (
                    this.selectedNetworks.length === 0
                    || (errors.errors.facebook.length === 0 && errors.errors.linkedin.length === 0 && errors.errors.instagram.length === 0)
                ) {
                    this.managePostsService.updateMedia(newFile.file, this.selectedFiles[index].id).subscribe(
                        response => {
                            if (typeof response !== 'undefined' && response !== null) {
                                if (response.status === 'progress') {
                                    load(response.message, '', this.selectedFiles, index);
                                } else if (response.status === 200) {
                                    newFile.source = response.data.url;
                                    this.selectedFiles[index].width = newFile.width;
                                    this.selectedFiles[index].height = newFile.height;
                                    this.selectedFiles[index].file = newFile.file;
                                    this.selectedFiles[index].id = response.data.id;
                                    this.selectedFiles[index].mediaInfo = info;
                                    load(response.data.url, response.data.url_preview, this.selectedFiles, index);
                                    this.postMediasService.updateMedia(index, {
                                        id: response.data.id,
                                        label: response.data.id + '.' + newFile.type.split('/')[1],
                                        altText: [],
                                        mediaUrl: newFile.source,
                                        fileSize: newFile.file.size,
                                        height: newFile.height,
                                        width: newFile.width,
                                        previewUrl: response.data.previewUrl,
                                        file: (typeof newFile.file !== 'undefined') ? newFile.file : null,
                                        type: newFile.type,
                                        path: response.data.path,
                                        pathPreview: response.data.path_preview,
                                        postId: response.data.post_id
                                    } as Media);
                                }
                            }
                        }, error => console.log(error)
                    );
                } else {
                    const dialogRef = this.dialog.open(FilesErrorsComponent, {
                        width: '400px',
                        data: {from: 'file', networks: errors.errors}
                    });
                    dialogRef.afterClosed().subscribe(
                        data => {
                            if (typeof data !== 'undefined' && data !== null && typeof data.data !== 'undefined' && data.data !== null && data.data === 'edit') {
                                this.currentMediaIndexEditing = index;
                                if (this.selectedFiles[this.currentMediaIndexEditing].type.indexOf('image') !== -1) {
                                    this.ImageEditor.open(newFile.file);
                                } else {
                                    this.openVideoEditor(this.currentMediaIndexEditing);
                                }
                            }
                        }
                    );
                }
            });
        });
    }

    changeFileName(event: {index: number, type: string}): void {
        // this.currentMediaIndexEditing = event;
        // this.ImageEditor.open(this.selectedFiles[event].file);

        if (event.type === 'edit') {
            this.currentMediaIndexEditing = event.index;
            if (this.selectedFiles[event.index].type.indexOf('image') !== -1) {
                this.ImageEditor.open(this.selectedFiles[event.index].file);
            } else {
                this.openVideoEditor(event.index);
            }
        } else {
            const dialogRef = this.dialog.open(ChangeFileNameComponent, {
                width: '250px'
            });
            dialogRef.afterClosed().subscribe(result => {
                const newName = result.split(' ').join('_');
                // console.log('INDEX: ', newName, event);
                this.managePostsService.changeFileName(this.selectedFiles[event.index].source, newName).subscribe(
                    response => {
                        const newUrl = response.newUrl.split('.');
                        const extension = newUrl[newUrl.length - 1];
                        this.selectedFiles[event.index].source = response.newUrl;
                        this.fileService.getFile(this.selectedFiles[event.index].source).subscribe(
                            file => {
                                this.selectedFiles[event.index].file = new File([file], newName + '.' + extension);
                                if (response.mediaData !== null) {
                                    this.postMediasService.updateMedia(event.index, {
                                        id: response.mediaData.id,
                                        label: newName + '.' + extension,
                                        altText: [],
                                        mediaUrl: this.selectedFiles[event.index].source,
                                        fileSize: this.selectedFiles[event.index].file.size,
                                        height: this.selectedFiles[event.index].height,
                                        width: this.selectedFiles[event.index].width,
                                        previewUrl: this.selectedFiles[event.index].previewUrl,
                                        file: (typeof this.selectedFiles[event.index].file !== 'undefined') ? this.selectedFiles[event.index].file : null,
                                        type: this.selectedFiles[event.index].type,
                                        path: response.mediaData.path,
                                        pathPreview: response.mediaData.path_preview,
                                        postId: response.mediaData.post_id
                                    } as Media);
                                } else {
                                    this.postMediasService.updateMediaProperties(event.index, {
                                        label: newName + '.' + extension,
                                        mediaUrl: this.selectedFiles[event.index].source,
                                        file: (typeof this.selectedFiles[event.index].file !== 'undefined') ? this.selectedFiles[event.index].file : null,
                                    });
                                }
                            },
                            error => console.log(error)
                        );
                    }, error => console.log(error)
                );
            });
        }
    }

    searchPages(event: KeyboardEvent): void {
        console.log(event);
    }

    validateTextTags(text): void {
        if(text !== null && text !== '') {
            const taggedPages = this.selectedTags.filter(tag => (tag.type === 'page' && text.indexOf(tag.name) === -1));
            const tags = text.split('#');
            this.selectedTags = [];
            if (tags.length > 1 && tags[1] !== '') {
                tags.shift();
                this.selectedTags = tags.map(tag => ({
                    id: null,
                    name: tag.split(' ')[0],
                    description: '',
                    status: 'ACTIVE',
                    type: 'tag'
                } as Tag));
            }
            this.selectedTags.push(...taggedPages);
            this.postsService.updateAllPosts('tags', this.selectedTags);
        }
    }

    private validateMediaInfo(file: FileSnippet, info: any, networkTypes: Array<any>): any {
        if (typeof info.media.track[0].ImageCount === 'undefined' && Number(Math.floor(info.media.track[1].DisplayAspectRatio * 1000) / 1000) === 0.562) {
            this.eventsForm.get('ig_post_type').setValue('reel');
            this.alert.info('Because of the media display ratio value the Instagram post type will be set as REEL.');
        }
        return {fileName: file.file.name, errors: this.mediaService.getMediaInfo(info, networkTypes)};
    }

    getMetadata(mediainfo: any, file: any): Promise<string> {
        return new Promise<string>((resolve) => {
            // const file = fileinput.files[0];
            if (!file) {
                return resolve('Can\'t get media information');
            }

            const getSize = () => file.size;
            const readChunk: any = (chunkSize, offset) =>
                new Promise((resolve2, reject) => {
                    const reader = new FileReader();
                    reader.onload = (event: ProgressEvent<FileReader>) => {
                        if (event.target.error) {
                            reject(event.target.error);
                        }
                        resolve2(new Uint8Array(event.target.result as ArrayBuffer));
                    };
                    reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
                });
            const p = mediainfo.analyzeData(getSize, readChunk) as Promise<any>;
            p.then((result: string) => resolve(result)).catch(() =>
                resolve('Can\'t get media information')
            );
        });
    }

    customValidation() {
        setTimeout(() => {
            const selectElement = document.querySelector('ngx-mat-timepicker input[formcontrolname="hour"]');
            // document.querySelector('ngx-mat-timepicker input[formcontrolname="hour"]').disabled = true;
            // document.querySelector('ngx-mat-timepicker input[formcontrolname="hour"]').style.color = '#000000';
            selectElement.addEventListener('blur', (event) => {
                    setTimeout(() => {
                        // @ts-ignore
                        if (event.target.value === '00' || event.target.value === 0){
                            // @ts-ignore
                            document.querySelector('ngx-mat-timepicker input[formcontrolname="hour"]').value = '12';
                        }
                    }, 100);
            });
            selectElement.addEventListener('change', (event) => {
                // @ts-ignore
                if (event.target.value === '12' || event.target.value === 12){
                    setTimeout(() => {
                        // @ts-ignore
                        document.querySelector('ngx-mat-timepicker input[formcontrolname="hour"]').value = '12';
                    }, 100);
                }
            });
        }, 100);
    }

    openVideoEditor(selectedFileIndex: number, mediaInfo = null) {
        let info = this.selectedFiles[selectedFileIndex].mediaInfo === null ? mediaInfo : this.selectedFiles[selectedFileIndex].mediaInfo;
        if (info !== null) {
            console.log(info);
            info = {
                voutput: this.selectedFiles[selectedFileIndex].file.name.split('.')[1].toUpperCase(),
                vencoder: info.media.track[0].Format.toUpperCase(),
                vframerate: (isNaN(info.media.track[1].FrameRate)) ? info.media.track[1].FrameRate.split('.')[0].toUpperCase() : info.media.track[1].FrameRate,
                vrotation: info.media.track[1].Rotation.split('.')[0].toUpperCase(),
                vresolution: (isNaN(info.media.track[1].Width)) ? info.media.track[1].Width.toUpperCase() + 'x' + info.media.track[1].Height.toUpperCase() : info.media.track[1].Width + 'x' + info.media.track[1].Height,
                vbitrate: (isNaN(info.media.track[1].BitRate)) ? info.media.track[1].BitRate.toUpperCase() / 1000 + 'K' : info.media.track[1].BitRate / 1000 + 'K',
                vchunk: 0,
                aencoder: info.media.track[2].Format.toUpperCase(),
                aquality: (isNaN(info.media.track[2].BitRate)) ? (info.media.track[2].BitRate.toUpperCase() / 1000).toFixed(0) + 'k' : (info.media.track[2].BitRate / 1000).toFixed(0) + 'k',
                achannel: (isNaN(info.media.track[2].Channels)) ? info.media.track[2].Channels.toUpperCase() : info.media.track[2].Channels,
                abitrate: (isNaN(info.media.track[2].BitRate)) ? (info.media.track[2].BitRate.toUpperCase() / 1000).toFixed(0) + 'k' : (info.media.track[2].BitRate / 1000).toFixed(0) + 'k',
                asamplerate: (isNaN(info.media.track[2].SamplingRate)) ? info.media.track[2].SamplingRate.toUpperCase() : info.media.track[2].SamplingRate,
                vscan: info.media.track[1].ScanType.toUpperCase(),
                vgop: 0,
                vduration: (isNaN(info.media.track[1].Duration)) ? info.media.track[1].Duration.split('.')[0].toUpperCase() : info.media.track[1].Duration,
                vsize: (isNaN(info.media.track[1].Duration)) ? ((info.media.track[0].FileSize.toUpperCase() / 1024) / 1024).toFixed(2) + 'M' : ((info.media.track[0].FileSize / 1024) / 1024).toFixed(2) + 'M',
            };
        }
        const dialogRef = this.dialog.open(VideoEditorComponent, {
            width: '800px',
            data: {file: this.selectedFiles[selectedFileIndex].file, mediaInfo: info}
        });
        dialogRef.afterClosed().subscribe(
            data => {
                if (typeof data !== 'undefined' && data !== null && typeof data.file !== 'undefined' && data.file !== null) {
                    const manualFile = new FileSnippet();
                    // @ts-ignore
                    manualFile.source = '';
                    manualFile.previewUrl = '';
                    // @ts-ignore
                    manualFile.width = data.file.width;
                    // @ts-ignore
                    manualFile.height = data.file.height;
                    manualFile.type = data.file.type;
                    manualFile.file = data.file;
                    if (this.selectedFiles[selectedFileIndex].id !== null) {
                        this.editMedia(manualFile, this.loadFile, selectedFileIndex);
                    } else {
                        this.uploadMediaServer(manualFile, this.loadFile, selectedFileIndex);
                    }
                } else {
                    if (this.selectedFiles[selectedFileIndex].id === null && this.selectedFiles.length - 1 === selectedFileIndex) {
                        this.selectedFiles.splice(selectedFileIndex, 1);
                    }
                }
            }
        );
    }

    // onChangeFile(input: HTMLInputElement): void {
    //     MediaInfoFactory({ format: 'text' }, (mediainfo: MediaInfo) => {
    //         this.getMetadata(mediainfo, input).then((info) => {
    //             this.videoInfo = info.replace(/(?:\r\n|\r|\n)/g, '<br>');
    //             console.log(this.videoInfo);
    //         });
    //     });
    // }
    pinterestBoardSelected(event: any[]) {
        console.log('pinterestBoardSelected: ', event);
        const index = this.posts.findIndex(i => i.network.network_id === event[0].owner.username);
        this.postsService.updatePostProperty(index, 'boards', event.map(i => i.id));
    }
}

@Component({
    selector: 'app-change-file-name',
    template: `<div style="padding: 24px !important;">
        <h1 matDialogTitle>New File Name</h1>
    <div mat-dialog-content>
        <mat-form-field>
            <mat-label>New Name</mat-label>
            <input matInput [(ngModel)]="name">
        </mat-form-field>
    </div>
    <div mat-dialog-actions class="pt-24">
        <button mat-raised-button class="mat-accent mr-16" [mat-dialog-close]="name.split('.')[0]" cdkFocusInitial>Save</button>
    </div></div>`,
})
export class ChangeFileNameComponent {
    name = '';
}

