import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FacebookPost, InstagramPost, LinkedinPost, Post, TwitterPost, YoutubePost} from '../../../../models/post';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {PostService, PostsService} from '../post.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ShareComponent} from '../share/share.component';
import {CommentsComponent} from '../../post-comments/comments/comments.component';
import {FuseConfirmDialogComponent} from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {LinkedinPostReactionsComponent} from '../linkedin-post-reactions/linkedin-post-reactions.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FuseUtils} from '../../../../../@fuse/utils';
import {Network} from '../../../../models/network';
import * as moment from 'moment';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
  animations   : fuseAnimations
})
export class PostComponent implements OnInit {
    @Input() post: Post | FacebookPost | InstagramPost | LinkedinPost | TwitterPost | YoutubePost;
    showCommentInput = false;
    clickComment = false;
    @Input() showComments = false;
    @Input() fromBoard = false;
    @Output() editPost = new EventEmitter<bigint>();
    @Output() deletedPost = new EventEmitter<bigint>();
    typeByDate: 'posted' | 'scheduled';
    @ViewChild('videoBox') videoBox: ElementRef<HTMLVideoElement>;
    @ViewChild('postComments') postComments: CommentsComponent;
    showPlayButton = true;
    reactions: {LIKE: Array<any>, LOVE: Array<any>, WOW: Array<any>, HAHA: Array<any>, SAD: Array<any>, ANGRY: Array<any>, CARE: Array<any>} =
        {LIKE: [], LOVE: [], WOW: [], HAHA: [], SAD: [], ANGRY: [], CARE: []};
    likedBySelf = false;
    token: string = null;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    shortText = false;

    constructor(
        private postsService: PostsService,
        private postService: PostService,
        private dialog: MatDialog,
        private _matSnackBar: MatSnackBar
        ) {
    }

    ngOnInit(): void {
        this.shortText = this.fromBoard;
        if (this.fromBoard && this.post instanceof FacebookPost && typeof this.post.reactions !== 'undefined'
            && this.post.reactions !== null
            && typeof this.post.reactions.data !== 'undefined'
            && this.post.reactions.data !== null) {
            this.reactions = this.post.reactions.data.reduce((rv, x) => {
                if (!this.likedBySelf && String(this.post.id).indexOf(x.id) !== -1) {
                    this.likedBySelf = true;
                }
                (rv[x['type']] = rv[x['type']] || []).push(x);
                return rv;
            }, {});
        }
        if (this.fromBoard && this.post instanceof LinkedinPost) {
            this.likedBySelf = this.post.likedBySelf;
        }
        if (this.post.media === null || this.post.media.length === 0 || typeof this.post.media[0].mediaUrl === 'undefined') {
            this.getPostLinkPreview();
        }
    }

    removePost(): void {
        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete the post?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                this.postService.removePost(this.post).subscribe(
                    response => {
                        this.postsService.removePost(this.post.id);
                        this.deletedPost.emit(this.post.id);
                    }, error => {
                        // this.postsService.removePost(this.post.id);
                        // this.deletedPost.emit(this.post.id);
                    }
                );
            }
        });
    }

    showGeneralComment(): void {
        this.showCommentInput = !this.showCommentInput;
    }

    playVideo(): void {
        this.showPlayButton = false;
        this.videoBox.nativeElement.play();
    }

    likeAction(): void {
        if (this.post instanceof FacebookPost) {
            this.likedBySelf = !this.likedBySelf;
            if (!this.likedBySelf) {
                this.reactions.LIKE.splice(this.reactions.LIKE.findIndex(item => String(this.post.id).indexOf(item.id) !== -1), 1);
                this.post.reactions.data.splice(this.reactions.LIKE.findIndex(item => String(this.post.id).indexOf(item.id) !== -1), 1);
            } else {
                this.reactions.LIKE.push({id: String(this.post.id).split('_')[0], name: this.post.network.name, type: 'LIKE'});
                if (typeof this.post.reactions === 'undefined') {
                    this.post.reactions = {paging: {}, data: []};
                }
                this.post.reactions.data.push({id: String(this.post.id).split('_')[0], name: this.post.network.name, type: 'LIKE'});
            }
            this.postService.likeManagment(this.likedBySelf, this.post.network.auth_token, String(this.post.id)).subscribe(
                response => console.log(response),
                error => console.log(error)
            );
        } else if (this.post instanceof InstagramPost) {
            this.likedBySelf = !this.likedBySelf;
            this.postService.likeManagment(this.likedBySelf, this.post.network.auth_token, this.post.network.network_id + '_' + this.post.id).subscribe(
                response => console.log(response),
                error => console.log(error)
            );
        } else if (this.post instanceof LinkedinPost) {
            this.likedBySelf = !this.likedBySelf;
            this.postService.likeLinkedinManagment(this.likedBySelf, this.post.network, String(this.post.id)).subscribe(
                response => console.log(response),
                error => console.log(error)
            );
        }
    }

    sharePost(): void {
        // if (this.post instanceof FacebookPost || this.post instanceof LinkedinPost) {
            const dialogRef = this.dialog.open(ShareComponent, {
                width: '460px',
                data: {post: this.post}
            });
            dialogRef.afterClosed().subscribe(result => {
                switch (this.post.type.toLowerCase()) {
                    case 'facebook':
                        break;
                    case 'instagram':
                        break;
                    case 'linkedin':
                        this.postService.reshareLinkedinPost(
                            result.network,
                            String(this.post.id),
                            result.text,
                            (typeof this.post.media[0] !== 'undefined' ? this.post.media[0].type : 'regular')
                        ).subscribe(
                            response => {
                                console.log(response);

                            },
                            error => console.log(error)
                        );
                        break;
                    case 'twitter':
                        console.log(this.post);
                        this.postService.reshareTwitterPost(
                            result.network,
                            (this.post as TwitterPost).id_str,
                            // @ts-ignore
                            (this.post.from === null) ? this.post.network.description : this.post.from.screen_name,
                            result.text,
                            (typeof this.post.media[0] !== 'undefined' ? this.post.media[0].type : 'regular')
                        ).subscribe(
                            response => {
                                console.log(response);

                            },
                            error => console.log(error)
                        );
                        break;
                }
            });
        // }
    }

    getPostLinkPreview(): void {
        if (this.post.link !== null && (this.post.media === null || this.post.media.length === 0)) {
            if (typeof this.post.link.link !== 'undefined' && this.post.link.link !== null && this.post.link.link !== ''
                && (this.post.link.title === null || this.post.link.description === null)) {
                this.postService.getLinkPreview(this.post.link.link).subscribe(
                    response => {
                        this.post.link.imageUrl = (this.post.link.imageUrl !== null && this.post.link.imageUrl !== '' ? this.post.link.imageUrl :
                            (typeof response.imageUrl !== 'undefined' && response.imageUrl !== null && response.imageUrl !== '' ? response.imageUrl : ''));
                        this.post.link = {...response, link: this.post.link.link, imageUrl: this.post.link.imageUrl, host: this.post.link.host};
                        this.postsService.updateAllPosts('link', this.post.link);
                    },
                    error => console.log(error)
                );
            } else {
                // this.postsService.updateAllPosts('link', null);
            }
        }
    }

    getSocialActions(): void {
        if (this.post instanceof LinkedinPost) {
            const dialogRef = this.dialog.open(LinkedinPostReactionsComponent, {
                width: '320px',
                data: {postId: this.post.id}
            });
            dialogRef.afterClosed().subscribe(result => {
                console.log(result);
            });
        }
    }

    twitterReplay(): void {
        return;
    }

    retweetUnretweet(): void {
        if ((this.post as TwitterPost).retweeted) {
            this.postService.unretweet(this.post.network.network_id, (this.post as TwitterPost).id_str).subscribe();
        } else {
            this.postService.retweet(this.post.network.network_id, (this.post as TwitterPost).id_str).subscribe();
        }
        (this.post as TwitterPost).retweeted = !(this.post as TwitterPost).retweeted;
    }

    likeUnlikeTweet(): void {
        if (this.post.likedBySelf) {
            this.postService.unLikeTwitter(this.post.network.network_id, (this.post as TwitterPost).id_str).subscribe();
        } else {
            this.postService.likeTwitter(this.post.network.network_id, (this.post as TwitterPost).id_str).subscribe();
        }
        this.post.likedBySelf = !this.post.likedBySelf;
    }

    shareTweet(theInput: HTMLInputElement): void {
        const copyText = theInput;
        // @ts-ignore
        copyText.select();
        // @ts-ignore
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
        this._matSnackBar.open('Share Link Copied to Clipboard.', 'OK', {
            verticalPosition: 'top',
            duration        : 2000
        });
    }

    getConvertedPost(post: any): boolean | Post {
        return FuseUtils.formatServerPost(post,
            {network_id: post.user.id, network_type: this.post.network.network_type, name: post.user.name, picture: post.user.profile_image_url_https} as Network);
    }

    convertDate(date: any) {
        return moment(date).format('MM/DD/YY hh:mm:ss');
    }
}
