import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import {ManagePostsComponent} from "../../../main/apps/manage-posts/manage-posts/manage-posts.component";
import {ManagePostsModule} from "../../../main/apps/manage-posts/manage-posts.module";
import {SocialMediaManagementModule} from "../../../main/apps/social-media-management/social-media-management.module";

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        ManagePostsModule,
        SocialMediaManagementModule
    ],
    exports     : [
        ToolbarComponent
    ]
})
export class ToolbarModule
{
}
