<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Facebook Audience Restrictions</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 pb-0 m-0" fusePerfectScrollbar>
        <form name="eventForm" [formGroup]="form" class="event-form w-100-p" fxLayout="column" fxFlex>
            <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>Company Size</mat-label>
                <mat-select formControlName="size" multiple>
                    <mat-option value="SIZE_1">Self Employed</mat-option>
                    <mat-option value="SIZE_2_TO_10">2 - 10</mat-option>
                    <mat-option value="SIZE_11_TO_50">11 - 50</mat-option>
                    <mat-option value="SIZE_51_TO_200">51 - 200</mat-option>
                    <mat-option value="SIZE_201_TO_500">201 - 500</mat-option>
                    <mat-option value="SIZE_501_TO_1000">501 - 1000</mat-option>
                    <mat-option value="SIZE_1001_TO_5000">1001 - 5000</mat-option>
                    <mat-option value="SIZE_5001_TO_10000">5001 - 10000</mat-option>
                    <mat-option value="SIZE_10001_OR_MORE">10001+</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <mat-label>Languages</mat-label>
                <mat-chip-list #languageList name="languages">
                    <mat-chip style="line-height: 16px;" *ngFor="let language of selectedList.languages"
                              [selectable]="true"
                              [value]="language.alpha2"
                              [removable]="true" (removed)="remove(language)">
                        {{language.English}}
                        <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
                    </mat-chip>
                    <input #languageInput [matChipInputFor]="languageList" autocomplete="none"
                           formControlName="languages"
                           [matAutocomplete]="autoLanguage"
                           [matChipInputAddOnBlur]="false"
                           placeholder="Type a language..." />
                </mat-chip-list>
                <mat-autocomplete #autoLanguage="matAutocomplete" (optionSelected)="selected($event, languageInput, 'languages')">
                    <mat-option *ngFor="let language of languages" [value]="language">
                        {{language.English}}
                    </mat-option>
                </mat-autocomplete>
                <mat-spinner diameter="20" class="input-loader" *ngIf="inputLoaders.languages"></mat-spinner>
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <mat-label>Locations</mat-label>
                <mat-chip-list #locationList name="locations">
                    <mat-chip style="line-height: 16px;" *ngFor="let location of selectedList.locations"
                              [selectable]="true"
                              [value]="location.$URN"
                              [removable]="true" (removed)="remove(location, 'locations')">
                        {{location.name.value}}
                        <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
                    </mat-chip>
                    <input #locationInput [matChipInputFor]="locationList" autocomplete="none"
                           formControlName="locations"
                           [matAutocomplete]="autoLocations"
                           [matChipInputAddOnBlur]="false"
                           placeholder="Type a location..." />
                </mat-chip-list>
                <mat-autocomplete #autoLocations="matAutocomplete" (optionSelected)="selected($event, locationInput, 'locations')">
                    <mat-option *ngFor="let location of filteredLocationsData | async" [value]="location">
                        {{location.name.value}}
                    </mat-option>
                </mat-autocomplete>
                <mat-spinner diameter="20" class="input-loader" *ngIf="inputLoaders.locations"></mat-spinner>
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <mat-label>Functions</mat-label>
                <mat-chip-list #functionList name="functions">
                    <mat-chip style="line-height: 16px;" *ngFor="let function of selectedList.functions"
                              [selectable]="true"
                              [value]="function.id"
                              [removable]="true" (removed)="remove(function, 'functions')">
                        {{function.name.localized.en_US}}
                        <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
                    </mat-chip>
                    <input #functionInput [matChipInputFor]="functionList" autocomplete="none"
                           formControlName="functions"
                           [matAutocomplete]="autoFunctions"
                           [matChipInputAddOnBlur]="false"
                           placeholder="Type a city..." />
                </mat-chip-list>
                <mat-autocomplete #autoFunctions="matAutocomplete" (optionSelected)="selected($event, functionInput,'functions')">
                    <mat-option *ngFor="let function of filteredFunctionsData | async" [value]="function">
                        {{function.name.localized.en_US}}
                    </mat-option>
                </mat-autocomplete>
                <mat-spinner diameter="20" class="input-loader" *ngIf="inputLoaders.functions"></mat-spinner>
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <mat-label>Industries</mat-label>
                <mat-chip-list #industryList name="functions">
                    <mat-chip style="line-height: 16px;" *ngFor="let industry of selectedList.industries"
                              [selectable]="true"
                              [value]="industry.$URN"
                              [removable]="true" (removed)="remove(industry, 'industries')">
                        {{industry.name.localized.en_US}}
                        <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
                    </mat-chip>
                    <input #industryInput [matChipInputFor]="industryList" autocomplete="none"
                           formControlName="industries"
                           [matAutocomplete]="autoIndustries"
                           [matChipInputAddOnBlur]="false"
                           placeholder="Type a city..." />
                </mat-chip-list>
                <mat-autocomplete #autoIndustries="matAutocomplete" (optionSelected)="selected($event, industryInput,'industries')">
                    <mat-option *ngFor="let industry of filteredIndustriesData | async" [value]="industry">
                        {{industry.name.localized.en_US}}
                    </mat-option>
                </mat-autocomplete>
                <mat-spinner diameter="20" class="input-loader" *ngIf="inputLoaders.industries"></mat-spinner>
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <mat-label>Seniority Level</mat-label>
                <mat-chip-list #seniorityList name="seniorities">
                    <mat-chip style="line-height: 16px;" *ngFor="let seniority of selectedList.seniorities"
                              [selectable]="true"
                              [value]="seniority.$URN"
                              [removable]="true" (removed)="remove(seniority, 'seniorities')">
                        {{seniority.name.localized.en_US}}
                        <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
                    </mat-chip>
                    <input #seniorityInput [matChipInputFor]="seniorityList" autocomplete="none"
                           formControlName="seniorities"
                           [matAutocomplete]="autoSeniorities"
                           [matChipInputAddOnBlur]="false"
                           placeholder="Type a city..." />
                </mat-chip-list>
                <mat-autocomplete #autoSeniorities="matAutocomplete" (optionSelected)="selected($event, seniorityInput,'seniorities')">
                    <mat-option *ngFor="let seniority of filteredSenioritiesData | async" [value]="seniority">
                        {{seniority.name.localized.en_US}}
                    </mat-option>
                </mat-autocomplete>
                <mat-spinner diameter="20" class="input-loader" *ngIf="inputLoaders.seniorities"></mat-spinner>
            </mat-form-field>
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button
            mat-button color="primary"
            class="save-button mat-button-base"
            (click)="matDialogRef.close()"
            aria-label="CLOSE">
            CLOSE
        </button>
        <button
            mat-button
            class="save-button mat-focus-indicator mat-accent mat-raised-button mat-button-base"
            (click)="matDialogRef.close({
                    size: form.get('size').value,
                    languages: selectedList.languages,
                    locations: selectedList.locations,
                    functions: selectedList.functions,
                    industries: selectedList.industries,
                    seniorities: selectedList.seniorities
                })"
            aria-label="SAVE">
            SAVE
        </button>

    </div>
</div>
