<div class="manage-posts-container">
    <div *ngIf="loading" style="position: absolute;top:0px;bottom:0px;left:0px;right:0px;background: rgba(0,0,0,0.6);z-index: 1;">
        <mat-progress-spinner style="position: absolute;top: calc(50vh - 40px);left: calc(50vw - 20px);" color="accent" mode="indeterminate" [diameter]="40"></mat-progress-spinner>
    </div>
    <div class="header blue-bg" fxLayout="row" fxLayoutAlign="space-between">
        <div class="h2 mb-4 title">{{dialogTitle}}</div>
        <div class="cursor-pointer"><i mat-dialog-close class="fas fa-times" style="color: white"></i></div>
    </div>
    <div class="body" fxLayout.gt-xs="row" fxLayout.xs="column">
        <div class="posts-form" fxFlex="50%" fxFlex.xs="100%" fusePerfectScrollbar *ngIf="events === null || events.length === 0 || events[0].editable">
            <div class="box-container">
                <form name="productForm" [formGroup]="eventsForm" class="product w-100-p emojis-input-container" fxLayout="column" fxFlex>
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                        <mat-label>Post to</mat-label>
                        <mat-chip-list #networkList name="networks" style="max-height: 72px;overflow: scroll;">
                            <mat-chip style="line-height: 30px;" *ngFor="let network of selectedNetworks"
                                      [selectable]="true"
                                      [value]="network.id"
                                      [removable]="true" (removed)="removeNetwork(network)">
<!--                                <i *ngIf="network.network_type.toLowerCase() === 'facebook'" class="fab fa-facebook" style="font-size: 17px;"></i>-->
<!--                                <i *ngIf="network.network_type.toLowerCase() === 'twitter'" class="fab fa-twitter" style="font-size: 17px;"></i>-->
<!--                                <i *ngIf="network.network_type.toLowerCase() === 'linkedin'" class="fab fa-linkedin-in" style="font-size: 17px;"></i>-->
<!--                                <i *ngIf="network.network_type.toLowerCase() === 'instagram'" class="fab fa-instagram" style="font-size: 17px;"></i>-->
                                <div style="padding: 5px" class="avatar-wrapper" fxFlex="0 1 auto" fxLayoutAlign="center center">
                                    <img class="avatar" [src]="(network.picture !== '') ? network.picture : 'assets/images/agency.png'"
                                         alt="{{network.name}}"/>
                                    <mat-icon style="color: #0A66C2" *ngIf="network.network_type === 'FACEBOOK'" class="s-16 status">thumb_up</mat-icon>
                                    <mat-icon style="color: hotpink" *ngIf="network.network_type === 'INSTAGRAM'" class="s-16 status">favorite</mat-icon>
                                    <i style="color: #00A0DC;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'LINKEDIN'" class="s-16 status fab fa-linkedin"></i>
                                    <i style="color: #000000;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'TWITTER'" class="s-16 status fab fa-x-twitter"></i>
                                    <i style="color: #e60023;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'PINTEREST'" class="s-16 status fab fa-pinterest"></i>
                                    <i style="color: #c00;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'YOUTUBE'" class="s-16 status fab fa-youtube"></i>
                                </div>
                                {{network.network_type === 'INSTAGRAM' ? '@' + network.name : network.name}}
                                <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
                            </mat-chip>
                            <input #networkInput [matChipInputFor]="networkList"
                                   [formControl]="networkInputControl"
                                   [matAutocomplete]="auto"
                                   [matChipInputAddOnBlur]="false"
                                   (matChipInputTokenEnd)="addNetwork($event)" placeholder="Type a network's name..." />
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedNetwork($event)">
                            <mat-option *ngFor="let team of filteredTeams | async" [value]="team">
                                <div style="padding: 5px" class="avatar-wrapper" fxFlex="0 1 auto" fxLayoutAlign="center center">
                                    <mat-icon style="color: #676869;position: unset;margin-right: 15px;font-size: 30px !important;" class="s-16 status">supervised_user_circle</mat-icon>
                                </div>
                                {{team.name}}
                            </mat-option>
                            <mat-option *ngFor="let network of filteredNetworks | async" [value]="network" [disabled]="network.notification_flag > 0">
                                <div style="padding: 5px" class="avatar-wrapper" fxFlex="0 1 auto" fxLayoutAlign="center center">
                                    <img class="avatar" [src]="(network.picture !== '') ? network.picture : 'assets/images/agency.png'"
                                         alt="{{network.name}}"/>
                                    <mat-icon style="color: #0A66C2" *ngIf="network.network_type === 'FACEBOOK'" class="s-16 status">thumb_up</mat-icon>
                                    <mat-icon style="color: hotpink" *ngIf="network.network_type === 'INSTAGRAM'" class="s-16 status">favorite</mat-icon>
                                    <i style="color: #00A0DC;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'LINKEDIN'" class="s-16 status fab fa-linkedin"></i>
                                    <i style="color: #000000;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'TWITTER'" class="s-16 status fab fa-x-twitter"></i>
                                    <i style="color: #e60023;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'PINTEREST'" class="s-16 status fab fa-pinterest"></i>
                                    <i style="color: #c00;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'YOUTUBE'" class="s-16 status fab fa-youtube"></i>
                                </div>
                                {{network.network_type === 'INSTAGRAM' ? '@' + network.name : network.name}}
                                <span *ngIf="network.notification_flag > 0" style="font-size: 11px;position: absolute;right: 15px;background: #ddd;border-radius: 28px;padding: 2px 8px;line-height: 18px;color: #848080;margin-top: 14px;">Needs to be re-connected</span>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-spinner diameter="20" class="input-loader" *ngIf="inputLoader"></mat-spinner>
                    </mat-form-field>
                    <div *ngIf="pinterestNetworks.length > 0 || hasTypeOfPost('youtube')">
                        <mat-form-field class="w-100-p" appearance="outline" floatLabel="">
                            <mat-label>Title</mat-label>
                            <input [(ngModel)]="pinterestTitle" [ngModelOptions]="{standalone: true}" matInput placeholder="" value="" type="text" maxlength="100" (keyup)="propertyChanged('title', $event.currentTarget['value']);">
                        </mat-form-field>
                    </div>
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                        <mat-label>Text</mat-label>
                        <textarea [maxlength]="(
                        (hasTypeOfPost('twitter') && !hasTypeOfPost('facebook') && !hasTypeOfPost('instagram') && !hasTypeOfPost('linkedin') && !hasTypeOfPost('pinterest') && !hasTypeOfPost('youtube')) ? 270 :
                        (hasTypeOfPost('pinterest') ? 500 : (hasTypeOfPost('linkedin') ? 1300 : (hasTypeOfPost('facebook') ? 2000 : (hasTypeOfPost('instagram') ? 2200 : 5000)))))"
                        class="emojis-input" formControlName="text" rows="8" matInput placeholder="Enter your text and links..." #text></textarea>
                    </mat-form-field>
                    <i class="far fa-grin-alt add-emoji" (click)="emojiClicked($event)"></i>
                    <div class="text-posts-limit" style="text-align: right;margin-top: -48px;margin-bottom: 25px;">
                        <small class="ml-4" *ngIf="hasTypeOfPost('facebook')"><i style="color: rgb(24, 119, 242);font-size: 12px;margin-right: 1px;" class="fab fa-facebook"></i>{{2000 - textValue.length}}</small>
                        <small class="ml-4" *ngIf="hasTypeOfPost('instagram')"><i style="color: rgb(217, 49, 117);font-size: 12px;margin-right: 1px;" class="fab fa-instagram"></i>{{2200 - textValue.length}}</small>
                        <small class="ml-4" *ngIf="hasTypeOfPost('twitter') && text.textLength <= 270"><i style="color: rgb(0, 0, 0);font-size: 12px;margin-right: 1px;" class="fab fa-x-twitter"></i>{{270 - textValue.length}}</small>
                        <small class="ml-4" *ngIf="hasTypeOfPost('linkedin')"><i style="color: rgb(10, 102, 194);font-size: 12px;margin-right: 1px;" class="fab fa-linkedin-in"></i>{{1300 - textValue.length}}</small>
                        <small class="ml-4" *ngIf="hasTypeOfPost('pinterest')"><i style="color: #e60023;font-size: 12px;margin-right: 1px;" class="fab fa-pinterest"></i>{{500 - textValue.length}}</small>
                        <small class="ml-4" *ngIf="hasTypeOfPost('youtube')"><i style="color: #c00;font-size: 12px;margin-right: 1px;" class="fab fa-youtube"></i>{{5000 - textValue.length}}</small>
                    </div>

                    <mat-form-field *ngIf="
                    text.textLength > 270
                    && hasTypeOfPost('twitter')
                    && (hasTypeOfPost('facebook') || hasTypeOfPost('instagram') || hasTypeOfPost('linkedin') || hasTypeOfPost('pinterest') || hasTypeOfPost('youtube'))" appearance="outline" floatLabel="always" class="w-100-p">
                        <mat-label>Text Twitter</mat-label>
                        <textarea [maxlength]="270"
                                  class="emojis-input" formControlName="text_twitter" rows="8" matInput placeholder="Enter your text and links..." #textTwitter></textarea>
                    </mat-form-field>
<!--                    <i *ngIf="text.textLength > 280" class="far fa-grin-alt add-emoji" (click)="emojiClicked($event)"></i>-->
<!--                    <div #emojiBlock>-->
<!--                        <emoji-mart color="#2196f3" [ngStyle]="{-->
<!--                        height: (showEmojis ? 'auto' : 0),-->
<!--                        visibility: (showEmojis ? 'unset' : 'hidden')-->
<!--                        }" set="apple" (emojiSelect)="emojiSelection($event)"></emoji-mart>-->
<!--                    </div>-->
                    <div>
                        <button class="open-media-button blue mat-accent mat-raised-button mat-button mat-button-base" (click)="showMediaLibrary = !showMediaLibrary">
                            <i class="fas fa-cloud-download-alt" style="color: #fff;font-size: 16px;"></i> Media Library
                        </button>
                        <div style="width: 100%">
                            <app-file-uploader (fileEdit)="changeFileName($event)" (filesOrderChanged)="mediaOrderChanged($event)" [selectedFiles]="selectedFiles" [serverActions]="mediaUploaderSettings" acceptTypes="image/jpg,image/jpeg,image/gif,image/png,video/mp4,video/quicktime,video/mov,video/qt"></app-file-uploader>
                        </div>
                    </div>
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p mt-20">
                        <mat-label>Tags</mat-label>
                        <mat-chip-list #tagList name="tags">
                            <ng-container *ngFor="let tag of selectedTags">
                                <mat-chip style="line-height: 16px;" *ngIf="tag.type !== 'page'"
                                          [selectable]="true"
                                          [value]="tag.id"
                                          [removable]="true" (removed)="removeTag(tag)">
                                    <i class="fas fa-tag" style="font-size: 17px;line-height: 25px;"></i>
                                    {{tag.name}}
                                    <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
                                </mat-chip>
                            </ng-container>
                            <input #tagInput [matChipInputFor]="tagList"
                                   [formControl]="tagInputControl"
                                   [matAutocomplete]="autoTag"
                                   [matChipInputAddOnBlur]="false"
                                   (matChipInputTokenEnd)="addTag($event)" placeholder="Type or add a tag..." />
                        </mat-chip-list>
                        <mat-autocomplete #autoTag="matAutocomplete" (optionSelected)="selectedTag($event)">
                            <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                                <i class="fas fa-tag" style="font-size: 17px;"></i>
                                {{tag.name.replace('#', '')}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-spinner diameter="20" class="input-loader" *ngIf="tagLoader"></mat-spinner>
                    </mat-form-field>
                    <div formGroupName="locations">
<!--                        <mat-form-field *ngIf="hasTypeOfPost('facebook')" class="w-100-p mt-12" appearance="outline">-->
<!--                            <mat-label><i style="color: rgb(24, 119, 242);font-size: 16px;margin-right: 1px;" class="fab fa-facebook"></i> Location</mat-label>-->
<!--                            <mat-chip-list #facebookLocation name="facebookLocation">-->
<!--                                <mat-chip style="line-height: 16px;"-->
<!--                                          *ngIf="eventsForm.get('locations').get('facebook').value !== null && eventsForm.get('locations').get('facebook').value !== ''-->
<!--                                                    && eventsForm.get('locations').get('facebook').value.id &&eventsForm.get('locations').get('facebook').value.id !== null && eventsForm.get('locations').get('facebook').value.id !== ''"-->
<!--                                          [value]="eventsForm.get('locations').get('facebook').value.id"-->
<!--                                          [removable]="true" (removed)="eventsForm.get('locations').get('facebook').setValue('')">-->
<!--                                    {{-->
<!--                                        eventsForm.get('locations').get('facebook').value.name + ' - ' +-->
<!--                                        ((eventsForm.get('locations').get('facebook').value.location.street) ? (eventsForm.get('locations').get('facebook').value.location.street + ', ' ) : '') +-->
<!--                                        ((eventsForm.get('locations').get('facebook').value.location.city) ? (eventsForm.get('locations').get('facebook').value.location.city + ', ' ) : '') +-->
<!--                                        ((eventsForm.get('locations').get('facebook').value.location.state) ? (eventsForm.get('locations').get('facebook').value.location.state + ', ' ) : '') +-->
<!--                                        ((eventsForm.get('locations').get('facebook').value.location.country) ? (eventsForm.get('locations').get('facebook').value.location.country) : '')-->
<!--                                    }}-->
<!--                                    <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>-->
<!--                                </mat-chip>-->
<!--                                <input #facebookLocationInput [matChipInputFor]="facebookLocation" autocomplete="none"-->
<!--                                       formControlName="facebook"-->
<!--                                       [matAutocomplete]="autoLocFacebook"-->
<!--                                       [matChipInputAddOnBlur]="false"-->
<!--                                       placeholder="Type a location..." />-->
<!--                            </mat-chip-list>-->
<!--                            <mat-autocomplete #autoLocFacebook="matAutocomplete" (optionSelected)="locationSelected($event, facebookLocationInput, 'facebook')">-->
<!--                                <mat-option *ngFor="let loc of filteredLocations | async" [value]="loc">-->
<!--                                    <label>{{loc.name}}</label>-->
<!--                                    <small *ngIf="loc.location"> - {{loc.location.street + ', ' + loc.location.city + ', ' + loc.location.state + ' ' + loc.location.country}}</small>-->
<!--                                </mat-option>-->
<!--                            </mat-autocomplete>-->
<!--                        </mat-form-field>-->
<!--                        <mat-form-field *ngIf="hasTypeOfPost('instagram')" class="w-100-p" appearance="outline" style="margin-top: -20px;">-->
<!--                            <mat-label><i style="color: rgb(217, 49, 117);font-size: 16px;margin-right: 1px;" class="fab fa-instagram"></i> Location</mat-label>-->
<!--                            <mat-chip-list #instagramLocation name="instagramLocation">-->
<!--                                <mat-chip style="line-height: 16px;"-->
<!--                                          *ngIf="eventsForm.get('locations').get('instagram').value !== null && eventsForm.get('locations').get('instagram').value !== ''-->
<!--                                                    && eventsForm.get('locations').get('instagram').value.id && eventsForm.get('locations').get('instagram').value.id !== null && eventsForm.get('locations').get('instagram').value.id !== ''"-->
<!--                                          [value]="eventsForm.get('locations').get('instagram').value.id"-->
<!--                                          [removable]="true" (removed)="eventsForm.get('locations').get('instagram').setValue('')">-->
<!--                                    {{-->
<!--                                    eventsForm.get('locations').get('instagram').value.name + ' - ' +-->
<!--                                    ((eventsForm.get('locations').get('instagram').value.location.street) ? (eventsForm.get('locations').get('instagram').value.location.street + ', ' ) : '') +-->
<!--                                    ((eventsForm.get('locations').get('instagram').value.location.city) ? (eventsForm.get('locations').get('instagram').value.location.city + ', ' ) : '') +-->
<!--                                    ((eventsForm.get('locations').get('instagram').value.location.state) ? (eventsForm.get('locations').get('instagram').value.location.state + ', ' ) : '') +-->
<!--                                    ((eventsForm.get('locations').get('instagram').value.location.country) ? (eventsForm.get('locations').get('instagram').value.location.country) : '')-->
<!--                                    }}-->
<!--                                    <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>-->
<!--                                </mat-chip>-->
<!--                                <input #instagramLocationInput [matChipInputFor]="instagramLocation" autocomplete="none"-->
<!--                                       formControlName="instagram"-->
<!--                                       [matAutocomplete]="autoLocInstagram"-->
<!--                                       [matChipInputAddOnBlur]="false"-->
<!--                                       placeholder="Type a location..." />-->
<!--                            </mat-chip-list>-->
<!--                            <mat-autocomplete #autoLocInstagram="matAutocomplete" (optionSelected)="locationSelected($event, instagramLocationInput, 'instagram')">-->
<!--                                <mat-option *ngFor="let loc of filteredLocations | async" [value]="loc">-->
<!--                                    {{loc.name}}-->
<!--                                </mat-option>-->
<!--                            </mat-autocomplete>-->
<!--                        </mat-form-field>-->
                        <mat-form-field *ngIf="hasTypeOfPost('twitter')" class="w-100-p" appearance="outline" style="margin-top: -20px;">
                            <mat-label><i style="color: rgb(0, 0, 0);font-size: 16px;margin-right: 1px;" class="fab fa-x-twitter"></i> Location</mat-label>
                            <mat-chip-list #twitterLocation name="twitterLocation">
                                <mat-chip style="line-height: 16px;"
                                          *ngIf="eventsForm.get('locations').get('twitter').value !== null && eventsForm.get('locations').get('twitter').value !== ''
                                                    && eventsForm.get('locations').get('twitter').value.id && eventsForm.get('locations').get('twitter').value.id !== null && eventsForm.get('locations').get('instagram').value.id !== ''"
                                          [value]="eventsForm.get('locations').get('twitter').value.id"
                                          [removable]="true" (removed)="eventsForm.get('locations').get('twitter').setValue('')">
                                    {{
                                        eventsForm.get('locations').get('twitter').value.full_name
                                    }}
                                    <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
                                </mat-chip>
                                <input #twitterLocationInput [matChipInputFor]="twitterLocation" autocomplete="none"
                                       formControlName="twitter"
                                       [matAutocomplete]="autoLocTwitter"
                                       [matChipInputAddOnBlur]="false"
                                       placeholder="Type a location..." />
                            </mat-chip-list>
                            <mat-autocomplete #autoLocTwitter="matAutocomplete" (optionSelected)="locationSelected($event, twitterLocationInput, 'twitter')">
                                <mat-option *ngFor="let loc of filteredLocations | async" [value]="loc">
                                    {{loc.full_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-spinner diameter="20" class="input-loader" *ngIf="inputLocationLoader"></mat-spinner>
                        </mat-form-field>
<!--                        <mat-form-field *ngIf="hasTypeOfPost('twitter')" class="w-100-p"  formGroupName="twitter" appearance="outline" style="margin-top: -20px;">-->
<!--                            <mat-label><i style="color: rgb(29, 161, 242);font-size: 16px;margin-right: 1px;" class="fab fa-twitter"></i> Location</mat-label>-->
<!--                            <input type="text" matInput formControlName="name" [matAutocomplete]="autoLocTwitter">-->
<!--                            <mat-autocomplete #autoLocTwitter="matAutocomplete">-->
<!--                                <mat-option *ngFor="let loc of filteredLocations | async" [value]="loc">-->
<!--                                    {{loc.label}}-->
<!--                                </mat-option>-->
<!--                            </mat-autocomplete>-->
<!--                        </mat-form-field>-->
                    </div>
                    <div fxLayout="row" class="" style="align-items: center;" *ngIf="hasTypeOfPost('instagram')">
                        <mat-label style="font-weight: 700;">INSTAGRAM posting type: </mat-label>
                        <mat-radio-group formControlName="ig_post_type" aria-label="Select an option">
                            <mat-radio-button value="regular" style="margin-left: 20px;">Regular</mat-radio-button>
                            <mat-radio-button value="reel" style="margin-left: 20px;">Reel</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div fxLayout="column" class="audienceTrigger" *ngIf="hasTypeOfPost('facebook')">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <label class=""><strong>Facebook Audience Restrictions</strong></label>
                            <i class="fas fa-plus-circle icon-button" (click)="openAudienceModal('facebook')"></i>
                        </div>
                        <div *ngIf="eventsForm.controls.audience['controls'].facebook.controls.age_min.value !== ''">
                            <label>Minimum Age: </label> +{{eventsForm.controls.audience['controls'].facebook.controls.age_min.value}}
                        </div>
                        <div *ngIf="eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.countries.value.length > 0
                        && eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.countries.value[0] !== null
                        && eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.countries.value[0] !== ''">
                            <label>Countries: </label>
                            {{eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.countries.value | arrayJoin : 'name' : ','}}
                        </div>
                        <div *ngIf="eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.cities.value.length > 0
                        && eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.cities.value[0] !== null
                        && eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.cities.value[0] !== ''">
                            <label>Cities: </label>
                            {{eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.cities.value | arrayJoin : 'name' : ','}}
                        </div>
                        <div *ngIf="eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.regions.value.length > 0
                        && eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.regions.value[0] !== null
                        && eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.regions.value[0] !== ''">
                            <label>Regions: </label>
                            {{eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.regions.value | arrayJoin : 'name' : ','}}
                        </div>
                        <div *ngIf="eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.zips.value.length > 0
                        && eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.zips.value[0] !== null
                        && eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.zips.value[0] !== ''">
                            <label>Zip Codes: </label>
                            {{eventsForm.controls.audience['controls'].facebook.controls.geo_locations.controls.zips.value  | arrayJoin : 'name' : ','}}
                        </div>
                    </div>
                    <div fxLayout="column" class="audienceTrigger" *ngIf="hasTypeOfPost('linkedin') && linkedinFollowers.followerCountsByAssociationType >= 300">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <label class=""><strong>Linkedin Audience Restrictions</strong></label>
                            <i class="fas fa-plus-circle icon-button" (click)="openAudienceModal('linkedin')"></i>
                        </div>
                        <div *ngIf="eventsForm.controls.audience['controls'].linkedin.controls.size.value.length > 0
                        && eventsForm.controls.audience['controls'].linkedin.controls.size.value[0] !== null
                        && eventsForm.controls.audience['controls'].linkedin.controls.size.value[0] !== ''">
                            <label>Company size: </label>
                            {{eventsForm.controls.audience['controls'].linkedin.controls.size.value.join(', ')}}
                        </div>
                        <div *ngIf="eventsForm.controls.audience['controls'].linkedin.controls.languages.value.length > 0
                        && eventsForm.controls.audience['controls'].linkedin.controls.languages.value[0] !== null
                        && eventsForm.controls.audience['controls'].linkedin.controls.languages.value[0] !== ''">
                            <label>Languages: </label>
                            {{eventsForm.controls.audience['controls'].linkedin.controls.languages.value | arrayJoin : 'English' : ' - '}}
                        </div>
                        <div *ngIf="eventsForm.controls.audience['controls'].linkedin.controls.locations.value.length > 0
                        && eventsForm.controls.audience['controls'].linkedin.controls.locations.value[0] !== null
                        && eventsForm.controls.audience['controls'].linkedin.controls.locations.value[0] !== ''">
                            <label>Locations: </label>
                            {{eventsForm.controls.audience['controls'].linkedin.controls.locations.value | arrayJoin : 'name.value' : ' - '}}
                        </div>
                        <div *ngIf="eventsForm.controls.audience['controls'].linkedin.controls['functions'].value.length > 0
                        && eventsForm.controls.audience['controls'].linkedin.controls['functions'].value[0] !== null
                        && eventsForm.controls.audience['controls'].linkedin.controls['functions'].value[0] !== ''">
                            <label>Functions: </label>
                            {{eventsForm.controls.audience['controls'].linkedin.controls['functions'].value | arrayJoin : 'name.localized.en_US' : ' - '}}
                        </div>
                        <div *ngIf="eventsForm.controls.audience['controls'].linkedin.controls.industries.value.length > 0
                        && eventsForm.controls.audience['controls'].linkedin.controls.industries.value[0] !== null
                        && eventsForm.controls.audience['controls'].linkedin.controls.industries.value[0] !== ''">
                            <label>Industries: </label>
                            {{eventsForm.controls.audience['controls'].linkedin.controls.industries.value | arrayJoin : 'name.localized.en_US' : ' - '}}
                        </div>
                        <div *ngIf="eventsForm.controls.audience['controls'].linkedin.controls.seniorities.value.length > 0
                        && eventsForm.controls.audience['controls'].linkedin.controls.seniorities.value[0] !== null
                        && eventsForm.controls.audience['controls'].linkedin.controls.seniorities.value[0] !== ''">
                            <label>Seniority: </label>
                            {{eventsForm.controls.audience['controls'].linkedin.controls.seniorities.value | arrayJoin : 'name.localized.en_US' : ' - '}}
                        </div>
                    </div>
                    <div *ngFor="let pinterestNetwork of pinterestNetworks">
                        <app-boards-selector [preSelectedBoards]="(pinterestBoards[pinterestNetwork.network_id]) ? pinterestBoards[pinterestNetwork.network_id] : []" [account]="pinterestNetwork" [multiple]="false" (boardsSelection)="pinterestBoardSelected($event)"></app-boards-selector>
                    </div>
                    <div *ngIf="pinterestNetworks.length > 0">
                        <mat-form-field class="w-100-p" appearance="outline" floatLabel="always">
                            <mat-label>Pinterest Destination Link</mat-label>
                            <input [(ngModel)]="pinterestDestination" [ngModelOptions]="{standalone: true}" matInput placeholder="Ex. https://link.com" value="" type="url" (change)="propertyChanged('destination', $event.currentTarget['value']);">
                        </mat-form-field>
                    </div>
                </form>
            </div>
        </div>
        <div class="posts-preview" [fxFlex]="(events === null || events.length === 0 || events[0].editable) ? '50%' : '100%'" fxFlex.xs="100%" fusePerfectScrollbar>
            <div class="box-container box-media-library" *ngIf="showMediaLibrary" [@slideInRight]="{value:'*',params:{duration:'600ms', x:'60px'}}">
                <i class="fas fa-times-circle" (click)="showMediaLibrary = false"></i>
                <app-media-library (selectedMediaData)="addExternalMedia($event)"></app-media-library>
            </div>
            <div class="box-container" style="background: #aba7a7;">
                <app-post *ngFor="let post of posts" [post]="post"></app-post>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="m-0 p-16 buttons-bar" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-button-toggle-group [disabled]="events !== null && events.length > 0 && !events[0].editable" (valueChange)="publishTypeChanged($event)" [value]="eventsForm.get('publishType').value">
            <mat-button-toggle value="mobile" matTooltip="Publish via mobile notification">
                <i class="fas fa-mobile"></i>
            </mat-button-toggle>
            <mat-button-toggle value="directly"  matTooltip="Publish directly">
                <i class="fas fa-paper-plane"></i>
            </mat-button-toggle>
        </mat-button-toggle-group>
        <div [formGroup]="eventsForm">
            <mat-form-field style="position: absolute;visibility: hidden;" >

                <input [disabled]="events !== null && events.length > 0 && !events[0].editable" matInput [ngxMatDatetimePicker]="picker" formControlName="schedule" [min]="minDate" [max]="" (dateChange)="scheduleDateChanged($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker (opened)="dateSelectorOpened = true;" [disabled]="events !== null && events.length > 0 && !events[0].editable" #picker [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                                         [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'accent'"
                                         [enableMeridian]="true" [disableMinute]="false" [hideTime]="false" [defaultTime]="defaultTime">
                </ngx-mat-datetime-picker>
            </mat-form-field>
            <button [disabled]="events !== null && events.length > 0 && !events[0].editable"
                    mat-button
                class="save-button mat-focus-indicator mat-accent mat-raised-button mat-button-base"
                (click)="picker.open();customValidation()"
                aria-label="Schedule for Later">
                <i class="fas fa-calendar" style="color: white;font-size: 18px;"></i> {{(eventsForm.get('schedule').value !== null && eventsForm.get('schedule').value !== '') ? eventsForm.get('schedule').value.toDateString() + ' at ' + (dateSelectorOpened ? '...' : eventsForm.get('schedule').value.toLocaleTimeString()) : 'Schedule for Later'}}
            </button>
            <button [disabled]="events !== null && events.length > 0 && !events[0].editable"
                    style="margin-left: -4px;border-radius: 0 4px 4px 0;"
                *ngIf="eventsForm.get('schedule').value !== null && eventsForm.get('schedule').value !== ''"
                mat-button
                class="save-button mat-focus-indicator mat-accent mat-raised-button mat-button-base"
                (click)="eventsForm.get('schedule').setValue('');this.postsService.updateAllPosts('postDate', null)">
                <i class="fas fa-calendar-times" style="color: white;font-size: 18px;"></i>
            </button>
        </div>
        <div>
            <button *ngIf="posts !== null && posts.length > 0 && posts[0].id"
                    mat-button
                    class="mat-focus-indicator mat-warn mat-raised-button mat-button-base"
                    (click)="removePosts()"
                    matTooltip="Delete Post(s)" matTooltipPosition="above"
                    aria-label="DELETE">
                <i class="far fa-trash-alt" style="color: #ffffff;font-size: 20px;"></i>
            </button>
            <button [disabled]="(events !== null && events.length > 0 && !events[0].editable) || selectedNetworks.length === 0 || (!eventsForm.get('text').valid)"
                    mat-button
                    [ngClass]="(action !== 'edit' || true) ? 'base-button' : ''"
                class="save-button mat-focus-indicator mat-accent mat-raised-button mat-button-base"
                (click)="postPosts()"
                aria-label="SAVE">
                {{(action === 'edit' && false) ? 'Save' : ((eventsForm.get('schedule').value !== null && eventsForm.get('schedule').value !== '') ? 'Schedule' : 'Post now')}}
            </button>
            <button *ngIf="action !== 'edit' || true" [disabled]="(events !== null && events.length > 0 && !events[0].editable) ||  selectedNetworks.length === 0 || (!eventsForm.get('text').valid)"
                    mat-button
                [matMenuTriggerFor]="beforeMenu"
                class="save-button mat-focus-indicator mat-accent mat-raised-button mat-button-base complementary-button"
                aria-label="">
                <i class="fas fa-caret-down" style="color: #ffffff"></i>
            </button>
            <mat-menu #beforeMenu="matMenu" xPosition="before">
                <button [disabled]="selectedNetworks.length === 0" mat-menu-item (click)="postPosts('save_draft')"><i class="far fa-save"></i> Save draft</button>
                <button [disabled]="selectedNetworks.length === 0" mat-menu-item (click)="postPosts('reuse_networks')"><i class="fas fa-recycle"></i> {{eventsForm.get('schedule').value !== '' ? 'Schedule' : 'Post'}} and reuse networks</button>
                <button [disabled]="selectedNetworks.length === 0" mat-menu-item (click)="postPosts('duplicate')"><i class="far fa-clone"></i> {{eventsForm.get('schedule').value !== '' ? 'Schedule' : 'Post'}} and duplicate</button>
            </mat-menu>
        </div>
    </div>
</div>