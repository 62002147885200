<div [@animate]="{value:'*',params:{y:'50%', duration: '500ms'}}" *ngIf="post !== null && post.type !== null && post.type.toLowerCase() === 'facebook'" class="facebook full-post">
    <div class="sub-full-post">
        <div class="social-body">
            <div class="sub-social-body">
                <div *ngIf="post.link !== null && (post.media === null || post.media.length === 0)" class="link-post-box">
                    <img style="border-radius: 8px 8px 0px 0px;" src="{{post.link.imageUrl}}" />
                    <div class="box-text">
                        <div class="host">{{post.link.host.toUpperCase()}}</div>
                        <div class="title">{{post.link.title}}</div>
                        <div class="description">{{post.link.description}}</div>
                    </div>
                </div>
                <div *ngIf="post.media !== null && post.media.length > 0" class="post-images-block"
                     [ngClass]="[
                     (post.media.length > 3) ? 'count-4' : ((post.media.length === 3) ? 'count-3' : ((post.media.length === 2) ? 'count-2' : 'count-1' )),
                     (post.media[0].height > post.media[0].width) ? 'vertical' : ''
                     ]"
                     style="border-radius: 8px 8px 0px 0px;"
                >
                    <div *ngIf="post.media.length > 1 && post.media[0].type.toLowerCase().indexOf('image') !== -1" class="first-image">
                        <div style="width: 100%">
                            <div style="border-radius: 8px 8px 0px 0px;" class="the-image" [ngStyle]="{backgroundImage: 'url(' + post.media[0].mediaUrl + ')'}"></div>
                        </div>
                    </div>
                    <img style="border-radius: 8px 8px 0px 0px;" *ngIf="post.media.length === 1 && post.media[0].type.toLowerCase().indexOf('image') !== -1" src="{{post.media[0].mediaUrl}}" />
                    <div style="border-radius: 8px 8px 0px 0px;" *ngIf="post.media.length > 1 && post.media[0].type.toLowerCase().indexOf('image') !== -1" class="other-images">
                        <div *ngFor="let img of post.media.slice(1, 4); let i = index;" class="each-image">
                            <div *ngIf="post.media.length > 4 && i === 2" class="hover-more">+{{post.media.length - 4}}</div>
                            <div class="the-image" [ngStyle]="{backgroundImage: 'url(' + img.mediaUrl + ')'}"></div>
                        </div>
                    </div>
                    <div *ngIf="post.media[0].type.toLowerCase().indexOf('video') !== -1 && showPlayButton" class="play-button">
                        <i *ngIf="showPlayButton" class="fas fa-play-circle" (click)="playVideo()"></i>
                    </div>
                    <video [controls]="!showPlayButton" (pause)="showPlayButton = true;" id="videoBox" #videoBox *ngIf="post.media[0].type.toLowerCase().indexOf('video') !== -1" src="{{post.media[0].mediaUrl}}" style="width: 450px;border-radius: 8px 8px 0px 0px;"></video>
                </div>
                <div class="network-name font-weight-600 m-12 mb-4">
                    <label>{{post.network.name}}</label>
                </div>
                <div class="network-hour font-size-12 ml-12">
                    <label>{{post.postDate | dateAgo}} <i class="fas fa-globe-americas font-size-12"></i></label>
                </div>
                <div class="post-text-content p-12 pt-0">
                    <p style="margin-top: 0;margin-bottom: 0;">{{post.text}}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div [@animate]="{value:'*',params:{y:'50%', duration: '500ms'}}" *ngIf="post !== null && post.type !== null && post.type.toLowerCase() === 'instagram'" class="instagram full-post">
    <div class="sub-full-post">
        <div class="social-body">
            <div class="sub-social-body">
                <div class="social-body-header">
                    <div class="social-header-network">
                        <div style="display: flex;">
                            <div class="network-logo">
                                <div>
                                    <img class="sc-fhqXRA jyIiAf" src="{{post.network.picture}}" width="40px" height="40px" alt="Palm Beach Harvest">
                                </div>
                            </div>
                            <div style="margin-right: 8px;">
                                <p style="font-size: 16px;margin: 0px;line-height: 30px;">
                                    <span style="font-weight: 700;color: rgb(36, 31, 33);">@{{post.network.name}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="header-dots">
                            <div style="
                            background-image: url(https://i.hootsuite.com/assets/plancreate/ig-menu.svg);
                            background-size: 100%;
                            height: 24px;
                            width: 24px;
                            background-repeat: no-repeat;
                            background-position: center center;"></div>
                        </div>
                    </div>
                </div>
                <div class="post-images-block count-1" *ngIf="post.media !== null && post.media[0]">
                    <img *ngIf="post.media.length === 1 && post.media[0].type.toLowerCase().indexOf('image') !== -1" src="{{post.media[0].mediaUrl}}">
                    <mat-carousel *ngIf="post.media !== null && post.media.length > 1 && post.media[0].type.toLowerCase().indexOf('image') !== -1"
                                  timings="250ms ease-in"
                                  [autoplay]="true"
                                  interval="5000"
                                  color="accent"
                                  maxWidth="auto"
                                  proportion="100"
                                  slides="5"
                                  [loop]="false"
                                  [hideArrows]="true"
                                  [hideIndicators]="false"
                                  [useKeyboard]="true"
                                  [useMouseWheel]="false"
                                  orientation="ltr"
                    >
                        <mat-carousel-slide
                            #matCarouselSlide
                            *ngFor="let slide of post.media; let i = index"
                            [image]="slide.mediaUrl"
                            overlayColor="#00000040"
                            [hideOverlay]="false"
                        ></mat-carousel-slide>
                    </mat-carousel>
                    <div *ngIf="post.media[0].type.toLowerCase().indexOf('video') !== -1 && showPlayButton" class="play-button">
                        <i *ngIf="showPlayButton" class="fas fa-play-circle" (click)="playVideo()"></i>
                    </div>
                    <video [controls]="!showPlayButton" (pause)="showPlayButton = true;" id="videoBox" #videoBox *ngIf="post.media[0].type.toLowerCase().indexOf('video') !== -1" src="{{post.media[0].mediaUrl}}" style="width: 450px"></video>
                </div>
                <div class="post-bottom">
                    <div class="bottom-boxes">
                        <div class="shares heart"></div>
                        <div class="shares comment"></div>
                        <div class="shares share"></div>
                    </div>
                    <div class="bottom-boxes bookmark"></div>
                </div>
                <div style="padding: 5px 0px 0px;">
                    <p class="post-text-content">{{post.text}}</p>
                    <p class="post-time">{{(post.editable) ? 'Just now' : post.postDate | dateAgo}}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div [@animate]="{value:'*',params:{y:'50%', duration: '500ms'}}" *ngIf="post !== null && post.type !== null && post.type.toLowerCase() === 'linkedin'" class="linkedin full-post">
    <div class="sub-full-post">
        <div class="social-body">
            <div class="sub-social-body">
                <div class="social-body-header">
                    <div class="social-header-network">
                        <div style="display: flex;">
                            <div class="network-logo">
                                <div>
                                    <img class="sc-fhqXRA jyIiAf" src="{{post.network.picture}}" width="48px" height="48px" alt="Palm Beach Harvest">
                                </div>
                            </div>
                            <div style="margin-right: 8px;">
                                <p style="font-size: 16px;margin: 0px;">
                                    <span style="font-weight: 700;color: rgb(36, 31, 33);">{{post.network.name}}</span>
                                </p>
                                <p style="margin-bottom: 0;font-size: 13px;color: rgb(93, 99, 102);margin: 0px;">
                                    <a style="color: unset;" matTooltipPosition="above" target="_blank" matTooltip="View post" href="{{(post.postLink) ? post.postLink : 'javascript::;'}}">
                                        {{(post.editable && post.status !== "scheduled") ? 'Just now' : post.postDate | dateAgo}}
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div class="header-dots">
                            <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
                                <path d="M10,12.5 C8.61,12.5 7.5,11.38 7.5,10 C7.5,8.61 8.61,7.5 10,7.5 C11.38,7.5 12.5,8.61 12.5,10 C12.5,11.38 11.38,12.5 10,12.5 Z M2.5,12.5 C1.11,12.5 0,11.38 0,10 C0,8.61 1.11,7.5 2.5,7.5 C3.88,7.5 5,8.61 5,10 C5,11.38 3.88,12.5 2.5,12.5 Z M17.5,12.5 C16.11,12.5 15,11.38 15,10 C15,8.61 16.11,7.5 17.5,7.5 C18.88,7.5 20,8.61 20,10 C20,11.38 18.88,12.5 17.5,12.5 Z">
                                </path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="post-text-content">
                    <p style="margin-top: 0;margin-bottom: 0;overflow-y: hidden;height: auto" >
                        {{post.text}}
                    </p>
                </div>
                <div *ngIf="post.link !== null && (post.media === null || post.media.length === 0)" class="link-post-box">
                    <img src="{{post.link.imageUrl}}" />
                    <div class="box-text">
                        <div class="host">{{post.link.host.toUpperCase()}}</div>
                        <div class="title">{{post.link.title}}</div>
                        <div class="description">{{post.link.description}}</div>
                    </div>
                </div>
                <div *ngIf="post.media !== null && post.media.length > 0" class="post-images-block"
                     [ngClass]="[
                     (post.media.length > 4) ? 'count-5' : ((post.media.length === 4) ? 'count-4' : ((post.media.length === 3) ? 'count-3' : ((post.media.length === 2) ? 'count-2' : 'count-1' ))),
                     (post.media[0].height > post.media[0].width) ? 'vertical' : ''
                     ]"
                >
                    <div *ngIf="post.media.length > 1 && post.media[0].type.toLowerCase().indexOf('image') !== -1" class="first-image">
                        <div [ngStyle]="{width: (post.media.length > 4) ? '50%' : '100%'}" *ngFor="let img of post.media.slice(0, (post.media.length < 5) ? 1 : 2); let i = index;">
                            <div class="the-image" [ngStyle]="{backgroundImage: 'url(' + img.mediaUrl + ')'}"></div>
                        </div>
                    </div>
                    <img *ngIf="post.media.length === 1 && post.media[0].type.toLowerCase().indexOf('image') !== -1" src="{{post.media[0].mediaUrl}}" />
                    <div *ngIf="post.media.length > 1 && post.media[0].type.toLowerCase().indexOf('image') !== -1" class="other-images">
                        <div *ngFor="let img of post.media.slice((post.media.length < 5) ? 1 : 2, (post.media.length < 5) ? 4 : 5); let i = index;" class="each-image">
                            <div *ngIf="post.media.length > 5 && i === 2" class="hover-more">+{{post.media.length - 5}}</div>
                            <div class="the-image" [ngStyle]="{backgroundImage: 'url(' + img.mediaUrl + ')'}"></div>
                        </div>
                    </div>
                    <div *ngIf="post.media[0].type.toLowerCase().indexOf('video') !== -1 && showPlayButton" class="play-button">
                        <i *ngIf="showPlayButton" class="fas fa-play-circle" (click)="playVideo()"></i>
                    </div>
                    <video [controls]="!showPlayButton" (pause)="showPlayButton = true;" id="videoBox" #videoBox *ngIf="post.media[0].type.toLowerCase().indexOf('video') !== -1" src="{{post.media[0].mediaUrl}}" style="width: 100%"></video>
                </div>
            </div>
        </div>
    </div>
</div>

<div [@animate]="{value:'*',params:{y:'50%', duration: '500ms'}}" *ngIf="post !== null && post.type !== null && post.type.toLowerCase() === 'twitter'" class="twitter full-post">
    <div class="sub-full-post">
        <div class="social-body">
            <div class="sub-social-body">
                <div class="body-box">
                    <div class="network-logo">
                        <div>
                            <img *ngIf="post.from === null" class="sc-fhqXRA jyIiAf" src="{{post.network.picture}}" width="36px" height="36px" alt="Palm Beach Harvest">
                            <img *ngIf="post.from !== null" class="sc-fhqXRA jyIiAf" src="{{post.from.profile_image_url}}" width="36px" height="36px" alt="Palm Beach Harvest">
                        </div>
                    </div>
                </div>
                <div class="body-box">
                    <div class="body-title">
                        <div class="title">{{(post.from === null) ? post.network.name : post.from.name}}</div>
                        <div class="user">@{{(post.from === null) ? post.network.description : post.from.screen_name}}</div>
                        <div class="time">•
                            <a style="color: unset;" matTooltipPosition="above" target="_blank" matTooltip="View post" href="{{(post.postLink) ? post.postLink : 'javascript::;'}}">
                                {{(post.editable && post.status !== "scheduled") ? 'Just now' : post.postDate | dateAgo}}
                            </a>
                        </div>
                    </div>
                    <div class="post-text-content">
                        <p>{{post.text}}</p>
                    </div>
                    <div *ngIf="post.media !== null && post.media.length > 0" class="post-images-block"
                         [ngClass]="[
                     (post.media.length > 3) ? 'count-4' : ((post.media.length === 3) ? 'count-3' : ((post.media.length === 2) ? 'count-2' : 'count-1' )),
                     (post.media[0].height > post.media[0].width) ? 'vertical' : ''
                     ]"
                    >
                        <div *ngIf="post.media.length > 1" class="first-image">
                            <div style="width: 100%">
                                <div class="the-image" [ngStyle]="{backgroundImage: 'url(' + post.media[0].mediaUrl + ')'}"></div>
                            </div>
                        </div>
                        <img *ngIf="post.media.length === 1 && post.media[0].type.toLowerCase().indexOf('video') === -1" src="{{post.media[0].mediaUrl}}" />
                        <div *ngIf="post.media.length > 1" class="other-images">
                            <div *ngFor="let img of post.media.slice(1, 4); let i = index;" class="each-image">
                                <div class="the-image" [ngStyle]="{backgroundImage: 'url(' + img.mediaUrl + ')'}"></div>
                            </div>
                        </div>
                        <div *ngIf="post.media[0].type.toLowerCase().indexOf('video') !== -1 && showPlayButton" class="play-button">
                            <i *ngIf="showPlayButton" class="fas fa-play-circle" (click)="playVideo()"></i>
                        </div>
                        <video [controls]="!showPlayButton" (pause)="showPlayButton = true;" id="videoBox" #videoBox *ngIf="post.media[0].type.toLowerCase().indexOf('video') !== -1" src="{{post.media[0].mediaUrl}}" style="width: 100%"></video>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>