import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacebookComponent } from './facebook/facebook.component';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from "@angular/material/select";
import {MatChipsModule} from "@angular/material/chips";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { LinkedinComponent } from './linkedin/linkedin.component';



@NgModule({
  declarations: [FacebookComponent, LinkedinComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatToolbarModule,
        FuseSharedModule,
        MatDialogModule,
        MatButtonModule,
        MatSelectModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule
    ]
})
export class AudienceModule { }
