<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Add Network</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 pb-0 m-0" fusePerfectScrollbar>
        <div class="container">
            <div id="content">
                <div id="main-content">
                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <i class="fab fa-facebook" style="font-size: 17px;"></i>
                                Facebook
                            </ng-template>
                            <div class="p-16">
                                <div style="margin-left: 10px">
                                    <b>Add Facebook Pages, groups, and profile.</b>
                                </div>
                                <div style="margin-left: 10px">
                                    <p>Connect with Facebook and click OK when prompted to authorize access to your account. Then you’ll be able to add your:</p>
                                    <ul>
                                        <li>Facebook Pages</li>
                                        <li>Facebook Groups</li>
                                        <li>Facebook Profile</li>
                                    </ul>
                                </div>
                                <div style="margin-left: 10px">
                                    <button (click)="signInWithFB()"  mat-raised-button class="facebook">
                                        <i class="fab fa-facebook"></i>
                                        Connect with Facebook
                                    </button>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <i class="fab fa-x-twitter" style="font-size: 17px;"></i>
                                Twitter
                            </ng-template>
                            <div class="p-16">
                                <div style="margin-left: 10px">
                                    <b>Add Twitter Profiles</b>
                                </div>
                                <div style="margin-left: 10px">
                                    <p>To allow SocialForce access to your Twitter accounts, you must first give authorization from Twitter.com</p>
                                </div>
                                <div style="margin-left: 10px">
                                    <a target="_blank" href="{{twitterV2OauthUrl}}" mat-raised-button class="instagram">
                                        <i class="fab fa-x-twitter" style="font-size: 17px;"></i>
                                        <span>Connect with Twitter</span>
                                    </a>
<!--                                    <button (click)="signInWithTwitterOld()" mat-raised-button class="instagram">-->
<!--                                        <i class="fab fa-twitter" style="font-size: 17px;"></i>-->
<!--                                        <span>Connect with Twitter</span>-->
<!--                                    </button>-->
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <i class="fab fa-instagram" style="font-size: 17px;"></i>
                                Instagram
                            </ng-template>
                            <div class="p-16">
                                <div style="margin-left: 10px">
                                    <b>Add Instagram Profiles</b>
                                </div>
                                <div style="margin-left: 10px">
                                    <p>To allow SocialForce access to your Instagram account, you must first give authorization from the Instagram website</p>
                                </div>
                                <div style="margin-left: 10px">
                                    <button (click)="signInWithInstagram()" mat-raised-button class="instagram">
                                        <i class="fab fa-instagram"></i>
                                        Connect with Instagram
                                    </button>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <i class="fab fa-linkedin-in" style="font-size: 17px;"></i>
                                Linkedin
                            </ng-template>
                            <div class="p-16">
                                <div style="margin-left: 10px">
                                    <b>Add Linkedin Profiles</b>
                                </div>
                                <div style="margin-left: 10px">
                                    <p>To allow SocialForce access to your Linkedin account, you must first give authorization from the Linkedin website</p>
                                </div>
                                <div style="margin-left: 10px">
                                    <button (click)="signInWithIN()" mat-raised-button class="instagram">
                                        <i class="fab fa-linkedin-in"></i>
                                        Connect with Linkedin
                                    </button>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <i class="fab fa-pinterest" style="font-size: 17px;"></i>
                                Pinterest
                            </ng-template>
                            <div class="p-16">
                                <div style="margin-left: 10px">
                                    <b>Add Pinterest Profiles</b>
                                </div>
                                <div style="margin-left: 10px">
                                    <p>To allow SocialForce access to your Pinterest account, you must first give authorization from pinterest.com</p>
                                </div>
                                <div style="margin-left: 10px">
                                    <button (click)="signInWithPinterest()" mat-raised-button class="pinterest">
                                        <i class="fab fa-pinterest" style="font-size: 17px;"></i>
                                        <span>Connect with Pinterest</span>
                                    </button>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <i class="fab fa-youtube" style="font-size: 17px;"></i>
                                Youtube
                            </ng-template>
                            <div class="p-16">
                                <div style="margin-left: 10px">
                                    <b>Add Youtube Profiles</b>
                                </div>
                                <div style="margin-left: 10px">
                                    <p>To allow SocialForce access to your Youtube account, you must first give authorization from google.com</p>
                                </div>
                                <div style="margin-left: 10px">
                                    <button (click)="signInWithGoogle()" mat-raised-button class="youtube">
                                        <i class="fab fa-youtube" style="font-size: 17px;"></i>
                                        <span>Connect with Youtube</span>
                                    </button>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button
            mat-button color="primary"
            class="save-button mat-button-base"
            (click)="matDialogRef.close()"
            aria-label="CLOSE">
            CLOSE
        </button>
        <!--<button-->
            <!--mat-button-->
            <!--class="save-button mat-focus-indicator mat-accent mat-raised-button mat-button-base"-->
            <!--(click)="matDialogRef.close({-->
                <!--minimumAge: form.get('minimumAge').value,-->
                <!--countries: selectedList.countries,-->
                <!--regions: selectedList.regions,-->
                <!--cities: selectedList.cities,-->
                <!--zipCodes: selectedList.zipCodes-->
                <!--})"-->
            <!--aria-label="SAVE">-->
            <!--SAVE-->
        <!--</button>-->

    </div>
</div>
