import {AfterViewInit, Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Comment} from '../../../models/comment';
import {ChatService} from '../../../main/apps/chat/chat.service';
import {PrivateMessageService} from './private-message.service';
import {Message} from "../../../models/message";

@Component({
  selector: 'app-private-message',
  templateUrl: './private-message.component.html',
  styleUrls: ['./private-message.component.scss']
})
export class PrivateMessageComponent implements OnInit, AfterViewInit {
    comment: Comment | Message[];
    network: any;
    contactId: any;
    @ViewChild('privateMessage') privateMessage: ElementRef<HTMLInputElement>;
    @ViewChild('emojiBlock') emojiBlock: ElementRef<HTMLInputElement>;
    showEmojis = false;
    showBar = true;
    type = 'comment';
    sendLoading = false;
    text = '';

    constructor(
        public dialogRef: MatDialogRef<PrivateMessageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private pmService: PrivateMessageService,
        private _chatService: ChatService
    ) {
        if (typeof data.comment.answers === 'undefined') {
            this.comment = data.comment.reverse();
        } else {
            this.comment = data.comment;
        }
        this.network = data.network;
        this.contactId = data.contactId;
    }

    ngOnInit(): void {
        this.type = (this.comment instanceof Comment) ? 'comment' : 'messages';
        // this._chatService.getEmoji().subscribe(
        //     emoji => {
        //         this.privateMessage.nativeElement.value = this.privateMessage.nativeElement.value + emoji;
        //     },
        //     error => console.log(error)
        // );
    }

    // @HostListener('document:click', ['$event']) onDocumentClick(event): void {
    //     if (event.path[0].className.indexOf('far fa-grin-alt private-text-emoji') === -1) {
    //         if (!((this.emojiBlock.nativeElement as HTMLElement).contains(event.srcElement as HTMLElement))) {
    //             this.showEmojis = false;
    //         }
    //     } else {
    //         this.showEmojis = true;
    //     }
    // }

    // emojiSelection(event: any): void {
    //     this.privateMessage.nativeElement.value = this.privateMessage.nativeElement.value + event.emoji.native;
    // }

    ngAfterViewInit(): void {
        // this.showBar = false;
    }

    sendPrivateMessage(): void {
        this.sendLoading = true;
        this.pmService.sendMessage(this.network.network_type, {
            message: this.text,
            network_id: this.network.network_id,
            user_id: this.contactId
        }).subscribe(
            response => { this.sendLoading = false; this.dialogRef.close({

            }); },
            error => { console.log(error); this.sendLoading = false; this.dialogRef.close({

            }); }
        );
    }

    emojiClicked(event: MouseEvent): void {
        this._chatService.onEmojisOpen.next(event);
    }

    validateReplyWindow(): boolean {
        if (this.comment instanceof Comment) {
            return false;
        }
        // @ts-ignore
        return new Date() - new Date(this.comment[this.comment.length - 1]['created_time']) > 24 * 60 * 60 * 1000;
    }

}
