import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Comment} from './../../../models/comment';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {Network} from "../../../models/network";

@Injectable({
  providedIn: 'root'
})
export class PostCommentsService {

    linkedinUrl = '/linkedin-v2/';
    onUserTaggedToInput: Subject<Comment> = new Subject<Comment>();
    constructor(private _httpClient: HttpClient) { }

    sendCommentAnswer(comment: Comment): Observable<Comment> {
        return this._httpClient.post<Comment>('/test', comment);
    }

    sendFbComment(comment: any): Observable<any> {
        return this._httpClient.post<Comment>(`${environment.apiUrl}fb-comment/`, comment);
    }

    removeFbComment(data: any): Observable<any> {
        return this._httpClient.post<Comment>(`${environment.apiUrl}fb-remove-comment/`, data);
    }

    sendInComment(comment: any): Observable<any> {
        return this._httpClient.post<Comment>(`${environment.apiUrl}in-comment/`, comment);
    }

    sendTwitterComment(comment: any): Observable<any> {
        return this._httpClient.post<Comment>(`${environment.apiUrl}twitter/comments`, comment);
    }

    setUserTaggedToInput(data: Comment): void {
        this.onUserTaggedToInput.next(data);
    }

    getUserTaggedToInput(): Observable<Comment> {
        return this.onUserTaggedToInput.asObservable();
    }

    getLinkedinPostCommments(network: Network, postId: string): Observable<any> {
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + network.auth_token
        //     })
        // };
        // return this._httpClient.get(this.linkedinUrl + 'socialActions/' + postId
        //     + '/comments?projection=(elements(*(*,actor~(*,logoV2(original~digitalmediaAsset:playableStreams),profilePicture(displayImage~:playableStreams)))))',
        //     httpOptions).pipe(map(response => this.fullFillLinkedinPostComments(response['elements'], network)));

        return this._httpClient.get(`${environment.apiUrl}` + 'linkedin/post/comments/' + network.network_id + '/' + postId)
            .pipe(map(response => this.fullFillLinkedinPostComments(response['elements'], network)));
    }

    fullFillLinkedinPostComments(comments: Array<any>, network: Network): Array<Comment> {
        const commentsFormated: Array<Comment> = [];
        for (const comm of comments) {
            const comment = new Comment();
            comment.id = comm['$URN'];
            comment.text = comm.message.text;
            comment.contactId = comm.actor;
            comment.postDate = comm.lastModified.time;
            if (comm.actor.indexOf('person') !== -1) {
                comment.contactName = comm['actor~'].localizedFirstName + ' ' + comm['actor~'].localizedLastName;
                comment.contactAvatar = comm['actor~'].profilePicture['displayImage~'].elements[0].identifiers[0].identifier;
            } else {
                comment.contactName = comm['actor~'].localizedName;
                comment.contactAvatar = comm['actor~'].logoV2['original~'].elements[0].identifiers[0].identifier;
            }
            if (typeof comm.commentsSummary !== 'undefined' && typeof comm.commentsSummary.selectedComments !== 'undefined' && comm.commentsSummary.selectedComments.length > 0) {
                this.getLinkedinPostCommments(network, comm['$URN']).subscribe(
                    response => comment.answers = response,
                    error => console.log(error)
                );
            }
            if (typeof comm.likesSummary !== 'undefined' && typeof comm.likesSummary.selectedLikes !== 'undefined' && comm.likesSummary.selectedLikes.length > 0) {
                for (const like of comm.likesSummary.selectedLikes) {
                    if (like.indexOf(network.network_id) !== -1) {
                        comment.liked = true;
                        break;
                    }
                }
            }

            commentsFormated.push(comment);
        }
        return commentsFormated;
    }

    sendLinkedinPostCommment(network: Network, resourceId: string, comment: Comment): Observable<any> {
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + token
        //     })
        // };
        const data = {
            actor: comment.contactId,
            //  object: 'urn:li:activity:6631349431612559360',
            message: {
                text: comment.text
            }
        };

        if (resourceId.indexOf('activity') !== -1) {
            data['parentComment'] = resourceId;
        }
        // return this._httpClient.post(this.linkedinUrl + 'socialActions/' + resourceId + '/comments', data, httpOptions);
        return this._httpClient.post(`${environment.apiUrl}` + 'linkedin/post/comment/' + network.network_id + '/' + resourceId, data);
    }

    deleteLinkedinComment(network: Network, postId: string, commentId: string, actorId: string): Observable<any> {
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + token
        //     })
        // };
        // return this._httpClient.delete(this.linkedinUrl + 'socialActions/' + postId + '/comments/' + commentId.split(',')[1].replace(')', '') + '?actor=' + actorId, httpOptions);
        return this._httpClient.delete(`${environment.apiUrl}` + 'linkedin/post/comment/' + network.network_id + '/' + postId + '/'
            + commentId.split(',')[1].replace(')', '') + '/' + actorId);
    }

    likeComment(network: Network, commentURN: string, actor: string, postId: string): Observable<any> {
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + token
        //     })
        // };
        const data = {
            actor: actor,
            object: postId
        };
        // return this._httpClient.post(this.linkedinUrl + 'socialActions/' + commentURN + '/likes', data, httpOptions);
        return this._httpClient.post(`${environment.apiUrl}` + 'linkedin/post/comment/like/' + network.network_id + '/' + commentURN, data);
    }

    unLikeComment(network: Network, commentURN: string, actor: string): Observable<any> {
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + token
        //     })
        // };

        return this._httpClient.delete(`${environment.apiUrl}` + 'linkedin/post/comment/like/' + network.network_id + '/' + commentURN + '/'
            + actor);
    }
}
