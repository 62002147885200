<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
    <mat-label>Select Board(s) from {{account.name}} account</mat-label>
    <mat-chip-list #chipList aria-label="Fruit selection" [multiple]="multiple">
        <mat-chip style="line-height: 16px;" *ngIf="board !== null && !multiple" [removable]="multiple" (removed)="removeBoard(board)">
            <i *ngIf="board.privacy === 'SECRET'" class="fas fa-lock" style="font-size: 14px;margin-top: 10px;"></i> {{board.name}}
            <i *ngIf="multiple" style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
        </mat-chip>
        <ng-container *ngIf="multiple">
            <mat-chip  style="line-height: 16px;" *ngFor="let board of selectedBoards"
                       [selectable]="true"
                       [value]="board.id"
                       [removable]="true" (removed)="removeBoard(board)">
                <i *ngIf="board.privacy === 'SECRET'" class="fas fa-lock" style="font-size: 14px;margin-top: 10px;"></i> {{board.name}}
                <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
            </mat-chip>
        </ng-container>
        <input
            #boardsInput
            [formControl]="boardsInputControl"
            [matChipInputAddOnBlur]="false"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedBoard($event)">
        <mat-option *ngFor="let board of filteredBoards | async" [value]="board">
            <i *ngIf="board.privacy === 'SECRET'" class="fas fa-lock" style="font-size: 14px;margin-top: 10px;"></i> {{board.name}}
        </mat-option>
    </mat-autocomplete>
    <mat-spinner diameter="20" class="input-loader" style="position: absolute;right: 0;top: 14px;" *ngIf="inputLoader"></mat-spinner>
</mat-form-field>
