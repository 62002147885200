import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ManageNetworksService implements Resolve<any> {
    networks: any;
    onNetworksUpdated: Subject<any>;
    onNetworksPassedUpdated: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onNetworksUpdated = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getNetworksSync()
            ]).then(
                ([networks]: [any]) => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get networks sync
     *
     * @returns {Promise<any>}
     */
    getNetworksSync(): Promise<any>
    {
        return new Promise((resolve, reject) => {

            this._httpClient.get('api/calendar/networks')
                .subscribe((response: any) => {
                    console.log('api/calendar/networks: ', response);
                    this.networks = response.data;
                    this.onNetworksUpdated.next(this.networks);
                    resolve(this.networks);
                }, reject);
        });
    }

    /**
     * Get networks
     *
     * @returns {Promise<any>}
     */
    getNetworks(type = null): Observable<any>
    {
        return this._httpClient.get(`${environment.apiUrl}all-networks/` + (type !== null ? (type) : ''));
    }

    /**
     * Update networks
     *
     * @param networks
     * @returns {Promise<any>}
     */
    updateNetworks(networks): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/calendar/networks', {
                id  : 'networks',
                data: [...networks]
            })
                .subscribe((response: any) => {
                    this.getNetworksSync();
                }, reject);
        });
    }
}
