import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {tap} from 'rxjs/operators';

@Injectable()
export class SocialMediaManagementMembersService implements Resolve<any>
{
    members: any[];
    onMembersChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onMembersChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getMembers()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get members
     *
     * @returns {Promise<any>}
     */
    getMembers(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}all-members/`)
                .subscribe((response: any) => {
                    this.members = response['members'];
                    this.onMembersChanged.next(this.members);
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get members
     *
     * @returns {Promise<any>}
     */
    getMembers2(): Observable<any>
    {
        return this._httpClient.get(`${environment.apiUrl}all-members/`);
    }

    removeMember(memberId: number): Observable<any> {
        return this._httpClient.delete(`${environment.apiUrl}members/` + memberId).pipe(
            tap(item => { this.members.splice(this.members.findIndex(i => i.id === memberId), 1); this.onMembersChanged.next(this.members); })
        );
    }
}
