<mat-toolbar style="height: auto;">

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="center center" fxFlex>

        <div fxLayout="row" fxLayoutAlign="center center" fxHide fxShow.gt-xs>
            <span style="padding: 0 16px;font-size: 14px;">2016 - {{year}} © Gale Force Digital, Inc.</span>
            <span>&bull;</span>
            <a style="line-height: 16px;" mat-button routerLink="/pages/terms">Terms of Service</a>
            <span>&bull;</span>
            <a style="line-height: 16px;" mat-button routerLink="/pages/privacy-policy">Privacy Policy</a>
        </div>

    </div>

</mat-toolbar>
