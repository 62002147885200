import {Component, Directive, EventEmitter, HostBinding, HostListener, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

export class FileSnippet {
    constructor(
        public id: number = null,
        public source: string = '',
        public previewUrl: string = '',
        public file: File = null,
        public type: string = null,
        public loading = true,
        public process: number = 0,
        public width: number = null,
        public height: number = null,
        public mediaErrors = {instagram: [], facebook: [], linkedin: []},
        public mediaInfo: any = null
    ) {}
}

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
    @Output() uploadedFile = new EventEmitter<FileSnippet>();
    @Input() acceptTypes = '*';
    @Input() serverActions: {
        uploader: (file: FileSnippet, load: (source: string | number, previewUrl: string, selectedFiles: Array<FileSnippet>, index: number) => void, index: number ) => void,
        loader: (file: File, load: (source: string | number, previewUrl: string, selectedFiles: Array<FileSnippet>, index: number) => void) => void,
        remove: (index: number) => void
    } = {uploader: null, loader: null, remove: null};
    @Input() public selectedFiles: Array<FileSnippet> = [];
    @Input() hideUploadsList = false;
    @Output() filesOrderChanged = new EventEmitter<{files: Array<FileSnippet>, previousIndex: number, currentIndex: number}>();
    @Output() fileEdit = new EventEmitter<number>();

    constructor(){}

    ngOnInit(): void {
    }

    processFile(fileInput: HTMLInputElement): void {
        // @ts-ignore
        for (const f of fileInput.files) {
            const file: File = f;
            const reader = new FileReader();
            const newFile = new FileSnippet(null, '', '', file, file.type);

            this.uploadedFile.emit(newFile);
            reader.addEventListener('load', (event: any) => {
                const index  = this.selectedFiles.push(newFile);
                if (typeof this.serverActions.uploader !== 'undefined' && this.serverActions.uploader !== null) {
                    // this.serverActions.uploader(newFile, this.loadFile, index - 1);
                } else {
                    this.selectedFiles[index - 1].source = event.target.result;
                }
                fileInput.value = '';
            });
            reader.readAsDataURL(file);
        }
    }

    loadFile(source: string | number, previewUrl: string, selectedFiles: Array<FileSnippet>, index: number): void {
        if (typeof source === 'string') {
            selectedFiles[index].source = source;
            if (previewUrl !== null && previewUrl !== '') {
                selectedFiles[index].previewUrl = previewUrl;
            }
            selectedFiles[index].loading = false;
        } else if (typeof source === 'number') {
            selectedFiles[index].process = source;
        }
    }

    uploadFile(data: {fileSnippet: FileSnippet, index: number}): void {
        if (typeof this.serverActions.uploader !== 'undefined' && this.serverActions.uploader !== null) {
            this.serverActions.uploader(data.fileSnippet, this.loadFile, data.index);
        } else {
            const reader = new FileReader();
            reader.addEventListener('load', (event: any) => {
                this.selectedFiles[data.index].source = event.target.result;
            });
            reader.readAsDataURL(data.fileSnippet.file);
        }
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.selectedFiles, event.previousIndex, event.currentIndex);
        this.filesOrderChanged.emit({files: this.selectedFiles, previousIndex: event.previousIndex, currentIndex: event.currentIndex});
    }

    removeFile(event: number): void {
        this.selectedFiles.splice(event, 1);
        if (this.serverActions.remove !== null) {
            this.serverActions.remove(event);
        }
    }

    onFileDropped(event: any): void {
        this.processFile({files: Object.values(event)} as unknown as HTMLInputElement);
    }
}

@Directive({selector: '[dragDropFile]'})
export class DragDropFileDirective {
    @HostBinding('class.fileover') fileOver: boolean;
    @Output() fileDropped = new EventEmitter<any>();

    // Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = true;
    }

    // Dragleave listener
    @HostListener('dragleave', ['$event']) onDragLeave(evt): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
    }

    @HostListener('drop', ['$event']) ondrop(evt): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
