export const orderStatuses = [
    {
        id   : 1,
        name : 'Awaiting check payment',
        color: 'blue-500'
    },
    {
        id   : 2,
        name : 'Payment accepted',
        color: 'green-500'
    },
    {
        id   : 3,
        name : 'Preparing the order',
        color: 'orange-500'
    },
    {
        id   : 4,
        name : 'Shipped',
        color: 'purple-500'
    },
    {
        id   : 5,
        name : 'Delivered',
        color: 'green-800'
    },
    {
        id   : 6,
        name : 'Canceled',
        color: 'pink-500'
    },
    {
        id   : 7,
        name : 'Refunded',
        color: 'red-500'
    },
    {
        id   : 8,
        name : 'Payment error',
        color: 'red-900'
    },
    {
        id   : 9,
        name : 'On pre-order (paid)',
        color: 'purple-300'
    },
    {
        id   : 10,
        name : 'Awaiting bank wire payment',
        color: 'blue-500'
    },
    {
        id   : 11,
        name : 'Awaiting PayPal payment',
        color: 'blue-500'
    },
    {
        id   : 12,
        name : 'Remote payment accepted',
        color: 'green-500'
    },
    {
        id   : 13,
        name : 'On pre-order (not paid)',
        color: 'purple-300'
    },
    {
        id   : 14,
        name : 'Awaiting Cash-on-delivery payment',
        color: 'blue-500'
    }
];
