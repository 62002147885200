import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayJoin',
  pure: false
})
export class ArrayJoinPipe implements PipeTransform {

  transform(array: Array<any>, property: string, joiner: string = ''): string {
      if (typeof property !== 'undefined' && property !== '') {
          const properties = property.split('.');
          return array.map(item => this.getPropertyValue(item, properties, 0)).join(joiner);
      } else {
          return null;
      }
  }

  getPropertyValue(item: any, properties: string[], pos: number): string {
      if (pos < properties.length - 1) {
          return this.getPropertyValue(item[properties[pos]], properties, pos + 1);
      } else {
          return item[properties[pos]];
      }
  }

}
