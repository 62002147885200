<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
    <mat-label>Select Network</mat-label>
    <mat-chip-list #chipList aria-label="Fruit selection" [multiple]="multiple">
        <mat-chip style="line-height: 16px;" *ngIf="network !== null && !multiple" [removable]="multiple" (removed)="removeNetwork(network)">
            <div style="padding: 5px" class="avatar-wrapper" fxFlex="0 1 auto" fxLayoutAlign="center center">
                <img class="avatar" [src]="(network.picture !== '') ? network.picture : 'assets/images/agency.png'"
                     alt="{{network.name}}"/>
                <mat-icon style="color: #0A66C2" *ngIf="network.network_type === 'FACEBOOK'" class="s-16 status">thumb_up</mat-icon>
                <mat-icon style="color: hotpink" *ngIf="network.network_type === 'INSTAGRAM'" class="s-16 status">favorite</mat-icon>
                <i style="color: #00A0DC;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'LINKEDIN'" class="s-16 status fab fa-linkedin"></i>
                <i style="color: #000000;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'TWITTER'" class="s-16 status fab fa-x-twitter"></i>
            </div>
            {{network.network_type === 'INSTAGRAM' ? '@' + network.name : network.name}}
            <i *ngIf="multiple" style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
        </mat-chip>
        <ng-container *ngIf="multiple">
        <mat-chip  style="line-height: 16px;" *ngFor="let network of selectedNetworks"
                  [selectable]="true"
                  [value]="network.id"
                  [removable]="true" (removed)="removeNetwork(network)">
            <div style="padding: 5px" class="avatar-wrapper" fxFlex="0 1 auto" fxLayoutAlign="center center">
                <img class="avatar" [src]="(network.picture !== '') ? network.picture : 'assets/images/agency.png'"
                     alt="{{network.name}}"/>
                <mat-icon style="color: #0A66C2" *ngIf="network.network_type === 'FACEBOOK'" class="s-16 status">thumb_up</mat-icon>
                <mat-icon style="color: hotpink" *ngIf="network.network_type === 'INSTAGRAM'" class="s-16 status">favorite</mat-icon>
                <i style="color: #00A0DC;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'LINKEDIN'" class="s-16 status fab fa-linkedin"></i>
                <i style="color: #000000;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'TWITTER'" class="s-16 status fab fa-x-twitter"></i>
            </div>
            {{network.network_type === 'INSTAGRAM' ? '@' + network.name : network.name}}
            <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
        </mat-chip>
        </ng-container>
        <input
            #networkInput
            [formControl]="networkInputControl"
            [matChipInputAddOnBlur]="false"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedNetwork($event)">
        <mat-option *ngFor="let network of filteredNetworks | async" [value]="network">
            <div style="padding: 5px" class="avatar-wrapper" fxFlex="0 1 auto" fxLayoutAlign="center center">
                <img class="avatar" [src]="(network.picture !== '') ? network.picture : 'assets/images/agency.png'"
                     alt="{{network.name}}"/>
                <mat-icon style="color: #0A66C2" *ngIf="network.network_type === 'FACEBOOK'" class="s-16 status">thumb_up</mat-icon>
                <mat-icon style="color: hotpink" *ngIf="network.network_type === 'INSTAGRAM'" class="s-16 status">favorite</mat-icon>
                <i style="color: #00A0DC;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'LINKEDIN'" class="s-16 status fab fa-linkedin"></i>
                <i style="color: #000000;position: absolute;top: 28px;left: 28px;" *ngIf="network.network_type === 'TWITTER'" class="s-16 status fab fa-x-twitter"></i>
            </div>
            {{network.network_type === 'INSTAGRAM' ? '@' + network.name : network.name}}
        </mat-option>
    </mat-autocomplete>
    <mat-spinner diameter="20" class="input-loader" style="position: absolute;right: 0;top: 14px;" *ngIf="inputLoader"></mat-spinner>
</mat-form-field>
