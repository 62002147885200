// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    // facebookRedirectUrl: 'https://socialforce.com:4200',
    // linkedinRedirectUrl: 'https://socialforce.com:4200',
    // twitterRedirectUrl: 'https://socialforce.com:4200',
    // pinterestRedirectUrl: 'http://localhost:8085/',
    // apiUrl: 'https://socialforce.test/api/',
    apiUrl: 'https://api.devsforce.galeforcedigital.com/api/',
    facebookRedirectUrl: 'https://devsforce.galeforcedigital.com/',
    linkedinRedirectUrl: 'https://devsforce.galeforcedigital.com/',
    twitterRedirectUrl: 'https://devsforce.galeforcedigital.com/',
    pinterestRedirectUrl: 'https://devsforce.galeforcedigital.com/',
    facebookClientId: '296628491209737',
    // facebookClientId: '957283491466192',
    linkedinClientId: '86kizjjsidutf6',
    pinterestClientId: '1472121',
    twitterClientId: 'SHhGMFJ0UzkyQ2NFajZQREUteWs6MTpjaQ'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
