import { CalendarEventAction } from 'angular-calendar';
import {startOfDay, endOfDay, addDays, addMinutes} from 'date-fns';
import {Post} from '../../../models/post';

export class CalendarEventModel
{
    start: Date;
    end?: Date;
    title: string;
    color: {
        primary: string;
        secondary: string;
    };
    actions?: CalendarEventAction[];
    allDay?: boolean;
    cssClass?: string;
    resizable?: {
        beforeStart?: boolean;
        afterEnd?: boolean;
    };
    draggable?: boolean;
    meta?: {
        location: string,
        notes: string
    };
    post?: Post;

    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?)
    {
        data = data || {};
        if (data instanceof Post) {
            this.post = data;
            this.start = (typeof data.postDate !== 'undefined' && data.postDate !== null) ? new Date(data.postDate) : startOfDay(new Date());
            this.end = addMinutes(this.start, 1)  || endOfDay(new Date());
            this.title = data.network.name || '';
            this.color = {
                primary: '#1e90ff',
                secondary: '#D1E8FF'
            };
            this.draggable = (typeof data.editable !== 'undefined') ? data.editable : true;
            this.resizable = {
                beforeStart: false,
                afterEnd: false
            };
            this.actions = data['actions'] || [];
            this.allDay = false;
            this.cssClass = '';
            this.meta = {
                location: '',
                notes: ''
            };
        } else {
            this.start = new Date(data.start) || startOfDay(new Date());
            this.end = new Date(data.end) || endOfDay(new Date());
            this.title = data.title || '';
            this.color = {
                primary: data.color && data.color.primary || '#1e90ff',
                secondary: data.color && data.color.secondary || '#D1E8FF'
            };
            this.draggable = data.draggable;
            this.resizable = {
                beforeStart: data.resizable && data.resizable.beforeStart || true,
                afterEnd: data.resizable && data.resizable.afterEnd || true
            };
            this.actions = data.actions || [];
            this.allDay = data.allDay || false;
            this.cssClass = data.cssClass || '';
            this.meta = {
                location: data.meta && data.meta.location || '',
                notes: data.meta && data.meta.notes || ''
            };
        }
    }
}
