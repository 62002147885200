import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DateAgoPipe} from './date-ago.pipe';
import { ArrayJoinPipe } from './array-join.pipe';



@NgModule({
  declarations: [
      DateAgoPipe,
      ArrayJoinPipe
  ],
  imports: [
    CommonModule
  ],
    exports: [
        DateAgoPipe,
        ArrayJoinPipe
    ]
})
export class CustomPipesModule { }
