import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ActionLogsService} from './action-logs.service';

@Component({
  selector: 'app-action-logs',
  templateUrl: './action-logs.component.html',
  styleUrls: ['./action-logs.component.scss']
})
export class ActionLogsComponent implements OnInit {
    @Input() userId: string;
    actionType: Array<string>;
    fromDate: any;
    toDate: any;
    protected readonly Object = Object;
    loading = false;

    displayedColumns: string[] = ['created_at', 'action_type', 'data_request', 'data_error'];
    dataSource = [];
    constructor(private actionLogsService: ActionLogsService) { }

    ngOnInit(): void {
        this.fromDate = moment().subtract(30, 'days');
        this.toDate = moment();
        this.actionType = ['posting_new', 'posting_update'];
        this.getActionLogs(this.userId);
    }

    getActionLogs(userId: string): void {
        console.log(this.fromDate);
        this.loading = true;
        this.actionLogsService.getUserActionLogs(userId,
            {from: this.fromDate.format('YYYY-MM-DD'), to: this.toDate.format('YYYY-MM-DD') + ' 23:59:59', type: this.actionType}).subscribe(
            response => {
                console.log(response);
                const data = [];
                for (const action of response.user_action_logs) {
                    console.log(action)
                    switch (action.action_type) {
                        case 'posting_new':
                            action.data_request = JSON.parse(action.data_request);
                            if (/^[\],:{}\s]*$/.test(action.data_error.replace(/\\["\\\/bfnrtu]/g, '@').
                            replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
                            replace(/(?:^|:|,)(?:\s*\[)+/g, '')) && action.data_error !== '') {
                                action.data_error = JSON.parse(action.data_error);
                                if (typeof action.data_error === 'string') {
                                    action.data_error = [action.data_error];
                                } else {
                                    const errors = [];
                                    for (const error of Object.values(action.data_error)) {
                                        // @ts-ignore
                                        if (typeof error.error !== 'undefined' && typeof error.error.message !== 'undefined') {
                                            // @ts-ignore
                                            errors.push(error.error.message);
                                            // @ts-ignore
                                        } else if (typeof error.message !== 'undefined') {
                                            // @ts-ignore
                                            errors.push(error.message);
                                            // @ts-ignore
                                        } else if (typeof error.error !== 'undefined' && typeof error.error === 'string') {
                                            // @ts-ignore
                                            errors.push(error.error);
                                        }
                                    }
                                    action.data_error = errors;
                                }
                            }
                            action.action_type_message = 'Publish/Schedule New Posts';
                            break;
                        case 'posting_update':
                            action.data_request = JSON.parse(action.data_request);
                            if (/^[\],:{}\s]*$/.test(action.data_error.replace(/\\["\\\/bfnrtu]/g, '@').
                            replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
                            replace(/(?:^|:|,)(?:\s*\[)+/g, '')) && action.data_error !== '') {
                                action.data_error = JSON.parse(action.data_error);
                                if (typeof action.data_error === 'string') {
                                    action.data_error = [action.data_error];
                                } else {
                                    const errors = [];
                                    for (const error of Object.values(action.data_error)) {
                                        // @ts-ignore
                                        if (typeof error.error !== 'undefined' && typeof error.error.message !== 'undefined') {
                                            // @ts-ignore
                                            errors.push(error.error.message);
                                            // @ts-ignore
                                        } else if (typeof error.message !== 'undefined') {
                                            // @ts-ignore
                                            errors.push(error.message);
                                            // @ts-ignore
                                        } else if (typeof error.error !== 'undefined' && typeof error.error === 'string') {
                                            // @ts-ignore
                                            errors.push(error.error);
                                        }
                                    }
                                    action.data_error = errors;
                                }
                            }
                            action.action_type_message = 'Update Posts';
                            break;
                    }
                    action.created_at = moment(action.created_at).format('MM/DD/YYYY HH:mm:ss');
                    data.push(action);
                }
                this.dataSource = data;
                console.log(this.dataSource);
                this.loading = false;
            }, error => console.log(error)
        );
    }
}
