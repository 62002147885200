import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LinkedinService {
    linkedinUrl = '/linkedin-v2/';
    constructor(private http: HttpClient) { }

    getLinkedinFunctions(token: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };
        return this.http.get(this.linkedinUrl + 'functions', httpOptions);
    }

    getLinkedinIndustries(token: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };
        return this.http.get(this.linkedinUrl + 'industries', httpOptions);
    }

    getLinkedinSeniorities(token: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };
        return this.http.get(this.linkedinUrl + 'seniorities?count=100', httpOptions);
    }
}
