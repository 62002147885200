import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import {SocialMediaService} from "../social-media.service";
import {environment} from "../../../../../environments/environment";

@Injectable()
export class SocialMediaManagementNetworkService implements Resolve<any>
{
    routeParams: any;
    network: any;
    onNetworkChanged: BehaviorSubject<any>;

    /**
     * Constructor
     * @param {HttpClient} _httpClient
     * @param {SocialMediaService} mediaService
     */
    constructor(
        private _httpClient: HttpClient,
        private mediaService: SocialMediaService
    )
    {
        // Set the defaults
        this.onNetworkChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getNetwork()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get network
     *
     * @returns {Promise<any>}
     */
    getNetwork(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onNetworkChanged.next(false);
                resolve(false);
            }
            else
            {
                this._httpClient.get(`${environment.apiUrl}get-network/` + this.routeParams.id)
                    .subscribe((response: any) => {
                        this.network = response['network'];
                        this.network.teams = response['groups'];
                        this.network.members = response['members'];
                        this.onNetworkChanged.next(this.network);
                        resolve(response);
                    }, reject);

            }
        });
    }

    /**
     * Save network
     *
     * @param network
     * @returns {Promise<any>}
     */
    saveNetwork(network): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}update-network/` + network.id, network)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    hootsuiteImport(network): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}import-hootsuite`, network)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Add network
     *
     * @param network
     * @returns {Promise<any>}
     */
    addNetwork(network): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/social-media-management-networks/', network)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
