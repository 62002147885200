<div class="main-container">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="file-view-container" [@slideInLeft]="{value:'*',params:{duration:'600ms', x:'60px'}}">
        <div class="file-view-icon">
            <i *ngIf="fileSnippet.type === null" class="fas fa-file"></i>
            <img
                src="{{(fileSnippet.previewUrl !== null && fileSnippet.previewUrl !== '') ? fileSnippet.previewUrl : fileSnippet.source}}"
                *ngIf="fileSnippet.type !== null && (fileSnippet.type.indexOf('image') !== -1 || fileSnippet.type.indexOf('video') !== -1)"
                [hidden]="true"
                (load)="loadImagePreview($event)"
            />
            <i *ngIf="fileSnippet.type === null || (fileSnippet.type !== null && fileSnippet.type.indexOf('image') !== -1 && previewLoading)" class="fas fa-file-image"></i>
            <i *ngIf="fileSnippet.type === null || (fileSnippet.type !== null && fileSnippet.type.indexOf('video') !== -1 && previewLoading)" class="fas fa-file-video"></i>
            <div
                *ngIf="fileSnippet.type !== null && (fileSnippet.type.indexOf('video') !== -1 || fileSnippet.type.indexOf('image') !== -1) && !previewLoading"
                class="file-preview"
                [ngStyle]="{backgroundImage: 'url(' + ((fileSnippet.previewUrl !== null && fileSnippet.previewUrl !== '') ? fileSnippet.previewUrl : fileSnippet.source) + ')'}"
            ></div>
        </div>
        <div class="file-view-loader" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
                <label>
                    <mat-icon matTooltip="Change Media Name" (click)="editFile.emit({index: index, type: 'rename'});">edit_calendar</mat-icon>
                    {{(fileSnippet.file !== null) ? fileSnippet.file.name : 'Loading...'}}
                </label>
                <label>{{fileSnippet.process}}%</label>
            </div>
            <mat-progress-bar
                [mode]="(
                fileSnippet.type !== null
                && ((fileSnippet.type.indexOf('video') === -1 && previewLoading)
                || (fileSnippet.type.indexOf('video') !== -1 && fileSnippet.loading && fileSnippet.process === 100)))
                ? 'indeterminate' : 'determinate'"
                value="{{fileSnippet.process}}">
            </mat-progress-bar>
        </div>
    </div>
    <div class="file-view-actions-container">
        <div class="file-view-actions" fxLayout="column" fxLayoutAlign="space-between center">
            <button (click)="editFile.emit({index: index, type: 'edit'});"><i class="fas fa-pencil-alt"></i></button>
            <button (click)="remove()"><i class="fas fa-times"></i></button>
        </div>
    </div>
</div>