import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoardsSelectorComponent } from './boards-selector/boards-selector.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {FlexModule} from "@angular/flex-layout";



@NgModule({
    declarations: [BoardsSelectorComponent],
    exports: [
        BoardsSelectorComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatChipsModule,
        MatIconModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        FlexModule
    ]
})
export class PinterestModule { }
