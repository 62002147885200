<div id="members" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent"
             fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0"
                 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    list_alt
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Agencies
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Search for an agency">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button
                    [routerLink]="'/apps/social-media-management/agencies/new'"
                    class="add-member-button fuse-white mt-24 mt-md-0">
                <span>ADD NEW AGENCY</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="members-table"
                       #table [dataSource]="dataSource"
                       matSort
                       [@animateStagger]="{value:'50'}"
                       fusePerfectScrollbar>

                <!-- Image Column -->
                <ng-container matColumnDef="profile_photo">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let agency">
                        <mat-icon style="transform: scale(2);margin-left: 10px" >account_circle</mat-icon>
<!--                        <img style="border-radius: 50%" *ngIf="member.profile_photo" src="{{member.profile_photo}}" width="50px">-->
                    </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Agency Name</mat-header-cell>
                    <mat-cell *matCellDef="let agency">
                        <p class="text-truncate">{{agency.name}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Category Column -->
                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef fxHide mat-sort-header fxShow.gt-md>Email</mat-header-cell>
                    <mat-cell *matCellDef="let agency" fxHide fxShow.gt-md>
                        <p class="category text-truncate">
                            {{agency.email}}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Price Column -->
                <ng-container matColumnDef="created_at">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Created At</mat-header-cell>
                    <mat-cell *matCellDef="let agency" fxHide fxShow.gt-xs>
                        <p class="category text-truncate">
                            {{agency.created_at | date: 'M-d-yyyy HH:mm'}}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Quantity Column -->
                <!--<ng-container matColumnDef="quantity">-->
                <!--<mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Quantity</mat-header-cell>-->
                <!--<mat-cell *matCellDef="let member" fxHide fxShow.gt-sm>-->

                <!--<span class="quantity-indicator text-truncate"-->
                <!--[ngClass]="{'red-500':member.quantity <= 5, 'amber-500':member.quantity > 5 && member.quantity <= 25,'green-600':member.quantity > 25}">-->
                <!--</span>-->
                <!--<span>-->
                <!--{{member.quantity}}-->
                <!--</span>-->

                <!--</mat-cell>-->
                <!--</ng-container>-->

                <!-- Active Column -->
                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let agency">
                        <button class="mat-elevation-z mr-4" (click)="impersonate(agency.id)" mat-mini-fab color="primary" aria-label="Impersonate Agency">
                            <mat-icon>input</mat-icon>
                        </button>
                        <button class="mat-elevation-z mr-4" [routerLink]="'/apps/social-media-management/agencies/' + agency.id" mat-mini-fab color="primary" aria-label="Edit agency">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button class="mat-elevation-z" (click)="delete(agency)" mat-mini-fab color="primary" aria-label="Delete agency">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let agency; columns: displayedColumns;"
                         class="member"
                         matRipple
                >
                </mat-row>

            </mat-table>

            <mat-paginator #paginator
                           [length]="dataSource.filteredData.length"
                           [pageIndex]="0"
                           [pageSize]="10"
                           [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
