import {Component, Inject, Input, OnInit} from '@angular/core';
import {Post} from '../../../../models/post';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnotifyService} from 'ng-snotify';
import {Network} from '../../../../models/network';
import {ChatService} from '../../../../main/apps/chat/chat.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
    post: Post;
    networkSelected: Network = null;
    text = '';

    constructor(
        public dialogRef: MatDialogRef<ShareComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private alert: SnotifyService,
        private _chatService: ChatService)
    {
        if (typeof this.data !== 'undefined' && typeof data.post !== 'undefined' && data.post !== null) {
            this.post = this.data.post;
        }
    }

    ngOnInit(): void {
    }

    setNetwork(event: Network[]): void {
        this.networkSelected = event[0];
    }

    emojiClicked(event: MouseEvent): void {
        this._chatService.onEmojisOpen.next(event);
    }
}
