<div class="networks-icons p-16" fxLayout="row">
    <i *ngIf="networksSelectedTypes.indexOf('facebook') !== -1" (click)="networkType = 'facebook'" class="fab fa-facebook-square mr-16" [ngClass]="(networkType === 'facebook') ? 'selected' : ''"></i>
    <i *ngIf="networksSelectedTypes.indexOf('instagram') !== -1" (click)="networkType = 'instagram'" class="fab fa-instagram-square mr-16" [ngClass]="(networkType === 'instagram') ? 'selected' : ''"></i>
    <i *ngIf="networksSelectedTypes.indexOf('linkedin') !== -1" (click)="networkType = 'linkedin'" class="fab fa-linkedin mr-16" [ngClass]="(networkType === 'linkedin') ? 'selected' : ''"></i>
    <i *ngIf="networksSelectedTypes.indexOf('twitter') !== -1"  (click)="networkType = 'twitter'" class="fab fa-square-x-twitter" [ngClass]="(networkType === 'twitter') ? 'selected' : ''"></i>
</div>
<div class="networks-icons p-16" fxLayout="row">
    <mat-form-field *ngIf="networkType === 'facebook'" appearance="legacy" class="w-100-p" style="height: 52px;overflow: hidden;padding: 8px;border: 1px solid #eee;border-radius: 5px;" floatLabel="always">
            <input
                #pageInput
                matInput
                placeholder="Type Page Name"
                [formControl]="pageInputControl"
                [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedPage($event)">
            <mat-option style="margin: 15px 0;" *ngFor="let page of filteredPages | async" [value]="page">
                <i style="border-radius: 50%;margin-right: 10px;" *ngIf="!hasImage(page)" class="fab fa-facebook-square mr-16"></i>
                <img style="border-radius: 50%;margin-right: 10px;" *ngIf="hasImage(page)" src="{{page.picture.data.url}}"/>
                {{page.name}}
            </mat-option>
        </mat-autocomplete>
        <mat-spinner diameter="20" class="input-loader" style="position: absolute;right: 0;top: 0px;" *ngIf="inputLoader"></mat-spinner>
    </mat-form-field>
    <mat-form-field *ngIf="networkType === 'instagram'" appearance="legacy" class="w-100-p" style="height: 52px;overflow: hidden;padding: 8px;border: 1px solid #eee;border-radius: 5px;" floatLabel="always">
        <input
            #pageInput
            matInput
            placeholder="Enter Full Contact User"
            [formControl]="pageInputControl">
    </mat-form-field>
    <mat-form-field *ngIf="networkType === 'linkedin'" appearance="legacy" class="w-100-p" style="height: 52px;overflow: hidden;padding: 8px;border: 1px solid #eee;border-radius: 5px;" floatLabel="always">
        <input
            #pageInput
            matInput
            placeholder="Type Page Name"
            [formControl]="pageInputControl"
            [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedPage($event)">
            <mat-option *ngFor="let page of filteredPages | async" [value]="page">
                {{page.name}}
            </mat-option>
        </mat-autocomplete>
        <mat-spinner diameter="20" class="input-loader" style="position: absolute;right: 0;top: 14px;" *ngIf="inputLoader"></mat-spinner>
    </mat-form-field>
    <mat-form-field *ngIf="networkType === 'twitter'" appearance="legacy" class="w-100-p" style="height: 52px;overflow: hidden;padding: 8px;border: 1px solid #eee;border-radius: 5px;" floatLabel="always">
        <input
            #pageInput
            matInput
            placeholder="Type Page Name"
            [formControl]="pageInputControlTwitter"
            [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedPage($event)">
            <mat-option *ngFor="let page of filteredPages | async" [value]="page">
                {{page.name}} - @{{page.screen_name}}
            </mat-option>
        </mat-autocomplete>
        <mat-spinner diameter="20" class="input-loader" style="position: absolute;right: 0;top: 14px;" *ngIf="inputLoader"></mat-spinner>
    </mat-form-field>
    <button *ngIf="networkType !== 'facebook' && networkType !== 'twitter'" (click)="sendTag()" class="mat-accent mat-raised-button mat-button-base" style="margin-left: 5px;"><mat-icon style="margin-top: 11px;">person_add</mat-icon></button>
</div>

