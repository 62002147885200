import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworksSelectorComponent } from './networks-selector/networks-selector.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatChipsModule} from '@angular/material/chips';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';



@NgModule({
    declarations: [NetworksSelectorComponent],
    exports: [
        NetworksSelectorComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatChipsModule,
        FuseSharedModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatIconModule
    ]
})
export class NetworksModule { }
