import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentComponent } from './comment/comment.component';
import { CommentsComponent } from './comments/comments.component';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {MatDialogModule} from '@angular/material/dialog';
import {CustomPipesModule} from "../../../pipes/custom-pipes.module";
import {MatProgressBarModule} from "@angular/material/progress-bar";



@NgModule({
    declarations: [CommentComponent, CommentsComponent],
    exports: [
        CommentsComponent
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatTooltipModule,
        MatMenuModule,
        MatIconModule,
        PickerModule,
        MatDialogModule,
        CustomPipesModule,
        MatProgressBarModule
    ]
})
export class PostCommentsModule { }
