import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NetworkAutofillService {
    facebookUrl = 'https://graph.facebook.com/';
    constructor(private http: HttpClient) { }

    getFacebookPages(networkId: string, query: string): Observable<any> {
        // return this.http.get(`${environment.apiUrl}` + 'pages/search/facebook/' + query);
        return this.http.get(`${environment.apiUrl}` + 'facebook/search/pages/' + networkId + '/' + query);
    }

    getTwitterUsers(networkId: string, query: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}` + 'twitter/users/lookup/' + networkId + '/' + query);
    }

    /**
     * Get pages by query
     *
     * @returns {Promise<any>}
     */
    _filterFacebookPages(networkId: string, text: string): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.getFacebookPages(networkId, text)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
