import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-files-errors',
  templateUrl: './files-errors.component.html',
  styleUrls: ['./files-errors.component.scss']
})
export class FilesErrorsComponent {
    constructor(
        public dialogRef: MatDialogRef<FilesErrorsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

}
