<div id="orders" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent"
             fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-sm-0"
                 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">Orders</span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper  ml-sm-32">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Search for an order">
                </div>
            </div>
            <!-- / SEARCH -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="orders-table"
                       #table [dataSource]="dataSource"
                       matSort
                       [@animateStagger]="{value:'50'}"
                       fusePerfectScrollbar>

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
                    <mat-cell *matCellDef="let order">
                        <p class="text-truncate">{{order.id}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Reference Column -->
                <ng-container matColumnDef="reference">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Reference</mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{order.reference}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="customer">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Customer</mat-header-cell>
                    <mat-cell *matCellDef="let order">
                        <p class="text-truncate">
                            {{order.customer.firstName}}
                            {{order.customer.lastName}}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Total Price Column -->
                <ng-container matColumnDef="total">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-md>Total</mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-md>
                        <p class="total-price text-truncate">
                            {{order.total | currency:'USD':'symbol'}}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Payment Column -->
                <ng-container matColumnDef="payment">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Payment</mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <p class="text-truncate">
                            {{order.payment.method}}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                    <mat-cell *matCellDef="let order">
                        <p class="status text-truncate h6 p-4" [ngClass]="order.status[0].color">
                            {{order.status[0].name}}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Date Column -->
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Date</mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <p class="text-truncate">
                            {{order.date}}
                        </p>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let order; columns: displayedColumns;"
                         class="order"
                         matRipple
                         [routerLink]="'/apps/social-media-management/orders/'+order.id">
                </mat-row>
            </mat-table>

            <mat-paginator #paginator
                           [length]="dataSource.filteredData.length"
                           [pageIndex]="0"
                           [pageSize]="10"
                           [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
