import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {of, Observable, Subject, BehaviorSubject} from 'rxjs';
import {environment} from '../../environments/environment';
import {Member} from '../models/member';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonAuthService {
    loggedIn = false;
    userSub = new BehaviorSubject<Member>(null);
    userLogged = null;

    constructor(private http: HttpClient) {
        const currentUser = localStorage.getItem('user_logged');
        if (currentUser !== null) {
            this.loggedIn = true;
            this.userLogged = JSON.parse(currentUser);
            this.userSub.next(JSON.parse(currentUser));
        }
    }

    registerUser(form: any) {
        return this.http.post(`${environment.apiUrl}register/`, form.value);
    }

    isAuthonticated(): Promise<any> {
        const promise = new Promise(
            (resolve, reject) => {
                setTimeout(() => {
                    let t = localStorage.getItem('token');
                    if (t) {
                        this.loggedIn = true;
                        resolve(this.loggedIn);
                    } else {
                        this.loggedIn = false;
                        reject();
                    }
                }, 800);
            });
        return promise;
    }

    logIn(form: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}login/`, form.value).pipe(
            tap(response => {
                this.loggedIn = true;
                localStorage.removeItem('token');
                localStorage.setItem('token', response.token);
                localStorage.setItem('user_logged', JSON.stringify( response.user));
                this.userLogged = response.user;
                this.userSub.next(this.userLogged);
            })
        );
    }

    resetPassword(form: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}password/reset/`, form.value);
    }

    forgotPassword(form: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}forgot/`, form.value);
    }

    logout(token: any): Observable<any> {
        this.removeUser();
        return this.http.post(`${environment.apiUrl}logout/`, {token: token});
    }

    logInGoogle(userData: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}google-login/`, userData).pipe(
            tap(response => {
                console.log(response);
                this.loggedIn = true;
                localStorage.removeItem('token');
                localStorage.setItem('token', response.token);
                localStorage.setItem('user_logged', JSON.stringify( response.user));
                this.userLogged = response.user;
                this.userSub.next(this.userLogged);
            })
        );
    }

    logInFacebook(userData: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}facebook-login/`, userData).pipe(
            tap(response => {
                this.loggedIn = true;
                localStorage.removeItem('token');
                localStorage.setItem('token', response.token);
                localStorage.setItem('user_logged', JSON.stringify( response.user));
                this.userLogged = response.user;
                this.userSub.next(this.userLogged);
            })
        );
    }

    getSocialToken(network): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(`${environment.apiUrl}user-social-token/${network}`)
                .subscribe(response => {
                    resolve(response);
                }, reject);
        });
    }

    setUser(user: Member): void {
        this.userLogged = user;
        this.userSub.next(this.userLogged);
    }

    removeUser(): void {
        this.loggedIn = false;
        this.userLogged = null;
        this.userSub.next(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user_logged');
    }

    getUser(): Observable<Member> {
        return this.userSub.asObservable();
    }

    getByToken(token: string) {
        return this.http.get<any>(`${environment.apiUrl}` + 'user').pipe(
            tap(_ => console.log('fetched getByToken'))
        );
    }

    setTermsAcceptance(): Observable<any> {
        return this.http.put(`${environment.apiUrl}` + 'user/accept-terms', {accepted: true})
            .pipe(
                tap(_ => console.log('fetched setTermsAcceptance'))
            ) as Observable<any>;
    }
}
