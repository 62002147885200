import {Component, Input, OnInit} from '@angular/core';
import {CalendarEventModel} from '../../../../main/apps/calendar/event.model';
import * as moment from 'moment';

@Component({
  selector: 'app-post-small-view',
  templateUrl: './post-small-view.component.html',
  styleUrls: ['./post-small-view.component.scss']
})
export class PostSmallViewComponent implements OnInit {

  @Input() event: CalendarEventModel;
  postTime: string;
  loadingPreview = true;
  constructor() { }

  ngOnInit(): void {
      this.postTime = moment(this.event.post.postDate).format('hh:mma');
  }

  loadPreview(): void {
      this.loadingPreview = false;
  }
}
