<div id="product" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/apps/social-media-management/teams'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div class="product-image mr-8 mr-sm-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    <img *ngIf="product.images[0]" [src]="product.images[0].url">
                    <img *ngIf="!product.images[0]" [src]="'assets/images/ecommerce/product-image-placeholder.png'">
                </div>

                <div fxLayout="column" fxLayoutAlign="start start"
                     [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="pageType ==='edit'">
                        {{product.name}}
                    </div>
                    <div class="h2" *ngIf="pageType ==='new'">
                        New Product
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Product Detail</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button
                    class="save-product-button"
                    [disabled]="productForm.invalid"
                    *ngIf="pageType ==='new'" (click)="addProduct()">
                <span>ADD</span>
            </button>

            <button mat-raised-button
                    class="save-product-button"
                    [disabled]="productForm.invalid || productForm.pristine"
                    *ngIf="pageType ==='edit'" (click)="saveProduct()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="productForm" [formGroup]="productForm" class="product w-100-p" fxLayout="column" fxFlex>

                    <mat-tab-group>

                        <mat-tab label="Basic Info">

                            <div class="tab-content p-24" fusePerfectScrollbar>

                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Product Name</mat-label>
                                    <input matInput placeholder="Product Name"
                                           name="name"
                                           formControlName="name"
                                           required>
                                </mat-form-field>

                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Product Description</mat-label>
                                    <textarea matInput placeholder="Product Description"
                                              name="description"
                                              formControlName="description"
                                              rows="5">
                                    </textarea>
                                </mat-form-field>

                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">

                                    <mat-label>Categories</mat-label>

                                    <mat-chip-list #categoryList name="categories" formControlName="categories">

                                        <mat-chip *ngFor="let category of product.categories"
                                                  [removable]="true" (removed)="product.removeCategory(category)">
                                            {{category}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>

                                        <input [matChipInputFor]="categoryList"
                                               [matChipInputAddOnBlur]="true"
                                               (matChipInputTokenEnd)="product.addCategory($event)"/>

                                    </mat-chip-list>

                                </mat-form-field>

                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">

                                    <mat-label>Tags</mat-label>

                                    <mat-chip-list #tagList name="tags" formControlName="tags">

                                        <mat-chip *ngFor="let tag of product.tags"
                                                  [removable]="true" (removed)="product.removeTag(tag)">
                                            {{tag}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>

                                        <input [matChipInputFor]="tagList"
                                               [matChipInputAddOnBlur]="true"
                                               (matChipInputTokenEnd)="product.addTag($event)"/>

                                    </mat-chip-list>

                                </mat-form-field>

                            </div>

                        </mat-tab>

                        <mat-tab label="Product Images">

                            <div class="tab-content p-24" fusePerfectScrollbar>
                                <div fxLayout="row wrap" fxLayoutAlign="start start">

                                    <div *ngIf="product.images.length === 0"
                                         class="product-image" fxlayout="row" fxLayoutAlign="center center">
                                        <img class="media"
                                             [src]="'assets/images/ecommerce/product-image-placeholder.png'">
                                    </div>

                                    <div *ngFor="let image of product.images">
                                        <div *ngIf="product.images.length > 0"
                                             class="product-image" fxlayout="row" fxLayoutAlign="center center">
                                            <img class="media" [src]="image.url">
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </mat-tab>

                        <mat-tab label="Pricing">

                            <div class="tab-content p-24" fusePerfectScrollbar>

                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Tax Excluded Price</mat-label>
                                    <input matInput placeholder="Tax Excluded Price"
                                           name="priceTaxExcl"
                                           formControlName="priceTaxExcl"
                                           type="number">
                                    <span matPrefix>$&nbsp;</span>
                                </mat-form-field>

                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Tax Included Price</mat-label>
                                    <input matInput placeholder="Tax Included Price"
                                           name="priceTaxIncl"
                                           formControlName="priceTaxIncl"
                                           type="number">
                                    <span matPrefix>$&nbsp;</span>
                                </mat-form-field>

                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Tax Rate</mat-label>
                                    <input matInput placeholder="Tax Rate"
                                           name="taxRate"
                                           formControlName="taxRate"
                                           type="number">
                                    <span matPrefix>&#37;&nbsp;</span>
                                </mat-form-field>

                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Compared Price</mat-label>
                                    <input matInput placeholder="Compared Price"
                                           name="comparedPrice"
                                           formControlName="comparedPrice"
                                           type="number">
                                    <span matPrefix>$&nbsp;</span>
                                    <mat-hint align="start">Add a compare price to show next to the real price
                                    </mat-hint>
                                </mat-form-field>

                            </div>
                        </mat-tab>

                        <mat-tab label="Inventory">

                            <div class="tab-content p-24" fusePerfectScrollbar>

                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>SKU</mat-label>
                                    <input matInput placeholder="SKU"
                                           name="sku"
                                           formControlName="sku">
                                </mat-form-field>

                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Quantity</mat-label>
                                    <input matInput placeholder="Quantity"
                                           name="quantity"
                                           formControlName="quantity"
                                           type="number">
                                </mat-form-field>

                            </div>

                        </mat-tab>

                        <mat-tab label="Shipping">

                            <div class="tab-content p-24" fusePerfectScrollbar fxLayout="column">

                                <div fxLayout="row" fxLayoutAlign="space-between">

                                    <mat-form-field appearance="outline" floatLabel="always" fxFlex="30">
                                        <mat-label>Width</mat-label>
                                        <input matInput placeholder="Width"
                                               name="Width"
                                               formControlName="width">
                                        <span matSuffix>cm</span>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" floatLabel="always" fxFlex="30">
                                        <mat-label>Height</mat-label>
                                        <input matInput placeholder="Height"
                                               name="Height"
                                               formControlName="height">
                                        <span matSuffix>cm</span>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" floatLabel="always" fxFlex="30">
                                        <mat-label>Depth</mat-label>
                                        <input matInput placeholder="Depth"
                                               name="Depth"
                                               formControlName="depth">
                                        <span matSuffix>cm</span>
                                    </mat-form-field>

                                </div>

                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Weight</mat-label>
                                    <input matInput placeholder="Weight"
                                           name="Weight"
                                           formControlName="weight">
                                    <span matSuffix>kg</span>
                                </mat-form-field>

                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Extra Shipping Fee</mat-label>
                                    <input matInput placeholder="Extra Shipping Fee"
                                           name="extraShippingFee"
                                           formControlName="extraShippingFee"
                                           type="number">
                                    <span matPrefix>$&nbsp;</span>
                                </mat-form-field>

                            </div>

                        </mat-tab>

                    </mat-tab-group>

                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
