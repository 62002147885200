import {Component, Input, OnInit} from '@angular/core';
import {Message} from '../../../../models/message';
import {PrivateMessageComponent} from '../private-message.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-list-view-message',
  templateUrl: './list-view-message.component.html',
  styleUrls: ['./list-view-message.component.scss']
})
export class ListViewMessageComponent implements OnInit {

    @Input() message: any;
    @Input() fromBoard = false;
    shortText = false;

    constructor(public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.shortText = this.fromBoard;
    }

    openConversation(): void {
        const dialogRef = this.dialog.open(PrivateMessageComponent, {
            maxWidth: '500px',
            width: '100%',
            disableClose: true,
            data: {
                comment: [...this.message.messages],
                network: this.message.network,
                contactId: this.message.senders.data[0].id
            },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (typeof result !== 'undefined' && result !== null) {

            }
        });
    }
}
