<div id="teams" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent"
             fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0"
                 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    accessibility
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Teams
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Search for a team">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button *ngIf="currentUser.userLogged['role'] !== 'BASIC'"
                    [routerLink]="'/apps/social-media-management/teams/new'"
                    class="add-team-button fuse-white mt-24 mt-md-0">
                <span>ADD NEW TEAM</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="teams-table"
                       #table [dataSource]="dataSource"
                       matSort
                       [@animateStagger]="{value:'50'}"
                       fusePerfectScrollbar>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Team Name</mat-header-cell>
                    <mat-cell *matCellDef="let team">
                        <p class="text-truncate"><mat-icon>people</mat-icon>{{team.name}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="created_at">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Created At</mat-header-cell>
                    <mat-cell *matCellDef="let team">
                        <p class="text-truncate">{{team.created_at | date: 'M-d-yyyy HH:mm'}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let team">
                        <button *ngIf="currentUser.userLogged['role'] !== 'BASIC'" class="mat-elevation-z mr-4" [routerLink]="'/apps/social-media-management/teams/'+team.id+'/'+team.id" mat-mini-fab color="primary" aria-label="Edit team">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button *ngIf="currentUser.userLogged['role'] !== 'BASIC'" class="mat-elevation-z" (click)="delete(team)" mat-mini-fab color="primary" aria-label="Delete team">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let team; columns: displayedColumns;"
                         class="team"
                         matRipple>
                </mat-row>

            </mat-table>

            <mat-paginator #paginator
                           [length]="dataSource.filteredData.length"
                           [pageIndex]="0"
                           [pageSize]="10"
                           [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
