import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import * as moment from 'moment';
import {CalendarEventModel} from "./event.model";

@Injectable()
export class CalendarService implements Resolve<any>
{
    events: any;
    networks: any;
    onEventsUpdated: Subject<any>;
    onAddedTransformedEvents: BehaviorSubject<any> = new BehaviorSubject([]);

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onEventsUpdated = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getEvents(new Date(), new Date())
            ]).then(
                ([events]: [any]) => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get events
     *
     * @returns {Promise<any>}
     */
    getEvents(startDate: any, endDate: any, teams: any[] = [], networks: string[] = []): Promise<any>
    {
        startDate = moment(startDate);
        endDate = moment(endDate);
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}` + 'get-calendar-posts?start=' + startDate.format('D MMMM YYYY')
                + '&end=' + endDate.format('D MMMM YYYY') + '&teams=' + teams.join(',') + '&networks=' + networks.join(','))
                .subscribe((response: any) => {
                    this.events = response.posts;
                    this.networks = response.networks;
                    this.onEventsUpdated.next(this.events);
                    resolve(this.events);
                }, reject);
        });
    }

    /**
     * Get posts
     *
     * @returns {Observable<any>}
     */
    getPosts(): Observable<any>
    {
        return this._httpClient.get(`${environment.apiUrl}` + 'calendar');
    }

    /**
     * Update events
     *
     * @param events
     * @returns {Promise<any>}
     */
    updateEvents(events): void
    {
        this.events = events;
        this.onEventsUpdated.next();
        // return new Promise((resolve, reject) => {
        //     this._httpClient.post('api/calendar/events', {
        //         id  : 'events',
        //         data: [...events]
        //     })
        //         .subscribe((response: any) => {
        //             this.getEvents(new Date(), new Date());
        //         }, reject);
        // });
    }

    addFormatedEvents(events: Array<CalendarEventModel>): void {
        this.onAddedTransformedEvents.next([...events]);
    }

    getPostsData(posts: Array<any>) {
        return this._httpClient.post(`${environment.apiUrl}` + 'posts/data', posts);
    }
}
