import { Injectable } from '@angular/core';
import {IMediaLibrary} from '../../../../models/IMediaLibrary';
import {Observable} from 'rxjs';
import {MediaLibraryData, MediaLibraryPreview} from '../media-library/media-library.component';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PixabayService implements IMediaLibrary{

    private apiKey = '17992991-eb4ac75fa35efc0c059aefdec';
    constructor(private httpClient: HttpClient) { }

    getMediaData(query: string = '', amount: number = 18, page: number = 1): Observable<MediaLibraryData> {
        return this.httpClient.get('/pixabay/?key=' + this.apiKey
                + '&q=' + query + '&image_type=all&page=' + page + '&per_page=' + amount)
            .pipe(
                map(item => {
                    const result = new MediaLibraryData();
                    result.total = item['totalHits'];
                    result.data = [];
                    for (const img of item['hits']) {
                        result.data.push({
                            id: img['id'],
                            previewUrl: img['webformatURL'],
                            source: 'Pixabay',
                            url: img['largeImageURL'],
                            width: img['imageWidth'],
                            height: img['imageHeight'],
                            size: img['imageSize']
                        } as MediaLibraryPreview);
                    }
                    return result;
                })
        );
    }
}
