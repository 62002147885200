<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-warn m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span style="display: flex;align-items: center;" class="title dialog-title">
                <mat-icon style="margin-right: 10px;">warning</mat-icon> Networks - Files conflicts
            </span>
            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div mat-dialog-content class="p-16 pb-0 m-0" fusePerfectScrollbar>
        <div *ngIf="data.from === 'network'">
            <div style="font-weight: bold;">Selected {{data.deniedNetworkType.join(', ')}} network(s) can not be added because has incompatibilities with the post files.</div>
            <div *ngFor="let file of data.files">
                <ng-container *ngFor="let type of data.deniedNetworkType">
                    <div class="network_error_container" *ngIf="file['errors'][type.toLowerCase()].length > 0">
                        <div style="font-weight: bold;">Conflicts with file: {{file['fileName']}}</div>
                        <ul>
                            <li *ngFor="let error of file['errors'][type.toLowerCase()]">{{error}}</li>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </div>
        <div *ngIf="data.from === 'file'">
            <div style="font-weight: bold;">The selected file can not be added because has incompatibilities with the post networks.</div>
            <div class="network_error_container" *ngIf="data.networks.facebook.length > 0">
                <div style="font-weight: bold;">FACEBOOK:</div>
                <ul>
                    <li *ngFor="let error of data.networks.facebook">{{error}}</li>
                </ul>
            </div>
            <div class="network_error_container" *ngIf="data.networks.instagram.length > 0">
                <div style="font-weight: bold;">INSTAGRAM:</div>
                <ul>
                    <li *ngFor="let error of data.networks.instagram">{{error}}</li>
                </ul>
            </div>
            <div class="network_error_container" *ngIf="data.networks.linkedin.length > 0">
                <div style="font-weight: bold;">LINKEDIN:</div>
                <ul>
                    <li *ngFor="let error of data.networks.linkedin">{{error}}</li>
                </ul>
            </div>
            <div class="network_error_container" *ngIf="data.networks.twitter.length > 0">
                <div style="font-weight: bold;">TWITTER:</div>
                <ul>
                    <li *ngFor="let error of data.networks.linkedin">{{error}}</li>
                </ul>
            </div>
            <div class="network_error_container" *ngIf="data.networks.pinterest.length > 0">
                <div style="font-weight: bold;">PINTEREST:</div>
                <ul>
                    <li *ngFor="let error of data.networks.pinterest">{{error}}</li>
                </ul>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
        <button *ngIf="data.from === 'file'"
            mat-button
            mat-raised-button
            class="save-button mat-button-base"
            (click)="dialogRef.close({'data': 'edit'})"
            aria-label="CLOSE">
            EDIT MEDIA
        </button>
        <button
            mat-button
            mat-raised-button
            class="save-button mat-button-base"
            (click)="dialogRef.close()"
            aria-label="CLOSE">
            CLOSE
        </button>
    </div>
</div>