import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {
    constructor(private http: HttpClient) {}

    public getFile(source: string): Observable<any> {
        // return this.http.get(source, {responseType: 'blob', headers: {
        //         'Accept': 'application/json, text/plain, */*',
        //         'Access-Control-Allow-Origin': '*',
        //         'Access-Control-Allow-Credentials': 'true',
        //         'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
        //         'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
        //     }});
        return this.http.post(`${environment.apiUrl}get-file`, {url: source}, { responseType: 'blob' });
    }
}
