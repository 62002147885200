import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {PostsService} from '../post.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {ManagePostsComponent} from '../../../../main/apps/manage-posts/manage-posts/manage-posts.component';
import {FuseUtils} from '../../../../../@fuse/utils';
import {MatCheckboxChange} from "@angular/material/checkbox";

export interface UnpostedData {
    message: string;
    mediaType: string;
    mediaUrl: string;
    mediaAmount: number;
    schedule: Date;
}

@Component({
  selector: 'app-unposted-view',
  templateUrl: './unposted-view.component.html',
  styleUrls: ['./unposted-view.component.scss'],
  animations: fuseAnimations
})
export class UnpostedViewComponent implements OnInit {
    @Input() post: any;
    @Input() postData: any;
    @Input() checked: any = false;
    @Output() deleted = new EventEmitter<string>();
    @Output() update = new EventEmitter<string>();
    @Output() checkChange = new EventEmitter<string>();
    @Output() UnCheckChange = new EventEmitter<string>();
    formatedPostData: UnpostedData = {message: '', mediaType: '', mediaUrl: '', mediaAmount: 0, schedule: null};
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    dialogRef: any;

    constructor(private postsService: PostsService, private dialog: MatDialog) { }

    ngOnInit(): void {
        if (this.post.type === 'draft') {
            this.formatedPostData.message = (typeof this.postData.message !== 'undefined') ? this.postData.message : '';
            this.formatedPostData.schedule = (typeof this.postData.schedule_time !== 'undefined') ? new Date(this.postData.schedule_time) : null;
            this.formatedPostData.mediaAmount = (typeof this.postData.medias !== 'undefined') ? this.postData.medias.length : 0;
            this.formatedPostData.mediaUrl = (this.formatedPostData.mediaAmount > 0) ? this.postData.medias[0].url : '';
            this.formatedPostData.mediaType = (this.formatedPostData.mediaAmount > 0) ? this.postData.medias[0].type : '';
        } else {
            switch (this.post.posts_scheduled[0]['network_type'].toLowerCase()) {
                case 'facebook':
                    this.formatedPostData.message = (typeof this.postData.message !== 'undefined') ? this.postData.message : '';
                    this.formatedPostData.schedule = (typeof this.postData.scheduled_publish_time !== 'undefined') ? new Date(this.postData.scheduled_publish_time * 1000) : null;
                    this.formatedPostData.mediaAmount = (typeof this.postData.attachments !== 'undefined' && typeof this.postData.attachments.data !== 'undefined')
                        ? this.postData.attachments.data.length
                        : 0;
                    this.formatedPostData.mediaType = (this.formatedPostData.mediaAmount > 0) ? this.postData.attachments.data[0].media_type : '';
                    this.formatedPostData.mediaUrl = (this.formatedPostData.mediaAmount > 0)
                        ? ((this.formatedPostData.mediaType === 'photo' || this.formatedPostData.mediaType === 'album')
                            ? this.postData.attachments.data[0].media.image.src
                            : this.postData.attachments.data[0].media.source)
                        : '';
                    break;
                case 'instagram':
                    this.formatedPostData.message = (typeof this.postData.text !== 'undefined') ? this.postData.text : '';
                    this.formatedPostData.schedule = (typeof this.postData.schedule_for !== 'undefined') ? new Date(this.postData.schedule_for) : null;
                    this.formatedPostData.mediaAmount = (typeof this.postData.nonformatted_data.media !== 'undefined')
                        ? this.postData.nonformatted_data.media.length
                        : 0;
                    this.formatedPostData.mediaType = (this.formatedPostData.mediaAmount > 0) ? this.postData.nonformatted_data.media[0].type : '';
                    this.formatedPostData.mediaUrl = (this.formatedPostData.mediaAmount > 0)
                        ? this.postData.nonformatted_data.media[0].mediaUrl
                        : '';
                    break;
                case 'linkedin':
                    this.formatedPostData.message = (typeof this.postData.text !== 'undefined') ? this.postData.text : '';
                    this.formatedPostData.schedule = (typeof this.postData.schedule_for !== 'undefined') ? new Date(this.postData.schedule_for) : null;
                    this.formatedPostData.mediaAmount = (typeof this.postData.nonformatted_data.media !== 'undefined')
                        ? this.postData.nonformatted_data.media.length
                        : 0;
                    this.formatedPostData.mediaType = (this.formatedPostData.mediaAmount > 0) ? this.postData.nonformatted_data.media[0].type : '';
                    this.formatedPostData.mediaUrl = (this.formatedPostData.mediaAmount > 0)
                        ? this.postData.nonformatted_data.media[0].mediaUrl
                        : '';
                    break;
                case 'twitter':
                    this.formatedPostData.message = (typeof this.postData.text !== 'undefined') ? this.postData.text : '';
                    this.formatedPostData.schedule = (typeof this.postData.schedule_for !== 'undefined') ? new Date(this.postData.schedule_for) : null;
                    this.formatedPostData.mediaAmount = (typeof this.postData.nonformatted_data.media !== 'undefined')
                        ? this.postData.nonformatted_data.media.length
                        : 0;
                    this.formatedPostData.mediaType = (this.formatedPostData.mediaAmount > 0) ? this.postData.nonformatted_data.media[0].type : '';
                    this.formatedPostData.mediaUrl = (this.formatedPostData.mediaAmount > 0)
                        ? this.postData.nonformatted_data.media[0].mediaUrl
                        : '';
                    break;
            }
        }
    }

    selectPost(event): void {
        if (event.checked) {
            this.checkChange.emit(this.post);
        } else {
            this.UnCheckChange.emit(this.post);
        }
    }

    removeUnposted(): void {
        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete the post(s)?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                this.postsService.removeUnpostedPosts(this.post.id).subscribe(
                    response => {
                        this.deleted.emit(this.post.main_id);
                        this.update.emit('updateList');
                    }, error => console.log(error)
                );
            }
        });
    }

    editGroup(): void {
        this.dialogRef = this.dialog.open(ManagePostsComponent, {
            panelClass: 'manager-posts-modal',
            backdropClass: 'manager-posts-modal-backdrop',
            disableClose: true,
            data      : {
                events : [],
                action: null
            },
            width: '96vw',
            maxWidth: '1200px',
            height: '96vh',
            hasBackdrop: true
        });
        this.dialogRef.componentInstance.loading = true;
        this.getGroupData();
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if ( !response )
                {
                    return;
                }
                this.update.emit('updateList');
            });
    }

    getGroupData(): Array<any> | void {
        // if (this.post.related > 0) {
            this.postsService.getUnpostedPosts(this.post.id, this.post.type).subscribe(
                response => {
                    if (this.post.type === 'draft') {
                        this.dialogRef.componentInstance._data = {
                            events: response.posts.map((item, index) =>
                                FuseUtils.formatDraftPost(item, item.network)
                            ), action: 'edit'
                        };
                    } else {
                        this.dialogRef.componentInstance._data = {
                            events: response.posts.map((item, index) =>
                                FuseUtils.formatServerPost(item, response.networks[index])
                            ), action: 'edit'
                        };
                    }
                    this.dialogRef.componentInstance.setPassedData();
                    this.dialogRef.componentInstance.loading = false;
                    // return response.posts.map(item => new CalendarEventModel(FuseUtils.formatServerPost(item, item.network)));
                }, error => console.log(error)
            );
        // } else {
        //     this.dialogRef.componentInstance.loading = false;
        //     return [this.postData];
        // }
    }
}
