import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-linkedin-post-reactions',
  templateUrl: './linkedin-post-reactions.component.html',
  styleUrls: ['./linkedin-post-reactions.component.scss']
})
export class LinkedinPostReactionsComponent implements OnInit {
    postId: string;
    constructor(
      public dialogRef: MatDialogRef<LinkedinPostReactionsComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.postId = this.data.postId;
    }

    ngOnInit(): void {
    }

}
