import {ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import {FuseErrorHandler} from './fuse-config/fuse-error-handler';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {ChatService} from './main/apps/chat/chat.service';
import {AuthInterceptor} from './auth/auth-interceptor.interceptor';
import {CommonAuthService} from './auth/common-auth.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

const fbLoginOptions = {
    scope: 'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
    return_scopes: true,
    enable_profile_selector: true,
    offline_access: true,
    prompt: 'consent',
}; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

const googleLoginOptions = {
    scope: 'profile email https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/plus.business.manage',
    offline_access: true,
    prompt: 'consent',
}; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig


// // Configs
// const config = new AuthServiceConfig([
//     {
//         id: GoogleLoginProvider.PROVIDER_ID,
//         provider: new GoogleLoginProvider('47303362769-64957dqgdhs9qh9atuokmjt8dj8e6kdf.apps.googleusercontent.com', googleLoginOptions)
//     },
//     {
//         id: FacebookLoginProvider.PROVIDER_ID,
//         provider: new FacebookLoginProvider('296628491209737', fbLoginOptions)
//     }
// ]);

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'auth/login',
        pathMatch: 'full',
    },
    {
        path        : 'auth',
        loadChildren: () => import('./auth/common-auth.module').then(m => m.CommonAuthModule)
    },
    {
        path        : 'apps',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
    },
    {
        path        : 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path        : 'ui',
        loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule)
    },
    {
        path        : 'page',
        loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule)
    },
    {
        path        : 'documentation',
        loadChildren: () => import('./main/documentation/documentation.module').then(m => m.DocumentationModule)
    },
    {
        path      : '**',
        // redirectTo: 'apps/dashboards/analytics'
        redirectTo: 'auth/login'
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 1500,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        NgxMatNativeDateModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule,
        SnotifyModule,
        PickerModule,
        MatProgressSpinnerModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        CommonAuthService,
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: HttpErrorInterceptor,
        //     multi: true
        // },
        {
            provide: ErrorHandler,
            useClass: FuseErrorHandler,
        },
        {
            provide: 'SnotifyToastConfig',
            useValue: ToastDefaults
        },
        SnotifyService,
        ChatService
    ]
})
export class AppModule
{
}
