import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgenciesService implements Resolve<any> {
    agencies: any[];
    onAgenciesChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onAgenciesChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getAgencies()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get members
     *
     * @returns {Promise<any>}
     */
    getAgencies(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}all-agencies/`)
                .subscribe((response: any) => {
                    this.agencies = response['agencies'];
                    this.onAgenciesChanged.next(this.agencies);
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get members
     *
     * @returns {Promise<any>}
     */
    pullAgencies(): Observable<any>
    {
        return this._httpClient.get(`${environment.apiUrl}all-agencies/`);
    }

    removeAgency(agencyId: number): Observable<any> {
        return this._httpClient.delete(`${environment.apiUrl}agencies/` + agencyId).pipe(
            tap(item => { this.agencies.splice(this.agencies.findIndex(i => i.id === agencyId), 1); this.onAgenciesChanged.next(this.agencies); })
        );
    }

    /**
     * Impersonate agency
     *
     * @param agency
     * @returns {Observable}
     */
    impersonateAgency(agencyId): Observable<any>
    {
        return this._httpClient.get(`${environment.apiUrl}impersonate-agency/` + agencyId);
    }
}
