export interface ConversionSettings {
    voutput: string,
    vencoder: string,
    vframerate: string,
    vrotation: string,
    vresolution: string,
    vbitrate: string,
    vchunk: string,
    aencoder: string,
    aquality: string,
    achannel: string,
    abitrate: string,
    asamplerate: string,
    vscan: string,
    vgop: string,
    vduration: string,
    vsize: string
}

export const facebookSettings: ConversionSettings = {
    voutput: 'mp4',
    vencoder: 'h.264',
    vframerate: '25',
    vrotation: '0',
    vresolution: '1920x1080',
    vbitrate: '5000K',
    vchunk: '0',
    aencoder: 'aac',
    aquality: '128k',
    achannel: '2',
    abitrate: '128000',
    asamplerate: '48000',
    vscan: 'progressive',
    vgop: '0',
    vduration: '14400',
    vsize: '1000'
}

export const instagramSettings: ConversionSettings = {
    voutput: 'mp4',
    vencoder: 'h.264',
    vframerate: '60',
    vrotation: '0',
    vresolution: '1920x1080',
    vbitrate: '5000K',
    vchunk: '0',
    aencoder: 'aac',
    aquality: '128k',
    achannel: '2',
    abitrate: '128000',
    asamplerate: '48000',
    vscan: 'progressive',
    vgop: '0',
    vduration: '60',
    vsize: '100'
}

export const linkedinSettings: ConversionSettings = {
    voutput: 'mp4',
    vencoder: 'h.264',
    vframerate: '25',
    vrotation: '0',
    vresolution: '1920x1080',
    vbitrate: '5000K',
    vchunk: '0',
    aencoder: 'aac',
    aquality: '128k',
    achannel: '2',
    abitrate: '128000',
    asamplerate: '48000',
    vscan: 'progressive',
    vgop: '0',
    vduration: null,
    vsize: '200'
}

export const twitterSettings: ConversionSettings = {
    voutput: 'mp4',
    vencoder: 'h.264',
    vframerate: '25',
    vrotation: '0',
    vresolution: '1920x1080',
    vbitrate: '5000K',
    vchunk: '0',
    aencoder: 'aac',
    aquality: '128k',
    achannel: '2',
    abitrate: '128000',
    asamplerate: '48000',
    vscan: 'progressive',
    vgop: '0',
    vduration: '14400',
    vsize: '15'
}