<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">New Private Message</span>
            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 pb-0 m-0" fusePerfectScrollbar style="flex-direction: column !important;">
        <div class="comment-to-reply w-100-p emojis-input-container" fxFlex fxLayout="column">
            <ng-container *ngIf="type === 'comment'">
                <p>Replying to {{comment.contactName}} comment on your post:</p>
                <div fxFlex fxLayout="row" class="pt-8 pb-8">
                    <img *ngIf="comment.contactAvatar" matTooltip="{{comment.contactName}}" matTooltipPosition="above" class="avatar commment-avatar" [src]="comment.contactAvatar"
                         alt=""/>
                    <img *ngIf="!comment.contactAvatar" matTooltip="{{comment.contactName}}" matTooltipPosition="above" class="avatar commment-avatar"
                         [src]="'assets/images/user-avatar.jpg'"
                         alt=""/>
                    <p class="mt-0" style="font-style: italic">"{{comment.text}}"</p>
                </div>
            </ng-container>
            <ng-container *ngIf="type === 'messages'">
                <div class="message-container" *ngFor="let message of comment">
                    <div class="profile mb-4" fxLayout="row">
                        <div class="profile-picture">
                            <img *ngIf="message.from.id !== network.network_id" class="avatar commment-avatar" src="assets/images/user-avatar.jpg">
                            <img *ngIf="message.from.id === network.network_id" class="avatar commment-avatar" src="{{network.picture}}">
                        </div>
                        <div class="user" fxLayout="column">
                            <div class="name">{{message.from.name}}</div>
                            <div class="time">{{message['created_time'] | date : 'medium'}}</div>
                        </div>
                    </div>
                    <div class="text-message ml-52" *ngIf="message.message !== null && message.message !== ''">
                        {{message.message}}
                    </div>
                </div>
            </ng-container>
            <mat-progress-bar *ngIf="sendLoading" mode="indeterminate"></mat-progress-bar>
            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <textarea [disabled]="validateReplyWindow()" class="emojis-input" matInput #privateMessage rows="5" [(ngModel)]="text" style="width: 100%" placeholder="Reply privately to this comment..."></textarea>
            </mat-form-field>
            <i class="far fa-grin-alt add-emoji" matTooltip="Add Emojis" matTooltipPosition="above" (click)="emojiClicked($event)"></i>
        </div>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button
            mat-button
            class="save-button mat-button-base"
            (click)="dialogRef.close()"
            aria-label="CLOSE">
            CLOSE
        </button>
        <button [disabled]="text === ''"
            mat-button
            class="save-button mat-focus-indicator mat-accent mat-raised-button mat-button-base"
            (click)="sendPrivateMessage()"
            aria-label="SAVE">
            SAVE
        </button>
    </div>
</div>
<!--<div #emojiBlock-->
<!--     [ngStyle]="{-->
<!--                visibility: (showEmojis ? 'unset' : 'hidden'),-->
<!--                height: (showEmojis ? 'auto' : '0px'),-->
<!--                position: 'absolute',-->
<!--                top: '36%',-->
<!--                left: 'calc(50% - 225px)'-->
<!--    }">-->
<!--    <emoji-mart color="#2196f3" set="apple" (emojiSelect)="emojiSelection($event)"></emoji-mart>-->
<!--</div>-->
