<div id="networks" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent"
             fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0"
                 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    phonelink
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Social Networks
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Search for a network">
                </div>
            </div>
            <!-- / SEARCH -->

            <a mat-raised-button (click)="openNewNetworkModal()"  *ngIf="currentUser.userLogged['role'] !== 'BASIC'">
                <mat-icon>find_in_page</mat-icon>
                <span>ADD NETWORKS</span>
            </a>

            <!-- ADD BUTTON -->
<!--            <button mat-raised-button-->
<!--                    [routerLink]="'/apps/social-media-management/networks/new'"-->
<!--                    class="add-network-button fuse-white mt-24 mt-md-0">-->
<!--                <span>ADD NEW NETWORK</span>-->
<!--            </button>-->
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="networks-table"
                       #table [dataSource]="dataSource"
                       matSort
                       [@animateStagger]="{value:'50'}"
                       fusePerfectScrollbar>

                <!-- Image Column -->
                <ng-container matColumnDef="image">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let network">
                        <img class="network-image"
                             *ngIf="network.picture" [alt]="network.name"
                             [src]="network.picture"/>
                        <img class="network-image"
                             *ngIf="!network.picture && network.network_type === 'FACEBOOK'"
                             src="../../../../../assets/images/logos/Cracked-Facebook-Logo.png" width="40px"/>
                        <img class="network-image"
                             *ngIf="!network.picture && network.network_type === 'INSTAGRAM'"
                             src="../../../../../assets/images/logos/seeklogo.com.png" width="40px"/>
                        <img class="network-image"
                             *ngIf="!network.picture && network.network_type === 'TWITTER'"
                             src="../../../../../assets/images/logos/rpn24k_large.png" width="40px"/>
                        <img class="network-image"
                             *ngIf="!network.picture && network.network_type === 'LINKEDIN'"
                             src="../../../../../assets/images/logos/linkedin-1653094-1402382.webp" width="40px"/>
                    </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Network Name</mat-header-cell>
                    <mat-cell *matCellDef="let network">
                        <p class="text-truncate">
                            <i *ngIf="network.network_type === 'FACEBOOK'" class="fab fa-facebook" style="font-size: 25px;"></i>
                            <i *ngIf="network.network_type === 'TWITTER'" class="fab fa-x-twitter" style="font-size: 25px;"></i>
                            <i *ngIf="network.network_type === 'LINKEDIN'" class="fab fa-linkedin-in" style="font-size: 25px;"></i>
                            <i *ngIf="network.network_type === 'INSTAGRAM'" class="fab fa-instagram" style="font-size: 25px;"></i>
                            {{network.name}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Category Column -->
                <ng-container matColumnDef="category">
                    <mat-header-cell *matHeaderCellDef fxHide mat-sort-header fxShow.gt-md>Category</mat-header-cell>
                    <mat-cell *matCellDef="let network" fxHide fxShow.gt-md>
                        <p class="category text-truncate">
                            {{network.category}}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Price Column -->
                <ng-container matColumnDef="created_at">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Created At</mat-header-cell>
                    <mat-cell *matCellDef="let network" fxHide fxShow.gt-xs>
                        <p class="category text-truncate">
                            {{network.created_at | date: 'M-d-yyyy HH:mm'}}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Quantity Column -->
                <!--<ng-container matColumnDef="quantity">-->
                    <!--<mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Quantity</mat-header-cell>-->
                    <!--<mat-cell *matCellDef="let network" fxHide fxShow.gt-sm>-->

                        <!--<span class="quantity-indicator text-truncate"-->
                              <!--[ngClass]="{'red-500':network.quantity <= 5, 'amber-500':network.quantity > 5 && network.quantity <= 25,'green-600':network.quantity > 25}">-->
                        <!--</span>-->
                        <!--<span>-->
                            <!--{{network.quantity}}-->
                        <!--</span>-->

                    <!--</mat-cell>-->
                <!--</ng-container>-->

                 Active Column
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let network" >
                        <button *ngIf="currentUser.userLogged['role'] !== 'BASIC'" class="mat-elevation-z mr-4" [routerLink]="'/apps/social-media-management/networks/'+network.id+'/'+network.network_id" mat-mini-fab color="primary" aria-label="Edit network">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button *ngIf="currentUser.userLogged['role'] !== 'BASIC'" class="mat-elevation-z" (click)="delete(network)" mat-mini-fab color="primary" aria-label="Delete network">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let network; columns: displayedColumns;"
                         class="network"
                         matRipple
                         >
                </mat-row>

            </mat-table>

            <mat-paginator #paginator
                           [length]="dataSource.filteredData.length"
                           [pageIndex]="0"
                           [pageSize]="10"
                           [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
