import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostComponent } from './post/post.component';
import {MatIconModule} from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { FuseSharedModule } from '@fuse/shared.module';
import {PostSmallViewComponent} from './post-small-view/post-small-view.component';
import {PostCommentsModule} from '../post-comments/post-comments.module';
import {MatMenuModule} from '@angular/material/menu';
import {CustomPipesModule} from '../../../pipes/custom-pipes.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import { PostShareViewComponent } from './post-share-view/post-share-view.component';
import {ShareComponent} from './share/share.component';
import {NetworksModule} from '../networks/networks.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCarouselModule} from '@ngbmodule/material-carousel';
import { UnpostedViewComponent } from './unposted-view/unposted-view.component';
import {MatBadgeModule} from "@angular/material/badge";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { LinkedinPostReactionsComponent } from './linkedin-post-reactions/linkedin-post-reactions.component';

@NgModule({
    declarations: [PostComponent, PostSmallViewComponent, PostShareViewComponent, ShareComponent, UnpostedViewComponent, LinkedinPostReactionsComponent],
    exports: [
        PostComponent,
        PostSmallViewComponent,
        PostShareViewComponent,
        ShareComponent,
        UnpostedViewComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        FuseSharedModule,
        PostCommentsModule,
        MatMenuModule,
        CustomPipesModule,
        MatCarouselModule,
        MatTooltipModule,
        NetworksModule,
        MatToolbarModule,
        MatDialogModule,
        MatBadgeModule,
        MatCheckboxModule
    ]
})
export class PostModule { }
