import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {CommonAuthService} from '../common-auth.service';
import {ClosedTermsComponent} from '../closed-terms/closed-terms.component';

@Component({
  selector: 'app-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.scss']
})
export class AcceptTermsComponent implements OnInit {
    loading = false;
    public envDataApiUrl = environment.apiUrl.replace('api/', '');

  constructor(
      public matDialogRef: MatDialogRef<AcceptTermsComponent>,
      private userService: CommonAuthService,
      private _matDialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

    sendAcceptance(): void {
        this.loading = true;
        this.userService.setTermsAcceptance().subscribe(response => {
            console.log(response);
            localStorage.setItem('user_logged', JSON.stringify(response.response.user));
            // this.authenticationService.setCustomUser(response.response.user);
            this.matDialogRef.close();
            window.location.reload();
            this.loading = false;
        }, error => {
            this.loading = false;
            console.log(error);
        });
    }

    closeAcceptance(): void {
        const modal = ClosedTermsComponent;
        const dialogRef = this._matDialog.open(modal, {
            maxWidth: '500px',
            width: '100%',
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('close dialog');
            if ( !result )
            {
                return;
            }
        });
        this.matDialogRef.close();
    }

}
