import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediaLibraryService {

  constructor(private httpClient: HttpClient) { }

  getMediaLibraries(): Observable<any> {
      return this.httpClient.get('api/calendar/media-libraries');
  }
}
