<div class="media-library-container" [@slideInRight]="{value:'*',params:{duration:'600ms', x:'60px'}}">
    <div class="header">
        <mat-form-field appearance="outline" floatLabel="always" class="w-75-p">
            <mat-label>Select media source</mat-label>
            <mat-select (valueChange)="mediaSelected($event)"  [(ngModel)]="mediaLibSelected" name="food">
                <mat-option *ngFor="let lib of mediaLibraries" [value]="lib.className">
                    {{lib.name}}
                </mat-option>
            </mat-select>
            <i matSuffix class="far fa-images"></i>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
            <mat-label>Media Filter</mat-label>
            <input matInput type="text" [(ngModel)]="queryValue" (keyup)="queryMediaSearch($event)" (keydown)="mediaFiltering = true" placeholder="Enter search term...">
            <button mat-button *ngIf="queryValue" matSuffix mat-icon-button aria-label="Clear" (click)="queryValue=''">
                <i class="fas fa-backspace" style="font-size: 20px;margin-top: -3px;margin-left: -3px;"></i>
            </button>
            <i style="font-size: 15px;position: relative;top: -4px;" matPrefix class="fas fa-search"></i>
        </mat-form-field>
    </div>
    <div class="body">

        <mat-progress-bar *ngIf="mediaFiltering" mode="indeterminate"></mat-progress-bar>
        <cdk-virtual-scroll-viewport [itemSize]="500" style="height: 535px;" (scrolledIndexChange)="mediaScroll($event)" >
            <div (click)="selectedMedia(img)" *ngFor="let img of this.mediaLibraryData.data; let j = index" class="media-image loading {{j}}"
                 [ngStyle]="{
                    backgroundColor: '#dddddd',
                    backgroundImage: 'url(' + img.previewUrl +')',
                    width: ((10000/img.height) * img.width) / 100 + 'px'
                 }"
            >
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</div>