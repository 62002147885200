<div class="settings">
    <form name="memberForm" [formGroup]="videoForm" class="w-100-p" fxLayout="column" fxFlex>
        <mat-tab-group>
            <mat-tab label="Video Preview">
                <div class="tab-content p-24 w-100-p">
                    <video controls [src]="videoUrl" class="w-100-p"></video>
                </div>
                <div style="justify-content: center;margin-bottom: 35px;" fxLayout="row" fxFlex>
                    <button mat-raised-button style="width: 100px;"
                            class="mat-focus-indicator mat-raised-button mat-button-base" (click)="cancelConverting()">
                        <span>CANCEL</span>
                    </button>
                </div>
            </mat-tab>
            <mat-tab label="Video Conversion">
                <div class="tab-content p-24" fusePerfectScrollbar fxLayout="column" fxFlex>
                    <div fxFlex fxLayout="row" class="w-100-p mb-16" fxLayoutAlign="center">
                        <button mat-raised-button class="facebook mr-8" (click)="setPredefinedSettings('facebook')">
                            <i class="fab fa-facebook" style="color: rgb(66, 103, 178);"></i>
                            Facebook Settings
                        </button>
                        <button mat-raised-button class="instagram mr-8" (click)="setPredefinedSettings('instagram')">
                            <i class="fab fa-instagram" style="color: rgb(217, 49, 117);"></i>
                            Instagram Settings
                        </button>
                        <button mat-raised-button class="twitter mr-8" (click)="setPredefinedSettings('twitter')">
                            <i class="fab fa-x-twitter" style="font-size: 17px;color: #000000;"></i>
                            <span>Twitter Settings</span>
                        </button>
                        <button mat-raised-button class="linkedin" (click)="setPredefinedSettings('linkedin')">
                            <i class="fab fa-linkedin-in" style="color: #0A66C2;"></i>
                            Linkedin Settings
                        </button>
                    </div>
                    <div fxFlex fxLayout="row" class="notice" *ngIf="notice !== ''">
                        <p>{{notice}}</p>
                    </div>
                    <div class="password-box w-100-p" fxLayout="row" fxFlex>
                        <mat-form-field appearance="outline"  floatLabel="always" class="w-100-p">
                            <mat-label>OUTPUT</mat-label>
                            <mat-select formControlName="voutput" name="role" required>
                                <mat-option value="mp4">MP4</mat-option>
<!--                                <mat-option value="avi">AVI</mat-option>-->
<!--                                <mat-option value="mpg">MPG</mat-option>-->
<!--                                <mat-option value="mov">MOV</mat-option>-->
<!--                                <mat-option value="flv">FLV</mat-option>-->
<!--                                <mat-option value="3gp">3GP</mat-option>-->
<!--                                <mat-option value="webm">WEBM</mat-option>-->
                                <mat-option value="mkv">MKV</mat-option>
<!--                                <mat-option value="mkv">Apple Video</mat-option>-->
<!--                                <mat-option value="mkv">Android Video</mat-option>-->
<!--                                <mat-option value="wmv">WMV</mat-option>-->
<!--                                <mat-option value="gif">GIF</mat-option>-->
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline"  floatLabel="always" class="w-100-p pl-4">
                            <mat-label>VIDEO ENCODER</mat-label>
                            <mat-select formControlName="vencoder" required>
                                <mat-option value="h.264">H.264</mat-option>
                                <mat-option value="mpeg-4">MPEG-4</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p pl-4">
                            <mat-label>VIDEO FRAME RATE</mat-label>
                            <input matInput formControlName="vframerate">
                        </mat-form-field>
                    </div>
                    <div class="password-box w-100-p" fxLayout="row" fxFlex>
                        <mat-form-field appearance="outline"  floatLabel="always" class="w-100-p pr-4">
                            <mat-label>VIDEO ROTATION</mat-label>
                            <mat-select formControlName="vrotation" name="role">
                                <mat-option value="0">No</mat-option>
                                <mat-option value="transpose=1">90 Clockwise</mat-option>
                                <mat-option value="hflip,vflip">180 Clockwise</mat-option>
                                <mat-option value="transpose=2">90 Counter Clockwise</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p pr-4">
                            <mat-label>VIDEO RESOLUTION</mat-label>
                            <input matInput formControlName="vresolution" placeholder="e.g. 1920x1080">
                        </mat-form-field>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p pr-4">
                            <mat-label>VIDEO BITRATE</mat-label>
                            <input matInput formControlName="vbitrate" placeholder="e.g. 1024K">
                        </mat-form-field>
                    </div>
                    <div class="password-box w-100-p" fxLayout="row" fxFlex>
                        <mat-form-field appearance="outline"  floatLabel="always" class="w-100-p pr-4">
                            <mat-label>VIDEO SCAN</mat-label>
                            <mat-select formControlName="vscan">
                                <mat-option value="progressive">Progressive</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline"  floatLabel="always" class="w-100-p pr-4">
                            <mat-label>VIDEO GOP</mat-label>
                            <mat-select formControlName="vgop">
                                <mat-option value="0">Closed</mat-option>
                                <mat-option value="1">Open</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline"  floatLabel="always" class="w-100-p">
                            <mat-label>VIDEO CHUNK START (seconds)</mat-label>
                            <input matInput formControlName="vchunk">
                        </mat-form-field>
                    </div>
                    <div class="password-box w-100-p" fxLayout="row" fxFlex>
                        <mat-form-field appearance="outline"  floatLabel="always" class="w-100-p pr-4">
                            <mat-label>VIDEO DURATION (seconds)</mat-label>
                            <input type="number" matInput formControlName="vduration">
                        </mat-form-field>
                        <mat-form-field appearance="outline"  floatLabel="always" class="w-100-p pr-4">
                            <mat-label>VIDEO SIZE (MB)</mat-label>
                            <input type="number" matInput formControlName="vsize">
                        </mat-form-field>
                        <mat-form-field appearance="outline"  floatLabel="always" class="w-100-p">
                            <mat-label>AUDIO CHANNEL</mat-label>
                            <mat-select formControlName="achannel">
                                <mat-option value="2">Stereo</mat-option>
                                <mat-option value="1">Mono</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="password-box w-100-p" fxLayout="row" fxFlex>
                        <mat-form-field appearance="outline"  floatLabel="always" class="w-100-p pr-4">
                            <mat-label>AUDIO ENCODER</mat-label>
                            <mat-select formControlName="aencoder">
                                <mat-option value="aac">AAC</mat-option>
                                <mat-option value="mp3">MP3</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p pr-4">
                            <mat-label>AUDIO SAMPLE RATE</mat-label>
                            <mat-select formControlName="asamplerate">
                                <mat-option value="8000">8000</mat-option>
                                <mat-option value="12000">12000</mat-option>
                                <mat-option value="16000">16000</mat-option>
                                <mat-option value="22050">22050</mat-option>
                                <mat-option value="24000">24000</mat-option>
                                <mat-option value="32000">32000</mat-option>
                                <mat-option value="44100">44100</mat-option>
                                <mat-option value="48000">48000</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p pr-4">
                            <mat-label>AUDIO QUALITY</mat-label>
                            <mat-select formControlName="aquality">
                                <mat-option value="48k">48k</mat-option>
                                <mat-option value="64k">64k</mat-option>
                                <mat-option value="80k">80k</mat-option>
                                <mat-option value="96k">96k</mat-option>
                                <mat-option value="128k">128k</mat-option>
                                <mat-option value="160k">160k</mat-option>
                                <mat-option value="256k">256k</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="converting" style="z-index: 1;margin: 20px;text-align: center;">
                    <span style="color: #2196f3;font-weight: 800;">{{percent}}%</span>
                    <mat-progress-bar color="accent" [mode]="'indeterminate'" [value]="percent"></mat-progress-bar>
                </div>
                <div style="justify-content: center;margin-bottom: 35px;" fxLayout="row" fxFlex>
                    <button *ngIf="videoConverted" mat-raised-button style="width: 150px;"
                            class="mat-focus-indicator mat-accent mat-raised-button mat-button-base mr-8" (click)="useVideo()">
                        <span>USE VIDEO</span>
                    </button>
                    <button mat-raised-button style="width: 150px;" [disabled]="converting"
                            class="mat-focus-indicator mat-accent mat-raised-button mat-button-base mr-8" (click)="convert()">
                        <span>{{buttonText}}</span>
                    </button>
                    <button mat-raised-button style="width: 100px;"
                            class="mat-focus-indicator mat-raised-button mat-button-base" (click)="cancelConverting()">
                        <span>CANCEL</span>
                    </button>
                </div>
            </mat-tab>
            <mat-tab label="Current Video Settings">
                <div fxFlex fxLayout="row" class="tab-content p-24" *ngIf="mediaInfo !== null">
                    <div fxFlex fxLayout="column">
                        <label>Video Settings:</label>
                        <ul>
                            <li><span>OUTPUT:</span> {{mediaInfo.voutput}}</li>
                            <li><span>ENCODER:</span> {{mediaInfo.vencoder}}</li>
                            <li><span>FRAME RATE:</span> {{mediaInfo.vframerate}}</li>
                            <li><span>ROTATION:</span> {{mediaInfo.vrotation}}</li>
                            <li><span>RESOLUTION:</span> {{mediaInfo.vresolution}}</li>
                            <li><span>BITRATE:</span> {{mediaInfo.vbitrate}}</li>
                            <li><span>SCAN:</span> {{mediaInfo.vscan}}</li>
                            <li><span>GOP:</span> {{mediaInfo.vgop}}</li>
                            <li><span>DURATION:</span> {{mediaInfo.vduration}}s</li>
                            <li><span>SIZE:</span> {{mediaInfo.vsize}}</li>
                        </ul>
                    </div>
                    <div fxFlex fxLayout="column">
                        <label>Audio Settings:</label>
                        <ul>
                            <li><span>CHANNEL:</span> {{mediaInfo.achannel}}</li>
                            <li><span>ENCODER:</span> {{mediaInfo.aencoder}}</li>
                            <li><span>SAMPLE RATE:</span> {{mediaInfo.asamplerate}}</li>
                            <li><span>QUALITY:</span> {{mediaInfo.aquality}}</li>
                        </ul>
                    </div>
                </div>
                <div fxFlex fxLayout="row" class="tab-content p-24 text-center" *ngIf="mediaInfo === null">
                    There is not any media information provided.
                </div>
            </mat-tab>
        </mat-tab-group>
    </form>
</div>