import { Injectable } from '@angular/core';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Network} from '../../../models/network';
import {
    FacebookPost,
    InstagramPost,
    LinkedinPost,
    PinterestPost,
    Post,
    TwitterPost,
    YoutubePost
} from '../../../models/post';
import {Media} from '../../../models/media';
import {Tag} from '../../../models/tag';
import {AudienceFacebook, AudienceLinkedin} from '../../../models/audience';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagePostsService {

    constructor(private http: HttpClient) { }

    uploadMedia(file, networks: Array<Network> = []): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('networks', JSON.stringify(networks));
        return this.http.post(`${environment.apiUrl}` + 'medias', formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(100 * event.loaded / event.total);
                        return { status: 'progress', message: progress };
                    case HttpEventType.Response:
                        return event.body;
                    default:
                        return null;
                }
            })
        );
    }

    updateMedia(file, mediaId: number): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post(`${environment.apiUrl}` + 'medias/update/' + mediaId, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(100 * event.loaded / event.total);
                        return { status: 'progress', message: progress };
                    case HttpEventType.Response:
                        return event.body;
                    default:
                        return null;
                }
            })
        );
    }

    createPost(
        network: Network,
        postDate: Date,
        text: string,
        text_twitter: string,
        media: Media[],
        status: string,
        tags: Tag[],
        audience: AudienceFacebook | AudienceLinkedin,
        location: {id: number, label: string},
        publishType: string,
        link: any,
        from: any = null,
        title: string = '',
        destination = '',
        boards = []
    ): Post {
        let post;
        if (network !== null && typeof network.network_type !== 'undefined' && network.network_type !== null) {
            switch (network.network_type.toLowerCase()) {
                case 'facebook':
                    post = new FacebookPost();
                    post.audience = audience;
                    post.location = location;
                    break;
                case 'instagram':
                    post = new InstagramPost();
                    post.location = location;
                    break;
                case 'twitter':
                    post = new TwitterPost();
                    post.location = location;
                    break;
                case 'linkedin':
                    post = new LinkedinPost();
                    post.audience = audience;
                    break;
                case 'pinterest':
                    post = new PinterestPost();
                    post.title = title;
                    post.boards = boards;
                    post.destination = destination;
                    break;
                case 'youtube':
                    post = new YoutubePost();
                    post.title = title;
                    break;
                default:
                    post = new Post();
            }
        }
        post.network = network;
        post.postDate = postDate;
        post.text = text;
        post.text_twitter  = text_twitter;
        post.type = (network !== null) ? network.network_type : null;
        post.media = media;
        post.status = status;
        post.tags = tags;
        post.publishType = publishType;
        post.link = link;
        post.from = from;
        return post;
    }

    getLinkPreview(link: string, short = false): Observable<any> {
        return this.http.get(`${environment.apiUrl}` + 'link-preview?url=' + link + '&short=' + short);
    }

    getLinkShortener(link: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}` + 'link-shortener', {url: link});
    }

    changeFileName(url: string, newName: string): Observable<any> {
        return this.http.put(`${environment.apiUrl}post/medias/rename`, {url: url, name: newName});
    }
}
