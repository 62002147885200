import { NgModule } from '@angular/core';

import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';
import { VerticalLayout2Module } from 'app/layout/vertical/layout-2/layout-2.module';
import { VerticalLayout3Module } from 'app/layout/vertical/layout-3/layout-3.module';

import { HorizontalLayout1Module } from 'app/layout/horizontal/layout-1/layout-1.module';
import { PrivateMessageComponent } from './components/private-message/private-message.component';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {FuseSharedModule} from '../../@fuse/shared.module';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatInputModule} from "@angular/material/input";
import {PickerModule} from "@ctrl/ngx-emoji-mart";
import {FuseProgressBarModule} from "../../@fuse/components";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { CircleLoaderComponent } from './components/circle-loader/circle-loader.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { ListViewMessageComponent } from './components/private-message/list-view-message/list-view-message.component';

@NgModule({
    imports: [
        VerticalLayout1Module,
        VerticalLayout2Module,
        VerticalLayout3Module,

        HorizontalLayout1Module,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatDialogModule,
        FuseSharedModule,
        MatTooltipModule,
        MatInputModule,
        PickerModule,
        FuseProgressBarModule,
        MatProgressBarModule,
        MatProgressSpinnerModule
    ],
    exports: [
        VerticalLayout1Module,
        VerticalLayout2Module,
        VerticalLayout3Module,

        HorizontalLayout1Module,
        CircleLoaderComponent,
        ListViewMessageComponent
    ],
    declarations: [PrivateMessageComponent, CircleLoaderComponent, ListViewMessageComponent]
})
export class LayoutModule
{
}
