import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Tag} from '../../../models/tag';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
    private subject = new Subject<Tag[]>();
    private tags: Tag[] = [];

    constructor(private httpClient: HttpClient) { }

    getTagsList(): Observable<any> {
        return this.httpClient.get('api/calendar/tags');
    }

    saveTag(tag: Tag): Observable<any> {
        return this.httpClient.post('api/calendar/tags', tag);
    }

    addTag(tag: Tag): void {
        this.tags.push(tag);
        this.subject.next(this.tags);
    }

    addTags(tags: Tag[]): void {
        this.subject.next(tags);
    }

    updateTag(index, post): void {
        this.tags[index] = post;
        this.subject.next(this.tags);
    }

    deleteTag(index): void {
        this.tags.splice(index, 1);
        this.subject.next();
    }

    getTags(): Observable<Tag[]> {
        return this.subject.asObservable();
    }
}
