import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {AgencyService} from './agency.service';
import {Member} from '../member/member.model';
import {takeUntil} from 'rxjs/operators';
import {FuseUtils} from "../../../../../@fuse/utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Location} from "@angular/common";
import {fuseAnimations} from "../../../../../@fuse/animations";

@Component({
    selector: 'app-agency',
    templateUrl: './agency.component.html',
    styleUrls: ['./agency.component.scss'],
    animations   : fuseAnimations,
})
export class AgencyComponent implements OnInit {
    agency: any;
    pageType: string;
    agencyForm: FormGroup;
    private _unsubscribeAll: Subject<any>;

    constructor(private agencyService: AgencyService, private _formBuilder: FormBuilder, private _matSnackBar: MatSnackBar, private _location: Location) {
        this._unsubscribeAll = new Subject();
        this.agency = new Agency();
    }

    ngOnInit(): void {
        this.agencyService.onAgencyChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(agency => {

                if ( agency )
                {
                    this.agency = new Agency(agency);
                    this.pageType = 'edit';
                }
                else
                {
                    this.pageType = 'new';
                    this.agency = new Agency();
                }

                this.agencyForm = this.createAgencyForm();
            });
    }

    /**
     * Create agency form
     *
     * @returns {FormGroup}
     */
    createAgencyForm(): FormGroup
    {
        return this._formBuilder.group({
            id              : [this.agency.id],
            name            : [this.agency.name],
            email            : [this.agency.email, [Validators.email]],
            address_line_1            : [this.agency.address_line_1],
            address_line_2            : [this.agency.address_line_2],
            city            : [this.agency.city],
            state            : [this.agency.state],
            country            : [this.agency.country],
            zip_code            : [this.agency.zip_code],
            phone            : [this.agency.phone],
            user_id            : [this.agency.user_id]
        });
    }

    /**
     * Save agency
     */
    saveAgency(): void
    {
        const data = this.agencyForm.getRawValue();
        // data.handle = FuseUtils.handleize(data.name);

        this.agencyService.saveAgency(data)
            .then(() => {

                // Trigger the subscription with new data
                this.agencyService.onAgencyChanged.next(data);

                // Show the success message
                this._matSnackBar.open('Agency saved', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }

    /**
     * Add agency
     */
    addAgency(): void
    {
        const data = this.agencyForm.getRawValue();
       //  data.handle = FuseUtils.handleize(data.name);

        this.agencyService.addAgency(data)
            .then(() => {

                // Trigger the subscription with new data
                this.agencyService.onAgencyChanged.next(data);

                // Show the success message
                this._matSnackBar.open('Agency added', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });

                // Change the location with new one
                this._location.go('apps/social-media-management/agencies/' + this.agency.id);
            });
    }

}

export class Agency {
    id: string;
    name: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string;
    country: string;
    zip_code: string;
    email: string;
    phone: string;
    user_id: string;
    member: Member;
    created_by: string;
    members: Array<Member>;

    /**
     * Constructor
     *
     * @param agency
     */
    constructor(agency?)
    {
        agency = agency || {};
        this.id = agency.id || null;
        this.name = agency.name || '';
        this.email = agency.email || '';
        this.address_line_1 = agency.address_line_1 || '';
        this.address_line_2 = agency.address_line_2 || '';
        this.city = agency.city || '';
        this.state = agency.state || '';
        this.country = agency.country || '';
        this.zip_code = agency.zip_code || '';
        this.phone = agency.phone || '';
        this.user_id = agency.user_id || '';
        this.member = agency.member || '';
        this.members = (typeof agency.members !== 'undefined' && agency.members.length > 0) ? agency.members.filter(item => item.role === 'ADMIN' || item.role === 'MANAGER') : [];
        this.created_by = agency.created_by || '';
    }
}
