import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Observable, Subject} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

import { Team } from 'app/main/apps/social-media-management/team/team.model';
import { SocialMediaManagementTeamService } from 'app/main/apps/social-media-management/team/team.service';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {ManageNetworksService} from "../../manage-networks/manage-networks.service";
import {Network} from "../../../../models/network";
import {SocialMediaManagementMembersService} from "../members/members.service";
import {Member} from "../../../../models/member";
import {SocialMediaManagementNetworkService} from "../network/network.service";
import {SocialMediaManagementNetworksService} from "../networks/networks.service";
import {FuseProgressBarService} from "../../../../../@fuse/components/progress-bar/progress-bar.service";

@Component({
    selector     : 'social-media-management-team',
    templateUrl  : './team.component.html',
    styleUrls    : ['./team.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class SocialMediaManagementTeamComponent implements OnInit, OnDestroy {
    team: any;
    pageType: string;
    teamForm: FormGroup;
    selectedNetworks: Array<any> = [];
    selectedMembers: Array<any> = [];
    members: Array<any> = [];

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = false;
    separatorKeysCodes = [ENTER, COMMA];

    fruitCtrl = new FormControl();
    filteredFruits: Observable<any[]>;
    fruits = [
        'Lemon',
    ];
    allFruits = [
        'Apple',
        'Lemon',
        'Lime',
        'Orange',
        'Strawberry'
    ];

    networkCtrl = new FormControl();
    filteredNetworks: Observable<any[]>;
    networks: Array<any> = [];

    memberCtrl = new FormControl();
    filteredMembers: Observable<any[]>;
    allMembers = [];

    inputLoader = false;


    @ViewChild('fruitInput') fruitInput: ElementRef;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    @ViewChild('networkInput') networkInput: ElementRef;
    @ViewChild('autoNet') matAutocompleteNetwork: MatAutocomplete;

    @ViewChild('memberInput') memberInput: ElementRef;
    @ViewChild('autoMember') matAutocompleteMember: MatAutocomplete;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     * @param {SocialMediaManagementMembersService} memberService
     * @param {ManageNetworksService} networksService
     * @param {SocialMediaManagementTeamService} _ecommerceTeamService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     * @param {FuseProgressBarService} _fuseProgressBarService
     */
    constructor(
        private _ecommerceTeamService: SocialMediaManagementTeamService,
        private _formBuilder: FormBuilder,
        private _location: Location,
        private _matSnackBar: MatSnackBar,
        private networksService: SocialMediaManagementNetworksService,
        private memberService: SocialMediaManagementMembersService,
        private _fuseProgressBarService: FuseProgressBarService
    ) {
        // Set the default
        this.team = new Team();

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
            startWith(null),
            map((fruit: string | null) => fruit ? this.filter(fruit) : this.allFruits.slice()));

        this.filteredNetworks = this.networkCtrl.valueChanges.pipe(
            startWith(null),
            map((network: string | null) => network ? this._filter(network) : this.networks.slice()));

        this.filteredMembers = this.memberCtrl.valueChanges.pipe(
            startWith(null),
            map((member: string | null) => member ? this.filterMember(member) : this.members.slice()));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to update team on changes
        this._ecommerceTeamService.onTeamChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(team => {
                if (team) {
                    this.team = new Team(team);
                    this.pageType = 'edit';
                    this.selectedNetworks = this.team.networks;
                    this.selectedMembers = this.team.members;
                } else {
                    this.pageType = 'new';
                    this.team = new Team();
                }

                this.teamForm = this.createTeamForm();
            });
        this.getNetworks();
        this.getMembers();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create team form
     *
     * @returns {FormGroup}
     */
    createTeamForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.team.id],
            name: [this.team.name],
            handle: [''],
            description: [this.team.description],
            networks: [this.team.networks],
            members: [this.team.members],
            image: [this.team.picture],
        });
    }

    /**
     * Save team
     */
    saveTeam(): void {
        this._fuseProgressBarService.show();
        const data = this.teamForm.getRawValue();
        data.handle = FuseUtils.handleize(data.name);

        this._ecommerceTeamService.saveTeam(data)
            .then(() => {

                // Trigger the subscription with new data
                this._ecommerceTeamService.onTeamChanged.next(data);

                this._fuseProgressBarService.hide();

                // Show the success message
                this._matSnackBar.open('Team saved', 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    /**
     * Add team
     */
    addTeam(): void {
        const data = this.teamForm.getRawValue();
        data.handle = FuseUtils.handleize(data.name);

        this._ecommerceTeamService.addTeam(data)
            .then(() => {

                // Trigger the subscription with new data
                this._ecommerceTeamService.onTeamChanged.next(data);

                // Show the success message
                this._matSnackBar.open('Team added', 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });

                // Change the location with new one
                this._location.go('apps/social-media-management/teams/' + this.team.id + '/' + this.team.handle);
            });
    }

    private updateNetInput(): void {
        this.teamForm.controls.networks.setValue(this.selectedNetworks);
    }

    private updateMemInput(): void {
        this.teamForm.controls.members.setValue(this.selectedMembers);
    }

    selectAllNetworks(): void {
        this.selectedNetworks = [];
        for (let i in this.networks)
            this.selectedNetworks.push(this.networks[i]);
        this.updateNetInput();
    }

    unSelectAllNetworks(): void {
        this.selectedNetworks = [];
        this.updateNetInput();
    }

    selectAllMembers(): void {
        this.selectedMembers = [];
        for (let i in this.members)
            this.selectedMembers.push(this.members[i]);
        this.updateMemInput();
    }

    unSelectAllMembers(): void {
        this.selectedMembers = [];
        this.updateMemInput();
    }

    private getNetworks(): void {
        this.inputLoader = true;
        this.networksService.getFullNetworks().subscribe(
            response => {
                this.networks = response.data;
                this.inputLoader = false;
            },
            error => { this.inputLoader = false; console.log(error); }
        );
    }

    private getMembers(): void {
        this.inputLoader = true;
        this.memberService.getMembers2().subscribe(
            response => {
                this.members = response.members;
                this.inputLoader = false;
            },
            error => { this.inputLoader = false; console.log(error); }
        );
    }

    addNetwork(event: MatChipInputEvent): void {
        this.updateNetInput();
    }

    removeNetwork(network: Network): void {
        const index = this.selectedNetworks.indexOf(network);
        if (index >= 0) {
            this.selectedNetworks.splice(index, 1);
        }
        this.networkInput.nativeElement.value = '';
        this.updateNetInput();
    }

    private _filter(value: string): Network[] {
        const filterValue = (typeof value['name'] !== 'undefined') ? value['name'].toLowerCase() : value.toLowerCase();
        return this.networks.filter(
            network => network.name.toLowerCase().indexOf(filterValue) === 0
                && this.selectedNetworks.findIndex(item => item.name === network.name) === -1
        );
    }

    selectedNetwork(event: MatAutocompleteSelectedEvent): void {
        this.selectedNetworks.push(event.option.value);
        this.networkInput.nativeElement.value = '';
        this.networkCtrl.setValue(null);
        this.updateNetInput();
    }

    addMember(event: MatChipInputEvent): void {
        this.updateMemInput();
    }

    removeMember(member: any): void {
        const index = this.selectedMembers.indexOf(member);
        if (index >= 0) {
            this.selectedMembers.splice(index, 1);
        }
        this.memberInput.nativeElement.value = '';
        this.updateMemInput();
    }

    filterMember(value: string): Member[] {
        const filterValue = (typeof value['name'] !== 'undefined') ? value['name'].toLowerCase() : value.toLowerCase();
        return this.members.filter(
            member => member.name.toLowerCase().indexOf(filterValue) === 0
                && this.selectedMembers.findIndex(item => item.name === member.name) === -1
        );
    }

    selectedMember(event: MatAutocompleteSelectedEvent): void {
        this.selectedMembers.push(event.option.value);
        this.memberInput.nativeElement.value = '';
        this.memberCtrl.setValue(null);
        this.updateMemInput();
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            this.fruits.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.fruitCtrl.setValue(null);
    }

    remove(fruit: any): void {
        const index = this.fruits.indexOf(fruit);

        if (index >= 0) {
            this.fruits.splice(index, 1);
        }
    }

    filter(name: string): string[] {
        return this.allFruits.filter(fruit =>
            fruit.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.fruits.push(event.option.viewValue);
        this.fruitInput.nativeElement.value = '';
        this.fruitCtrl.setValue(null);
    }
}
