<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Select Post Media </span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 pb-0 m-0" style="overflow: hidden;">
        <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="example-radio-group media-select-radio-group"
            [(ngModel)]="index">
            <mat-radio-button style="height: 70px;width: 100%;" class="example-radio-button" [value]="0">
                <span style="white-space: nowrap;text-overflow: ellipsis;width: 400px;overflow-x: hidden;">{{(data[0].type.indexOf('image') !== -1 && data.length > 2) ? (data.length - 1) + ' images' : (data[0].file.name)}}</span>
                <div  class="div-back-image" *ngIf="data[0].type.indexOf('image') !== -1" [ngStyle]="{backgroundImage: 'url(' + data[0].source + ')'}" >
                    <div *ngIf="data[0].type.indexOf('image') !== -1 && data.length > 2" class="hover-more">+{{data.length - 2}}</div>
                </div>
                <video class="video-select" *ngIf="data[0].type.indexOf('video') !== -1" src="{{data[0].source}}"></video>
            </mat-radio-button>
            <mat-radio-button style="height: 70px;width: 100%;" class="example-radio-button" [value]="data.length">
                <span style="white-space: nowrap;text-overflow: ellipsis;width: 400px;overflow-x: hidden;">{{data[data.length - 1].file.name}}</span>
                <div  class="div-back-image" *ngIf="data[data.length - 1].type.indexOf('image') !== -1" [ngStyle]="{backgroundImage: 'url(' + data[data.length - 1].source + ')'}" >
                </div>
                <video class="video-select" *ngIf="data[data.length - 1].type.indexOf('video') !== -1" src="{{data[data.length - 1].source}}"></video>
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
        <button
            mat-button
            class="save-button mat-focus-indicator mat-accent mat-raised-button mat-button-base"
            (click)="matDialogRef.close({index: index})"
            aria-label="SAVE">
            SAVE
        </button>

    </div>
</div>

