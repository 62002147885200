import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {environment} from "../../../../../environments/environment";
import {tap} from "rxjs/operators";

@Injectable()
export class SocialMediaManagementNetworksService implements Resolve<any>
{
    networks: any[];
    onNetworksChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onNetworksChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getNetworks()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get networks
     *
     * @returns {Promise<any>}
     */
    getNetworks(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}all-networks/`)
                .subscribe((response: any) => {
                    this.networks = response['data'];
                    this.onNetworksChanged.next(this.networks);
                    resolve(response);
                }, reject);
        });
    }

    getNetworks2(): any
    {
        return this._httpClient.get(`${environment.apiUrl}all-networks/`);
    }

    getFullNetworks(): any
    {
        return this._httpClient.get(`${environment.apiUrl}full-networks/`);
    }

    removeNetwork(networkId: number): Observable<any> {
        return this._httpClient.delete(`${environment.apiUrl}networks/` + networkId).pipe(
            tap(item => { this.networks.splice(this.networks.findIndex(i => i.id === networkId), 1); this.onNetworksChanged.next(this.networks); })
        );
    }
}
