import {Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {Network} from '../../../../models/network';
import {ManageNetworksService} from '../../../../main/apps/manage-networks/manage-networks.service';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {takeUntil} from "rxjs/internal/operators";

@Component({
  selector: 'app-networks-selector',
  templateUrl: './networks-selector.component.html',
  styleUrls: ['./networks-selector.component.scss']
})
export class NetworksSelectorComponent implements OnInit, OnDestroy {
    networkInputControl: FormControl =  new FormControl('');
    filteredNetworks: Observable<Network[]>;
    inputLoader = false;
    @ViewChild('networkInput') networkInput: ElementRef<HTMLInputElement>;
    networks: Array<Network> = [];
    network: Network = null;
    @Input() multiple = false;
    @Input() onlyType: string = null;
    @Input() preSelectedNetworks: Array<Network | string> = [];
    @Output() networkSelection: EventEmitter<Network[]> = new EventEmitter<Network[]>();
    selectedNetworks: Array<Network> = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private networksService: ManageNetworksService
    ){}

    ngOnInit(): void {
        this.filteredNetworks = this.networkInputControl.valueChanges.pipe(
            startWith(null),
            map((network: string | null) => network ? this._filter(network) : this.networks.slice()));
        if (this.networks === null || this.networks.length === 0) {
            this.getNetworks();
        }

        this.networksService.onNetworksPassedUpdated.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            networks => {
                this.networks = networks;
                this.networkInputControl.setValue('');
            }
        );
    }

    private getNetworks(): void {
        this.inputLoader = true;
        this.networksService.getNetworks(this.onlyType).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            response => {
                this.networks = response.data;
                this.setPreselectedNetworks();
                this.inputLoader = false;
            },
            error => { this.inputLoader = false; console.log(error); }
        );
    }

    private _filter(value: string): Network[] {
        const filterValue = (typeof value['name'] !== 'undefined') ? value['name'].toLowerCase() : value.toLowerCase();
        return this.networks.filter(
            network => network.name.toLowerCase().indexOf(filterValue) === 0
                && this.selectedNetworks.findIndex(item => item.name === network.name) === -1
        );
    }

    selectedNetwork(event: MatAutocompleteSelectedEvent): void {
        this.network = event.option.value;
        this.selectedNetworks.push(event.option.value);
        this.networkSelection.emit(this.selectedNetworks);
        this.networkInputControl.setValue('');
        this.networkInput.nativeElement.value = '';
    }

    removeNetwork(network: Network): void {
        const index = this.selectedNetworks.indexOf(network);
        if (index >= 0) {
            this.selectedNetworks.splice(index, 1);
            this.networkSelection.emit(this.selectedNetworks);
        }
        this.networkInput.nativeElement.value = '';
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.unsubscribe();
    }

    private setPreselectedNetworks() {
        if (this.preSelectedNetworks.length > 0) {
            if (typeof this.preSelectedNetworks[0] === 'object') {
                // @ts-ignore
                this.selectedNetworks = this.preSelectedNetworks;
            } else {
                // @ts-ignore
                this.selectedNetworks = this.networks.filter(i => this.preSelectedNetworks.indexOf(String(i.network_id)) !== -1);
            }
        }
    }
}
