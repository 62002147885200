import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FileSnippet} from '../../file-uploader/file-uploader.component';

@Component({
  selector: 'app-select-posts-media',
  templateUrl: './select-posts-media.component.html',
  styleUrls: ['./select-posts-media.component.scss']
})
export class SelectPostsMediaComponent implements OnInit {
  index = 0;
  data: Array<FileSnippet> = [];
  constructor(
      public matDialogRef: MatDialogRef<SelectPostsMediaComponent>,
      @Inject(MAT_DIALOG_DATA) private _data: Array<FileSnippet>
  ) { }

  ngOnInit(): void {
      this.data = this._data;
  }

}
