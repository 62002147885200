import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AcceptTermsComponent} from '../accept-terms/accept-terms.component';

@Component({
  selector: 'app-closed-terms',
  templateUrl: './closed-terms.component.html',
  styleUrls: ['./closed-terms.component.scss']
})
export class ClosedTermsComponent implements OnInit {

    constructor(private _matDialog: MatDialog, public matDialogRef: MatDialogRef<ClosedTermsComponent>) { }

    ngOnInit(): void {
    }

    backToAcceptance(): void {
        const modal = AcceptTermsComponent;
        const dialogRef = this._matDialog.open(modal, {
            maxWidth: '500px',
            width: '100%',
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('close dialog');
            if ( !result )
            {
                return;
            }
        });
        this.matDialogRef.close();
    }

}
