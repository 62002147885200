import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MediaLibraryService} from '../media-library.service';
import {MediaLibraryApi} from '../../../../models/media-library-api';
import {PixabayService} from '../libraries/pixabay.service';
import {GiphyService} from '../libraries/giphy.service';
import { debounce } from 'lodash';
import {fuseAnimations} from '../../../../../@fuse/animations';

export class MediaLibraryPreview {
    public id: number;
    public url: string;
    public previewUrl: string;
    public source: string;
    public width: number;
    public height: number;
}

export class MediaLibraryData {
    public total: number;
    public data: Array<MediaLibraryPreview>;
    public page = 0;
}

@Component({
  selector: 'app-media-library',
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-library.component.scss'],
  animations: fuseAnimations
})
export class MediaLibraryComponent implements OnInit {
    mediaLibraries: Array<MediaLibraryApi> = [];
    mediaLibraryData: MediaLibraryData = {total: 0, data: [], page: 1};
    queryValue = '';
    mediaIServices = {};
    cachedIndex = 0;
    mediaLibSelected = 'Pixabay';
    mediaFiltering = false;
    @Output() selectedMediaData = new EventEmitter<MediaLibraryPreview>();

    constructor(private mediaService: MediaLibraryService, private pixabayService: PixabayService, private giphyService: GiphyService) {
        this.mediaIServices['Pixabay'] = pixabayService;
        this.mediaIServices['Giphy'] = giphyService;
        this.queryMediaSearch = debounce(this.queryMediaSearch, 1000);
    }

    ngOnInit(): void {
      this.getMediaLibraries();
      this.getMediaData();
      this.mediaLibraryData.page = 2;
    }

    getMediaLibraries(): void {
      this.mediaService.getMediaLibraries().subscribe(
          response => {
                this.mediaLibraries = response.data;
          },
          error => console.log(error)
      );
    }

    mediaSelected(event: any): void {
        this.mediaLibraryData.data = [];
        this.mediaLibraryData.total = 0;
        this.mediaLibraryData.page = 1;
        this.cachedIndex = 0;
        this.mediaLibSelected = event;
        this.mediaFiltering = true;
        this.getMediaData();
        this.mediaLibraryData.page = 2;
    }

    getMediaData(amount = 30): void {
        this.mediaIServices[this.mediaLibSelected].getMediaData(this.queryValue, amount, this.mediaLibraryData.page).subscribe(
            response => {
                this.mediaLibraryData.data.push(...response.data);
                this.mediaLibraryData.total = response.total;
                this.mediaFiltering = false;
            },
            error => console.log(error)
        );
    }

    mediaScroll(event: number): void {
        if ((event > this.cachedIndex)/* && event % 5 === 0*/) {
            this.mediaLibraryData.page = this.mediaLibraryData.page + 1;
            this.getMediaData(15);
            this.cachedIndex = event;
        }
    }

    chunkArray(array, size): Array<any> {
        const chunkedArr = [];
        let index = 0;
        while (index < array.length) {
            chunkedArr.push(array.slice(index, size + index));
            index += size;
        }
        return chunkedArr;
    }

    queryMediaSearch(event: KeyboardEvent): void {
        this.mediaLibraryData.data = [];
        this.mediaLibraryData.total = 0;
        this.mediaLibraryData.page = 1;
        this.cachedIndex = 0;
        this.getMediaData();
        this.mediaLibraryData.page = 2;
    }

    selectedMedia(img: MediaLibraryPreview): void {
        this.selectedMediaData.emit(img);
    }
}
