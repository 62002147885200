<div id="order" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent"
             fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-16" [routerLink]="'/apps/social-media-management/orders'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                     [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2">
                        Order {{order.reference}}
                    </div>
                    <div class="subtitle secondary-text">
                        <span>from</span>
                        <span>{{order.customer.firstName}} {{order.customer.lastName}}</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <mat-tab-group fxLayout="column" fxFlex>

                    <mat-tab label="Order Details">

                        <div class="order-details tab-content p-24" fusePerfectScrollbar>

                            <div class="section pb-48">

                                <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon class="m-0 mr-16 secondary-text">account_circle</mat-icon>
                                    <div class="h2 secondary-text">Customer</div>
                                </div>

                                <div class="customer mb-32">
                                    <table class="simple">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Company</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div fxLayout="row" fxLayoutAlign="start center">
                                                        <img class="avatar" [src]="order.customer.avatar">
                                                        <span class="name text-truncate">{{order.customer.firstName}}
                                                            {{order.customer.lastName}}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span class="email text-truncate">{{order.customer.email}}</span>
                                                </td>
                                                <td>
                                                    <span class="phone text-truncate">{{order.customer.phone}}</span>
                                                </td>
                                                <td>
                                                    <span class="company text-truncate">{{order.customer.company}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <mat-accordion class="addresses">

                                    <mat-expansion-panel>

                                        <mat-expansion-panel-header>
                                            <mat-panel-title>Shipping Address</mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <div fxFlex fxLayout="column">
                                            <div class="address h4 py-24">{{order.customer.shippingAddress.address}}
                                            </div>
                                            <agm-map class="w-100-p h-400" [zoom]="15"
                                                     [latitude]="order.customer.shippingAddress.lat"
                                                     [longitude]="order.customer.shippingAddress.lng">
                                                <agm-marker [latitude]="order.customer.shippingAddress.lat"
                                                            [longitude]="order.customer.shippingAddress.lng">
                                                </agm-marker>
                                            </agm-map>
                                        </div>

                                    </mat-expansion-panel>

                                    <mat-expansion-panel>

                                        <mat-expansion-panel-header>
                                            <mat-panel-title>Invoice Address</mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <div fxFlex fxLayout="column">
                                            <div class="address h4 py-24">{{order.customer.invoiceAddress.address}}
                                            </div>
                                            <agm-map class="w-100-p h-400" [zoom]="15"
                                                     [latitude]="order.customer.invoiceAddress.lat"
                                                     [longitude]="order.customer.invoiceAddress.lng">
                                                <agm-marker [latitude]="order.customer.invoiceAddress.lat"
                                                            [longitude]="order.customer.invoiceAddress.lng">
                                                </agm-marker>
                                            </agm-map>
                                        </div>

                                    </mat-expansion-panel>

                                </mat-accordion>

                            </div>

                            <div class="section pb-48">

                                <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon class="m-0 mr-16 secondary-text">access_time</mat-icon>
                                    <div class="h2 secondary-text">Order Status</div>
                                </div>

                                <table class="simple">
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            <th>Updated On</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let status of order.status">
                                            <td>
                                                <span class="status h6 p-4" [ngClass]="status.color">
                                                    {{status.name}}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {{status.date | date}}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <form class="update-status p-24"
                                      (ngSubmit)="updateStatus()"
                                      [formGroup]="statusForm"
                                      fxLayout="row" fxLayoutAlign="start center">

                                    <mat-form-field appearance="outline" class="mr-16" fxFlex>
                                        <mat-label>Status</mat-label>
                                        <mat-select formControlName="newStatus"
                                                    required>
                                            <mat-option *ngFor="let status of orderStatuses"
                                                        [value]="status.id">
                                                {{status.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <button mat-raised-button class="mat-accent"
                                            [disabled]="statusForm.invalid">
                                        Update Status
                                    </button>
                                </form>

                            </div>

                            <div class="section pb-48">

                                <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon class="m-0 mr-16 secondary-text">attach_money</mat-icon>
                                    <div class="h2 secondary-text">Payment</div>
                                </div>

                                <table class="simple">
                                    <thead>
                                        <tr>
                                            <th>TransactionID</th>
                                            <th>Payment Method</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span class="text-truncate">
                                                    {{order.payment.transactionId}}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="text-truncate">
                                                    {{order.payment.method}}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="text-truncate">
                                                    {{order.payment.amount}}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="text-truncate">
                                                    {{order.payment.date | date}}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div class="section pb-48">

                                <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon class="m-0 mr-16 secondary-text">local_shipping</mat-icon>
                                    <div class="h2 secondary-text">Shipping</div>
                                </div>

                                <table class="simple">
                                    <thead>
                                        <tr>
                                            <th>Tracking Code</th>
                                            <th>Carrier</th>
                                            <th>Weight</th>
                                            <th>Fee</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let shipping of order.shippingDetails">
                                            <td class="tracking-code">
                                                {{shipping.tracking}}
                                            </td>
                                            <td>
                                                {{shipping.carrier}}
                                            </td>
                                            <td>
                                                {{shipping.weight}}
                                            </td>
                                            <td>
                                                {{shipping.fee}}
                                            </td>
                                            <td>
                                                {{shipping.date}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </mat-tab>

                    <mat-tab label="Teams">
                        <div class="teams tab-content p-24" fusePerfectScrollbar>
                            <table class="simple">
                                <thead>
                                    <tr>
                                        <th class="w-80">ID</th>
                                        <th class="w-120">Image</th>
                                        <th>Name</th>
                                        <th class="w-120">Price</th>
                                        <th class="w-80">Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="product-row"
                                        *ngFor="let product of order.teams"
                                        [routerLink]="'/apps/social-media-management/teams/'+product.id+'/'+product.handle">
                                        <td>
                                            {{product.id}}
                                        </td>
                                        <td>
                                            <img class="product-image" [src]="product.image">
                                        </td>
                                        <td>
                                            {{product.name}}
                                        </td>
                                        <td>
                                            {{product.price}}
                                        </td>
                                        <td>
                                            {{product.quantity}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </mat-tab>

                    <mat-tab label="Invoice">

                        <div class="invoice tab-content p-24" fusePerfectScrollbar>

                            <div id="invoice" class="compact page-layout blank" fxLayout="row" fusePerfectScrollbar>

                                <div class="invoice-container">

                                    <!-- INVOICE -->
                                    <div class="card">

                                        <div class="header">
                                            <div class="invoice-date">{{order.date}}</div>

                                            <div fxLayout="row" fxLayoutAlign="space-between stretch">
                                                <div class="client">
                                                    <div class="invoice-number" fxLayout="row"
                                                         fxLayoutAlign="start center">
                                                        <span class="title">INVOICE</span>
                                                        <span class="number">{{order.reference}}</span>
                                                    </div>

                                                    <div class="info">
                                                        <div class="title">
                                                            {{order.customer.firstName}}
                                                            {{order.customer.lastName}}
                                                        </div>
                                                        <div class="address">
                                                            {{order.customer.invoiceAddress.address}}
                                                        </div>
                                                        <div class="phone">{{order.customer.phone}}</div>
                                                        <div class="email">{{order.customer.email}}</div>
                                                    </div>
                                                </div>

                                                <div class="issuer accent" fxLayout="row" fxLayoutAlign="start center">
                                                    <div class="logo">
                                                        <img src="assets/images/logos/fuse.svg">
                                                    </div>

                                                    <div class="info">
                                                        <div class="title">FUSE INC.</div>
                                                        <div class="address">2810 Country Club Road Cranford, NJ 07016
                                                        </div>
                                                        <div class="phone">+66 123 455 87</div>
                                                        <div class="email">hello@fuseinc.com</div>
                                                        <div class="website">www.fuseinc.com</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="content">

                                            <table class="simple invoice-table">
                                                <thead>
                                                    <tr>
                                                        <th>PRODUCT</th>
                                                        <th class="text-right">PRICE</th>
                                                        <th class="text-right">QUANTITY</th>
                                                        <th class="text-right">TOTAL</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let product of order.teams">
                                                        <td>
                                                            <div class="title">
                                                                {{product.name}}
                                                            </div>
                                                        </td>
                                                        <td class="text-right">
                                                            {{product.price | currency:'USD':'symbol'}}
                                                        </td>
                                                        <td class="text-right">
                                                            {{product.quantity}}
                                                        </td>
                                                        <td class="text-right">
                                                            {{product.total | currency:'USD':'symbol'}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table class="simple invoice-table-footer">
                                                <tbody>
                                                    <tr class="subtotal">
                                                        <td>SUBTOTAL</td>
                                                        <td>{{order.subtotal | currency:'USD':'symbol'}}</td>
                                                    </tr>
                                                    <tr class="tax">
                                                        <td>TAX</td>
                                                        <td>{{order.tax | currency:'USD':'symbol'}}</td>
                                                    </tr>
                                                    <tr class="discount">
                                                        <td>DISCOUNT</td>
                                                        <td>-{{order.discount | currency:'USD':'symbol'}}</td>
                                                    </tr>
                                                    <tr class="total">
                                                        <td>TOTAL</td>
                                                        <td>{{order.total | currency:'USD':'symbol'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="footer">
                                            <div class="note">Please pay within 15 days. Thank you for your business.
                                            </div>
                                            <div fxLayout="row" fxLayoutAlign="start start">
                                                <div class="logo">
                                                    <img src="assets/images/logos/fuse.svg">
                                                </div>
                                                <div class="small-note">
                                                    In condimentum malesuada efficitur. Mauris volutpat placerat auctor.
                                                    Ut ac congue dolor. Quisque scelerisque lacus sed feugiat fermentum.
                                                    Cras aliquet facilisis pellentesque. Nunc hendrerit quam at leo
                                                    commodo, a suscipit tellus dapibus. Etiam at felis volutpat est
                                                    mollis lacinia. Mauris placerat sem sit amet velit mollis, in
                                                    porttitor ex finibus. Proin eu nibh id libero tincidunt lacinia et
                                                    eget eros.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- / INVOICE -->

                                </div>
                            </div>
                        </div>
                    </mat-tab>

                </mat-tab-group>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->
</div>
