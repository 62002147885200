import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            const theValue = isNaN(value) ? value : Number(value);
            const seconds = Math.floor((+new Date() - +new Date(theValue)) / 1000);
            const intervals = {
                'year': 31536000,
                'month': 2592000,
                'week': 604800,
                'day': 86400,
                'hour': 3600,
                'minute': 60,
                'second': 1
            };
            if (seconds < 0) {
                const positive = -1 * seconds;
                let counter2;
                for (const i in intervals) {
                    counter2 = Math.floor(positive / intervals[i]);
                    if (counter2 > 0)
                        if (counter2 === 1) {
                            return counter2 + ' ' + i + ' to post'; // singular (1 day ago)
                        } else {
                            return counter2 + ' ' + i + 's to post'; // plural (2 days ago)
                        }
                }
            }
            if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
                return 'Just now';
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        return counter + ' ' + i + ' ago'; // singular (1 day ago)
                    } else {
                        return counter + ' ' + i + 's ago'; // plural (2 days ago)
                    }
            }
        }
        return value;
    }

}
