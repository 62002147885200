import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(public alert: ToastrService) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // note add this header for CORS pass
        const token = localStorage.getItem('token');
        let user = JSON.parse(localStorage.getItem('user_logged'));
        if (typeof user !== 'undefined' && user !== null && typeof user['role'] !== 'undefined' && user['role'] !== null
            && user['role'] === 'ADMIN' && typeof user['email'] !== 'undefined' && user['email'] !== null && user['email'] === 'accounting@thinkstrategic.com' && req.method !== 'GET') {
            this.alert.error('You don\'t have enough permissions for this action.');
            return throwError(null);
        }
        if (token && req.url.indexOf('pixabay') === -1 && req.url.indexOf('linkedin-v2') === -1) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        return next.handle(req);
    }
}
