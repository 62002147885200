import {
    AfterViewInit,
    Component,
    ElementRef, EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Comment} from './../../../../models/comment';
import {Network} from '../../../../models/network';
import {ChatService} from '../../../../main/apps/chat/chat.service';
import {PrivateMessageComponent} from '../../private-message/private-message.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PostCommentsService} from '../post-comments.service';
import {Subscription} from 'rxjs';
import {Post} from '../../../../models/post';
import {FuseConfirmDialogComponent} from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {FuseProgressBarService} from '../../../../../@fuse/components/progress-bar/progress-bar.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() comments: Array<Comment> = [];
    @Input() post: Post = null;
    @Input() showCommentInput = false;
    @Input() commentParent = null;
    @Input() parent = null;
    @Input() fromBoard = false;
    @Output() tagInput: EventEmitter<Comment> = new EventEmitter<Comment>();
    showSubCommentInput = false;
    showEmojis = false;
    @ViewChild('commentInput') commentInput: ElementRef<HTMLInputElement>;
    @ViewChild('commentContainer') commentContainer: any;
    @ViewChild('emojiIcon') emojiIcon: ElementRef<HTMLInputElement>;
    cursorRange = [0, 0];
    flagShowComment = false;
    emojiSub$: Subscription;
    commentSub$: Subscription;
    // userTagSub$: Subscription;
    taggedComment: Comment = null;
    showOnlyMainInput = false;
    commentLoading = false;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    constructor(
        private chatService: ChatService,
        public dialog: MatDialog,
        private postCommService: PostCommentsService,
        private _fuseProgressBarService: FuseProgressBarService
    ) { }

    ngOnInit(): void {
        this.showOnlyMainInput = this.fromBoard;
        this.commentSub$ = this.chatService.getCommentStatus().subscribe(
            status => {
                (this.flagShowComment) ? this.showCommentInput = true : this.showCommentInput = false;
                this.flagShowComment = false;
            },
            (error) => console.log(error)
        );
        // this.userTagSub$ = this.postCommService.getUserTaggedToInput().subscribe(
        //     data => {
        //         if (this.commentParent === null) {
        //             this.showCommentInput = true;
        //         }
        //         this.taggedComment = data;
        //         this.commentInput.nativeElement.innerHTML = '';
        //         this.commentInput.nativeElement.innerHTML = '<a>' + (this.post.type === 'instagram' ? '@' : '') + data.contactName + '</a>&nbsp;'
        //             + this.commentInput.nativeElement.innerHTML;
        //         this.moveCursorToEnd(this.commentInput.nativeElement);
        //     },
        //     error => console.log(error)
        // );
    }

    moveCursorToEnd(el): void {
        if (typeof el.selectionStart === 'number') {
            el.focus();
            el.selectionStart = el.selectionEnd = el.value.length;
        } else if (typeof el.createTextRange !== 'undefined') {
            el.focus();
            const range = el.createTextRange();
            range.collapse(false);
            range.select();
        }
    }

    removeFromComments(id: any): void {

    }

    deleteComment(comment: any): void {
        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete the comment?';
        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                if (this.post.type === 'facebook' || this.post.type === 'instagram') {
                    this._fuseProgressBarService.show();
                    this.postCommService.removeFbComment({
                        network_id: this.post.network.id,
                        comment_id: comment.id
                    }).subscribe(response => {
                            console.log('response:', response);

                            this.comments = this.comments.filter( (el) => el.id !== comment.id);
                            this._fuseProgressBarService.hide();
                        },
                        error => { console.log(error); this._fuseProgressBarService.hide(); });
                } else if (this.post.type.toLowerCase() === 'linkedin') {
                    this.postCommService.deleteLinkedinComment(
                        this.post.network,
                        String(this.post.id),
                        comment.id,
                        'urn:li:organization:' + this.post.network.network_id
                    ).subscribe(
                        response => {
                            console.log(response);
                            this.comments = this.comments.filter( (el) => el.id !== comment.id);
                            this._fuseProgressBarService.hide();
                        },
                        error => console.log(error)
                    );
                }
            }
        });
    }

    ngAfterViewInit(): void {
    }

    sendPrivateMessage(comment: Comment): void {
        const dialogRef = this.dialog.open(PrivateMessageComponent, {
            maxWidth: '550px',
            width: '100%',
            disableClose: true,
            data: {comment, network: this.post.network, contactId: (this.post.type === 'twitter' ? comment['contactTwitterId'] : comment.contactId)}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (typeof result !== 'undefined' && result !== null) {

            }
        });
    }

    showComment(comment: Comment): void {
        this.flagShowComment = true;
        this.taggedComment = comment;
        this.chatService.setCommentStatus();
        this.commentInput.nativeElement.focus();
    }

    sendAnswer(): void {
        const newComment = {
            text: this.commentInput.nativeElement.innerText,
            contactId: (this.post.type.toLowerCase() !== 'linkedin') ? this.post.network.id : ('urn:li:organization:' + this.post.network.network_id),
            contactAvatar: this.post.network.picture,
            contactName: this.post.network.name,
            postDate: new Date(),
            tags: [],
            postId: this.post.id,
            commentParent: (this.taggedComment !== null) ? this.taggedComment.id : null,
            answers: []
        } as unknown as Comment;
        console.log('newComment:', newComment);
        console.log('taggedComment:', this.taggedComment, this.commentParent);
        if (this.taggedComment !== null) {
            switch (this.post.type.toLowerCase()) {
                case 'facebook':
                    this.commentLoading = true;
                    this.postCommService.sendFbComment({
                        network_id: newComment.postId.toString().split('_')[0],
                        object_id: this.taggedComment.id,
                        message: newComment.text.replace(this.taggedComment.contactName, '')
                    }).subscribe(comment => {
                            console.log('comment:', comment);
                            newComment.id = comment['id'];
                            if (typeof this.taggedComment === 'undefined' || typeof this.taggedComment.answers === 'undefined' || this.taggedComment.answers === null) {
                                this.taggedComment.answers = [newComment];
                            } else {
                                this.taggedComment.answers.push(newComment);
                            }
                            this.commentLoading = false;
                            this.commentInput.nativeElement.innerHTML = '';
                            this.taggedComment = null;
                        },
                        error => console.log(error));
                    break;
                case 'instagram':
                    this.commentLoading = true;
                    this.postCommService.sendInComment({
                        network_id: this.post.network.network_id,
                        object_id: this.post.id,
                        message: newComment.text.replace('@' + this.taggedComment.contactName, ''),
                        commentParent: newComment.commentParent
                    }).subscribe(comment => {
                            console.log('comment:', comment);
                            newComment.id = comment['id'];
                            if (typeof this.taggedComment === 'undefined' || typeof this.taggedComment.answers === 'undefined' || this.taggedComment.answers === null) {
                                this.taggedComment.answers = [newComment];
                            } else {
                                this.taggedComment.answers.push(newComment);
                            }
                            this.commentLoading = false;
                            this.commentInput.nativeElement.innerHTML = '';
                            this.taggedComment = null;
                        },
                        error => console.log(error));
                    break;
                case 'linkedin':
                    this.commentLoading = true;
                    this.postCommService.sendLinkedinPostCommment(this.post.network, this.taggedComment.id, newComment).subscribe(comment => {
                            console.log('comment:', comment);
                            newComment.id = comment['$URN'];
                            if (typeof this.taggedComment === 'undefined' || typeof this.taggedComment.answers === 'undefined' || this.taggedComment.answers === null) {
                                this.taggedComment.answers = [newComment];
                            } else {
                                this.taggedComment.answers.push(newComment);
                            }
                            this.commentLoading = false;
                            this.commentInput.nativeElement.innerHTML = '';
                            this.taggedComment = null;
                        },
                        error => console.log(error));
                    break;
                case 'twitter':
                    this.commentLoading = true;
                    this.postCommService.sendTwitterComment({
                        network_id: this.post.network.network_id,
                        object_id: this.post.id,
                        message: newComment.text,
                        to_reply_id: newComment.commentParent
                    }).subscribe(comment => {
                            console.log('comment:', comment);
                            newComment.id = comment['id'];
                            if (typeof this.taggedComment === 'undefined' || typeof this.taggedComment.answers === 'undefined' || this.taggedComment.answers === null) {
                                this.taggedComment.answers = [newComment];
                            } else {
                                this.taggedComment.answers.push(newComment);
                            }
                            this.commentLoading = false;
                            this.commentInput.nativeElement.innerHTML = '';
                            this.taggedComment = null;
                        },
                        error => console.log(error));
                    break;
            }


            // if (this.post.type === 'facebook') {
            //     this.commentLoading = true;
            //     this.postCommService.sendFbComment({
            //         network_id: newComment.postId.toString().split('_')[0],
            //         object_id: this.taggedComment.id,
            //         message: newComment.text.replace(this.taggedComment.contactName, '')
            //     }).subscribe(comment => {
            //             console.log('comment:', comment);
            //             newComment.id = comment['id'];
            //             if (typeof this.taggedComment === 'undefined' || typeof this.taggedComment.answers === 'undefined' || this.taggedComment.answers === null) {
            //                 this.taggedComment.answers = [newComment];
            //             } else {
            //                 this.taggedComment.answers.push(newComment);
            //             }
            //             this.commentLoading = false;
            //             this.commentInput.nativeElement.innerHTML = '';
            //             this.taggedComment = null;
            //         },
            //         error => console.log(error));
            // } else {
            //     this.commentLoading = true;
            //     this.postCommService.sendInComment({
            //         network_id: this.post.network.network_id,
            //         object_id: this.post.id,
            //         message: newComment.text.replace('@' + this.taggedComment.contactName, ''),
            //         commentParent: newComment.commentParent
            //     }).subscribe(comment => {
            //             console.log('comment:', comment);
            //             newComment.id = comment['id'];
            //             if (typeof this.taggedComment === 'undefined' || typeof this.taggedComment.answers === 'undefined' || this.taggedComment.answers === null) {
            //                 this.taggedComment.answers = [newComment];
            //             } else {
            //                 this.taggedComment.answers.push(newComment);
            //             }
            //             this.commentLoading = false;
            //             this.commentInput.nativeElement.innerHTML = '';
            //             this.taggedComment = null;
            //         },
            //         error => console.log(error));
            // }
        } else {
            if (this.commentParent !== null) {
                switch (this.post.type.toLowerCase()){
                    case 'facebook':
                        this.commentLoading = true;
                        this.postCommService.sendFbComment({
                            network_id: newComment.postId.toString().split('_')[0],
                            object_id: this.commentParent.id,
                            message: newComment.text.replace(this.commentParent.contactName, '')
                        }).subscribe(comment => {
                                console.log('comment:', comment);
                                newComment.id = comment['id'];
                                if (typeof this.commentParent === 'undefined' || typeof this.commentParent.answers === 'undefined' || this.commentParent.answers === null) {
                                    this.commentParent.answers = [newComment];
                                } else {
                                    this.commentParent.answers.push(newComment);
                                }
                                this.commentLoading = false;
                                this.commentInput.nativeElement.innerHTML = '';
                                this.taggedComment = null;
                            },
                            error => console.log(error));
                        break;
                    case 'instagram':
                        this.commentLoading = true;
                        this.postCommService.sendInComment({
                            network_id: this.post.network.network_id,
                            object_id: this.post.id,
                            message: newComment.text.replace('@' + this.commentParent.contactName, ''),
                            commentParent: newComment.commentParent
                        }).subscribe(comment => {
                                console.log('comment:', comment);
                                newComment.id = comment['id'];
                                if (typeof this.taggedComment === 'undefined' || typeof this.taggedComment.answers === 'undefined' || this.taggedComment.answers === null) {
                                    this.taggedComment.answers = [newComment];
                                } else {
                                    this.taggedComment.answers.push(newComment);
                                }
                                this.commentLoading = false;
                                this.commentInput.nativeElement.innerHTML = '';
                                this.taggedComment = null;
                            },
                            error => console.log(error));
                        break;
                    case 'linkedin':
                        this.commentLoading = true;
                        this.postCommService.sendLinkedinPostCommment(this.post.network, this.commentParent.id, newComment).subscribe(comment => {
                                console.log('comment:', comment);
                                newComment.id = comment['$URN'];
                                if (typeof this.commentParent === 'undefined' || typeof this.commentParent.answers === 'undefined' || this.commentParent.answers === null) {
                                    this.commentParent.answers = [newComment];
                                } else {
                                    this.commentParent.answers.push(newComment);
                                }
                                this.commentLoading = false;
                                this.commentInput.nativeElement.innerHTML = '';
                                this.taggedComment = null;
                            },
                            error => console.log(error));
                        break;
                }
                // if (this.post.type === 'facebook') {
                //     this.commentLoading = true;
                //     this.postCommService.sendFbComment({
                //         network_id: newComment.postId.toString().split('_')[0],
                //         object_id: this.commentParent.id,
                //         message: newComment.text.replace(this.commentParent.contactName, '')
                //     }).subscribe(comment => {
                //             console.log('comment:', comment);
                //             newComment.id = comment['id'];
                //             if (typeof this.commentParent === 'undefined' || typeof this.commentParent.answers === 'undefined' || this.commentParent.answers === null) {
                //                 this.commentParent.answers = [newComment];
                //             } else {
                //                 this.commentParent.answers.push(newComment);
                //             }
                //             this.commentLoading = false;
                //             this.commentInput.nativeElement.innerHTML = '';
                //             this.taggedComment = null;
                //         },
                //         error => console.log(error));
                // } else {
                //     this.commentLoading = true;
                //     this.postCommService.sendInComment({
                //         network_id: this.post.network.network_id,
                //         object_id: this.post.id,
                //         message: newComment.text.replace('@' + this.commentParent.contactName, ''),
                //         commentParent: newComment.commentParent
                //     }).subscribe(comment => {
                //             console.log('comment:', comment);
                //             newComment.id = comment['id'];
                //             if (typeof this.taggedComment === 'undefined' || typeof this.taggedComment.answers === 'undefined' || this.taggedComment.answers === null) {
                //                 this.taggedComment.answers = [newComment];
                //             } else {
                //                 this.taggedComment.answers.push(newComment);
                //             }
                //             this.commentLoading = false;
                //             this.commentInput.nativeElement.innerHTML = '';
                //             this.taggedComment = null;
                //         },
                //         error => console.log(error));
                // }
            } else {
                switch (this.post.type.toLowerCase()) {
                    case 'facebook':
                        this.commentLoading = true;
                        this.postCommService.sendFbComment({
                            network_id: newComment.postId.toString().split('_')[0],
                            object_id: this.post.id,
                            message: newComment.text
                        }).subscribe(comment => {
                                console.log('comment:', comment);
                                newComment.id = comment['id'];
                                if (typeof this.comments === 'undefined' || this.comments === null) {
                                    this.comments = [newComment];
                                } else {
                                    this.comments.push(newComment);
                                }
                                this.commentLoading = false;
                                this.commentInput.nativeElement.innerHTML = '';
                                this.taggedComment = null;
                            },
                            error => console.log(error));
                        break;
                    case 'instagram':
                        this.commentLoading = true;
                        this.postCommService.sendInComment({
                            network_id: this.post.network.network_id,
                            object_id: this.post.id,
                            message: newComment.text,
                            commentParent: newComment.commentParent
                        }).subscribe(comment => {
                                console.log('comment:', comment);
                                newComment.id = comment['id'];
                                if (typeof this.comments === 'undefined' || this.comments === null) {
                                    this.comments = [newComment];
                                } else {
                                    this.comments.push(newComment);
                                }
                                this.commentLoading = false;
                                this.commentInput.nativeElement.innerHTML = '';
                                this.taggedComment = null;
                            },
                            error => console.log(error));
                        break;
                    case 'linkedin':
                        this.commentLoading = true;
                        this.postCommService.sendLinkedinPostCommment(this.post.network, String(this.post.id), newComment).subscribe(comment => {
                                console.log('comment:', comment);
                                newComment.id = comment['$URN'];
                                if (typeof this.comments === 'undefined' || this.comments === null) {
                                    this.comments = [newComment];
                                } else {
                                    this.comments.push(newComment);
                                }
                                this.commentLoading = false;
                                this.commentInput.nativeElement.innerHTML = '';
                                this.taggedComment = null;
                            },
                            error => console.log(error));
                        break;
                }
                // if (this.post.type === 'facebook') {
                //     this.commentLoading = true;
                //     this.postCommService.sendFbComment({
                //         network_id: newComment.postId.toString().split('_')[0],
                //         object_id: this.post.id,
                //         message: newComment.text
                //     }).subscribe(comment => {
                //             console.log('comment:', comment);
                //             newComment.id = comment['id'];
                //             if (typeof this.comments === 'undefined' || this.comments === null) {
                //                 this.comments = [newComment];
                //             } else {
                //                 this.comments.push(newComment);
                //             }
                //             this.commentLoading = false;
                //             this.commentInput.nativeElement.innerHTML = '';
                //             this.taggedComment = null;
                //         },
                //         error => console.log(error));
                // } else {
                //     this.commentLoading = true;
                //     this.postCommService.sendInComment({
                //         network_id: this.post.network.network_id,
                //         object_id: this.post.id,
                //         message: newComment.text,
                //         commentParent: newComment.commentParent
                //     }).subscribe(comment => {
                //             console.log('comment:', comment);
                //             newComment.id = comment['id'];
                //             if (typeof this.comments === 'undefined' || this.comments === null) {
                //                 this.comments = [newComment];
                //             } else {
                //                 this.comments.push(newComment);
                //             }
                //             this.commentLoading = false;
                //             this.commentInput.nativeElement.innerHTML = '';
                //             this.taggedComment = null;
                //         },
                //         error => console.log(error));
                // }
            }
        }
        // this.postCommService.sendCommentAnswer(newComment).subscribe(
        //     response => {
        //         this.commentInput.nativeElement.innerText = '';
        //         this.showCommentInput = false;
        //         this.comments.push(newComment);
        //     },
        //     error => console.log(error)
        // );
    }

    tagUserInput(comment: Comment): void {
        // this.postCommService.setUserTaggedToInput(comment);
        // this.commentInput.nativeElement.focus();
        this.setTaggedComment(comment);
        this.tagInput.emit(comment);
    }

    ngOnDestroy(): void {
        this.commentSub$.unsubscribe();
    }

    emojiClicked(event: MouseEvent): void {
        this.chatService.onEmojisOpen.next(event);
    }

    setTaggedComment(event: Comment): void {
        if (this.commentParent === null) {
            this.showCommentInput = true;
        }
        this.taggedComment = event;
        this.commentInput.nativeElement.innerHTML = '';
        this.commentInput.nativeElement.innerHTML = '<a>' + (this.post.type === 'instagram' || this.post.type === 'twitter' ? '@' : '')
            + (this.post.type === 'twitter' ? event.contactId : event.contactName)
            + '</a>&nbsp;'
            + this.commentInput.nativeElement.innerHTML;
        this.commentInput.nativeElement.focus();
        // this.moveCursorToEnd(this.commentInput.nativeElement);
    }

    getComments(): void {
        this.commentLoading = true;
        this.postCommService.getLinkedinPostCommments(this.post.network, String(this.post.id)).subscribe(
            response => {
                console.log(response);
                this.post.comments = response;
                this.showOnlyMainInput = !this.showOnlyMainInput;
                this.commentLoading = false;
            }, error => console.log(error)
        );
    }

    likeUnlikeComment(comment: Comment): void {
        switch (this.post.type.toLowerCase()) {
            case 'linkedin':
                if (!comment.liked) {
                    this.postCommService.likeComment(
                        this.post.network,
                        comment.id,
                        'urn:li:organization:' + this.post.network.network_id,
                        String(this.post.id)
                    ).subscribe(
                        response => {
                            comment.liked = true;
                        },
                        error => console.log(error)
                    );
                } else {
                    this.postCommService.unLikeComment(
                        this.post.network,
                        comment.id,
                        'urn:li:organization:' + this.post.network.network_id
                    ).subscribe(
                        response => {
                            comment.liked = false;
                        },
                        error => console.log(error)
                    );
                }
                break;
        }
    }
}
