<div id="member" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/apps/social-media-management/agencies'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div class="member-image mr-8 mr-sm-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    <mat-icon>business</mat-icon>
<!--                    <img *ngIf="member.profile_photo" src="{{member.profile_photo}}">-->
                </div>

                <div fxLayout="column" fxLayoutAlign="start start"
                     [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="pageType ==='edit'">
                        {{agency.name}}
                    </div>
                    <div class="h2" *ngIf="pageType ==='new'">
                        New Agency
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Agency Detail</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button
                    class="save-member-button"
                    [disabled]="agencyForm.invalid"
                    *ngIf="pageType ==='new'" (click)="addAgency()">
                <span>ADD</span>
            </button>

            <button mat-raised-button
                    class="save-member-button"
                    [disabled]="agencyForm.invalid"
                    *ngIf="pageType ==='edit'" (click)="saveAgency()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="agencyForm" [formGroup]="agencyForm" class="member w-100-p" fxLayout="column" fxFlex>

                    <mat-tab-group>

                        <mat-tab label="Basic Info">

                            <div class="tab-content p-24" fxLayout="row wrap" fxLayoutAlign="center" style="overflow-x: hidden">

                                <mat-form-field disabled="true" appearance="outline" floatLabel="always" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" class="p-16">
                                    <mat-label>Agency Name</mat-label>
                                    <input matInput placeholder="Agency Name"
                                           name="name"
                                           formControlName="name" disabled required>
                                </mat-form-field>
                                <mat-form-field appearance="outline" floatLabel="always" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" class="p-16">
                                    <mat-label>Email</mat-label>
                                    <input matInput placeholder="Agency Email"
                                           type="email"
                                           name="email"
                                           formControlName="email" required>
                                    <mat-error *ngIf="agencyForm.controls['email'].pristine || agencyForm.controls['email'].errors?.email">Enter a correct email.</mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" floatLabel="always" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" class="p-16">
                                    <mat-label>Phone</mat-label>
                                    <input matInput placeholder="Agency Phone"
                                           type="text"
                                           name="phone"
                                           formControlName="phone">
                                </mat-form-field>

                                <mat-form-field appearance="outline" floatLabel="always" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" class="p-16">
                                    <mat-label>Address Line 1</mat-label>
                                    <input matInput placeholder="Agency Address"
                                           type="text"
                                           name="address_line_1"
                                           formControlName="address_line_1" required>
                                </mat-form-field>
                                <mat-form-field appearance="outline" floatLabel="always" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" class="p-16">
                                    <mat-label>Address Line 2</mat-label>
                                    <input matInput placeholder="Agency Address"
                                           type="text"
                                           name="address_line_2"
                                           formControlName="address_line_2">
                                </mat-form-field>

                                <mat-form-field appearance="outline" floatLabel="always" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" class="p-16">
                                    <mat-label>City</mat-label>
                                    <input matInput placeholder="Agency City"
                                           type="text"
                                           name="city"
                                           formControlName="city" required>
                                </mat-form-field>
                                <mat-form-field appearance="outline" floatLabel="always" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" class="p-16">
                                    <mat-label>State</mat-label>
                                    <input matInput placeholder="Agency State"
                                           type="text"
                                           name="state"
                                           formControlName="state" required>
                                </mat-form-field>
                                <mat-form-field appearance="outline" floatLabel="always" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" class="p-16">
                                    <mat-label>Country</mat-label>
                                    <input matInput placeholder="Agency Country"
                                           type="text"
                                           name="country"
                                           formControlName="country" required>
                                </mat-form-field>

                                <mat-form-field appearance="outline" floatLabel="always" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" class="p-16">
                                    <mat-label>Zip Code</mat-label>
                                    <input matInput placeholder="Agency Zip Code"
                                           type="text"
                                           name="zip_code"
                                           formControlName="zip_code" required>
                                </mat-form-field>

                                <mat-form-field appearance="outline" floatLabel="always" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100" class="p-16">
                                    <mat-label>Main Member</mat-label>
                                    <mat-select formControlName="user_id">
                                        <mat-option *ngFor="let member of agency.members" [value]="member.id">{{member.name}}</mat-option>
                                    </mat-select>
<!--                                    <mat-error *ngIf="agencyForm.controls['user_id'].pristine || agencyForm.controls['user_id'].errors?.required">You need to select a main member or create a new member in case the agency doesn't have any.</mat-error>-->
                                    <span matSuffix matTooltip="New member" [routerLink]="'/apps/social-media-management/members/new'"><mat-icon>person_add_alt_1</mat-icon></span>
                                </mat-form-field>
                            </div>

                        </mat-tab>

                    </mat-tab-group>

                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
