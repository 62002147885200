import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable, Subject} from "rxjs";
import {Network} from "../../../../models/network";
import {map, startWith} from "rxjs/operators";
import {takeUntil} from "rxjs/internal/operators";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {PinterestService} from "../pinterest.service";

@Component({
  selector: 'app-boards-selector',
  templateUrl: './boards-selector.component.html',
  styleUrls: ['./boards-selector.component.scss']
})
export class BoardsSelectorComponent implements OnInit {
    boardsInputControl: FormControl =  new FormControl('');
    filteredBoards: Observable<any[]>;
    inputLoader = false;
    @ViewChild('boardsInput') boardsInput: ElementRef<HTMLInputElement>;
    boards: Array<any> = [];
    board: any = null;
    @Input() multiple = false;
    @Input() preSelectedBoards: Array<any | string> = [];
    @Input() account: Network;
    @Output() boardsSelection: EventEmitter<any[]> = new EventEmitter<any[]>();
    selectedBoards: Array<any> = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private pinterestService: PinterestService
    ){}

    ngOnInit(): void {
        this.filteredBoards = this.boardsInputControl.valueChanges.pipe(
            startWith(null),
            map((board: string | null) => board ? this._filter(board) : this.boards.slice()));
        if (this.boards === null || this.boards.length === 0) {
            this.getBoards();
        }

        this.pinterestService.onBoardsPassedUpdated.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            boards => {
                this.boards = boards;
                this.boardsInputControl.setValue('');
            }
        );

        this.pinterestService.onAccountUpdated.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            account => {
                if (account !== null) {
                    this.account = account;
                    this.getBoards();
                }
            }
        );
    }

    private getBoards(): void {
        this.inputLoader = true;
        this.pinterestService.getBoards(this.account.network_id).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            response => {
                this.boards = response.items;
                this.setPreselectedBoards();
                this.inputLoader = false;
            },
            error => { this.inputLoader = false; console.log(error); }
        );
    }

    private _filter(value: string): any[] {
        const filterValue = (typeof value['name'] !== 'undefined') ? value['name'].toLowerCase() : value.toLowerCase();
        return this.boards.filter(
            board => board.name.toLowerCase().indexOf(filterValue) === 0
                && this.selectedBoards.findIndex(item => item.name === board.name) === -1
        );
    }

    selectedBoard(event: MatAutocompleteSelectedEvent): void {
        this.board = event.option.value;
        this.selectedBoards.push(event.option.value);
        this.boardsSelection.emit(this.selectedBoards);
        this.boardsInputControl.setValue('');
        this.boardsInput.nativeElement.value = '';
    }

    removeBoard(board: any): void {
        const index = this.selectedBoards.indexOf(board);
        if (index >= 0) {
            this.selectedBoards.splice(index, 1);
            this.boardsSelection.emit(this.selectedBoards);
        }
        this.boardsInput.nativeElement.value = '';
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.unsubscribe();
    }

    private setPreselectedBoards() {
        console.log('this.preSelectedBoards: ', this.preSelectedBoards);
        if (this.preSelectedBoards.length > 0) {
            if (typeof this.preSelectedBoards[0] === 'object') {
                // @ts-ignore
                this.selectedBoards = this.preSelectedBoards;
                this.board = this.preSelectedBoards[0];
            } else {
                // @ts-ignore
                this.selectedBoards = this.boards.filter(i => this.preSelectedBoards.indexOf(String(i.id)) > -1);
                this.board = this.boards.find(i => this.preSelectedBoards.indexOf(String(i.id)) > -1);
            }
        }
    }
}
