import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AgmCoreModule } from '@agm/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { SocialMediaManagementTeamsComponent } from 'app/main/apps/social-media-management/teams/teams.component';
import { SocialMediaManagementTeamsService } from 'app/main/apps/social-media-management/teams/teams.service';
import { SocialMediaManagementProductComponent } from 'app/main/apps/social-media-management/product/product.component';
import { SocialMediaManagementProductService } from 'app/main/apps/social-media-management/product/product.service';
import { SocialMediaManagementOrdersComponent } from 'app/main/apps/social-media-management/orders/orders.component';
import { SocialMediaManagementOrdersService } from 'app/main/apps/social-media-management/orders/orders.service';
import { SocialMediaManagementOrderComponent } from 'app/main/apps/social-media-management/order/order.component';
import { SocialMediaManagementOrderService } from 'app/main/apps/social-media-management/order/order.service';
import {SocialMediaManagementNetworksComponent} from './networks/networks.component';
import {SocialMediaManagementNetworksService} from './networks/networks.service';
import {SocialMediaManagementTeamComponent} from './team/team.component';
import {SocialMediaManagementTeamService} from './team/team.service';
import {SocialMediaManagementNetworkComponent} from './network/network.component';
import {SocialMediaManagementNetworkService} from './network/network.service';
import {SocialMediaManagementMembersComponent} from './members/members.component';
import {SocialMediaManagementMembersService} from './members/members.service';
import {SocialMediaManagementMemberComponent} from './member/member.component';
import {SocialMediaManagementMemberService} from './member/member.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatToolbarModule} from '@angular/material/toolbar';
import {ConnectNetworkComponent, SelectNetworksToAddComponent} from './connect-network/connect-network.component';
import {MatDialogModule} from '@angular/material/dialog';
import {CommonAuthService} from '../../../auth/common-auth.service';
import {
    FacebookLoginProvider,
    GoogleLoginProvider,
    SocialAuthService,
    SocialAuthServiceConfig,
} from 'angularx-social-login';
import {SocialMediaService} from './social-media.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {RoleGuardGuard} from '../../../auth/role-guard.guard';
import { AgenciesComponent } from './agencies/agencies.component';
import { AgencyComponent } from './agency/agency.component';
import {AgenciesService} from "./agencies/agencies.service";
import {AgencyService} from "./agency/agency.service";
import {MatTooltipModule} from "@angular/material/tooltip";
import {LinkedinLoginProvider} from "./connect-network/LinkedinLoginProvider";
import {TwitterLoginProvider} from "./connect-network/TwitterLoginProvider";
import {environment} from "../../../../environments/environment";
import {CustomFacebookLoginProvider} from "./connect-network/CustomFacebookLoginProvider";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {PinterestLoginProvider} from "./connect-network/PinterestLoginProvider";
import { ActionLogsComponent } from './action-logs/action-logs.component';
import {MatDatepickerModule} from "@angular/material/datepicker";


const routes: Routes = [
    {
        path     : 'teams',
        component: SocialMediaManagementTeamsComponent,
        resolve  : {
            data: SocialMediaManagementTeamsService
        }
    },
    {
        path     : 'teams/:id',
        component: SocialMediaManagementTeamComponent,
        data: {roles: ['ADMIN', 'MANAGER']},
        canActivate: [RoleGuardGuard],
        resolve  : {
            data: SocialMediaManagementTeamService
        }
    },
    {
        path     : 'teams/:id/:handle',
        component: SocialMediaManagementTeamComponent,
        data: {roles: ['ADMIN', 'MANAGER']},
        canActivate: [RoleGuardGuard],
        resolve  : {
            data: SocialMediaManagementTeamService
        }
    },
    {
        path     : 'networks',
        component: SocialMediaManagementNetworksComponent,
        resolve  : {
            data: SocialMediaManagementNetworksService
        }
    },
    {
        path     : 'networks/:id',
        component: SocialMediaManagementNetworkComponent,
        resolve  : {
            data: SocialMediaManagementNetworkService
        }
    },
    {
        path     : 'networks/:id/:handle',
        component: SocialMediaManagementNetworkComponent,
        resolve  : {
            data: SocialMediaManagementNetworkService
        }
    },
    {
        path     : 'members',
        component: SocialMediaManagementMembersComponent,
        resolve  : {
            data: SocialMediaManagementMembersService
        }
    },
    {
        path     : 'members/:id',
        component: SocialMediaManagementMemberComponent,
        resolve  : {
            data: SocialMediaManagementMemberService
        }
    },
    {
        path     : 'members/:id/:handle',
        component: SocialMediaManagementMemberComponent,
        resolve  : {
            data: SocialMediaManagementMemberService
        }
    },
    {
        path     : 'agencies',
        component: AgenciesComponent,
        data: {roles: ['ADMIN']},
        canActivate: [RoleGuardGuard],
        resolve  : {
            data: AgenciesService
        }
    },
    {
        path     : 'agencies/:id',
        component: AgencyComponent,
        data: {roles: ['ADMIN']},
        canActivate: [RoleGuardGuard],
        resolve  : {
            data: AgencyService
        }
    },
    {
        path     : 'orders',
        component: SocialMediaManagementOrdersComponent,
        resolve  : {
            data: SocialMediaManagementOrdersService
        }
    },
    {
        path     : 'orders/:id',
        component: SocialMediaManagementOrderComponent,
        resolve  : {
            data: SocialMediaManagementOrderService
        }
    }
];

@NgModule({
    declarations: [
        SocialMediaManagementTeamsComponent,
        SocialMediaManagementNetworksComponent,
        SocialMediaManagementProductComponent,
        SocialMediaManagementOrdersComponent,
        SocialMediaManagementOrderComponent,
        SocialMediaManagementTeamComponent,
        SocialMediaManagementNetworkComponent,
        SocialMediaManagementMembersComponent,
        SocialMediaManagementMemberComponent,
        ConnectNetworkComponent,
        AgenciesComponent,
        AgencyComponent,
        SelectNetworksToAddComponent,
        ActionLogsComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatToolbarModule,
        MatDialogModule,
        MatTabsModule,
        NgxChartsModule,
        MatProgressSpinnerModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatDatepickerModule
    ],
    providers   : [
        SocialMediaManagementTeamsService,
        SocialMediaManagementProductService,
        SocialMediaManagementOrdersService,
        SocialMediaManagementOrderService,
        SocialMediaManagementNetworksService,
        SocialMediaManagementTeamService,
        SocialMediaManagementNetworkService,
        SocialMediaManagementMembersService,
        SocialMediaManagementMemberService,
        AgencyService,
        AgenciesService,
        SocialAuthService,
        SocialMediaService,
        RoleGuardGuard,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '596893911175-meql8re5ebde6fqt2drp8g89ljgc279b.apps.googleusercontent.com'
                            // '855539413795-nc2t87j88shh3l4tuijer22n0o24qqps.apps.googleusercontent.com'
                        ),
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        // provider: new FacebookLoginProvider('296628491209737'),
                        provider: new FacebookLoginProvider(environment.facebookClientId),
                    },
                    // {
                    //     id: CustomFacebookLoginProvider.PROVIDER_ID,
                    //     // provider: new FacebookLoginProvider('296628491209737'),
                    //     provider: new CustomFacebookLoginProvider(environment.facebookClientId),
                    // },
                    {
                        id: LinkedinLoginProvider.PROVIDER_ID,
                        provider: new LinkedinLoginProvider(environment.linkedinClientId),
                    },
                    {
                        id: TwitterLoginProvider.PROVIDER_ID,
                        provider: new TwitterLoginProvider('28433330'),
                    },
                    {
                        id: PinterestLoginProvider.PROVIDER_ID,
                        provider: new PinterestLoginProvider(environment.pinterestClientId),
                    },
                ],
            } as SocialAuthServiceConfig,
        }
    ]
})
export class SocialMediaManagementModule
{
}
