<div id="member" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/apps/social-media-management/members'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div class="member-image mr-8 mr-sm-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    <mat-icon *ngIf="!member.profile_photo">perm_identity</mat-icon>
                    <img *ngIf="member.profile_photo" src="{{member.profile_photo}}">
                </div>

                <div fxLayout="column" fxLayoutAlign="start start"
                     [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="pageType ==='edit'">
                        {{member.name}}
                    </div>
                    <div class="h2" *ngIf="pageType ==='new'">
                        New Member
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Member Detail</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button
                    class="save-member-button"
                    [disabled]="memberForm.invalid"
                    *ngIf="pageType ==='new'" (click)="addMember()">
                <span>ADD</span>
            </button>

            <button mat-raised-button
                    class="save-member-button"
                    [disabled]="memberForm.invalid"
                    *ngIf="pageType ==='edit'" (click)="saveMember()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="memberForm" [formGroup]="memberForm" class="member w-100-p" fxLayout="column" fxFlex>

                    <mat-tab-group>

                        <mat-tab label="Basic Info">

                            <div class="tab-content p-24" fusePerfectScrollbar>

                                <mat-form-field disabled="true" appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Member Name</mat-label>
                                    <input matInput placeholder="Member Name"
                                           name="name"
                                           formControlName="name" disabled required>
                                </mat-form-field>

                                <div class="password-box w-100-p" style="display: flex;flex-direction: row">
                                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p pr-4">
                                        <mat-label>Email</mat-label>
                                        <input matInput placeholder="Member Email"
                                               type="email"
                                               name="email"
                                               formControlName="email" required>
                                        <mat-error *ngIf="memberForm.controls['email'].pristine || memberForm.controls['email'].errors?.email">Enter a correct email.</mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline"  floatLabel="always" class="w-100-p pl-4">
                                        <mat-label>Role</mat-label>
                                        <mat-select formControlName="role" name="role" required>
                                            <ng-container *ngFor="let role of roles">
                                                <mat-option *ngIf="this.userAuth.userLogged === 'ADMIN' || role !== 'ADMIN'" [value]="role">
                                                    {{role}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline"  floatLabel="always" class="w-100-p pl-4">
                                        <mat-label>Agency</mat-label>
                                        <mat-select formControlName="agency_id" name="agency_id" required>
                                            <mat-option *ngFor="let agency of agencies" [value]="agency.id">
                                                {{agency.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="password-box w-100-p" style="display: flex;flex-direction: row">
                                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p pr-4">
                                        <mat-label>Password</mat-label>
                                        <input matInput placeholder="Password"
                                               type="password"
                                               name="password"
                                               formControlName="password" [required]="pageType !== 'edit'">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p pl-4">
                                        <mat-label>Confirm Password</mat-label>
                                        <input matInput placeholder="Confirm Password"
                                               type="password"
                                               name="confirmPassword"
                                               formControlName="confirmPassword" [required]="pageType !== 'edit'" >
                                        <mat-error *ngIf="memberForm.controls['confirmPassword'].pristine || memberForm.controls['confirmPassword'].errors?.MatchPassword">Password does not match</mat-error>
                                        <mat-error *ngIf="memberForm.controls['confirmPassword'].pristine || memberForm.controls['confirmPassword'].errors?.required">Confirm Password is required.</mat-error>
                                    </mat-form-field>
                                </div>

                                <a (click)="selectAllNetworks()" mat-stroked-button>
                                    <mat-icon>check_box</mat-icon><span>SELECT ALL NETWORKS</span>
                                </a>
                                <a (click)="unSelectAllNetworks()" mat-stroked-button>
                                    <mat-icon>check_box_outline_blank</mat-icon><span>UNSELECT ALL NETWORKS</span>
                                </a>
                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Social Networks</mat-label>
                                    <mat-chip-list #networkList formControlName="networks">
                                        <mat-chip style="line-height: 16px;" *ngFor="let network of selectedNetworks"
                                                  [selectable]="true"
                                                  [value]="network.id"
                                                  [removable]="true" (removed)="removeNetwork(network)">
                                            <img *ngIf="network.picture" src="{{network.picture}}" width="35px">
                                            <i *ngIf="network.network_type.toLowerCase() === 'facebook'" class="fab fa-facebook" style="font-size: 17px;"></i>
                                            <i *ngIf="network.network_type.toLowerCase() === 'twitter'" class="fab fa-x-twitter" style="font-size: 17px;"></i>
                                            <i *ngIf="network.network_type.toLowerCase() === 'linkedin'" class="fab fa-linkedin-in" style="font-size: 17px;"></i>
                                            <i *ngIf="network.network_type.toLowerCase() === 'instagram'" class="fab fa-instagram" style="font-size: 17px;"></i>
                                            {{network.name}}
                                            <i style="font-size: 18px;" matChipRemove class="far fa-times-circle"></i>
                                        </mat-chip>
                                        <input  style="height: 100px" #networkInput [matChipInputFor]="networkList"
                                               [formControl]="networkCtrl"
                                               [matAutocomplete]="autoNet"
                                               [matChipInputAddOnBlur]="false"
                                               (matChipInputTokenEnd)="addNetwork($event)" placeholder="Type a network's name..." />
                                    </mat-chip-list>
                                    <mat-autocomplete #autoNet="matAutocomplete" (optionSelected)="selectedNetwork($event)">
                                        <mat-option *ngFor="let network of filteredNetworks | async" [value]="network">
                                            <img *ngIf="network.picture" src="{{network.picture}}" width="35px">
                                            <i *ngIf="network.network_type.toLowerCase() === 'facebook'" class="fab fa-facebook" style="font-size: 17px;"></i>
                                            <i *ngIf="network.network_type.toLowerCase() === 'twitter'" class="fab fa-x-twitter" style="font-size: 17px;"></i>
                                            <i *ngIf="network.network_type.toLowerCase() === 'linkedin'" class="fab fa-linkedin-in" style="font-size: 17px;"></i>
                                            <i *ngIf="network.network_type.toLowerCase() === 'instagram'" class="fab fa-instagram" style="font-size: 17px;"></i>
                                            {{network.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-spinner diameter="20" class="input-loader" *ngIf="inputLoader"></mat-spinner>
                                </mat-form-field>

                                <a (click)="selectAllTeams()" mat-stroked-button>
                                    <mat-icon>check_box</mat-icon><span>SELECT ALL TEAMS</span>
                                </a>
                                <a (click)="unSelectAllTeas()" mat-stroked-button>
                                    <mat-icon>check_box_outline_blank</mat-icon><span>UNSELECT ALL TEAMS</span>
                                </a>
                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>Teams</mat-label>
                                    <mat-chip-list #teamList formControlName="teams">
                                        <mat-chip *ngFor="let team of selectedTeams"
                                                  (removed)="removeTeam(team)"
                                                  [selectable]="selectable"
                                                  [removable]="removable"
                                        >
                                            {{team.name}}
                                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                        </mat-chip>
                                        <input  style="height: 100px" #teamInput [matChipInputFor]="teamList"
                                               (matChipInputTokenEnd)="addTeam($event)"
                                               [matAutocomplete]="autoTeam"
                                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                               [matChipInputAddOnBlur]="addOnBlur"
                                               [formControl]="teamCtrl"
                                               placeholder="Type a team's name..."
                                        />
                                    </mat-chip-list>
                                    <mat-autocomplete #autoTeam="matAutocomplete" (optionSelected)="selectedTeam($event)">
                                        <mat-option *ngFor="let team of filteredTeams | async" [value]="team">
                                            {{ team.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                            </div>

                        </mat-tab>

                        <mat-tab label="Action Logs">
                            <app-action-logs [userId]="member.id" style="width: 100%;"></app-action-logs>
                        </mat-tab>

                        <!--<mat-tab label="Social Networks">-->

                            <!--<div class="tab-content p-24" fusePerfectScrollbar>-->
                                <!--<div fxLayout="row wrap" fxLayoutAlign="start start">-->
                                    <!---->
                                <!--</div>-->
                            <!--</div>-->

                        <!--</mat-tab>-->

                        <!--<mat-tab label="Teams">-->

                            <!--<div class="tab-content p-24" fusePerfectScrollbar>-->
                                <!--<div fxLayout="row wrap" fxLayoutAlign="start start">-->
                                    <!---->
                                <!--</div>-->
                            <!--</div>-->

                        <!--</mat-tab>-->

                        <!--<mat-tab label="Pricing">-->

                            <!--<div class="tab-content p-24" fusePerfectScrollbar>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>Tax Excluded Price</mat-label>-->
                                    <!--<input matInput placeholder="Tax Excluded Price"-->
                                           <!--name="priceTaxExcl"-->
                                           <!--formControlName="priceTaxExcl"-->
                                           <!--type="number">-->
                                    <!--<span matPrefix>$&nbsp;</span>-->
                                <!--</mat-form-field>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>Tax Included Price</mat-label>-->
                                    <!--<input matInput placeholder="Tax Included Price"-->
                                           <!--name="priceTaxIncl"-->
                                           <!--formControlName="priceTaxIncl"-->
                                           <!--type="number">-->
                                    <!--<span matPrefix>$&nbsp;</span>-->
                                <!--</mat-form-field>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>Tax Rate</mat-label>-->
                                    <!--<input matInput placeholder="Tax Rate"-->
                                           <!--name="taxRate"-->
                                           <!--formControlName="taxRate"-->
                                           <!--type="number">-->
                                    <!--<span matPrefix>&#37;&nbsp;</span>-->
                                <!--</mat-form-field>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>Compared Price</mat-label>-->
                                    <!--<input matInput placeholder="Compared Price"-->
                                           <!--name="comparedPrice"-->
                                           <!--formControlName="comparedPrice"-->
                                           <!--type="number">-->
                                    <!--<span matPrefix>$&nbsp;</span>-->
                                    <!--<mat-hint align="start">Add a compare price to show next to the real price-->
                                    <!--</mat-hint>-->
                                <!--</mat-form-field>-->

                            <!--</div>-->
                        <!--</mat-tab>-->

                        <!--<mat-tab label="Inventory">-->

                            <!--<div class="tab-content p-24" fusePerfectScrollbar>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>SKU</mat-label>-->
                                    <!--<input matInput placeholder="SKU"-->
                                           <!--name="sku"-->
                                           <!--formControlName="sku">-->
                                <!--</mat-form-field>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>Quantity</mat-label>-->
                                    <!--<input matInput placeholder="Quantity"-->
                                           <!--name="quantity"-->
                                           <!--formControlName="quantity"-->
                                           <!--type="number">-->
                                <!--</mat-form-field>-->

                            <!--</div>-->

                        <!--</mat-tab>-->

                        <!--<mat-tab label="Shipping">-->

                            <!--<div class="tab-content p-24" fusePerfectScrollbar fxLayout="column">-->

                                <!--<div fxLayout="row" fxLayoutAlign="space-between">-->

                                    <!--<mat-form-field appearance="outline" floatLabel="always" fxFlex="30">-->
                                        <!--<mat-label>Width</mat-label>-->
                                        <!--<input matInput placeholder="Width"-->
                                               <!--name="Width"-->
                                               <!--formControlName="width">-->
                                        <!--<span matSuffix>cm</span>-->
                                    <!--</mat-form-field>-->

                                    <!--<mat-form-field appearance="outline" floatLabel="always" fxFlex="30">-->
                                        <!--<mat-label>Height</mat-label>-->
                                        <!--<input matInput placeholder="Height"-->
                                               <!--name="Height"-->
                                               <!--formControlName="height">-->
                                        <!--<span matSuffix>cm</span>-->
                                    <!--</mat-form-field>-->

                                    <!--<mat-form-field appearance="outline" floatLabel="always" fxFlex="30">-->
                                        <!--<mat-label>Depth</mat-label>-->
                                        <!--<input matInput placeholder="Depth"-->
                                               <!--name="Depth"-->
                                               <!--formControlName="depth">-->
                                        <!--<span matSuffix>cm</span>-->
                                    <!--</mat-form-field>-->

                                <!--</div>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>Weight</mat-label>-->
                                    <!--<input matInput placeholder="Weight"-->
                                           <!--name="Weight"-->
                                           <!--formControlName="weight">-->
                                    <!--<span matSuffix>kg</span>-->
                                <!--</mat-form-field>-->

                                <!--<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">-->
                                    <!--<mat-label>Extra Shipping Fee</mat-label>-->
                                    <!--<input matInput placeholder="Extra Shipping Fee"-->
                                           <!--name="extraShippingFee"-->
                                           <!--formControlName="extraShippingFee"-->
                                           <!--type="number">-->
                                    <!--<span matPrefix>$&nbsp;</span>-->
                                <!--</mat-form-field>-->

                            <!--</div>-->

                        <!--</mat-tab>-->

                    </mat-tab-group>

                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
